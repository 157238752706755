<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_assign',
            title: titlePopup,
            type: 'add',
            style: 'width: 35% !important; min-width:500px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 90%; min-height: 8vh;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_popup_assign" :key="reloadForm">
                <div class="gh-content-global" style="padding-top: unset; margin-top: -5px;">
                    <div class="gh-row-content">
                        <GhMultipleSearch
                            :datalabel="{
                                text: $t('leader') + '*',
                                style: 'min-width: 150px; width: 150px; text-align: right',
                                class: 'T15_b',
                                id: 'label_assign_leader'
                            }"
                            :datainput="{
                                required: true,
                                id: 'assign_leader_user',
                                name: 'assign[leader][name]',
                                nameS:'assign[leader][surname]',
                                nameId:'assign[leader][id]',
                                style: 'width: 166px',
                                value: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.leader !== undefined ? getterCompanyAssociatedByItinerary.leader.name : '',
                                valueId: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.leader !== undefined ? getterCompanyAssociatedByItinerary.leader.id : '',
                                valueS: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.leader !== undefined ? getterCompanyAssociatedByItinerary.leader.surname : '',
                                placeholder: $t('name'),
                                placeholderS: $t('surnames'),
                                result_1: 'first_name',
                                result_2: 'last_name',
                                result_id: 'id',
                                href: 'getLeaderUserSearch'
                            }"
                        />
                    </div>
                    <div class="gh-row-content">
                        <GhCalendar
                            :datalabel="{
                                id: 'label_assign_start_date',
                                text: $t('start_date'),
                                style: 'text-align: right; padding-top: 2px; width: 150px; min-width: 150px; text-align: right;'
                            }"
                            :datainput="{
                                id: 'input_assign_start_date',
                                name: 'assign[start_date]',
                                required: true,
                                value: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.start_date !== undefined && getterCompanyAssociatedByItinerary.start_date !== null ? getterCompanyAssociatedByItinerary.start_date : ''
                            }"
                        />
                        <GhCalendar
                            :datalabel="{
                                id: 'label_assign_end_date',
                                text: $t('end_date'),
                                style: 'text-align: right; padding-top: 2px; width: 90px; min-width: 90px; text-align: right;'
                            }"
                            :datainput="{
                                id: 'input_assign_end_date',
                                name: 'assign[end_date]',
                                required: true,
                                value: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.end_date !== undefined && getterCompanyAssociatedByItinerary.start_date !== null ? getterCompanyAssociatedByItinerary.end_date : ''
                            }"
                        />
                    </div>
                    <div class="gh-row-content">
                        <GhSearchWindow
                            :dataBlock="{
                                label: {
                                    style: 'margin-right: 10px; text-align: right; width: 150px; min-width: 150px;'
                                },
                                ResultSearch: 'result_search',
                                nameStore: 'getFeeds',
                                imagelocation: require('../../../../../assets/gh_new_dessign/floating.svg'),
                                id: 'tutor',
                                text: $t('tutorships'),
                                textlocation: $t('add_user'),
                                actionresult: 'loadResultSearchByType',
                                nameResult: 'assign[tutorships]',
                                supportPermision: true,
                            }"
                            :mainSelector="{
                                name: 'TutorshipSearch',
                                formId: 'user',
                                actionsearch: 'getSearchUsersLeader'
                            }"
                            :tableResolt="{
                                header: header,
                                data: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.tutorships !== undefined ? getterCompanyAssociatedByItinerary.tutorships : []
                            }"
                            :tableSelected="{
                                headersummary: headersummary,
                                selected: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.tutorships !== undefined ? getterCompanyAssociatedByItinerary.tutorships : []
                            }"
                        />
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15_b" style="width: 150px; min-width: 150px; text-align: right;">{{$t('support')}}</label>
                        <GhChecks
                            :datalabel="{text: '',style:'',class:'',id:''}"
                            :datainput="{
                                selected: getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.support !== undefined ? getterCompanyAssociatedByItinerary.support === 1 : '',
                                type: 'checkbox',
                                id: 'input_assign_support',
                                value: 1,
                                name: 'assign[support]'
                            }"
                        />
                    </div>
                    <div class="gh-row-content">
                        <GhDataSelector
                            :datablock="{
                                id: 'weighting_group',
                                class: 'T15_b',
                                subtitle: $t('select_weighting_group') + ':',
                                text: $t('weighting_group'),
                                label: $t('weighting_group'),
                                type: 'checkbox',
                                columns: 3,
                                required: false,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_weighting_group',
                                name: 'assign[company][weighting_group][]'
                            }"
                            :feed="getterWeightingGroupItineraryCompany !== undefined ? getterWeightingGroupItineraryCompany : []"
                            :selected_input="getterCompanyAssociatedByItinerary && getterCompanyAssociatedByItinerary.feed_weighting_groups !== undefined ? getterCompanyAssociatedByItinerary.feed_weighting_groups : []"
                        />
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_accept_popup_order',
                    text: $t('accept')
                }"
                @click="acceptAssign()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_accept_popup_order',
                    text: $t('close')
                }"
                @click="closeAndClean()"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: 'popup_assign',
        props: ['titlePopup'],
        components: {
            GhDataSelector,
            GhPopUp,
            GhMultipleSearch,
            GhCalendar,
            GhSearchWindow,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['ItineraryAssign']),
            ...mapGetters(['getterCompanyAssociatedByItinerary','getterWeightingGroupItineraryCompany'])
        },
        data(){
            return {
                reloadForm: 0,
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'max-width: 16px; min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'name', sorting: true,typeSort: 'string', style: 'max-width: 100px; min-width: 100px; width: 25%;'},
                    {text: this.$t('surname'), field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 100px; min-width: 100px; width: 25%;'},
                    {text: this.$t('assigned_itineraries'), field: 'assigned_itineraries', sorting: true, typeSort: 'string', style: 'max-width: 100px; min-width: 100px; width: 50%;'},
                ],
                headersummary: {
                    0: 'name',
                    1: 'surname'
                },
            }
        },
        watch: {
            'getterCompanyAssociatedByItinerary'(){
                this.reloadForm++;
            },
            'getterWeightingGroupItineraryCompany'(){
                this.reloadForm++;
            }
        },
        methods: {
            async acceptAssign(){
                if(this.Global.checkRequired('form_popup_assign') === 'OK') {
                    const formData = new FormData(document.getElementById('form_popup_assign'));
                    await this.$store.dispatch('setCompanyAssociatedByItinerary', {
                        itinerary_uuid: this.$parent.actualId,
                        company_uuid: this.$parent.assignId,
                        formData: [...formData]
                    }, {root: true}).then((response) => {
                        if(response.status === 200){
                            this.closeAndRefresh();
                        }
                    });
                }
            },
            async closeAndClean(){
                this.reloadForm++;

                this.Global.closePopUp('popup_assign');
            },
            async closeAndRefresh(){
                this.reloadForm++;

                if(this.$parent.actualId !== undefined) {
                    await this.$store.dispatch('getOngoingPrograms', '', {root: true});
                    await this.$store.dispatch('getOngoingProgramsCompany', {
                        itinerary: this.$parent.actualId
                    }, {root: true});

                    await this.$store.dispatch('getProgramsAssociate', '', {root: true});
                    await this.$store.dispatch('getProgramsAssociateCompany', {
                        itinerary: this.$parent.actualId
                    }, {root: true});
                }

                this.Global.closePopUp('popup_assign');
            }
        }
    }
</script>


<style>
    #weighting_group_selector_label{
        width: 150px;
        text-align: right;
    }

    #assign_leader_user_hidden {
        position: absolute;
    }
</style>