import ConfigCertificate from "@/view/config/creation/certificates/template/certificate.vue";

const routes = [
    {
        path: '/config/creation/certificate',
        name: 'config_certificate',
        component: ConfigCertificate,
        props: {id: null, showMenu: true, otherTitle: 'certificate'},
        children: [
            {
                path: '/config/creation/certificate/:id',
                name: 'config_certificate_id',
                component: ConfigCertificate,
                props: {id: null, showMenu: true, otherTitle: 'certificate'},
            },
            {
                path: '/config/creation/certificate/:id/:view',
                name: 'config_certificate_id_view',
                component: ConfigCertificate,
                props: {id: null, view: true, showMenu: true, otherTitle: 'certificate'},
            }
        ]
    }
];

export default routes;