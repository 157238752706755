var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhLocation',{staticStyle:{"margin-top":"-5px"},attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t(_vm.$root._route.params.type.replace('-', '_')),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'platform_pay',
                title: _vm.$t(_vm.$root._route.params.type.replace('-', '_'))
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('div',{staticClass:"centered"},[_c('div',{staticClass:"element_block"},[_c('div',{staticClass:"payment_box"},[_c('div',{staticClass:"centered"},[_c('div',{staticClass:"T14 header_payment"},[_c('div',{staticStyle:{"width":"70%"}},[_c('GhChecks',{ref:"ghCheckAllElements",attrs:{"datalabel":{
                                                    text: _vm.$t('all_programs'),
                                                    id: 'label_recomended_itinerary_all_payment_products'
                                                },"datainput":{
                                                    type: 'checkbox',
                                                    id: 'id_recomended_itinerary_all_payment_products',
                                                    value: 'all',
                                                    name: 'all'
                                                }},on:{"click":function($event){return _vm.checkAllElements()}}})],1),_c('div',{staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t('total_pay'))+" ")])])]),_c('hr'),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"T14 header_payment"},[_c('div',{staticStyle:{"width":"70%","padding-top":"12px"}},[_c('div',[_c('form',{attrs:{"id":'form_payment'}},[_vm._l((_vm.PlatformPay.records_data),function(item,index){return _c('div',{key:index,staticStyle:{"padding-top":"5px","padding-bottom":"5px"}},[(item.payed === false || item.payed === undefined)?_c('GhChecks',{ref:"GhCheckElements",refInFor:true,attrs:{"itemVal":item,"datalabel":{
                                                                text: item.name,
                                                                id: 'label_recomended_itinerary_' + item.id + '_payment_products'
                                                            },"datainput":{
                                                                type: 'checkbox',
                                                                id: 'id_recomended_itinerary_' + item.id + '_payment_products',
                                                                value: item.id,
                                                                name: 'pay[]'
                                                            }},on:{"click":function($event){_vm.isCheckAllElements(); _vm.toPay(item)}}}):_vm._e()],1)}),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"40px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('method_pay') + ':'))]),_vm._l((_vm.getFeeds.feed_pay_methods),function(item,index){return _c('GhChecks',{key:'method_pay_' + index,attrs:{"datalabel":{
                                                                text: _vm.$t(item.slug),
                                                                style: 'width: 150px',
                                                                id: 'label_target_' + index
                                                            },"datainput":{
                                                                required: false,
                                                                type: 'radio',
                                                                id: 'value_target' + index,
                                                                value: item.id,
                                                                name: 'pay_method',
                                                                selected: index === 0 ? true : false,
                                                                disabled: false
                                                            }}})})],2)],2)])]),_c('div',{staticStyle:{"width":"30%","padding-top":"12px"}},[(_vm.checkToPay.length !== 0)?[_c('div',_vm._l((_vm.checkToPay),function(item,index){return _c('div',{key:index,staticStyle:{"padding-top":"5px","padding-bottom":"5px","width":"100%","display":"flex"}},[_c('div',{staticClass:"container-element-info T15"},[_c('div',{staticStyle:{"width":"70%"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-align-right",staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(item.price + ' €'))])])])}),0),_c('hr'),_c('div',{staticClass:"container-element-info"},[_c('div',[_vm._v(_vm._s(_vm.$t('total')))]),_c('div',{staticClass:"flex-align-right",staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(_vm.total + ' €'))])])]:_vm._e()],2)])])])])])]:[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","margin-top":"15px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(_vm.checkToPay.length !== 0)?[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_make_payment',
                        text: _vm.$t('make_payment')
                    }},on:{"click":_vm.doPayment}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_cancel',
                    text: _vm.$t('cancel')
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }