var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getterTypeExercise.subquestion !== undefined && _vm.getterTypeExercise.subquestion.view && _vm.haveData)?_c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"5px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('subquestions')))])]),_c('GhTableCommon',{key:'KEY63' + _vm.refreshQuestions,staticStyle:{"margin-bottom":"10px"},attrs:{"extratable":{
            id: 'subquestions'
        },"header":_vm.$root._route.params.parent_grouper_id !== undefined && _vm.$root._route.params.parent_id !== undefined ? _vm.viewHeader : _vm.header,"data":_vm.getterSubquestions},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.$root._route.params.type === undefined || _vm.$root._route.params.type === 'elaboration')?[(itemProp.enabled)?_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_config_exercises',
                        text: _vm.$t('versioning'),
                        icon: require('../../../../../../assets/gh_new_dessign/return-blue.svg')
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','ParentExerciseVersion', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'type_of_exercise': itemProp.type_of_exercise}, _vm.$t('versioning_exercise'), _vm.$t('versioning_delete_exercise'), 'delete')}}}):(!itemProp.enabled)?_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_config_exercises',
                        text: _vm.$t('modify'),
                        icon: require('../../../../../../assets/gh_new_dessign/edit.svg')
                    }},on:{"click":function($event){return _vm.modifyExercise(itemProp)}}}):_vm._e()]:_vm._e()]}},(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?{key:"footer",fn:function(){return [(_vm.$root._route.params.type === undefined || _vm.$root._route.params.type === 'elaboration')?[_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_exercises',
                            text: _vm.$t('add')
                        }},on:{"click":function($event){return _vm.searchExercises(_vm.getterSubquestions)}}}),_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_new_exercises',
                            text: _vm.$t('add_new')
                        }},on:{"click":function($event){return _vm.addNewExercise()}}})],1)]:_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }