var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('code'),
                style: 'margin-left: 35px; padding-right: 10px;',
                class: 'T15_b'
            },"datainput":{
                required: false,
                id: 'search_format_code',
                name: 'search_format[code]',
                style: 'width: 25%',
                value: '',
                type: 'text'
            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('name'),
                style: 'margin-left: 35px; padding-right: 10px;',
                class: 'T15_b'
            },"datainput":{
                required: false,
                id: 'search_format_name',
                name: 'search_format[name]',
                style: 'width: 25%',
                value: '',
                type: 'text'
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                type: 'checkbox',
                id: 'search_format_topic',
                label: _vm.$t('topic'),
                title: _vm.$t('topic'),
                text: _vm.$t('select_topic') + ':',
                name: 'search_format[topic][]',
                id_value: 'value',
                name_label: 'text',
                child_key: 'children',
                required: true,
                checkparentdata: true,
                style: '',
                label_style: 'margin-left: 2px;',
                popupStyle: 'min-width: 900px; width: 55%;'
            },"selected_input":[],"items":_vm.getFeeds.feed_topic}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }