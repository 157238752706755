<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'ccaa', title: $t('ccaa')}">
            <template v-slot:content>
                <form id="form_ccaa" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="(!$root._route.params.view && $root._route.params.id === undefined) || (!$root._route.params.view && ConfigCCAA.data !== undefined && ConfigCCAA.data.is_admin)">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'ccaa_code',
                                        name: 'ccaa[code]',
                                        style: 'width: 200px',
                                        value: ConfigCCAA.data.code,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigCCAA.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="(!$root._route.params.view && $root._route.params.id === undefined) || (!$root._route.params.view && ConfigCCAA.data !== undefined && ConfigCCAA.data.is_admin)">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'ccaa_name',
                                        name: 'ccaa[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigCCAA.data.name,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigCCAA.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 164px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                    }"
                                    :datainput="{
                                        id: 'ccaa_description',
                                        name: 'ccaa[description]',
                                        value: ConfigCCAA.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigCCAA.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content no-min-width">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'ccaa_country',
                                        class: 'T15_b',
                                        subtitle: $t('select_country') + ':',
                                        text: $t('country') + '*',
                                        label: $t('country'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'value_ccaa_country',
                                        name: 'ccaa[country]'
                                    }"
                                    :feed="getFeeds.feed_country"
                                    :selected_input="ConfigCCAA.data.country !== undefined ? ConfigCCAA.data.country : []"
                                    @accept="searchProvince"
                                    ref="refCountry"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(ConfigCCAA.data.country).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'ccaa_province',
                                        class: 'T15_b',
                                        subtitle: $t('select_province') + ':',
                                        text: $t('province') + '*',
                                        label: $t('province'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :datainput="{
                                        id: 'value_ccaa_province',
                                        name: 'ccaa[province]'
                                    }"
                                    :feed="getterProvinceByCountry !== undefined ? getterProvinceByCountry : []"
                                    :selected_input="ConfigCCAA.data.province !== undefined ? ConfigCCAA.data.province : []"
                                    ref="refProvince"
                                    :key="'refresh_province_' + refreshProvince"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('province') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(ConfigCCAA.data.province).join()}}</label>
                            </template>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitCCAA"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: "ccaa_template",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['ConfigCCAA', 'getFeeds']),
            ...mapGetters(['getterProvinceByCountry'])
        },
        data(){
            return {
                refreshProvince: 0,
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCCAAData', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        watch: {
            'getterProvinceByCountry'(){
                this.refreshProvince++;
            },
        },
        methods: {
            async searchProvince(){
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    await this.$store.dispatch('getProvinceByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            async submitCCAA(){
                if(this.Global.checkRequired('form_ccaa') === 'OK') {
                    const formData = new FormData(document.getElementById('form_ccaa'));
                    this.$store.dispatch('setCCAAData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #ccaa_country_selector_label, #ccaa_province_selector_label{
        width: 150px !important;
        text-align: right;
    }

    .no-min-width > div {
        min-width: unset !important;
    }
</style>