<template>
    <div v-if="haveData">
        <input type="hidden" name="search_supports[type]" :value="$root._route.params.idblock">
        <div class="gh-row-content">
            <GhInputTextField
                :datalabel="{
                    text: $t('name_title'),
                    class: 'T15_b',
                    style: 'margin-left: 35px;'
                }"
                :datainput="{
                    id: 'search_supports_name_title',
                    name:'search_supports[name_title]',
                    style: 'width: 673px;',
                    type: 'text'
                }"
            />
        </div>
        <div class="gh-row-content references">
            <GhTreeSelectorBlock
                :dataBlock="{
                    type: 'checkbox',
                    id: 'search_supports_topic',
                    label: $t('topic'),
                    title: $t('topic'),
                    text: $t('select_topic') + ':',
                    name: 'search_supports[topic][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: true,
                    checkparentdata: true,
                    style: '',
                    label_style: 'margin-left: 35px;',
                    popupStyle: 'min-width: 900px; width: 55%;'
                }"
                :selected_input="[]"
                :items="getFeeds.feed_topic"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib'

    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "support_search",
        components:{
            GhTreeSelectorBlock,
            GhInputTextField
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
