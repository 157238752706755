<template>
    <span>
        <GhButton
            :class="block.class"
            v-bind="$attrs"
            :datainput="{
                id: 'btn_upload_attach_' + block.id,
                text: $t(block.text),
                style: 'display: unset !important'
            }"
            @click="loadInputClick('input_upload_attach_' + block.id)"
        />
        <input @change="uploadDocument($event)" :accept="datainput.accept" :id="'input_upload_attach_' + block.id" type="file" v-bind="$attrs" v-on="$listeners" style="display: none"/>
        <span v-for="(item, index) in files" :key="'FILE' + index" style="display: contents; line-height: 2;">
            <span style="display: inline-block">
                <GhAction
                    class="color-light-blue T-subtitle-second underline-light-blue"
                    :dataction="{
                        id: 'input_remove_attach_' + block.id + index,
                        text: item.file_name,
                    }"
                    @click="downloadDocument(item.file_download)"
                />
                <template v-if="datainput.remove">
                    <GhAction
                        class="color-light-blue T-subtitle-second underline-light-blue"
                        style="margin-left: -10px; padding-right: 5px;"
                        :dataction="{
                            id: 'input_remove_attach_' + block.id + index,
                            text: $t(datainput.removeText === '' ? '' : datainput.removeText),
                            icon: datainput.removeSvg
                        }"
                        @click="$attrs.disabled ? '' : deleteDocument(index)"
                    />
                </template>
                <input type="hidden" :name="datainput.name + '['+ index + '][file_name]'" :value="item.file_name">
                <input type="hidden" :name="datainput.name + '['+ index + '][file_download]'" :value="item.file_download">
                <input type="hidden" :name="datainput.name + '['+ index + '][file_path]'" :value="item.file_path">
            </span>
        </span>
    </span>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'gh-ce-multi-upload',
        props: ['block', 'datainput', 'dataFiles'],
        components: {
            GhAction,
            GhButton
        },
        data(){
            return {
                files: this.dataFiles
            }
        },
        methods: {
            loadInputClick(id){
                document.getElementById(id).click();
            },
            uploadDocument(e) {
                var data_append = new FormData();
                data_append.append('mis_archivos', e.target.files[0]);

                this.$store.dispatch(this.datainput.dispatch, {formData: data_append}, {root: true}).then((response) => {
                    this.data = JSON.parse(JSON.stringify(response.data));
                    if (this.data.length != 0) {
                        this.files.push({
                            file_name: e.target.files[0].name,
                            file_download: response.data.url,
                            file_path: response.data.path
                        });

                        this.$emit('evidenceLoad');
                    }
                });
            },
            deleteDocument(key){
                delete this.files[key];

                this.files = this.files.filter(n => n);

                this.$emit('evidenceLoad');
            },
            downloadDocument(file){
                this.$store.dispatch('easyDownload', {url: file}, {root: true});
            }
        }
    }
</script>