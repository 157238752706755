var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhTableCommon',{attrs:{"extratable":{ id:'table_validate'},"data":_vm.TabTodo.list_to_validate,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',{staticClass:"T15_b",style:(itemProp.color_end_date !== '' ? 'color:' + itemProp.color_end_date : '')},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]}},{key:"actions",fn:function({itemProp}){return [(itemProp.show)?_c('GhAction',{attrs:{"dataction":{
                    id: 'view_to_validate_' + itemProp.id,
                    text: _vm.$t('view'),
                    icon: require('@/assets/gh_new_dessign/view.svg'),
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('initial_itinerary_window',{type:'initial',id:itemProp.agrupator_id}),'initial_questionary')}}}):_vm._e(),(itemProp.modify)?_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_to_validate_' + itemProp.id,
                    text: _vm.$t('modify'),
                    icon: require('@/assets/gh_new_dessign/edit.svg'),
                }},on:{"click":function($event){return _vm.modify(itemProp)}}}):_vm._e(),_c('router-link',{attrs:{"to":{name: 'initial_itinerary_window', params: {type: 'initial', id: itemProp.agrupator_id}}}},[(itemProp.realize)?_c('GhAction',{attrs:{"dataction":{
                        id: 'realize_todo_' + itemProp.id,
                        text: _vm.$t('realize'),
                        icon: require('@/assets/gh_new_dessign/edit.svg'),
                    }}}):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }