import ListCompanies from "@/view/perms/companies/list_companies.vue";
import Comapnies from "./company/route";

const route = [
    {
        path: '/perms_company',
        name: 'perms_company',
        component: ListCompanies,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Comapnies
]

export default routes;