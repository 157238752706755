<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'professional_category'
            }"
            :header="header"
            :data="ConfigProfessionalCategory.records_data.length === 0 ? [] : ConfigProfessionalCategory.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_professional_category_id_view', params: { id: itemProp.id, view: true}}">
                    <a class="table-cell_link T13"> {{itemProp[labelProp]}} </a>
                </router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="ConfigEvaluators.counters !== undefined && ConfigEvaluators.counters.action !== undefined ? ConfigEvaluators.counters.action.edit : false">
                    <router-link :to="{name: 'config_professional_category_id', params: {id: itemProp.id}}">
                        <GhAction
                            :dataction="{
                                id: 'edit_config_professional_category',
                                text: $t('modify'),
                                icon: require('../../../../assets/gh_new_dessign/edit.svg')
                            }"
                        />
                    </router-link>
                </template>
                <template v-if="ConfigEvaluators.counters !== undefined && ConfigEvaluators.counters.action !== undefined ? ConfigEvaluators.counters.action.delete : false">
                    <GhAction
                        :dataction="{
                            id: 'delete_config_professional_category',
                            text: $t('delete'),
                            icon: require('../../../../assets/gh_new_dessign/trash.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','ProfessionalCategory', itemProp.id,{'code': itemProp.code, 'name': itemProp.name, 'description': itemProp.description}, $t('delete_professional_category'), $t('preparing_delete_professional_category'), 'delete')"
                    />
                </template>
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'config_professional_category'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_config_professional_category',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from 'fe-gh-action-lib';
import GhButton from "fe-gh-button-lib";

import {mapState} from "vuex";

export default {
    name: "list_professional_category",
    components: {
        GhTableCommon,
        GhButton,
        GhAction
    },
    computed: {
        ...mapState(['ConfigProfessionalCategory', 'ConfigEvaluators'])
    },
    data(){
        return {
            haveData: false,
            header: [
                {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                {text: '', field: 'actions', sorting: false}
            ]
        }
    },
    async beforeMount(){
        const token = localStorage.getItem("token");
        if (token) {
            await this.$store.dispatch('getProfessionalCategoryList', '', {root: true});

            if(this.ConfigEvaluators.counters !== undefined && this.ConfigEvaluators.counters.action !== undefined && !this.ConfigEvaluators.counters.action.edit && !this.ConfigEvaluators.counters.action.delete) {
                delete this.header[3];

                this.header = this.header.filter(n => n);
            }

            this.haveData = true;
        }
    }
}
</script>