<template>
    <div class="gh-content-global" v-if="haveData">
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabUserFile.records_data.user.name}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surnames') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabUserFile.records_data.user.surnames}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('birthday_date') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabUserFile.records_data.user.birthday_date}}</label>
          <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('dni') + ':'}}</label>
          <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 100px;">{{TabUserFile.records_data.user.dni}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('mail') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabUserFile.records_data.user.mail}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabUserFile.records_data.user.tel}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('rol') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabUserFile.records_data.user.role}}</label>
        </div>
    </div>
    <div v-else-if="!haveData">
        <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "user_file",
        computed: {
            ...mapState(['TabUserFile']),
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getUserData', '', {root: true})
                this.haveData = true;
            }
        }
    }
</script>