var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"12px"}},[(_vm.haveData)?[_vm._l((_vm.TabAction.sections),function(item,itemIndex){return _c('div',{key:itemIndex},[_c('GhDropSelector',{attrs:{"datablock":{
                    id: item.id,
                    title: _vm.$t(item.name),
                    num_results: item.count + ' ' + _vm.$t('results'),
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.list_companies,{tag:"component",attrs:{"id":item.id}})]},proxy:true}],null,true)})],1)}),(_vm.show.indicators)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'section_indicators',
                title: _vm.$t('indicators'),
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.list_action_indicators,{tag:"component"})]},proxy:true}],null,false,2879205570)}):_vm._e(),(_vm.ActionIndicators && _vm.ActionIndicators.haveResult)?_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'result_search_indicators',
                title:typeof _vm.ActionIndicators.resultTitle == 'number' ? _vm.ActionIndicators.resultTitle + ' ' + _vm.$t('results') : _vm.$t(_vm.ActionIndicators.resultTitle),
            }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{id: 'download_data_export_historical', class: 'T15_action', text: _vm.$t('export_results')}},on:{"click":function($event){return _vm.exportData()}}})]},proxy:true},{key:"content",fn:function(){return [_c(_vm.result_action_indicators,{tag:"component"})]},proxy:true}],null,false,2162711136)}):_vm._e(),(_vm.show.historic)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'actions_historical_search',
                title: _vm.$t('historical'),
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.list_action_historical,{tag:"component"})]},proxy:true}],null,false,3079066508)}):_vm._e(),(_vm.ActionHistorical && _vm.ActionHistorical.haveResult)?_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'result_search_historical',
                title:typeof _vm.ActionHistorical.resultTitle == 'number' ? _vm.ActionHistorical.resultTitle + ' ' + _vm.$t('results') : _vm.$t(_vm.ActionHistorical.resultTitle),
            }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{id: 'download_data_export_historical', class: 'T15_action', text: _vm.$t('export_results')}},on:{"click":function($event){return _vm.exportData()}}})]},proxy:true},{key:"content",fn:function(){return [_c(_vm.result_search,{tag:"component"})]},proxy:true}],null,false,2684414781)}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }