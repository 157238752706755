import SupervisedEvaluated from "@/view/supervised_evaluated/list_supervised_evaluated.vue";

const routes = [
    {
        path: '/supervised_evaluated',
        name: 'data_evaluated_tutored',
        component: SupervisedEvaluated,
        props: {showMenu: true}
    }
];

export default routes;