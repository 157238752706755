import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        results: [],
        refreshResults: false,
    },
    mutations: {
        loadListUsers(state, payload) {
            state.refreshResults = !state.refreshResults;
            state.results = payload;
        }
    },
    getters: {
        getterListPermissionEvaluators(state) {
            const refreshResults = state.refreshResults; // eslint-disable-line no-unused-vars
            return state.results;
        },
    },
    actions: {
        async getListPermissionEvaluators(state){
            let  requestLogin = API_URL + "perms/users-list/evaluators";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadListUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removePermsEvaluators(state, item){
            let request = API_URL + "perms/users/" + item.id;
            let formData = new FormData();

            await axios({
                method: "delete",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getPermsEvaluators');
                        await this.dispatch('getListPermissionEvaluators');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
