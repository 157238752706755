import ListAssign from "@/view/assign/list_assign.vue";
import ListItineraryAssign from "@/view/assign/itinerary/list_assign_itineraries.vue";
import TutorshipSearch from "@/view/assign/itinerary/templates/search/tutorship_search.vue";
import OngoingPrograms from "./ongoing_programs/route";
import ProgramsAssociate from "./programs_associate/route";
import Evaluators from "./evaluators/route";
import AssignationBase from "./assignation_base/route";
// import ItineraryAssign from "@/view/assign/itinerary/templates/itinerary_assign_itineraries.vue";

const route = [
    {
        path: '/assign',
        name: 'assignment',
        component: ListAssign,
        props: {showMenu: true},
    },
    {
        path: '/assign/itineraries',
        name: 'list_assign_itineraries',
        component: ListItineraryAssign,
        props: {id: null, showMenu: true,  otherTitle: 'itinerary_assignment'},
    },
    {
        path: '/tutorship/search',
        name: 'TutorshipSearch',
        component: TutorshipSearch,
        props: {id: null,showMenu: true, otherTitle: 'assignment'}
    }
];

const routes = [
    ...route,
    ...OngoingPrograms,
    ...ProgramsAssociate,
    ...Evaluators,
    ...AssignationBase
]

export default routes;
