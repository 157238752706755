var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"margin-top":"20px","display":"flex","justify-content":"end"},attrs:{"lass":"gh-content-global"}},[_c('GhAction',{attrs:{"dataction":{
                id: 'change_view',
                text: !_vm.stateView ? _vm.$t('see_more_detail') : _vm.$t('see_less_detail'),
            }},on:{"click":_vm.changeState}})],1),(!_vm.stateView)?_c('GhChartRadar',{key:_vm.haveData,attrs:{"dataBlock":{
            id: '',
            class: '',
            style: ''
        },"chartConfig":{
            margin: {
                top: 100,
                right: 100,
                bottom: 100,
                left: 100
            },
            color: ['#e82315', '#4158ef'],
            maxValue: 5,
            levels: 5,
            roundStrokes: false,
            labelFactor: 1.25,
            wrapWidth: 110,
            opacityArea: 0.2,
            dotRadius: 4,
            opacityCircles: 0.1,
            strokeWidth: 2,
            blur: 5
        },"chartData":_vm.ResultQuizz.chart_radio}}):_vm._e(),(_vm.stateView)?_c('GhChartTable',{attrs:{"extratable":{
            id: 'club'
        },"header":_vm.headerChart,"data":_vm.ResultQuizz.table_radio.dataChartTable,"footer":_vm.ResultQuizz.table_radio.footerChartTable},on:{"advice":_vm.advice}}):_vm._e(),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
           id: 'tips_popup',
           title: _vm.$t('tips_and_recommendations'),
           type: 'info',
           style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
           id: '',
           class: '',
           style: 'padding:2px;',
           text: '',
        },"footerConfiguration":{
           id: '',
           class: '',
           style: '',
           content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px","padding-bottom":"15px"}},_vm._l((_vm.showTips),function(item,index){return _c('div',{key:index,staticClass:"T15_b",staticStyle:{"padding-left":"60px"}},[_c('li',[_vm._v(_vm._s(item))])])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.Global.closePopUp('tips_popup');}}})]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"20px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_start',text: _vm.$t('want_start_excelence'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.toBuy();}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('back'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.back();}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }