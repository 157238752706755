var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refCompanyName",attrs:{"datalabel":{
                    text: _vm.$t('company_name') + '*',
                    style:'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_company_name',
                    name: 'request_collaboration[professional_relationship][company_name]',
                    style: 'width:60%',
                    value: _vm.persistance.company_name,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refSocialReason",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_social_reason',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_social_reason') + ':',
                    text: _vm.$t('social_reason') + '*',
                    label: _vm.$t('social_reason'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_social_reason',
                    name: 'request_collaboration[professional_relationship][social_reason]'
                },"feed":_vm.getFeeds.feed_social_reason,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refCif",attrs:{"datalabel":{
                    text: _vm.$t('cif') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_cif',
                    name: 'request_collaboration[professional_relationship][cif]',
                    style: 'width:20%; min-width: 100px;',
                    value: _vm.persistance.cif,
                    type: 'text'
                }}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refFullAdress",attrs:{"datalabel":{
                    text: _vm.$t('full_address') + '*',
                    style:'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_full_address',
                    name: 'request_collaboration[professional_relationship][full_address]',
                    style: 'width:60%',
                    value: _vm.persistance.full_address,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refLocality",attrs:{"datalabel":{
                    text: _vm.$t('locality') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_locality',
                    name: 'request_collaboration[professional_relationship][locality]',
                    style: 'width:60%',
                    value: _vm.persistance.locality,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refCp",attrs:{"datalabel":{
                    text: _vm.$t('cp') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_cp',
                    name: 'request_collaboration[professional_relationship][cp]',
                    style: 'width:20%; min-width: 100px;',
                    value: _vm.persistance.cp,
                    type: 'text'
                }}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_country',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_country') + ':',
                    text: _vm.$t('country'),
                    label: _vm.$t('country'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_country',
                    name: 'request_collaboration[professional_relationship][country]'
                },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.getFeeds.feed_ho_is_spain},on:{"accept":_vm.searchCommunity}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{key:'refresh_community_professional_' + _vm.refreshCommunity,ref:"refCommunity",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_community',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_community') + ':',
                    text: _vm.$t('community'),
                    label: _vm.$t('community'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_community',
                    name: 'request_collaboration[professional_relationship][community]'
                },"feed":_vm.getterCommunityByCountryProfessional !== undefined ? _vm.getterCommunityByCountryProfessional : [],"selected_input":[]},on:{"accept":_vm.searchProvince}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{key:'refresh_province_professional_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_province',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_province') + ':',
                    text: _vm.$t('province'),
                    label: _vm.$t('province'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_province',
                    name: 'request_collaboration[professional_relationship][province]'
                },"feed":_vm.getterProvinceByCommunityProfessional !== undefined ? _vm.getterProvinceByCommunityProfessional : [],"selected_input":[]}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refPosition",attrs:{"datalabel":{
                    text: _vm.$t('position') + '*',
                    style:'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_professional_relationship_position',
                    name: 'request_collaboration[professional_relationship][position]',
                    style: 'width:60%',
                    value: _vm.persistance.position,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"}),_c('div',{staticClass:"gh-row-column"})]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refActivitySector",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_professional_activity_sector',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_activity_sector') + ':',
                    text: _vm.$t('activity_sector') + '*',
                    label: _vm.$t('activity_sector'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_professional_activity_sector',
                    name: 'request_collaboration[professional_relationship][professional_activity_sector]'
                },"feed":_vm.getFeeds.feed_professional_activity_sector,"selected_input":[]}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refNumberEmployees",attrs:{"datablock":{
                    id: 'request_collaboration_professional_relationship_number_employees',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_number_employees') + ':',
                    text: _vm.$t('number_employees') + '*',
                    label: _vm.$t('number_employees'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"datainput":{
                    id: 'value_request_collaboration_professional_relationship_number_employees',
                    name: 'request_collaboration[professional_relationship][number_employees]'
                },"feed":_vm.getFeeds.feed_num_employers,"selected_input":[]}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }