var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'example_other_image',
                    class: '',
                    name_hidden: 'example[other_image]',
                    id_hidden: 'example_other_image',
                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'example_other_image',
                    style: 'margin-right: -15px'
                },"datainput":{
                    view: _vm.$root._route.params.view,
                    accept: '.png, .jpg, .jpeg',
                    text: _vm.$t(_vm.haveImage ? 'change_image' : 'attach_image'),
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: _vm.example.other_image.file_name,
                    file_path: _vm.example.other_image.file_path,
                    file_download: _vm.example.other_image.file_download,
                    required: false
                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                        text: _vm.$t('code') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'example_code',
                        name: 'example[code]',
                        style: 'width: 200px',
                        value: _vm.example.code,
                        type: 'text'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                        text: _vm.$t('name') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'example_title',
                        name: 'example[name]',
                        style: 'min-width: 625px; width: 40%',
                        value: _vm.example.name,
                        type: 'text'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefAuthor",attrs:{"datalabel":{
                        text: _vm.$t('author'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                                            required: false,
                                            id: 'example_author',
                                            name: 'example[author]',
                                            style: 'min-width: 625px; width: 40%',
                                            value: _vm.example.author,
                                            type: 'text'
                                        }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('author') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.author))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefEditor",attrs:{"datalabel":{
                        text: _vm.$t('editor'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'example_editor',
                        name: 'example[editor]',
                        style: 'min-width: 625px; width: 40%',
                        value: _vm.example.editor,
                        type: 'text'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('editor') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.editor))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{ref:"RefPublicationDate",attrs:{"datalabel":{
                        id: 'label_example_publication_date',
                        text: _vm.$t('publication_date'),
                        style: 'width:150px; min-width:150px; text-align: right;'
                    },"datainput":{
                        id: 'input_example_publication_date',
                        name: 'example[publication_date]',
                        value: _vm.example.publication_date
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('publication_date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.publication_date))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefReferences",attrs:{"datalabel":{
                        text: _vm.$t('references'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'example_references',
                        name: 'example[references]',
                        style: 'min-width: 625px; width: 40%',
                        value: _vm.example.references,
                        type: 'text'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('references') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.references))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefLink",attrs:{"datalabel":{
                        text: _vm.$t('link'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'example_link',
                        name: 'example[link]',
                        style: 'min-width: 625px; width: 40%',
                        value: _vm.example.link,
                        type: 'text'
                    }},on:{"keyup":function($event){return _vm.setLink()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('link') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.link))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                        type: 'radio',
                        id: 'label_topic',
                        label: _vm.$t('topic'),
                        title: _vm.$t('topic'),
                        text: _vm.$t('select_topic') + ':',
                        name: 'example[topic]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: true,
                        checkparentdata: true,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    },"selected_input":_vm.example.topic,"items":_vm.getFeeds.feed_topic}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('topic') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.topic[0].text))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                        text: _vm.$t('description'),
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                    },"datainput":{
                        id: 'example_description',
                        name: 'example[description]',
                        value: _vm.example.description,
                        styles: 'padding-top: 2px;'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.example.description))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('document') + ':'))]),_c('div',[_c('GhEasyUpload',{attrs:{"extrablock":{
                        id: 'example',
                        class: '',
                        name_hidden: 'example[document]',
                        id_hidden: 'example',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    },"datalabel":{
                        id: 'label_import_block',
                        style: 'margin-right: -15px'
                    },"datainput":{
                        view: _vm.$root._route.params.view,
                        accept: '.pdf, .png, .jpg, .jpeg',
                        text: _vm.$t('attach') + ' (PDF/Img)',
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: _vm.example.attached.file_name,
                        file_path: _vm.example.attached.file_path,
                        file_download: _vm.example.attached.file_download,
                        required: _vm.example.link !== '' ? false : true
                    }}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }