var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?_c('GhNoDropSelector',{attrs:{"datablock":{id: 'company_groups',title: _vm.$t('company')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.PermsCompaniesCompanies.data_general_companies.name))])])]),_c('div',{staticClass:"section_line"},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('profiles')))])]),_c('div',{staticClass:"gh-content-global"},[_c('form',{attrs:{"id":"form_companies_groups"}},_vm._l((_vm.PermsCompaniesCompanies.data_general_companies.groups),function(i,k){return _c('div',{key:'ELEMENT' + i.id + k,staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'result_search_data_users',
                                nameStore: 'PermsUsersUsers',
                                imagelocation: require('../../../../../assets/gh_new_dessign/floating.svg'),
                                id: i.type,
                                text: _vm.$t(i.name),
                                textlocation: `${_vm.$t('add')} ${i.name}`,
                                actionresult: 'loadResultSearchUsers',
                                nameResult: 'company[groups]['+i.type+'][]',
                                supportPermision: true,
                            },"mainSelector":{
                                name: 'Formsearchusers',
                                formId: 'group',
                                actionsearch: 'getSearchUsers'
                            },"tableResolt":{
                                header: _vm.header,
                                data: _vm.PermsCompaniesCompanies.list_group_users,
                            },"tableSelected":{
                                headersummary: _vm.headersummary,
                                selected: i.selected ? i.selected : []
                            }}})],1)}),0)])]},proxy:true}],null,false,1669206904)}):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.submitCompaniesGroup();}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.back();}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }