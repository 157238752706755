var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window",staticStyle:{"padding-bottom":"20px"}},[(_vm.show.company_file)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'list_business',
            title: _vm.$t('date_company'),
            second_title: '',
            num_results: ''
        }},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [(_vm.view)?[_c('ViewBusiness')]:_vm._e(),(!_vm.view)?[_c('EditBusiness')]:_vm._e()]},proxy:true}:null,(_vm.view)?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_business',
                    text: _vm.$t('modify'),
                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                }},on:{"click":_vm.changeState}})]},proxy:true}:null],null,true)}):_vm._e(),(_vm.show.users)?_c(_vm.components.list_users,{tag:"component"}):_vm._e(),(_vm.show.environment_personalization)?_c(_vm.components.list_around,{tag:"component"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }