<template>
    <div>
        <div class="gh-content-global">
            <div class="gh-row-line-flex">
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.name}}</label>
                </div>
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('social_reason') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.company.social_reason).join(', ')}}</label>
                </div>
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.cif}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('full_adress') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.direction}}</label>
                </div>
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('locality') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.locality}}</label>
                </div>
                <div class="gh-row-column-flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('postal_code') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.cp}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="width: 33.33%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.company.country).join(', ')}}</label>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('province') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.company.province).join(', ')}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="width: 33.33%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.email}}</label>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.company.telephone}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.company.activity).join(', ')}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_employees') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.company.num_employee).join(', ')}}</label>
                </div>
            </div>
        </div>
        <div class="section_line" style="margin-top: -7px; ">
            <div class="T15_b" style="padding-left:13px;">{{$t('contact_person')}}</div>
        </div>
        <div class="gh-content-global">
            <div class="gh-row-line-flex">
                <div style="width: 33.33%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.contact_person.name}}</label>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surnames') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.contact_person.surnames}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="width: 33.33%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.contact_person.email}}</label>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('position') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{Object.values(getterCompanyData.contact_person.position).join(', ')}}</label>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                    <label class="gh_text_field-label T15_b">{{getterCompanyData.contact_person.telephone}}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "view_business",
        computed: {
            ...mapGetters(['getterCompanyData'])
        },
    }
</script>