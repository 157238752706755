<template>
    <div class="float_window">
        <form id="form_search" :key="refresh">
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_from',
                            text: $t('date_from'),
                            style: 'margin-left: 35px;text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_date_from',
                            name: 'search[date_from]',
                            value: '',
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_until',
                            text: $t('date_until'),
                            style: 'width:85px;text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_end_date',
                            name: 'search[date_until]',
                            value: '',
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhTextarea
                        :datalabel="{
                            text: $t('questions'),
                            class: 'T15_b',
                            styles: 'text-align: right; margin-right: 12px; margin-left: 35px;',
                        }"
                        :datainput="{
                            id: 'search_question',
                            name: 'search[question]',
                            value: '',
                            required: false,
                            edited: false,
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhTextarea
                        :datalabel="{
                            text: $t('answers'),
                            class: 'T15_b',
                            styles: 'text-align: right; margin-right: 12px; margin-left: 35px;',
                        }"
                        :datainput="{
                            id: 'search_answer',
                            name: 'search[answer]',
                            value: '',
                            required: false,
                            edited: false
                        }"
                    />
                </div>
                <component
                    v-bind:is="reference_thesauros"
                    :props="{
                        name:'search',
                        selecteds:{
                            groupers: [],
                            topics: [],
                            exercises: [],
                            thesauros: [],
                        },
                        view:{
                            groupers: false,
                            topics: false,
                            exercises: false,
                            thesauros: false,
                        }
                    }"
                />


            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('search')}" @click="search()"/>
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()"/>
        </div>
    </div>
</template>
<script>
import GhCalendar from 'fe-gh-input-calendar-lib';
import GhChecks from 'fe-gh-checks-lib';
import GhButton from 'fe-gh-button-lib'
import {mapState} from "vuex";
import reference_thesauros from "@/view/base_creation/exercises/exercises/template/shared/reference_thesauros.vue";
import GhTextarea from "fe-gh-textarea-lib";
export default {
    name: "view_search",
    components:{
        GhTextarea,
        GhChecks,
        GhButton,
        GhCalendar
    },
    data(){
        return{
            refresh: 0,
            reference_thesauros: reference_thesauros,
        }
    },
    computed:{
        ...mapState(['TabSearch'])
    },
    methods:{
        selectRadio(value){
            if (this.$refs[value].$el.children[0].children[1].checked != true){
                this.$refs[value].checkUncheck();
            }
        },
        clean(){
            this.$store.dispatch('destroyedResultData','',{root:true});
            document.getElementById("form_search").reset();
            this.refresh++;
        },
        async search(){
            const formData = new FormData(document.getElementById('form_search'));
            await this.$store.dispatch('searchData',{formData: [...formData],type:'exercise'},{root:true});
        }
    }
}
</script>

<style>
@import "~fe-gh-input-calendar-lib/dist/fe-gh-input-calendar-lib.css";
</style>
