<template>
    <div class="gh-row-content">
        <GhInputTextField
            :datalabel="{
                text: $t('name'),
                id:' group_label_name',
                class: 'gh_colspan-6',
                style: 'width: 85px; text-align: right;'
            }"
            :datainput="{
                id: 'group_last_nombre',
                name: 'user[first_name]',
                style: 'width:280px; min-width:280px;',
                type: 'text'
            }"
        />
        <GhInputTextField
            :datalabel="{
                text: $t('surname'),
                id: 'group_label_last_name',
                class: 'gh_colspan-6',
                style: 'width: 100px; text-align: right;'
            }"
            :datainput="{
                class: '',
                id: 'group_nombre',
                name: 'user[worker_surname]',
                style: 'width:280px; min-width:280px;',
                type: 'text'
            }"
        />
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    export default {
        name: "form_search",
        components:{
            GhInputTextField
        }
    }
</script>
