import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: '',
        header: '',
    },
    mutations: {
        loadPriorities(state,payload){
            state.records_data=payload
        },
        loadPrioritiesData(state, payload) {
            state.data = payload;
        },
        loadHeaderPriority(state, payload){
            state.header = payload;
        }
    },
    actions: {
        async getPriorities(state){
            await axios.get(
                API_URL + "support/priorities"
            ).then(
                response => {
                    var create_header = Object.keys(response.data.data[0]);
                    var headers = new Array();
                    var column_name = {
                        'text': 'Prioridad',
                        'field': 'name',
                        'sorting': true,
                        'urlRedirect': true,
                        'typeSort': 'string',
                        'style': 'width:40%',
                    };
                    var column_action = {
                        'text': '',
                        'field': 'actions',
                        'sorting': false,
                        'style': 'width:10%',
                    };
                    var countLColumns = 0;
                    headers.push(column_name);
                    create_header.map((i) => {
                        if(i != 'id' && i != 'name') countLColumns++;
                    });
                    create_header.map((i) => {
                        if(i != 'id' && i != 'name'){
                            var column_priority = {
                                'text': i,
                                'field': i,
                                'sorting': true,
                                'typeSort': 'string',
                                'style': 'width: ' + (50/countLColumns) + '%',
                            };
                            headers.push(column_priority);
                        }
                    });
                    headers.push(column_action);

                    state.commit("loadHeaderPriority", headers);
                    state.commit('loadPriorities', response.data.data);
                }
            )
        },
        async removePriority(state, item){
            let requestLogin = API_URL + "support/priorities/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getPriorities');
                        await this.dispatch('getConfigSupportCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPrioritiesData(state, items){
            let requestLogin = items.id !== undefined ?  API_URL +  "support/priorities/"+items.id : API_URL + "support/groups/feeds";
            let formData = new FormData();
            formData.append("id", items.id);
            await axios({
                method: "get",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPrioritiesData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPrioritiesData(state, item){
            let requestLogin = item.id ? API_URL + "support/priorities/"+item.id : API_URL + "support/priorities";
            let formData = new FormData();
            formData.append('priority[id]', item.id);

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method:  "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200){
                        state.commit("loadPrioritiesData", '');
                        this.dispatch('getPriorities');
                    }
                    router.push({name: 'configuration'});
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}