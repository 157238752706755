<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab v-bind:items="$router.options.GhSubTabsSearch" :dataBlock="{id: 'search_tab_', calldispatch: 'GhSubTabItem'}"/>
        <GhDropSelector :datablock="{ id: 'search', title: $t('searcher'), second_title: ''}">
            <template v-slot:content>
                <component :key="'search_' + refreshView" v-bind:is="TabSearch.subTabActive" />
            </template>
        </GhDropSelector>
        <GhNoDropSelector :datablock="{id: 'result' ,title: typeof TabSearch.resultTitle == 'number' ? TabSearch.resultTitle + ' ' + $t('results') : $t((TabSearch.resultTitle !== undefined ? TabSearch.resultTitle : 'no_results_found')), second_title:'' }">
            <template v-slot:content>
                <component v-bind:is="result_search" />
            </template>
            <template v-slot:actions>
                <GhAction :dataction="{id: 'export_all_data', class: 'T15_action',text:$t('export_total')}" @click="exportTotal"/>
                <GhAction :dataction="{id: 'export_result_data', class: 'T15_action',text:$t('export_results')}" @click="exportResult"/>
            </template>
        </GhNoDropSelector>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
            <img :src="require('../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>
<script>
    import GhSubTab from 'fe-gh-subtab-lib';
    import GhAction from "fe-gh-action-lib";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";
    import result_search from "@/view/search/result_content/result_search.vue"
    export default {
        name: "list_search",
        components:{
            GhSubTab,
            GhNoDropSelector,
            GhDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['TabSearch']),
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            },
            'TabSearch.reload'(){
                console.log('entro');
                this.refreshView++;
                this.$store.dispatch('destroyedResultData');
            }
        },
        methods:{
            exportTotal(){
                this.$store.dispatch('exportTotal',{data:this.TabSearch.idSubTabActive === 1 ? 'exercise' : this.TabSearch.idSubTabActive})
            },
            exportResult() {
                const formData = new FormData(document.getElementById('form_search'));
                this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData !== '' && this.$store.state.TabSearch.searchData !== undefined ? this.$store.dispatch('exportResult',{data:this.TabSearch.idSubTabActive === 1 ? 'exercise' : this.TabSearch.idSubTabActive, formData: [...formData]}):''
            }
        },
        data(){
            return{
                haveData: false,
                result_search: result_search,
                refreshView: 0,
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGroupersTabs', '', {root: true});
                await this.$store.dispatch('getFeedReferenceThesauros', '', {root: true});
                await this.$store.dispatch('getFeedExercises', '', {root: true});
                await this.$store.dispatch('getFeedGroupers', '', {root: true});
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>
