<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: getterQuestionnaireEvaluation.name,
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="gh-content-global-questionnaire">
            <div v-for="(itemEvaluation, indexEvaluation) in getterQuestionnaireEvaluation.evaluation" :key="'SECTION_EVALUATION' + indexEvaluation">
                <template v-if="(itemEvaluation.current && !itemEvaluation.finished) || (!itemEvaluation.current && itemEvaluation.finished)">
                    <div class="question-body" :style="indexEvaluation !== 0 ? 'margin-top: -20px' : ''">
                        <div class="gh-statement-content T15">
                            <div class="T-subtitle" v-html="itemEvaluation.name" style="margin-left: 10px; margin-top: 2px;"></div>
                        </div>
                        <div class="separator-line" style="margin-top: unset;"></div>
                        <component :is="component.evaluation" :actualKey="indexEvaluation" :parentId="itemEvaluation.id" :key="'REFRESHER' + refreshComponent"></component>
                    </div>
                </template>
            </div>
            <template v-if="getterQuestionnaireEvaluation.evaluation_finished">
                <div class="question-body" style="margin-top: -20px">
                    <div class="gh-statement-content T15">
                        <div class="T-subtitle" style="margin-left: 10px; margin-top: 2px;">{{$t('oportunity_hard_points')}}</div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <form id="form_evaluate_questionnaire">
                        <component :is="component.oportunity_hard_points"/>
                    </form>
                </div>
            </template>
            <div class="centered" style="margin-top: -10px;">
                <GhButton
                    style="margin-bottom: 20px; margin-top: -10px;"
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_close_evaluation_questionnaire',
                        text: $t('close')
                    }"
                    :title="$t('close')"
                    @click="Global.windowClose()"
                />
                <GhButton
                    style="margin-bottom: 20px; margin-top: -10px;"
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_reset_evaluation_questionnaire',
                        text: $t('reset')
                    }"
                    :disabled="!getterQuestionnaireEvaluation.allow.reset"
                    :title="!getterQuestionnaireEvaluation.allow.reset ? $t('no_element_reset') : $t('reset')"
                    @click="setReset()"
                />
                <template v-if="!getterQuestionnaireEvaluation.evaluation_finished">
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-primary"
                        :datainput="{
                            id: 'btn_validate_evaluation_questionnaire',
                            text: $t('validate')
                        }"
                        :title="!getterQuestionnaireEvaluation.allow.validate ? $t('to_finish_evaluation_need_response_all_valoration') : $t('validate')"
                        :disabled="!getterQuestionnaireEvaluation.allow.validate"
                        @click="setValidate()"
                    />
                </template>
                <template v-if="getterQuestionnaireEvaluation.evaluation_finished">
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_save_evaluation_questionnaire',
                            text: $t('save')
                        }"
                        :title="$t('save')"
                        @click="saveEvaluation()"
                    />
                    <GhButton
                        style="margin-bottom: 20px; margin-top: -10px;"
                        class="button-questionnaire-primary"
                        :datainput="{
                            id: 'btn_finish_evaluation_questionnaire',
                            text: $t('finish')
                        }"
                        :title="$t('finish')"
                        @click="finishEvaluation()"
                    />
                </template>
            </div>
        </div>

        <component :is="component.confirmPopup" :text="textPopup" @accept="acceptReset()"/>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import evaluation from '../template/evaluate.vue';
    import confirmPopup from '../popup/confirm.vue';
    import oportunity_hard_points from '../template/oportunity_hard_points.vue';

    export default {
        name: 'view_evaluate',
        components: {
            GhLocation,
            GhButton
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return {
                haveData: false,
                disabledSave: true,
                refreshComponent: 0,
                textPopup: 'reset_evaluate_text',
                component: {
                    confirmPopup: confirmPopup,
                    evaluation: evaluation,
                    oportunity_hard_points: oportunity_hard_points
                }
            }
        },
        async beforeMount(){
            if(this.$root._route.params.user_id !== undefined && this.$root._route.params.user_id !== ''){
                await this.$store.dispatch('getEvaluationByUser', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    user_id: this.$root._route.params.user_id,
                    type: this.$root._route.params.type
                }, {root: true}).then((response) => {
                    if (response.status === 200) {
                        this.haveData = true;
                        this.refreshComponent++;
                    }
                });
            }else {
                await this.$store.dispatch('getStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type
                }, {root: true}).then((response) => {
                    if (response.status === 200) {
                        this.haveData = true;
                        this.refreshComponent++;
                    }
                });
            }
        },
        methods: {
            setReset(){
                this.Global.openPopUp('popup_confirm');
            },
            async acceptReset(){
                await this.$store.dispatch('setResetEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type
                }, {root: true}).then(async (response) => {
                    if(response.status === 200){
                        await this.$store.dispatch('getStartEvaluation', {
                            id: this.$root._route.params.id,
                            actual_id: this.$root._route.params.actual_id,
                            type: this.$root._route.params.type
                        }, {root: true}).then((response) => {
                            if(response.status === 200){
                                this.haveData = true;
                                this.refreshComponent++;
                            }
                        });

                        this.Global.closePopUp('popup_confirm');
                    }
                });
            },
            async setValidate(){
                await this.$store.dispatch('setValidateEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        await this.$store.dispatch('getStartEvaluation', {
                            id: this.$root._route.params.id,
                            actual_id: this.$root._route.params.actual_id,
                            type: this.$root._route.params.type
                        }, {root: true}).then((response) => {
                            if (response.status === 200) {
                                this.refreshComponent++;
                            }
                        });
                    }
                });
            },
            async saveEvaluation(){
                const formData = new FormData(document.getElementById('form_evaluate_questionnaire'));
                await this.$store.dispatch('setStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    type_action: 'save',
                    formData: [...formData]
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        await this.$store.dispatch('getStartEvaluation', {
                            id: this.$root._route.params.id,
                            actual_id: this.$root._route.params.actual_id,
                            type: this.$root._route.params.type
                        }, {root: true}).then((response) => {
                            if (response.status === 200) {
                                this.refreshComponent++;
                            }
                        });
                    }
                });
            },
            async finishEvaluation(){
                const formData = new FormData(document.getElementById('form_evaluate_questionnaire'));
                await this.$store.dispatch('setStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.actual_id,
                    type: this.$root._route.params.type,
                    type_action: 'finish',
                    formData: [...formData]
                }, {root: true}).then(async (response) => {
                    if (response.status === 200) {
                        this.Global.windowClose();
                    }
                });
            }
        }
    }
</script>