import Vue from "vue";
import vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

//LOGIN
import Login from './login/Login';
import Tabs from './tabs/tabs';
import getFeeds from './feeds/feeds';
import preSelecteds from './feeds/pre_selecteds';

// --- ITINERARY MAIN
import MainItinerary from './itinerary/initial/main';
// --- LIST QUIZZ
import ListQuizz from './itinerary/initial/list_quizz';
// --- RESULT QUIZZ
import ResultQuizz from './itinerary/initial/result_quizz';
// --- LIST COMPANIES
import ListCompanies from './gh_admin/list_companies';
// --- LIST QUIZZ
import ToPay from './itinerary/initial/to_payment';

// --- LIST GROUPS
import TabPerms from './perms/list_perms';
import PermsGroup from './perms/groups/list_groups';
import PermsGroupGroups from './perms/groups/groups/list_groups';
import PermsGroupProfiles from './perms/groups/profiles/list_profiles';
import PermsCompanies from './perms/companies/list_companies';
import PermsCompaniesCompanies from './perms/companies/companies/list_companies';
import PermsUsers from './perms/users/list_users';
import PermsUsersUsers from './perms/users/users/list_users';
import PermsUsersAssociatedUsers from './perms/users/associated_users/list_associated_users';
import PermsEvaluators from './perms/evaluators/list_evaluators';
import PermsEvaluatorsEvaluators from './perms/evaluators/evaluators/list_evaluators';

// -- GLOBAL
import TabTodo from './todo/list_todo';

// -- GLOBAL
import TabGlobal from './global/list_global';
import AllItinerary from './global/template/all_itinerary';
import RecomendedItinerary from './global/template/recomended_itinerary';

// -- MY ITINERARY
import TabMyItinerary from './itinerary/client/list_itinerary';
import AssignItinerary from './itinerary/client/template/assignment';
import ContractedPrograms from './itinerary/client/contracted_programs/list_contracted_programs';
import RecomendedPrograms from './itinerary/client/recomended_programs/list_recomended_programs';
import AllPrograms from './itinerary/client/all_programs/list_all_programs';
import AssignedUsers from './itinerary/client/assigned_users/list_assigned_users';

// -- MY SITUATION
import TabMySituation from './situation/list_situation';

// -- BUSINESS
import TabBusiness from './business/business';
// -- USERS
import TabUsers from './users/list_users';
import TemplateUser from './users/template/user';
// -- CONFIG
import TabConfig from './config/list_config';
import ConfigCreation from './config/creation/list_creation';
import ConfigTopics from './config/creation/topics/list_topics';
import ConfigGroupers from './config/creation/groupers/list_groupers';
import ConfigTypeExercise from './config/creation/type_exercise/list_type_exercise';
import ConfigResponsible from './config/creation/responsibles/list_responsibles';
import ConfigSeals from './config/creation/seals/list_seals';
import ConfigCertificates from './config/creation/certificates/list_certificates';
import ConfigWeightingElement from './config/creation/weighting_element/list_weighting_element';
import ConfigWeightingGroup from './config/creation/weighting_group/list_weighting_group';
import ConfigEvaluation from './config/creation/evaluation/list_evaluation';
import ConfigEvaluationBasis from './config/creation/evaluation_basis/list_evaluation_basis';
import ConfigAround from './config/around/list_around';
import ConfigCategories from './config/support/categories/list_categories';
import ConfigPriorities from './config/support/priorities/list_priorities';
import ConfigHolidays from './config/support/holidays/list_holidays';
import ConfigGenericHolidays from './config/support/generic_holidays/list_generic_holidays';
import ConfigFlows from './config/support/flows/list_flows';
import ConfigAssignment from './config/support/assignment/list_assignment';
import ConfigSchedule from './config/support/schedule/list_schedule';
import ConfigSupport from './config/support/list_config_support';
import ConfigReferential from './config/referential/list_referential';
import ConfigThesaurus from './config/referential/thesaurus/list_thesaurus';
import ConfigCompany from './config/company/list_company';
import ConfigSocialReason from './config/company/social_reason/list_social_reason';
import ConfigActivitySector from './config/company/activity_sector/list_activity_sector';
import ConfigNumberEmployees from './config/company/number_employees/list_number_employees';
import ConfigPositionCompany from './config/company/position_company/list_position_company';
import ConfigCountries from './config/company/country/list_country';
import ConfigBenchmark from './config/benchmark/benchmark/list_benchmark';
import ConfigEvaluators from './config/evaluators/list_evaluators';
import ConfigCCAA from './config/evaluators/ccaa/list_ccaa';
import ConfigClubCategory from './config/evaluators/club_category/list_club_category';
import ConfigLanguages from './config/evaluators/languages/list_languages';
import ConfigTypeEvaluator from './config/evaluators/type_evaluator/list_type_evaluator';
import ConfigProfessionalCategory from './config/evaluators/professional_category/list_professional_category';
import ConfigProfessionalActivitySector from './config/evaluators/professional_activity_sector/list_professional_activity_sector';
import ConfigProfessionalExperienceSector from './config/evaluators/professional_experience_sector/list_professional_experience_sector';
import ConfigTypeEvaluation from './config/evaluators/type_evaluation/list_type_evaluation';
import ConfigEvaluatorModality from './config/evaluators/evaluator_modality/list_evaluator_modality';
import ConfigGradeExperience from './config/evaluators/grade_experience/list_grade_experience';
import ConfigScoreRange from './config/evaluators/score_range/list_score_range';
import ConfigSealRange from './config/evaluators/seal_range/list_seal_range';
import ConfigCanal from './config/evaluators/channel/list_channel';

// -- BASE CREATION
import TabBaseCreation from './base_creation/list_base_creation';
import BaseCreationSupportMaterial from './base_creation/support_material/list_support_material';
import BaseCreationSupportMaterialBibliographies from '@/store/base_creation/support_material/bibliography/list_bibliography';
import BaseCreationSupportMaterialExamples from '@/store/base_creation/support_material/examples/list_examples';
import BaseCreationSupportMaterialSupportMaterial from '@/store/base_creation/support_material/support_material/list_support_material';
import BaseCreationSupportMaterialGoodPractice from '@/store/base_creation/support_material/good_practices/list_good_practices';
import BaseCreationSupportMaterialTheory from '@/store/base_creation/support_material/theory/list_theory';
import BaseCreationExercise from '@/store/base_creation/exercises/list_exercises';
import BaseCreationExerciseTable from '@/store/base_creation/exercises/exercises/list_exercises';
// -- CREATION
import TabCreation from './creation/list_creation';
import CreationGrouper from './creation/groupers/list_groupers';
import CreationGrouperElements from './creation/groupers/elements';
// -- BILLING
import TabBilling from './billing/list_billing';
import HistoricBilling from './billing/historic/historic';

// -- SUPPORT
import TabSupport from './support/list_support'
import SupportPendingRequestsGroups from './support/pending_requests_groups/list_pending_requests_groups'
import SupportPendingRequests from './support/pending_requests/list_pending_requests'
import SupportHistorical from './support/historical/list_historical'
import SupportIndicators from './support/indicators/list_indicators'

// -- USER FILE
import TabUserFile from './user_file/user_file'

// -- EVALUATION
import TabEvaluation from './evaluation/list_evaluations';

// -- TUTOR
import TabTutor from './tutor/list_tutor';

// -- ACTIONS
import TabAction from './actions/list_action'
import ActionListCompanies from './actions/list_companies/list_companies'
import ActionHistorical from './actions/historical/list_action_historical'
import ActionIndicators from "@/store/actions/indicators/list_action_indicators";
// -- DATA SUPERVISED/EVALUATED
import TabSupervisedEvaluated from './supervised_evaluated/list_supervised_evaluated';

import GlobalState from './Global';

import TabSearch from './search/list_search';

import UploadFiles from './attach/upload';


// -- ASSIGN
import TabAssign from './assign/list_assign';
import ItineraryAssign from './assign/itinerary_assign/itinerary_assign';
import AssignOgoingPrograms from './assign/ongoing_programs/list_ongoing_programs';
import AssignProgramsAssociate from './assign/programs_associate/list_programs_associate';
import AssignationBase from "./assign/assignation_base/list_assignation_base";
import SearchEvaluators from "./assign/evaluators/list_evaluators";

// -- QUESTIONNAIRE
import Questionnaire from "@/store/questionnaire/questionnaire";

import PlatformPay from "@/store/platform_pay/list_platform_pay";

// -- SHARED
import Shared from './shared/shared'

Vue.use(vuex);

export default new vuex.Store({
    modules: {
        Login: Login,
        MainItinerary: MainItinerary,
        ListQuizz: ListQuizz,
        ResultQuizz: ResultQuizz,
        ToPay: ToPay,
        Tabs: Tabs,
        getFeeds: getFeeds,
        preSelecteds: preSelecteds,
        TabSearch: TabSearch,
        TabTodo: TabTodo,
        TabGlobal: TabGlobal,
        AllItinerary: AllItinerary,
        RecomendedItinerary: RecomendedItinerary,
        TabMyItinerary: TabMyItinerary,
        AssignItinerary: AssignItinerary,
        ContractedPrograms: ContractedPrograms,
        RecomendedPrograms: RecomendedPrograms,
        AllPrograms: AllPrograms,
        AssignedUsers: AssignedUsers,
        TabMySituation: TabMySituation,
        TabBusiness: TabBusiness,
        ListCompanies: ListCompanies,
        TabConfig: TabConfig,
        ConfigCreation: ConfigCreation,
        ConfigTopics: ConfigTopics,
        ConfigGroupers: ConfigGroupers,
        ConfigTypeExercise: ConfigTypeExercise,
        ConfigResponsible: ConfigResponsible,
        ConfigSeals: ConfigSeals,
        ConfigCertificates: ConfigCertificates,
        ConfigWeightingElement: ConfigWeightingElement,
        ConfigWeightingGroup: ConfigWeightingGroup,
        ConfigEvaluation: ConfigEvaluation,
        ConfigEvaluationBasis: ConfigEvaluationBasis,
        ConfigAround: ConfigAround,
        ConfigSupport:ConfigSupport,
        ConfigReferential:ConfigReferential,
        ConfigThesaurus:ConfigThesaurus,
        ConfigCompany:ConfigCompany,
        ConfigSocialReason:ConfigSocialReason,
        ConfigActivitySector:ConfigActivitySector,
        ConfigNumberEmployees:ConfigNumberEmployees,
        ConfigPositionCompany:ConfigPositionCompany,
        ConfigCountries:ConfigCountries,
        ConfigCategories:ConfigCategories,
        ConfigPriorities:ConfigPriorities,
        ConfigHolidays:ConfigHolidays,
        ConfigGenericHolidays:ConfigGenericHolidays,
        ConfigFlows:ConfigFlows,
        ConfigSchedule:ConfigSchedule,
        ConfigAssignment:ConfigAssignment,
        ConfigBenchmark:ConfigBenchmark,
        ConfigEvaluators:ConfigEvaluators,
        ConfigCCAA:ConfigCCAA,
        ConfigClubCategory:ConfigClubCategory,
        ConfigLanguages:ConfigLanguages,
        ConfigTypeEvaluator:ConfigTypeEvaluator,
        ConfigProfessionalCategory:ConfigProfessionalCategory,
        ConfigProfessionalActivitySector:ConfigProfessionalActivitySector,
        ConfigProfessionalExperienceSector:ConfigProfessionalExperienceSector,
        ConfigTypeEvaluation:ConfigTypeEvaluation,
        ConfigEvaluatorModality:ConfigEvaluatorModality,
        ConfigGradeExperience:ConfigGradeExperience,
        ConfigScoreRange:ConfigScoreRange,
        ConfigSealRange:ConfigSealRange,
        ConfigCanal:ConfigCanal,
        TabBaseCreation: TabBaseCreation,
        BaseCreationSupportMaterial: BaseCreationSupportMaterial,
        BaseCreationSupportMaterialBibliographies: BaseCreationSupportMaterialBibliographies,
        BaseCreationSupportMaterialExamples: BaseCreationSupportMaterialExamples,
        BaseCreationSupportMaterialSupportMaterial: BaseCreationSupportMaterialSupportMaterial,
        BaseCreationSupportMaterialGoodPractice: BaseCreationSupportMaterialGoodPractice,
        BaseCreationSupportMaterialTheory: BaseCreationSupportMaterialTheory,
        BaseCreationExercise: BaseCreationExercise,
        BaseCreationExerciseTable: BaseCreationExerciseTable,
        TabUsers: TabUsers,
        TemplateUser: TemplateUser,
        TabCreation: TabCreation,
        CreationGrouper: CreationGrouper,
        CreationGrouperElements: CreationGrouperElements,
        TabSupport: TabSupport,
        SupportPendingRequestsGroups: SupportPendingRequestsGroups,
        SupportPendingRequests: SupportPendingRequests,
        SupportHistorical: SupportHistorical,
        SupportIndicators: SupportIndicators,
        TabBilling: TabBilling,
        HistoricBilling: HistoricBilling,
        TabPerms: TabPerms,
        PermsGroup: PermsGroup,
        PermsGroupGroups: PermsGroupGroups,
        PermsGroupProfiles: PermsGroupProfiles,
        PermsCompanies: PermsCompanies,
        PermsCompaniesCompanies: PermsCompaniesCompanies,
        PermsUsers: PermsUsers,
        PermsUsersUsers: PermsUsersUsers,
        PermsUsersAssociatedUsers: PermsUsersAssociatedUsers,
        PermsEvaluators: PermsEvaluators,
        PermsEvaluatorsEvaluators: PermsEvaluatorsEvaluators,
        GlobalState: GlobalState,
        UploadFiles: UploadFiles,
        TabUserFile: TabUserFile,
        TabAction:TabAction,
        ActionHistorical:ActionHistorical,
        ActionIndicators: ActionIndicators,
        TabEvaluation: TabEvaluation,
        TabTutor: TabTutor,
        TabSupervisedEvaluated: TabSupervisedEvaluated,
        TabAssign:TabAssign,
        ActionListCompanies:ActionListCompanies,
        ItineraryAssign:ItineraryAssign,
        Questionnaire: Questionnaire,
        PlatformPay: PlatformPay,
        AssignOgoingPrograms: AssignOgoingPrograms,
        AssignProgramsAssociate: AssignProgramsAssociate,
        AssignationBase: AssignationBase,
        SearchEvaluators: SearchEvaluators,
        Shared: Shared
    },
    plugins: [createPersistedState()]
});

