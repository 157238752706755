var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_order',
        title: _vm.$t('items'),
        type: 'add',
        style: 'width: 35% !important; min-width:80%'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 90%; min-height: 8vh;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_popup_order"}},[_c('div',{staticStyle:{"padding-left":"35px","overflow":"auto","max-height":"250px"}},[_c('div',{key:'KEY9' + _vm.refreshOrder,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{staticClass:"T15_b",staticStyle:{"margin-right":"15px","width":"59px"}},[_vm._v(" "+_vm._s(_vm.$t('order'))+" ")]),_c('div',{staticClass:"T15_b",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('items'))+" ")])])]),_vm._l((_vm.getterItemsOrdered),function(item,key){return _c('div',{key:'KEY10' + key,staticClass:"gh-row-content",staticStyle:{"margin-right":"5px"}},[_c('div',[_c('div',{staticStyle:{"margin-right":"15px"}},[_c('GhInputTextField',{ref:'RefInputOrder' + item.id,refInFor:true,attrs:{"datalabel":{
                                        text: '',
                                        style: '',
                                        class: ''
                                    },"datainput":{
                                        required: true,
                                        id: 'order_item_' + item.id,
                                        name: item.id,
                                        style: 'width: 50px;',
                                        label_required: item.name,
                                        value: item.order
                                    }},on:{"keyup":function($event){return _vm.formatInput($event, 'RefInputOrder' + item.id)}}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item.name))])])])])})],2)])])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.$root._route.params.view)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept_popup_order',
                    text: _vm.$t('accept')
                }},on:{"click":function($event){return _vm.acceptOrder()}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close_popup_order',
                text: _vm.$t('close')
            }},on:{"click":function($event){_vm.Global.closePopUp('popup_order'); _vm.refreshOrder++}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }