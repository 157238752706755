import ConfigCCAA from "@/view/config/evaluators/ccaa/template/ccaa.vue";

const routes = [
    {
        path: '/config/evaluator/ccaa',
        name: 'config_ccaa',
        component: ConfigCCAA,
        props: {id: null, showMenu: true, otherTitle: 'ccaa'},
        children: [
            {
                path: '/config/evaluator/ccaa/:id',
                name: 'config_ccaa_id',
                component: ConfigCCAA,
                props: {id: null, showMenu: true, otherTitle: 'ccaa'},
            },
            {
                path: '/config/evaluator/ccaa/:id/:view',
                name: 'config_ccaa_id_view',
                component: ConfigCCAA,
                props: {id: null, view: true, showMenu: true, otherTitle: 'ccaa'},
            }
        ]
    }
];

export default routes;