import Evaluation from "@/view/evaluation/template/evaluation.vue";
import Evaluate from "@/view/shared/questionnaire/evaluate.vue";

const routes = [
    {
        path: '/evaluations/:id',
        name: 'evaluations_id',
        component: Evaluation,
        props: {id: null, showMenu: false,background:true, otherTitle: 'evaluation'},
    },
    {
        path: '/evaluation/:type/:id/:actual_id',
        name: 'evaluation_questionnaire',
        component: Evaluate,
        props: {id: null, actual_id: null, type: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    },

];

export default routes;