<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'assigned_users_' + label.id,
                    title: $t(label['name']),
                    label: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import assigned_users from './assigned_users/assigned_users.vue';

    export default {
        name: 'list_assigned_users',
        components: {
            GhNoDropSelector
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'assigned_users', component: assigned_users, result: '', show: true , second_text: ''},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            this.haveData = true;
        }
    }
</script>

<style>
    [id^="assigned_users_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }
</style>