var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{ref:"RefComment",attrs:{"datalabel":{
                text: _vm.$t('comment') + '*',
                class: 'gh_text_field-label T15_b',
                styles: 'text-align: right; width: 150px !important; min-width: 150px !important;',
            },"datainput":{
                id: 'action_comment',
                name: 'action[comment]',
                value: '',
                styles: 'width: 100%',
                required: true
            },"minlength":"10"}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                id: 'action_attach',
                class: '',
                name_hidden: 'action[attach]',
                id_hidden: 'action_attach',
                icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
            },"datalabel":{
                id: '',
                style: 'margin-right: -15px'
            },"datainput":{
                view: false,
                text: _vm.$t('attach_send_mail') + '*',
                accept: '.pdf, .eml',
                show_image: false,
                delete: false,
                href: 'easyUpload',
                downloadHref: 'easyDownload',
                file_name: '',
                file_path: '',
                file_download: '',
                required: true
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }