import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: '',
    },
    mutations: {
        loadCategories(state,payload){
            state.records_data=payload
        },
        loadCategoriesData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getCategories(state){
            await axios.get(
                API_URL + "support/categories"
            ).then(
                response => {
                    state.commit('loadCategories', response.data.data);
                }
            )
        },
        async removeCategory(state, item){
            let requestLogin = API_URL + "support/categories/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getCategories');
                        await this.dispatch('getConfigSupportCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        cleanCategoryData(state){
            state.commit("loadCategoriesData", '');
        },
        async getCategoriesData(state, items){
            if(items.id === undefined){
                state.commit("loadCategoriesData", '');
            }else {
                let requestLogin = API_URL + "support/categories/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadCategoriesData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setCategoryData(state, item){
            let requestLogin = item.id ? API_URL + "support/categories/"+item.id : API_URL + "support/categories";
            let formData = new FormData();

            formData.append('category[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('getCategories');
                        this.dispatch('cleanCategoryData');
                        router.push({name: 'configuration'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
