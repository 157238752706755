var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?[_c('GhSubTabProgressBar',{ref:"RefSubTabProgressBar",attrs:{"items":_vm.$router.options.GhSubTabsQuestionary,"dataBlock":{
                id: 'initial_tabs',
                calldispatch: 'getSpecificQuizz',
                chart: _vm.MainItinerary.chart_quizz !== undefined ? _vm.MainItinerary.chart_quizz : []
            }}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                    id: 'drop_selector_section_initial',
                    title: _vm.$t(_vm.MainItinerary.data_quizz.from)
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.MainItinerary.data_quizz !== undefined && _vm.MainItinerary.data_quizz.id !== undefined)?_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-top":"20px"}},[_c('form',{ref:"form",attrs:{"id":"form_quizz"}},[_c(_vm.quizz,{key:_vm.refreshQuizz,tag:"component",attrs:{"dataQuizz":_vm.MainItinerary}})],1)]):_vm._e()]},proxy:true}],null,false,3956345615)})],1),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:_vm.video.refresh,attrs:{"generalConfiguration":{
                id: 'video_quizz_popup',
                title: _vm.video.name,
                type: 'info',
                style: 'width: 1024px; min-width: 1024px'
            },"bodyConfiguration":{id: 'video_body_quizz_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''},"footerConfiguration":{id: 'video_footer_quizz_popup', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('video',{attrs:{"width":"1024","height":"400","controls":""}},[_c('source',{attrs:{"src":_vm.video.url}})])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                        id: 'video_close_btn_popup',
                        text: _vm.$t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }},on:{"click":function($event){return _vm.closeVideo()}}})]},proxy:true}],null,false,3251765584)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[(_vm.stateQuestion !== 3)?[((_vm.stateQuestion === 2 && _vm.MainItinerary.finished.finish) || (_vm.stateQuestion === 1 && !_vm.pendingChildrens))?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_end',
                        text: _vm.$t('finish'),
                        class: '',
                        style: ''
                    }},on:{"click":function($event){return _vm.finish();}}}):_vm._e(),(_vm.stateQuestion === 0)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save'),
                        class: '',
                        style: ''
                    }},on:{"click":function($event){return _vm.save();}}}):_vm._e()]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_back',
                    text: _vm.$t('back'),
                    class: '',
                    style: ''
                }},on:{"click":function($event){return _vm.$router.go(-1)}}})],2),_c('div',{staticClass:"gh_text_field-label T15_b centered",staticStyle:{"padding-top":"10px","height":"20px","color":"green"}},[(_vm.MainItinerary.finished.finish)?_c('span',[_vm._v(_vm._s(_vm.$t('all_itineraries_exercises_finish')))]):(_vm.stateQuestion === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('exercise_finish')))]):(_vm.pendingChildrens)?_c('span',[_vm._v(_vm._s(_vm.$t('to_finish_exercise')))]):_vm._e()])]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }