var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('form',{key:_vm.refresh,attrs:{"id":"form_search"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                        id: 'label_search_date_from',
                        text: _vm.$t('date_from'),
                        style: 'margin-left: 35px;text-align: right;'
                    },"datainput":{
                        id: 'input_search_date_from',
                        name: 'search[date_from]',
                        value: '',
                    }}}),_c('GhCalendar',{attrs:{"datalabel":{
                        id: 'label_search_date_until',
                        text: _vm.$t('date_until'),
                        style: 'width:85px;text-align: right;'
                    },"datainput":{
                        id: 'input_search_end_date',
                        name: 'search[date_until]',
                        value: '',
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('questions'),
                        class: 'T15_b',
                        styles: 'text-align: right; margin-right: 12px; margin-left: 35px;',
                    },"datainput":{
                        id: 'search_question',
                        name: 'search[question]',
                        value: '',
                        required: false,
                        edited: false,
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('answers'),
                        class: 'T15_b',
                        styles: 'text-align: right; margin-right: 12px; margin-left: 35px;',
                    },"datainput":{
                        id: 'search_answer',
                        name: 'search[answer]',
                        value: '',
                        required: false,
                        edited: false
                    }}})],1),_c(_vm.reference_thesauros,{tag:"component",attrs:{"props":{
                    name:'search',
                    selecteds:{
                        groupers: [],
                        topics: [],
                        exercises: [],
                        thesauros: [],
                    },
                    view:{
                        groupers: false,
                        topics: false,
                        exercises: false,
                        thesauros: false,
                    }
                }}})],1)]),_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_save', class: 'T19 container-md_button_content', text: _vm.$t('search')}},on:{"click":function($event){return _vm.search()}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_return',class: 'T19 container-md_button_content', text: _vm.$t('clean')}},on:{"click":function($event){return _vm.clean()}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }