var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?[(_vm.getterProgramsAssociateCompany[_vm.itinerary] !== undefined)?[_c('GhTableCommon',{attrs:{"extratable":{
                        id: 'programs_associate_companies_' + _vm.itinerary
                    },"header":_vm.header,"data":_vm.getterProgramsAssociateCompany[_vm.itinerary].data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterProgramsAssociateCompany[_vm.itinerary].actions !== undefined)?[(_vm.getterProgramsAssociateCompany[_vm.itinerary].actions.associate)?[_c('GhAction',{attrs:{"dataction":{
                                            id: 'view_programs_associate_companies_' + itemProp.id,
                                            text: _vm.$t('associate'),
                                            icon: require('../../../../assets/gh_new_dessign/tick.svg')
                                        }},on:{"click":function($event){return _vm.setAssign(itemProp.id, itemProp.name)}}})]:_vm._e()]:_vm._e()]}}],null,false,350580779)})]:_vm._e()]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }