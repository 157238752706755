var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'thesauros',title: _vm.$t('thesauros')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_thesauros"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('order') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'thesauros_order',
                                    name: 'thesauros[order]',
                                    style: 'width: 200px',
                                    value: _vm.ConfigThesaurus.data.order,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('order') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigThesaurus.data.order))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'thesauros_name',
                                    name: 'thesauros[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.ConfigThesaurus.data.name,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigThesaurus.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                },"datainput":{
                                    id: 'thesauros_description',
                                    name: 'thesauros[description]',
                                    value: _vm.ConfigThesaurus.data.description
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigThesaurus.data.description))])]],2)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitThesauros}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }