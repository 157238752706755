<template>
    <div :key="'block_metrics_60_' + refreshMetrics">
        <GhDropDownField
            :dataBlock="{
                id: 'drop_metrics',
                style: 'width: 100%; margin-right: 0px;',
                title: $t('metrics'),
                subTitle: getterMetrics !== undefined ? getterMetrics.length + ' ' + $t('results') :  0 + ' ' + $t('results'),
                isDrop: false
            }"
        >
            <template v-slot:content>
                <GhTableCommon v-if="haveHeader"
                    :extratable="{
                        id: 'metrics_'
                    }"
                    :header="header"
                    :data="getterMetrics"
                >
                    <template v-slot:actions="{itemProp}"  v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                        <GhAction
                            :dataction="{
                                id: 'delete_metrics',
                                text: $t('delete'),
                                icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                                item_prop: itemProp
                            }"
                            @click="Global.deleteConfirm('delete_confirm','TemporalMetrics', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_metric'), $t('preparing_delete_metric'), 'delete')"
                        />
                    </template>
                    <template v-slot:footer>
                        <div class="centered" v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                            <GhButton
                                :datainput="{
                                    id: 'btn_add_metrics',
                                    text: $t('add')
                                }"
                                @click="openMetrics"
                            />
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropDownField>

        <component v-bind:is="metrics_popup" />
    </div>
</template>

<script>
    import GhDropDownField from 'fe-gh-drop-down-field-lib';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhButton from 'fe-gh-button-lib';

    import metrics_popup from '../popup/metrics.vue';

    import {mapGetters, mapState} from "vuex";

    export default {
        name: "metrics_template",
        components: {
            GhDropDownField,
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable']),
            ...mapGetters(['getterMetrics'])
        },
        data(){
            return {
                metrics_popup: metrics_popup,
                haveHeader: false,
                refreshMetrics: 0,
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            }
        },
        watch:{
            'getterMetrics'(){
                this.refreshMetrics++;
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.BaseCreationExerciseTable.data.metrics = [];
                if (this.BaseCreationExerciseTable.exercise_data.metrics !== undefined) {
                    await this.$store.dispatch('setMetrics', {data: this.BaseCreationExerciseTable.exercise_data.metrics}, {root: true});
                }
            }
        },
        mounted() {
            if(this.$root._route.params.view || this.$root._route.params.type === 'enabled'){
                this.header = [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                ];
            }
            this.haveHeader = true;
        },
        methods: {
            openMetrics(){
                document.getElementById('form_metrics_data').reset();
                this.Global.openPopUp('metrics_popup');
            }
        },
    }
</script>

<style>
    #drop_metrics > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_metrics > div > div {
        display: flex;
    }

    #drop_metrics > div > div > .definition-field_header-text {
        width: 151px;
        display: flex;
        flex-flow: row-reverse;
        margin-right: 10px;
    }
</style>