var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'no_drop_selector_user',title: _vm.$t('user')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_users"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_firstname',
                                    name: 'user[firstname]',
                                    style:'width: 10%',
                                    value: _vm.getterPermissionUser.firstname,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') +':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPermissionUser.firstname))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('surnames') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_last_name',
                                    name: 'user[surname]',
                                    style: 'width: 20%',
                                    value: _vm.getterPermissionUser.surname,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surnames') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPermissionUser.surname))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_email',
                                    name: 'user[email]',
                                    style: 'width: 20%',
                                    value: _vm.getterPermissionUser.email,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email')+':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPermissionUser.email))])]],2),_c('div',{staticClass:"gh-row-content"},[(_vm.getFeeds.feed_groups_type_company.type === 1)?[_c('label',{staticClass:"gh_text_field-label",class:_vm.$root._route.params.view ? 'T15' : 'T15_b',staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group') + (_vm.$root._route.params.view ? ':' : '*')))]),_vm._l((_vm.getFeeds.feed_groups_type_company.feed),function(group,keyGroup){return _c('div',{key:keyGroup},[_c('GhChecks',{attrs:{"datalabel":{
                                        text: '',
                                        style: '',
                                        class: '',
                                        id: ''
                                    },"datainput":{
                                        disabled: _vm.$root._route.params.view,
                                        label_required: _vm.$t('group'),
                                        required: true,
                                        selected: _vm.getterPermissionUser.group !== undefined ? Object.keys(_vm.getterPermissionUser.group)[0] === keyGroup : false,
                                        type: 'checkbox',
                                        id: 'value_user_group',
                                        value: keyGroup,
                                        name: 'user[groups][]'
                                    }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(group))])],1)})]:[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'user_group',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_group') + ':',
                                        text: _vm.$t('group') + '*',
                                        label: _vm.$t('group'),
                                        type: 'checkbox',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    },"datainput":{
                                        id: 'value_user_group',
                                        name: 'user[groups][]'
                                    },"feed":_vm.getFeeds.feed_groups_type_company.feed,"selected_input":_vm.getterPermissionUser.group !== undefined ? _vm.getterPermissionUser.group : []}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group')+':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.getterPermissionUser.group).join(', ')))])]]],2)])])]},proxy:true}],null,false,2501403395)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_user_save',
                text: _vm.$t('save'),
            },"type":"submit"},on:{"click":_vm.submitUser}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_user_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }