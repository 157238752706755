import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import Login from './login/route';
import Todo from './todo/route';
import MisItinerarios from './itinerary/client/route';
import ItinerariosIniciales from './itinerary/initial/route';
import MiSituacion from './situation/route';
import Empresa from './business/route';
import Usuarios from './users/route';
import Configuracion from './config/route';
import BaseCreation from './base_creation/route';
import Buscador from './search/route';
import GHAdmin from './gh_admin/route';
import Soporte from './support/route';
import Permisos from './perms/route';
import Creacion from './creation/route';
import Facturacion from './billing/route';
import FichaUsuario from './user_file/route';
import Acciones from './actions/route'
import Evaluacion from './evaluation/route';
import Tutoria from './tutor/route';
import DatosEvaluadosTutelados from './supervised_evaluated/route';
import Floatwindow from './floatwindow/route';
import Asignar from './assign/route';

import PlatformPay from './platform_pay/route';

const routes = [
    ...ItinerariosIniciales,
    ...Floatwindow,
    ...Login,
    ...Todo,
    ...MisItinerarios,
    ...MiSituacion,
    ...Empresa,
    ...Usuarios,
    ...Configuracion,
    ...BaseCreation,
    ...Buscador,
    ...GHAdmin,
    ...Soporte,
    ...Permisos,
    ...Creacion,
    ...Facturacion,
    ...FichaUsuario,
    ...Acciones,
    ...Evaluacion,
    ...Tutoria,
    ...DatosEvaluadosTutelados,
	...Asignar,
    ...PlatformPay
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    GhSubTabAssign: [
        {id: 1, value: 'ongoing_programs', call: 'assign_ongoing_programs'},
        {id: 2, value: 'programs_associate', call: 'assign_programs_associate'},
        // {id: 3, value: 'evaluators', call: 'assign_evaluators'},
    ],
    GhSubTabsQuestionary: [],
    GhSubTabsSearch: [
        {id:1,value:'itineraries', call: 'result_itineraries'}
    ],
    GhSubTabsMyItinerary: [
        {id:1, value: 'contracted_programs', call: 'contracted_programs'},
        {id:2, value: 'recomended_programs', call: 'recomended_programs'},
        {id:3, value: 'all_programs', call: 'all_programs'},
        {id:4, value: 'assigned_users', call: 'assigned_users'},
    ],
    GhSubTabsConfiguration: [
        {id: 1, value: 'company', call: 'config_company'},
        {id: 2, value: 'creation', call: 'config_creation'},
        {id: 3, value: 'benchmark', call: 'config_benchmark'},
        {id: 4, value: 'support', call: 'config_support'},
        {id: 5, value: 'referential', call: 'config_referential'},
        {id: 6, value: 'evaluators', call: 'config_evaluators'}
    ],
    GhSubTabsPerms: [
        {id: 1, value: 'groups', call: 'perms_groups'},
        {id: 2, value: 'company', call: 'perms_company'},
        {id: 3, value: 'user', call: 'perms_user'},
        {id: 4, value: 'evaluators', call: 'perms_evaluators'},
    ],
    GhSubTabsBaseCreation: [
        {id: 1, value: 'contents', call: 'base_creation_support_material'},
        {id: 2, value: 'exercises', call: 'config_exercises'},
    ],
    GhSubTabsCreation: [],
    GhTabs: [
        {id: 1, value: 'todo'},
        {id: 2, value: 'my_itineraries'},
        {id: 3, value: 'company'},
        {id: 4, value: 'perms'},
        {id: 5, value: 'configuration'},
        {id: 6, value: 'base_creation'},
        {id: 7, value: 'create'},
        {id: 8, value: 'searcher'},
        {id: 9, value: 'actions'},
        {id: 10, value: 'evaluation'},
        {id: 11, value: 'tutorships'},
        {id: 12, value: 'billing'},
        {id: 13, value: 'assignment'},
        {id: 14, value: 'support'},
        {id: 15, value: 'gh_admin'},
        {id: 16, value: 'user_file'}
    ],
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('token');

    if (to.path !== '/sso' &&  to.name !== 'login' && !isAuthenticated) {
        if(to.path === '/register' || to.path === '/colaboration'){
            next();
        }else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});

export default router;
