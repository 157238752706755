import ItineraryList from "@/view/itinerary/client/list_itinerary";
import ItineraryAssignment from "@/view/itinerary/client/template/assignment";

import ContractedPrograms from './contracted_programs/route';
import RecomendedPrograms from './recomended_programs/route';
import AllPrograms from './all_programs/route';
import AssignedUsers from './assigned_users/route';

const route = [
    {
        path: '/programs',
        name: 'my_itineraries',
        component: ItineraryList,
        props: {showMenu: true},
    },
    {
        path: '/my_itineraryes/assignment/:id',
        name: 'Assignment',
        component: ItineraryAssignment,
        props: {id: null, view: false, showMenu: true, otherTitle: 'user'},
    },
];

const routes = [
    ...route,
    ...ContractedPrograms,
    ...RecomendedPrograms,
    ...AllPrograms,
    ...AssignedUsers
]
export default routes;