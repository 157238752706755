import ListCompany from "@/view/config/company/list_company";

import SocialReason from './social_reason/route';
import ActivitySector from './activity_sector/route';
import NumerEmployees from './number_employees/route';
import PositionCompany from './position_company/route';

const route = [
    {
        path: '/config_company',
        name: 'config_company',
        component: ListCompany,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...SocialReason,
    ...ActivitySector,
    ...NumerEmployees,
    ...PositionCompany
]

export default routes;