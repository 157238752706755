import ConfigSealRange from "@/view/config/evaluators/seal_range/template/seal_range.vue";

const routes = [
    {
        path: '/config/evaluator/seal-range',
        name: 'config_seal_range',
        component: ConfigSealRange,
        props: {id: null, showMenu: true, otherTitle: 'seal_range'},
        children: [
            {
                path: '/config/evaluator/seal-range/:id',
                name: 'config_seal_range_id',
                component: ConfigSealRange,
                props: {id: null, showMenu: true, otherTitle: 'seal_range'},
            },
            {
                path: '/config/evaluator/seal-range/:id/:view',
                name: 'config_seal_range_id_view',
                component: ConfigSealRange,
                props: {id: null, view: true, showMenu: true, otherTitle: 'seal_range'},
            }
        ]
    }
];

export default routes;