var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhDropSelector',{attrs:{"datablock":{
            id: 'list_around',
            title: _vm.$t('environment'),
            second_title: '',
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_around"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.around.image,"width":"168"}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                    id: 'business_logo',
                                    class: '',
                                    name_hidden: 'around[image]',
                                    id_hidden: 'bibliography_other_image',
                                    icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: 'business_logo',
                                    style: ''
                                },"datainput":{
                                    view: _vm.$root._route.params.view,
                                    accept: '.png, .jpg, .jpeg',
                                    text: _vm.$t(_vm.haveImage ? 'change_logo' : 'attach_logo'),
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: _vm.ConfigAround.data.file_name,
                                    file_path: _vm.ConfigAround.data.file_path,
                                    file_download: _vm.ConfigAround.data.file_download,
                                    required: false
                                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-left":"50px"}},[_c('div',{staticClass:"gh-row-content selector-no-margin-bottom"},[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'label_around_language',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_environment_language') + ':',
                                    text: _vm.$t('environment_language') + '*',
                                    label: _vm.$t('environment_language'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true,
                                },"datainput":{
                                    id: 'value_around_language',
                                    name: 'around[language]'
                                },"selected_input":_vm.ConfigAround.data.language ? _vm.ConfigAround.data.language : [],"feed":_vm.getFeeds.feed_languages}})],1),_c('div',{staticClass:"gh-row-content T15_b"},[_c('label',{staticClass:"gh_text_field-label T14"},[_vm._v(_vm._s(_vm.$t('environment_palette') + ':'))])]),_c('div',{staticClass:"gh-row-content"},[_vm._l((_vm.getFeeds.feed_pallette),function(pallette,keyPallette){return _c('div',{key:keyPallette},[_c('div',{staticStyle:{"width":"80px","border-radius":"7px","margin-right":"10px","cursor":"pointer"},style:('border: 4px solid ' + (_vm.selectedPallette === pallette.id ? '#b3dbff' : 'black') + ';'),on:{"click":function($event){return _vm.checkPallette(pallette.id)}}},[_vm._l((pallette.pallette),function(colorPallette,keyColorPallette){return [_c('div',{key:pallette.id + keyColorPallette,staticStyle:{"height":"10px"},style:('background-color: ' + colorPallette + '; ' + ((pallette.pallette.length - 1) === keyColorPallette ? 'border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;' : keyColorPallette === 0 ? 'border-top-left-radius: 3px; border-top-right-radius: 3px;' : ''))})]})],2)])}),_c('input',{attrs:{"type":"hidden","name":"around[pallette]"},domProps:{"value":_vm.selectedPallette}})],2),_c('div',{staticClass:"gh-row-content T15_b"},[_c('label',{staticClass:"gh_text_field-label T14"},[_vm._v(_vm._s(_vm.$t('environment_font') + ':'))])]),_c('div',{staticClass:"gh-row-content"},_vm._l((_vm.getFeeds.feed_font),function(font,keyFont){return _c('div',{key:keyFont},[_c('GhChecks',{staticStyle:{"margin-right":"10px"},attrs:{"datalabel":{
                                        text: '',
                                        style: '',
                                        class: '',
                                        id: ''
                                    },"datainput":{
                                        disabled: false,
                                        required: false,
                                        selected: _vm.ConfigAround.data.font === font.id,
                                        type: 'radio',
                                        id: 'value_around_font',
                                        value: font.id,
                                        name: 'around[font]'
                                    }}}),_c('div',{staticStyle:{"margin-right":"25px"}},[_c('div',[_c('label',{staticClass:"T15",style:('font-family:\'' + font.font + '-Regular\'' +  '!important;')},[_vm._v(" "+_vm._s(_vm.$t('text_example')))])]),_c('div',[_c('label',{staticClass:"T15",style:('font-family:\'' + font.font + '-SemiBold\'' +  '!important;')},[_vm._v(" "+_vm._s(_vm.$t('text_example')))])]),_c('div',[_c('label',{staticClass:"T15",style:('font-family:\'' + font.font + '-Bold\'' +  '!important;')},[_vm._v(" "+_vm._s(_vm.$t('text_example')))])])])],1)}),0)])])]):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-bottom":"10px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save',
                        text: _vm.$t('save'),
                    }},on:{"click":_vm.preSubmitAround}}):_vm._e()],1)]},proxy:true}])}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'save_around_popup',
            title: _vm.$t('save'),
            type: 'info',
            style: 'width: 500px!important; min-width: 500px!important'
        },"bodyConfiguration":{id: 'save_around_body_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''},"footerConfiguration":{id: 'save_around_footer_popup', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('this_election_redifined_parametres')))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('are_you_sure')))])])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save_around',
                    text: _vm.$t('save'),
                }},on:{"click":_vm.submitAround}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'around_close_btn_popup',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.Global.closePopUp('save_around_popup')}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }