var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'grouper',title: _vm.$t('grouper')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_grouper"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('code') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'grouper_code',
                                    name: 'grouper[code]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.ConfigGroupers.data.code,
                                    type: 'text'
                                },"maxlength":"4"}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigGroupers.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'grouper_name',
                                    name: 'grouper[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.ConfigGroupers.data.name,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigGroupers.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                },"datainput":{
                                    id: 'grouper_description',
                                    name: 'grouper[description]',
                                    value: _vm.ConfigGroupers.data.description
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigGroupers.data.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'grouper_elements',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_elements') + ':',
                                    text: _vm.$t('elements') + '*',
                                    label: _vm.$t('elements'),
                                    type: 'checkbox',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                },"datainput":{
                                    id: 'value_grouper_elements',
                                    name: 'grouper[elements][]'
                                },"feed":_vm.getFeeds.feed_elements,"selected_input":_vm.ConfigGroupers.data.elements}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('elements') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.ConfigGroupers.data.elements).join(', ')))])])]],2),_c('div',{staticClass:"gh-row-content striped_white",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"},style:(!_vm.$root._route.params.view ? 'margin-top: -10px;' : '')},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeResponsible",attrs:{"datalabel":{text: _vm.$t('responsible'), style: 'width: 130px', id: 'label_grouper_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_responsible', value: 1, name: 'grouper[attributes][responsible][]',
                                        selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.responsible && _vm.ConfigGroupers.data.attributes.responsible[0] === '1',
                                        disabled: _vm.$root._route.params.view
                                    }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeResponsible', 'GhCheckAttributeResponsibleRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeResponsibleRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_responsible', value: 2, name: 'grouper[attributes][responsible][]',
                                        selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.responsible && _vm.ConfigGroupers.data.attributes.responsible[1] === '2',
                                        disabled: _vm.$root._route.params.view
                                    }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeResponsible', 'GhCheckAttributeResponsibleRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeInformation",attrs:{"datalabel":{text: _vm.$t('information'), style: 'width: 130px', id: 'label_grouper_information'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_information', value: 1, name: 'grouper[attributes][information][]',
                                        selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.information && _vm.ConfigGroupers.data.attributes.information[0] === '1',
                                        disabled: _vm.$root._route.params.view
                                    }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeInformation', 'GhCheckAttributeInformationRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeInformationRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_information'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_information', value: 2, name: 'grouper[attributes][information][]',
                                        selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.information && _vm.ConfigGroupers.data.attributes.information[1] === '2',
                                        disabled: _vm.$root._route.params.view
                                    }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeInformation', 'GhCheckAttributeInformationRequired', true)}}})],1)])]),_c('div',{staticClass:"gh-row-content striped",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeExamples",attrs:{"datalabel":{text: _vm.$t('examples'), style: 'width: 130px', id: 'label_grouper_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_examples', value: 1, name: 'grouper[attributes][examples][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.examples && _vm.ConfigGroupers.data.attributes.examples[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeExamples', 'GhCheckAttributeExamplesRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeExamplesRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_examples', value: 2, name: 'grouper[attributes][examples][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.examples && _vm.ConfigGroupers.data.attributes.examples[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeExamples', 'GhCheckAttributeExamplesRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeBibliography",attrs:{"datalabel":{text: _vm.$t('bibliography'), style: 'width: 130px', id: 'label_grouper_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_bibliography', value: 1, name: 'grouper[attributes][bibliography][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.bibliography && _vm.ConfigGroupers.data.attributes.bibliography[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeBibliography', 'GhCheckAttributeBibliographyRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeBibliographyRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_bibliography', value: 2, name: 'grouper[attributes][bibliography][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.bibliography && _vm.ConfigGroupers.data.attributes.bibliography[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeBibliography', 'GhCheckAttributeBibliographyRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeSupportMaterial",attrs:{"datalabel":{text: _vm.$t('materials'), style: 'width: 130px', id: 'label_grouper_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_support_material', value: 1, name: 'grouper[attributes][support_material][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.support_material && _vm.ConfigGroupers.data.attributes.support_material[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeSupportMaterial', 'GhCheckAttributeSupportMaterialRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeSupportMaterialRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_support_material', value: 2, name: 'grouper[attributes][support_material][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.support_material && _vm.ConfigGroupers.data.attributes.support_material[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeSupportMaterial', 'GhCheckAttributeSupportMaterialRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeGoodPractices",attrs:{"datalabel":{text: _vm.$t('good_practices'), style: 'width: 130px', id: 'label_grouper_good_practices'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_good_practices', value: 1, name: 'grouper[attributes][good_practices][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.good_practices && _vm.ConfigGroupers.data.attributes.good_practices[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeGoodPractices', 'GhCheckAttributeGoodPracticesRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeGoodPracticesRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_good_practices'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_good_practices', value: 2, name: 'grouper[attributes][good_practices][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.good_practices && _vm.ConfigGroupers.data.attributes.good_practices[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeGoodPractices', 'GhCheckAttributeGoodPracticesRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeTheory",attrs:{"datalabel":{text: _vm.$t('theory'), style: 'width: 130px', id: 'label_grouper_theory'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_theory', value: 1, name: 'grouper[attributes][theory][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.theory && _vm.ConfigGroupers.data.attributes.theory[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeTheory', 'GhCheckAttributeTheoryRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeTheoryRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_theory'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_theory', value: 2, name: 'grouper[attributes][theory][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.theory && _vm.ConfigGroupers.data.attributes.theory[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeTheory', 'GhCheckAttributeTheoryRequired', true)}}})],1)])])]),_c('div',{staticClass:"gh-row-content striped_white",staticStyle:{"margin-left":"160px","padding-top":"3px","padding-bottom":"5px","padding-left":"5px"}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"display":"flex","max-width":"400px","box-sizing":"border-box"}},[_c('GhChecks',{ref:"GhCheckAttributeStamp",attrs:{"datalabel":{text: _vm.$t('stamp'), style: 'width: 130px', id: 'label_grouper_stamp'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_stamp', value: 1, name: 'grouper[attributes][stamp][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.stamp && _vm.ConfigGroupers.data.attributes.stamp[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeStamp', 'GhCheckAttributeStampRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeStampRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_stamp'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_stamp', value: 2, name: 'grouper[attributes][stamp][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.stamp && _vm.ConfigGroupers.data.attributes.stamp[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeStamp', 'GhCheckAttributeStampRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckAttributeCertificate",attrs:{"datalabel":{text: _vm.$t('certificate'), style: 'width: 130px', id: 'label_grouper_certificate'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_responsible', value: 1, name: 'grouper[attributes][certificate][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.certificate && _vm.ConfigGroupers.data.attributes.certificate[0] === '1',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeCertificate', 'GhCheckAttributeCertificateRequired', false)}}}),_c('GhChecks',{ref:"GhCheckAttributeCertificateRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_grouper_required_certificate'},"datainput":{required: false, type: 'checkbox', id: 'value_grouper_required_certificate', value: 2, name: 'grouper[attributes][certificate][]',
                                            selected: _vm.ConfigGroupers.data.attributes && _vm.ConfigGroupers.data.attributes.certificate && _vm.ConfigGroupers.data.attributes.certificate[1] === '2',
                                            disabled: _vm.$root._route.params.view
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckAttributeCertificate', 'GhCheckAttributeCertificateRequired', true)}}})],1)])])])])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitGrouper}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }