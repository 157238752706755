<template>
    <div class="float_window">
        <GhLocation
            style="margin-top: -5px;"
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'OK',
                text: $t($root._route.params.type.replace('-', '_')),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />
        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'platform_pay',
                    title: $t($root._route.params.type.replace('-', '_'))
                }"
            >
                <template v-slot:content>
                    <template v-if="haveData">
                        <div class="centered">
                            <div class="element_block">
                                <div class="payment_box">
                                    <div class="centered">
                                        <div class="T14 header_payment">
                                            <div style="width: 70%">
                                                <GhChecks
                                                    :datalabel="{
                                                        text: $t('all_programs'),
                                                        id: 'label_recomended_itinerary_all_payment_products'
                                                    }"
                                                    :datainput="{
                                                        type: 'checkbox',
                                                        id: 'id_recomended_itinerary_all_payment_products',
                                                        value: 'all',
                                                        name: 'all'
                                                    }"
                                                    @click="checkAllElements()"
                                                    ref="ghCheckAllElements"
                                                />
                                            </div>
                                            <div style="width: 30%">
                                                {{$t('total_pay')}}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="centered">
                                        <div class="T14 header_payment">
                                            <div style="width: 70%; padding-top: 12px;">
                                                <div >
                                                    <form :id="'form_payment'">
                                                        <div v-for="(item, index) in PlatformPay.records_data" :key="index" style="padding-top: 5px; padding-bottom: 5px;">
                                                            <GhChecks v-if="item.payed === false || item.payed === undefined"
                                                                :itemVal="item"
                                                                :datalabel="{
                                                                    text: item.name,
                                                                    id: 'label_recomended_itinerary_' + item.id + '_payment_products'
                                                                }"
                                                                :datainput="{
                                                                    type: 'checkbox',
                                                                    id: 'id_recomended_itinerary_' + item.id + '_payment_products',
                                                                    value: item.id,
                                                                    name: 'pay[]'
                                                                }"
                                                                ref="GhCheckElements"
                                                                @click="isCheckAllElements(); toPay(item)"
                                                            />
                                                        </div>
                                                        <div class="gh-row-content" style="margin-top: 40px;">
                                                            <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('method_pay') + ':'}}</label>
                                                            <GhChecks v-for="(item,index) in getFeeds.feed_pay_methods" :key="'method_pay_' + index"
                                                                :datalabel="{
                                                                    text: $t(item.slug),
                                                                    style: 'width: 150px',
                                                                    id: 'label_target_' + index
                                                                }"
                                                                :datainput="{
                                                                    required: false,
                                                                    type: 'radio',
                                                                    id: 'value_target' + index,
                                                                    value: item.id,
                                                                    name: 'pay_method',
                                                                    selected: index === 0 ? true : false,
                                                                    disabled: false
                                                                }"
                                                            />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div style="width: 30%; padding-top: 12px;">
                                                <template v-if="checkToPay.length !== 0">
                                                    <div>
                                                        <div v-for="(item, index) in checkToPay" :key="index" style="padding-top: 5px; padding-bottom: 5px; width: 100%; display: flex">
                                                            <div class="container-element-info T15">
                                                                <div style="width: 70%">{{item.name}}</div>
                                                                <div style="padding-right: 5px" class="flex-align-right">{{item.price + ' €'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="container-element-info">
                                                        <div>{{$t('total')}}</div>
                                                        <div class="flex-align-right" style="padding-right: 5px">{{ total + ' €'}}</div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="centered" style="padding-bottom: 10px; margin-top: 15px;">
                            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                        </div>
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px; padding-bottom: 8px;">
                <template v-if="checkToPay.length !== 0">
                    <GhButton
                        :datainput="{
                            id: 'btn_make_payment',
                            text: $t('make_payment')
                        }"
                        @click="doPayment"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_cancel',
                        text: $t('cancel')
                    }"
                    @click="Global.windowClose()"
                />
            </div>

        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";

    import {mapState} from "vuex";

    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'platform_pay',
        components:{
            GhButton,
            GhNoDropSelector,
            GhLocation,
            GhChecks
        },
        computed: {
            ...mapState(['PlatformPay','getFeeds'])
        },
        data(){
            return {
                haveData: false,
                total: 0,
                checkToPay: []
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPayMethods', '', {root:true});
            await this.$store.dispatch('getDataPay', {
                type: this.$root._route.params.type
            }, {root: true});

            this.haveData = true;
        },
        methods: {
            checkAllElements(){
                var check = this.$refs.ghCheckAllElements.selected;

                for(var i=0; i < this.$refs.GhCheckElements.length;i++) {
                    if (check) {
                        if (!this.$refs.GhCheckElements[i].selected) {
                            this.$refs.GhCheckElements[i].selected = true;
                            this.toPay(this.$refs.GhCheckElements[i].$attrs.itemVal);
                        }
                    } else {
                        if (this.$refs.GhCheckElements[i].selected) {
                            this.$refs.GhCheckElements[i].selected = false;
                            this.toPay(this.$refs.GhCheckElements[i].$attrs.itemVal);
                        }
                    }
                }
            },
            isCheckAllElements(){
                var allChecked = 1;
                for(var i=0; i < this.$refs.GhCheckElements.length;i++){
                    if(!this.$refs.GhCheckElements[i].selected){
                        allChecked = 0;
                    }
                }

                this.$refs.ghCheckAllElements.selected = allChecked;
            },
            toPay(item){
                const pos = this.checkToPay.find((i) => i.id === item.id);
                if(pos === undefined) {
                    this.checkToPay.push(item);
                }else {
                    this.checkToPay.forEach((i, k) => {
                       if(i.id === item.id){
                           delete this.checkToPay[k];
                       }
                    });
                }

                this.checkToPay = this.checkToPay.filter(n => n);

                var countTotal = 0;
                this.checkToPay.forEach((item) => {
                    countTotal += parseFloat(item.price);
                });

                this.total = countTotal.toFixed(2);
            },
            async doPayment(){
                const formData = new FormData(document.getElementById('form_payment'));
                await this.$store.dispatch('setPay', {
                    formData: [...formData],
                    type: this.$root._route.params.type
                }, {root: true}).then((response) => {
                    if(response.status === 200){
                        window.opener.UpdatedSelect({
                            response: true
                        });
                        this.Global.windowClose();
                    }
                });
            },
        }
    }
</script>

<style>
    .payment_box{
        max-height: unset !important;
    }
</style>