var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticStyle:{"padding-top":"10px","padding-left":"15px","padding-bottom":"15px","padding-right":"15px"}},[_c('GhTreeActionBlock',{attrs:{"dataBlock":{
                id: 'tree_companies',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
            },"data":_vm.getterListPermissionCompanies},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [_c('router-link',{attrs:{"to":{name: 'permission_company_group_id', params: {id: Action.Action.Action.Action.id}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'btn_modify_company',
                            text: _vm.$t('modify'),
                            key_show_condition: '',
                            value_show_condition: '',
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }}})],1)]}}],null,false,3260688085)})],1)]):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }