import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
import Global from "@/store/Global";
Vue.use(vuex);

export default{
    state: {
        counters: '',
        elaboration_data: [],
        pending_data: [],
        enabled_data: [],
        historic_data: [],
        configuration_data: [],
        grouper_data: [],
        actual_grouper_id: '',
        refreshGrouperConfig: false,
        shouldDestroyComponent: 0,
        refreshSupport: 0
    },
    mutations: {
        destroyComponent(state) {
            state.shouldDestroyComponent++;
        },
        loadCountersByGrouper(state, payload) {
            state.counters = payload
        },
        loadElaborationByGrouper(state, payload) {
            state.elaboration_data = payload
        },
        loadActualGrouperId(state, payload) {
            state.actual_grouper_id = payload
        },
        loadPendingByGrouper(state, payload) {
            state.pending_data = payload
        },
        loadEnabledByGrouper(state, payload) {
            state.enabled_data = payload
        },
        loadHistoricByGrouper(state, payload) {
            state.historic_data = payload
        },
        loadConfigurationGrouper(state, payload) {
            state.refreshGrouperConfig = !state.refreshGrouperConfig;
            state.configuration_data = payload
        },
        loadCreationGrouperData(state, payload) {
            state.grouper_data = payload
        },
        loadMergeDisabledSupport(state, payload){
            if(payload['bibliography']){
                if(state.grouper_data.bibliography === undefined){
                    state.grouper_data['bibliography'] = [];
                }

                payload['bibliography'].forEach((i) => {
                    if(state.grouper_data['bibliography'].find((x) => x.id === i.id) === undefined){
                        state.grouper_data['bibliography'].push(i);
                    }
                });
            }

            if(payload['examples']){
                if(state.grouper_data.examples === undefined){
                    state.grouper_data['examples'] = [];
                }

                payload['examples'].forEach((i) => {
                    if(state.grouper_data['examples'].find((x) => x.id === i.id) === undefined){
                        state.grouper_data['examples'].push(i);
                    }
                });
            }

            if(payload['support_material']){
                if(state.grouper_data.support_material === undefined){
                    state.grouper_data['support_material'] = [];
                }

                payload['support_material'].forEach((i) => {
                    if(state.grouper_data['support_material'].find((x) => x.id === i.id) === undefined){
                        state.grouper_data['support_material'].push(i);
                    }
                });
            }

            if(payload['videography']){
                if(state.grouper_data.videography === undefined){
                    state.grouper_data['videography'] = [];
                }

                payload['videography'].forEach((i) => {
                    if(state.grouper_data['videography'].find((x) => x.id === i.id) === undefined){
                        state.grouper_data['videography'].push(i);
                    }
                });
            }

            state.refreshSupport++;
        },
        loadCleanGrouperData(state){
            state.grouper_data = [];
        }
    },
    getters: {
        getterGrouper(state){
            const dummy = state.refreshGrouperConfig; // eslint-disable-line no-unused-vars
            return state.configuration_data;
        },
    },
    actions: {
        cleanGrouperData(state){
            state.commit('loadCleanGrouperData');
        },
        async getConfigurationGrouper(state, item){
            await axios({
                url: API_URL + 'grouper/creation/' + item.id
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit("loadConfigurationGrouper", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCountersByGrouper(state, item){
            if(item.id !== undefined) {
                await axios({
                    url: API_URL + 'grouper/counter/' + item.id
                }).then(
                    async response => {
                        if(response.status === 200) {
                            state.commit("loadCountersByGrouper", response.data.data);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async getElaborationByGrouper(state, item){
            if(item.id !== undefined) {
                await axios({
                    url: API_URL + 'grouper/elaboration/' + item.id
                }).then(
                    async response => {
                        if(response.status === 200) {
                            state.commit("loadElaborationByGrouper", response.data.data);
                            state.commit("loadActualGrouperId", item.id);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async getPendingByGrouper(state, item){
            await axios({
                url: API_URL + 'grouper/pending/' + item.id
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit("loadPendingByGrouper", response.data.data);
                        state.commit("loadActualGrouperId", item.id);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getEnabledByGrouper(state, item){
            await axios({
                url: API_URL + 'grouper/enabled/' + item.id
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit("loadEnabledByGrouper", response.data.data);
                        state.commit("loadActualGrouperId", item.id);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getHistoricByGrouper(state, item){
            await axios({
                url: API_URL + 'grouper/historic/' + item.id
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit("loadHistoricByGrouper", response.data.data);
                        state.commit("loadActualGrouperId", item.id);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCreationGrouper(state, item){
            let requestLogin = API_URL + 'grouper/delete/' + item.id;
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(window.location.pathname === '/list_creation'){
                            await this.dispatch('getElaborationByGrouper',{id:state.state.actual_grouper_id});
                            await this.dispatch('getCountersByGrouper',{id:state.state.actual_grouper_id});
                        }else{
                            router.push({name: 'create'});
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeToHistoricCreationGrouper(state, item){
            let requestLogin = API_URL + 'grouper/to-historic/' + item.id;
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getPendingByGrouper',{id:state.state.actual_grouper_id});
                        await this.dispatch('getEnabledByGrouper',{id:state.state.actual_grouper_id});
                        await this.dispatch('getHistoricByGrouper',{id:state.state.actual_grouper_id});
                        await this.dispatch('getCountersByGrouper',{id:state.state.actual_grouper_id});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeVersionCreationGrouper(state, item){
            let requestLogin = API_URL + 'grouper/versioning/' + item.id;
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getEnabledByGrouper',{id:state.state.actual_grouper_id});
                        await this.dispatch('getPendingByGrouper',{id:state.state.actual_grouper_id});
                        await this.dispatch('getCountersByGrouper',{id:state.state.actual_grouper_id});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setNewUUIDGrouper(state, item){
            let request =  API_URL + 'grouper/new';

            let formData = new FormData();
            formData.append('grouper[grouper_id]', item.grouper);
            formData.append('grouper[type]', item.type);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        await router.push({
                            name: 'view_grouper_id',
                            params: {
                                grouper: item.grouper,
                                id: response.data.data,
                                type: item.type
                            }
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async checkGrouperBeforeDestroy(state, item){
            let request =  API_URL + 'groupers/destroy';

            let formData = new FormData();
            formData.append('grouper[grouper_id]', item.grouper);
            formData.append('grouper[id]', item.id);
            formData.append('grouper[type]', item.type);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            });
        },
        async getCreationGrouperData(state, item){
            let requestLogin = API_URL + 'grouper/data/' + item.id + (item.grouper_id !== undefined ? '/' + item.grouper_id : '') + (item.view !== undefined && item.view !== null ? '?view=true' : '');

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit('loadCreationGrouperData',response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCreationGrouperCopy(state, item){
            let requestLogin = API_URL + "grouper/copy/" + item.id;
            let formData = new FormData();

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        router.push({name: 'create'});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPreviewSelected(state, item){
            state.commit('loadMergeDisabledSupport', item.params);
        },
        async setItemCreateVersion(state, item){
            let requestLogin = API_URL + item.type + "/from-grouper/versioning/" + item.id;

            return await axios({
                method: "post",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setCreationGrouperData(state, item){
            let requestLogin = item.id ? API_URL + "grouper/" + item.type + "/" + item.id + (item.version ? '/version' : ''): API_URL + "grouper/" + item.type;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            state.rootState.BaseCreationExerciseTable.records.added.forEach((item,index) => {
                formData.append('grouper[metrics][added][' + index + '][name]', item.name);
                formData.append('grouper[metrics][added][' + index + '][description]', item.description);
            });

            state.rootState.BaseCreationExerciseTable.records.deleted.forEach((item) => {
                formData.append('grouper[metrics][deleted][]', item.id);
            });

            formData.append('grouper[metrics][deleted][]', item.id);

            if(item.version) {
                formData.append('grouper[version]', item.version);
            }

            if(item.formDataPopup !== undefined){
                item.formDataPopup.forEach((item) => {
                    formData.append(item[0], item[1]);
                });
            }

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    if(response.status === 200) {
                        state.commit('destroyComponent');

                        if(item.version === undefined){
                            if(item.type !== 'save') {
                                router.push({name: 'create'});
                            }

                            if(item.type === 'save' && response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data !== item.id){
                                router.push({name: 'view_grouper_id',params: {grouper: item.grouper, id: response.data.data, type: (item.type_url !== undefined ? item.type_url : 'elaboration')}});
                            }else if(item.type === 'save' && response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data === item.id){
                                await state.dispatch('getCreationGrouperData',{id: item.id, view: undefined},{root:true});
                            }

                            if(item.type === 'save'){
                                Global.openPopUp('popup_warning_save');
                            }
                        }else{
                            window.close();
                        }
                    }
                },
                error => {
                    return error;
                }
            )
        },
    }
};