import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        data: [],
        flagRecomendedPrograms: false
    },
    mutations: {
        loadRecomendedProgramsData(state, payload){
            state.flagRecomendedPrograms = !state.flagRecomendedPrograms;
            state.data = payload;
        }
    },
    getters: {
        getterRecomendedPrograms(state) {
            const flagTreeItinerary = state.flagRecomendedPrograms; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getRecomendedPrograms(state){
            let requestLogin = API_URL + "programs/recomended";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadRecomendedProgramsData", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};