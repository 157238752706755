var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticStyle:{"margin-left":"15px","margin-right":"15px","margin-bottom":"10px","margin-top":"10px"}},[_c('GhTreeActionBlock',{attrs:{"dataBlock":{
                id: 'tree_benchmark',
                actionWithoutChildren: true,
                actionWithChildren: false,
                extraLabelNumber: false,
                extraLabelText: '',
                loadStorageOpen: true
            },"data":_vm.ConfigBenchmark.records_data},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [_c('router-link',{attrs:{"to":{name: 'config_benchmark_id', params: {id: Action.Action.Action.Action.id}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_config_benchmark',
                            text: _vm.$t('modify'),
                            icon: require('@/assets/gh_new_dessign/edit.svg')
                        }}})],1)]}}],null,false,1796504725)})],1)]):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }