<template>
    <div class="centered">
        <div class="gh-content-global" style="width: 80%; min-width: 1024px;">
            <GhQuiz ref="quiz"
                :dataBlock="{
                    id: 'quizz_test',
                    text: dataQuizz.data_quizz.title,
                    icon: {
                        more: require('../../../../../assets/gh_new_dessign/arrowdown.svg'),
                        less: require('../../../../../assets/gh_new_dessign/arrowup.svg'),
                        info: require('../../../../../assets/gh_new_dessign/info-blue.svg'),
                        ok: require('../../../../../assets/gh_new_dessign/tick_green.svg'),
                        ko: require('../../../../../assets/gh_new_dessign/denied_red.svg')
                    },
                    name: 'quizz',
                    actualPage: dataQuizz.actual_page,
                    answer_question:{
                        width: '600'
                    },
                    header: header
                }"
                :data="dataQuizz.data_quizz"
            >
                <template v-slot:extraSlot="{itemProp, keyProp}">
                    <template v-if="itemProp.evidence !== false && itemProp.evidence !== 'false'">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" :title="stripHtmlTags(itemProp.text_evidence)" style="text-align: left; margin-left: 24px; overflow: hidden !important; white-space: nowrap !important; text-overflow: ellipsis !important; width: 70% !important;" v-html="itemProp.text_evidence"/>
                            <div>
                                <div class="quizz_upload" style="width: 30%;">
                                    <GhEasyUpload
                                        :class="itemProp.readonly ? 'disabled_attach' : ''"
                                        style="flex-direction: row-reverse;"
                                        :extrablock="{
                                            id: 'quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                                            class: '',
                                            style: 'flex-direction: row !important;',
                                            name_hidden: 'quizz[attach][' + itemProp.id + ']',
                                            id_hidden: 'example',
                                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                        }"
                                        :datalabel="{
                                            id: 'label_quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                                            style: ''
                                        }"
                                        :datainput="{
                                            text: $t('attach'),
                                            show_image: false,
                                            delete: false,
                                            href: 'easyUpload',
                                            downloadHref: 'easyDownload',
                                            file_name: itemProp.file_name,
                                            file_path: itemProp.file_path,
                                            file_download: itemProp.file_download,
                                            required: itemProp.evidence_required,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <span style="display: none">{{$parent.$parent.questionId = itemProp.id}}</span>
                    <span style="display: none">{{$parent.$parent.stateQuestion = itemProp.state}}</span>
                    <span style="display: none">{{$parent.$parent.pendingChildrens = itemProp.pending_childrens !== undefined ? itemProp.pending_childrens : false}}</span>
                    <template v-if="dataQuizz.support !== null && dataQuizz.support !== undefined && dataQuizz.support[itemProp.id] !== undefined && dataQuizz.support[itemProp.id].title !== undefined">
                        <div class="section_line">
                            <div class="T15_b" style="padding-left:13px;">{{dataQuizz.support[itemProp.id].title}}</div>
                        </div>
                        <div style="display: flex">
                            <div style="width: 50%;">
                                <div v-for="(element, keyElement) in dataQuizz.support[itemProp.id].elements.slice(0, Math.ceil(dataQuizz.support[itemProp.id].elements.length / 2))" :key="'E1' + keyElement + keyProp" class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="text-align: left;">{{element.title}}</label>
                                    </div>
                                    <div v-for="(material, keyMaterial) in element.materials" class="gh-row-content" style="padding-left: 15px; padding-top: 5px;" :key="'M' + keyMaterial">
                                        <template v-if="material.img !== undefined && material.img !== ''">
                                            <img :src="material.img" height="75" style="cursor: pointer; margin-right: 5px; margin-top: -2px; border-radius: 6px;" class="opimg" @click="getSupportContent(material)">
                                            <div>
                                                <div style="display: grid; align-content: center;">
                                                    <label class="gh_text_field-label T15" style="text-align: left; margin-top: -5px; margin-left: 10px;">{{material.title}}</label>
                                                    <GhAction style="padding-left: 10px;"
                                                        :dataction="{
                                                            id: 'edit_config_responsible',
                                                            text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                            icon: ''
                                                        }"
                                                        @click="getSupportContent(material)"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="text-align: left; margin-left: 0px;">{{material.title}}</label>
                                            <GhAction style="padding-left: 10px; margin-top: -2px;"
                                                :dataction="{
                                                    id: 'edit_config_responsible',
                                                    text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                    icon: ''
                                                }"
                                                @click="getSupportContent(material)"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 50%; padding-top: 12px;">
                                <div v-for="(element, keyElement) in dataQuizz.support[itemProp.id].elements.slice(Math.ceil(dataQuizz.support[itemProp.id].elements.length / 2))" :key="'E2' + keyElement + keyProp" class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="text-align: left;">{{element.title}}</label>
                                    </div>
                                    <div v-for="(material, keyMaterial) in element.materials" class="gh-row-content" style="padding-left: 15px; padding-top: 5px;" :key="'M2' + keyMaterial">
                                        <template v-if="material.img !== undefined && material.img !== ''">
                                            <img :src="material.img" height="75" style="cursor: pointer; margin-right: 5px; margin-top: -2px; border-radius: 6px;" class="opimg" @click="getSupportContent(material)">
                                            <div>
                                                <div style="display: grid; align-content: center;">
                                                    <label class="gh_text_field-label T15" style="text-align: left; margin-top: -5px; margin-left: 10px;">{{material.title}}</label>
                                                    <GhAction style="padding-left: 10px;"
                                                        :dataction="{
                                                            id: 'edit_config_responsible',
                                                            text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                            icon: ''
                                                        }"
                                                        @click="getSupportContent(material)"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="text-align: left; margin-left: 0px;">{{material.title}}</label>
                                            <GhAction style="padding-left: 10px; margin-top: -2px;"
                                                :dataction="{
                                                    id: 'edit_config_responsible',
                                                    text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                    icon: ''
                                                }"
                                                @click="getSupportContent(material)"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </GhQuiz>
        </div>
    </div>
</template>

<script>
    import GhQuiz from "fe-gh-quiz-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "quizz_list",
        props: ['dataQuizz'],
        components: {
            GhAction,
            GhQuiz,
            GhEasyUpload
        },
        data(){
            return {
                header: {
                    '1': [
                        {text: 'questions', field: 'question', style: 'min-width: 100px; max-width: 100px; width: 90%;'},
                        {text: '', field: 'yes', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'},
                        {text: '', field: 'no', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'}
                    ],
                    '2': [
                        {text: 'questions', field: 'question', style: 'min-width: 100px; max-width: 100px; width: 90%;'},
                        {text: 'true', field: 'true', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'},
                        {text: 'false', field: 'false', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'}
                    ],
                    '3': [
                        {text: 'questions', field: 'question', style: 'min-width: 100px; max-width: 100px; width: 90%;'},
                        {text: '', field: 'ok', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'},
                        {text: '', field: 'ko', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'}
                    ],
                    '4': [
                        {text: 'questions', field: 'question', style: 'min-width: 100px; max-width: 100px; width: 90%;'},
                        {text: '', field: 'check', field_component: true, style: 'min-width: 65px; max-width: 65px; width: 5%;'}
                    ]
                }
            }
        },
        methods:{
            getSupportContent(material){
                switch(material.type){
                    case 'view':
                        this.$parent.$parent.video.name = material.title;
                        this.$parent.$parent.video.url = material.url;
                        this.$parent.$parent.video.refresh++
                        var that = this;
                        setTimeout(function(){
                            that.Global.openPopUp('video_quizz_popup')
                        },100);
                        break;
                    default:
                        this.setTargetBlankAction(material);
                        break;
                }
            },
            setTargetBlankAction(material){
                const link = document.createElement('a');
                link.href = material.url;
                link.download = material.file_name;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            stripHtmlTags(htmlString) {
                let temporalDivElement = document.createElement("div");
                temporalDivElement.innerHTML = htmlString;
                return temporalDivElement.textContent || temporalDivElement.innerText || "";
            }
        }
    }
</script>

<style>
    .disabled_attach > div > .iupload_inputfile_anexar{
        opacity: .70;
        pointer-events: none;
    }

    .opimg{
        box-shadow: #5c5c5c 0px 0px 5px !important;
        opacity: 0.5;
        transition: opacity .6s ease-out;
        -moz-transition: opacity .6s ease-out;
        -webkit-transition: opacity .6s ease-out;
        -o-transition: opacity .6s ease-out;
    }

    .opimg:hover{
        box-shadow: #5c5c5c 0px 0px 5px !important;
        opacity: 1;
    }

    .center-inputs{
        text-align:justify;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }

    .quizz_upload > div > [id^="quizz_easy_upload_"][id$="attach_file_block"]{
        white-space: nowrap;
    }

    .quizz_upload > div > [id^="quizz_easy_upload_"][id$="easy_download_block"] > div{
        display: inline-grid;
    }

    .quizz_upload > div > [id^="quizz_easy_upload_"][id$="easy_download_block"] > div > [id^="btn_download_quizz_easy_upload_"][id$="attach_file"]{
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        width: 100% !important;
    }
    #list_header_answers_false{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
