<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'historic_actions'
            }"
            :header="header"
            :data="getterHistoricActionCompany"
        >
        </GhTableCommon>
    </div>
    <div v-else>
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>
<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapGetters} from "vuex";

    export default {
        name: "view_company_historic",
        props: ['id'],
        components: {
            GhTableCommon
        },
        computed:{
            ...mapGetters(['getterHistoricActionCompany'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'realized_action', field: 'realized_action', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'date_hour', field: 'date_hour', sorting: true, typeSort: 'date', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'contact_person', field: 'contact_person', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width:10%;'},
                    {text: 'note', field: 'note', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width:55%;'},
                    {text: 'document', field: 'document', sorting: true, typeSort: 'string', urlDocument:'download_document', style: 'min-width: 100px; max-width: 100px; width:15%;'}
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getHistoricByCompanyActions', {
                    id: this.id
                }, {root: true});

                this.haveData = true;
            }
        }
    }
</script>

