var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_start_date',
                text: _vm.$t('date_from'),
                style: 'min-width: 125px !important;  width: 125px !important; text-align: right;'
            },"datainput":{
                id: 'search_start_date',
                name: 'historical[start_date]',
                value: '',
            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_date_end',
                text: _vm.$t('date_until'),
                style: 'margin-right: 25px margin-left: 20px; min-width: 77px; !important; width: 40px !important;',
            },"datainput":{
                id: 'search_end_date',
                name: 'historical[end_date]',
                value: '',
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhSimpleSearch',{attrs:{"datalabel":{
                text: _vm.$t('company'),
                style: 'width: 102px; text-align:right',
                class: 'T15_b',
                id: 'label_search_business'
            },"datainput":{
                required: false,
                class: 'inputs',
                id: 'first_name',
                name: 'historical[business]',
                style: 'width: 15%',
                value: '',
                placeholder: '',
                href: _vm.dispatchBusiness
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }