<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="'KEY45' + labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'exercises'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: BaseCreationExercise.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import list_exercises from "@/view/base_creation/exercises/exercises/list_exercises.vue";
    import list_pending from "@/view/base_creation/exercises/exercises/list_pending.vue";
    import list_enabled from "@/view/base_creation/exercises/exercises/list_enabled.vue";
    import list_historic from "@/view/base_creation/exercises/exercises/list_historic.vue";
    import {mapState} from "vuex";
    export default {
        name: "list_exercises",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['BaseCreationExercise','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: this.$t('in_elaboration'), component: list_exercises, result: 'exercises', show: false, second_text: this.$t('results')},
                    {id: 2, name: this.$t('pending_activate'), component: list_pending, result: 'pending', show: true, second_text: this.$t('results')},
                    {id: 3, name: this.$t('enabled'), component: list_enabled, result: 'enabled', show: true, second_text: this.$t('results')},
                    {id: 4, name: this.$t('historic'), component: list_historic, result: 'historic', show: true, second_text: this.$t('results')},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getConfigExercisesCounters', '', {root: true});

                if (this.Login.tabs.length !== 0) {
                    this.Login.tabs[5]['sub-modules'][1]['sub-sections'].forEach((subroute, subkey) => {
                        if (this.data[subkey] !== undefined) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }

                this.haveData = true;
            }
        }
    }
</script>

<style>
    .class-table-exercise{
        padding: 15px;
        padding-top: 5px;
    }

    .class-table-exercise > table{
        min-width: 900px !important;
    }
</style>