var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.haveData ? 'padding-bottom: 15px; padding-right: 15px;' : '')},[(_vm.haveData)?_vm._l((_vm.TabUserFile.records_data.assignments),function(item,itemIndex){return _c('div',{key:itemIndex,staticStyle:{"padding-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                    id: 'drop_itinerary_' + item.id,
                    style: 'margin-left: 20px;',
                    title: item.text,
                    subTitle: item.data.length + ' ' + _vm.$t('results'),
                    isDrop: false
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                            id: 'table_itinerary_' + item.id,
                        },"header":_vm.header,"data":item.data.length == 0 ? [] : item.data}})]},proxy:true}],null,true)})],1)}):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }