<template>
    <div :key="'KEY23' + remountComponent" :style="$root._route.params.from === 'parent-grouper' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? 'margin-top: -4px;' : ''" class="float_window">
        <GhLocation v-if="$root._route.params.from === 'parent-grouper' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view)"
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: haveData ? 'OK' : '',
                text: TabCreation.actual_grouper,
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('new_incidence', {add: true}),'new_incidence')"
        />
        <div class="content_page">
            <GhNoDropSelector :datablock="{id: 'grouper', title: TabCreation.actual_grouper}">
                <template v-slot:content>
                    <template v-if="haveData">
                        <form id="form_grouper">
                            <input type="hidden" name="grouper[type_grouper]" :value="$root._route.params.grouper">
                            <div class="gh-content-global">
                                <div v-if="$root._route.params.type !== 'elaboration' && Object.values($root._route.params).length !== 1" class="gh-row-content">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.code !== undefined ? CreationGrouper.grouper_data.code : ''}}</label>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                        <GhInputTextField
                                            :datalabel="{
                                                text: $t('name') + '*',
                                                style: 'width: 150px; text-align: right;',
                                                class: 'T15_b'
                                            }"
                                            :datainput="{
                                                required: true,
                                                id: 'input_grouper_name',
                                                name: 'grouper[name]',
                                                style: 'width: 200px',
                                                value: inputName,
                                                type: 'text',
                                            }"
                                            ref="refInputName"
                                            @blur="persistanceName"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.name !== undefined ? CreationGrouper.grouper_data.name : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                        <GhTextarea
                                            style="margin-top: -5px; width: 100%;"
                                            :datalabel="{
                                                text: $t('description') + '*',
                                                class: 'T15_b',
                                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'textarea_grouper_description',
                                                name: 'grouper[description]',
                                                value: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.description !== undefined ? CreationGrouper.grouper_data.description : '',
                                                required: true,
                                                edited: true,
                                                image: {
                                                    subscript: require('@/assets/img/text/subscript.svg'),
                                                    superscript: require('@/assets/img/text/superscript.svg'),
                                                    bold: require('@/assets/img/text/text-bold.svg'),
                                                    italic: require('@/assets/img/text/text-italic.svg'),
                                                    underline: require('@/assets/img/text/text-underline.svg'),
                                                }
                                            }"
                                            ref="refTextareaDescription"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px !important; max-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.description !== undefined ? CreationGrouper.grouper_data.description : ''"></label>
                                    </template>
                                </div>
                                <div v-if="CreationGrouper.configuration_data !== undefined && CreationGrouper.configuration_data.information !== undefined && CreationGrouper.configuration_data.information.view" class="gh-row-content">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                        <GhTextarea
                                            style="margin-top: -5px; width: 100%;"
                                            :datalabel="{
                                                text: $t('information') + (CreationGrouper.configuration_data.information.required ? '*' : ''),
                                                class: 'T15_b',
                                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                            }"
                                            :datainput="{
                                                id: 'textarea_grouper_information',
                                                name: 'grouper[information]',
                                                value: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.information !== undefined ? CreationGrouper.grouper_data.information : '',
                                                required: CreationGrouper.configuration_data.information.required ? true : false,
                                                edited: true,
                                                image: {
                                                    subscript: require('@/assets/img/text/subscript.svg'),
                                                    superscript: require('@/assets/img/text/superscript.svg'),
                                                    bold: require('@/assets/img/text/text-bold.svg'),
                                                    italic: require('@/assets/img/text/text-italic.svg'),
                                                    underline: require('@/assets/img/text/text-underline.svg'),
                                                }
                                            }"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px !important; max-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.information !== undefined ? CreationGrouper.grouper_data.information : ''"></label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view  && $root._route.params.type !== 'enabled'">
                                        <GhTreeSelectorBlock
                                            :dataBlock="{
                                                type: 'radio',
                                                id: 'label_topic',
                                                label: $t('topic') + '*',
                                                title: $t('topic'),
                                                text: $t('select_topic') + ':',
                                                name: 'grouper[topic]',
                                                id_value: 'value',
                                                name_label: 'text',
                                                child_key: 'children',
                                                required: true,
                                                checkparentdata: true,
                                                style: '',
                                                label_style: 'width: 149px',
                                                popupStyle: 'min-width: 900px; width: 55%;'
                                            }"
                                            :selected_input="CreationGrouper.grouper_data.topic !== undefined && CreationGrouper.grouper_data.topic.length !== 0 ? CreationGrouper.grouper_data.topic : []"
                                            :items="getFeeds.feed_topic"
                                            ref="refTopic"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px !important; max-width: 150px; text-align: right;">{{$t('topic') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.topic !== undefined ? CreationGrouper.grouper_data.topic[0].text : ''}}</label>
                                    </template>
                                </div>
                                <div v-if="CreationGrouper.configuration_data !== undefined && CreationGrouper.configuration_data.responsible !== undefined && CreationGrouper.configuration_data.responsible.view" class="gh-row-content">
                                    <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                                        <GhTreeSelectorBlock
                                            :dataBlock="{
                                                type: 'radio',
                                                id: 'label_grouper_responsible',
                                                label: $t('responsible') + (CreationGrouper.configuration_data.responsible.required ? '*' : ''),
                                                title: $t('responsible'),
                                                text: $t('select_responsible') + ':',
                                                name: 'grouper[responsible]',
                                                id_value: 'value',
                                                name_label: 'text',
                                                child_key: 'children',
                                                required: CreationGrouper.configuration_data.responsible.required,
                                                checkparentdata: false,
                                                style: '',
                                                label_style: 'width: 149px',
                                                popupStyle: 'min-width: 900px; width: 55%;'
                                            }"
                                            :selected_input="CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.responsible !== undefined ? CreationGrouper.grouper_data.responsible : []"
                                            :items="getFeeds.feed_responsible"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px !important; max-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.responsible !== undefined && CreationGrouper.grouper_data.responsible[0] !== undefined ? CreationGrouper.grouper_data.responsible[0].text : ''}}</label>
                                    </template>
                                </div>
                                <div class="gh-row-content">
                                    <template v-if="!$root._route.params.view  && $root._route.params.type !== 'enabled'">
                                        <div>
                                            <GhChecks
                                                :datalabel="{
                                                    text: $t('structural'),
                                                    style: '',
                                                    class: '',
                                                    id: ''
                                                }"
                                                :datainput="{
                                                    disabled: false,
                                                    required: false,
                                                    selected: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.structural !== undefined && CreationGrouper.grouper_data.structural ? true : false,
                                                    type: 'checkbox',
                                                    id: 'checkbox_structural',
                                                    value: '1',
                                                    name: 'grouper[structural]'
                                                }"
                                                style="margin-left: 161px"
                                                ref="refCheckStructural"
                                                @click="showAddImage"
                                            />
                                            <template v-if="flagAddImage">
                                                <GhEasyUpload
                                                    :extrablock="{
                                                        id: 'grouper_image',
                                                        class: '',
                                                        name_hidden: 'grouper[image]',
                                                        id_hidden: 'hidden_grouper_image',
                                                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                                    }"
                                                    :datalabel="{
                                                        id: 'label_grouper_image',
                                                        style: ''
                                                    }"
                                                    :datainput="{
                                                        view: false,
                                                        accept: '.png, .jpg, .jpeg',
                                                        text: $t('attach_image'),
                                                        show_image: true,
                                                        delete: false,
                                                        href: 'easyUpload',
                                                        downloadHref: 'easyDownload',
                                                        file_name: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_name !== undefined ? CreationGrouper.grouper_data.image.file_name : '',
                                                        file_path: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_path !== undefined ? CreationGrouper.grouper_data.image.file_path : '',
                                                        file_download: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_download !== undefined ? CreationGrouper.grouper_data.image.file_download : '',
                                                        image_path: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.image_path !== undefined ? CreationGrouper.grouper_data.image.image_path : '',
                                                        required: false
                                                    }"
                                                />
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px !important; max-width: 150px; text-align: right;">{{$t('structural') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.structural !== undefined && CreationGrouper.grouper_data.structural ? $t('yes') : $t('no')}}</label>
                                        <template v-if="flagAddImage">
                                            <GhEasyUpload
                                                :extrablock="{
                                                    id: 'grouper_image',
                                                    class: '',
                                                    name_hidden: 'grouper[image]',
                                                    id_hidden: 'hidden_grouper_image',
                                                    icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                                }"
                                                :datalabel="{
                                                    id: 'label_grouper_image',
                                                    style: ''
                                                }"
                                                :datainput="{
                                                    view: true,
                                                    accept: '.png, .jpg, .jpeg',
                                                    text: $t('attach_image'),
                                                    show_image: true,
                                                    delete: false,
                                                    href: 'easyUpload',
                                                    downloadHref: 'easyDownload',
                                                    file_name: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_name !== undefined ? CreationGrouper.grouper_data.image.file_name : '',
                                                    file_path: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_path !== undefined ? CreationGrouper.grouper_data.image.file_path : '',
                                                    file_download: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.image !== undefined && CreationGrouper.grouper_data.image.file_download !== undefined ? CreationGrouper.grouper_data.image.file_download : '',
                                                    required: false
                                                }"
                                            />
                                        </template>
                                    </template>
                                </div>
                                <template v-if="CreationGrouper.grouper_data.pre_saved">
                                    <component
                                        v-if="CreationGrouper.configuration_data !== undefined && CreationGrouper.configuration_data.elements !== undefined && CreationGrouper.configuration_data.elements.view"
                                        v-bind:is="components.elements"
                                    />
                                    <component v-bind:is="components.support"/>
                                    <component v-bind:is="components.know_more"/>
                                    <template v-if="$root._route.params.type === 'enabled' || $root._route.params.type === 'historic'">
                                        <component v-bind:is="components.format"/>
                                    </template>
                                    <component v-bind:is="components.associate"
                                               v-if="CreationGrouper.configuration_data !== undefined && CreationGrouper.configuration_data.referentials !== undefined && CreationGrouper.configuration_data.referentials.view"
                                               :props="{
                                            name: 'grouper',
                                            selecteds:{
                                                groupers: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.groupers_associated !== undefined ? CreationGrouper.grouper_data.groupers_associated : [],
                                            }
                                        }"
                                    />
                                    <template v-if="CreationGrouper.configuration_data !== undefined && CreationGrouper.configuration_data.referentials !== undefined && CreationGrouper.configuration_data.referentials.view">
                                        <component
                                            v-bind:is="components.reference_thesauros"
                                            :props="{
                                                name:'grouper',
                                                selecteds:{
                                                    groupers: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.groupers !== undefined ? CreationGrouper.grouper_data.groupers : [],
                                                    topics: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.topics !== undefined ? CreationGrouper.grouper_data.topics : [],
                                                    exercises: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.exercises !== undefined ? CreationGrouper.grouper_data.exercises : [],
                                                    thesauros: CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.thesauros !== undefined ? CreationGrouper.grouper_data.thesauros : [],
                                                },
                                                view:{
                                                    groupers: true,
                                                    topics: false,
                                                    exercises: true,
                                                    thesauros: false
                                                },
                                                fixTopics: topicFixed
                                            }"
                                        />
                                    </template>

                                    <template v-if="CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.notes !== undefined && CreationGrouper.grouper_data.notes.length !== 0">
                                        <div class="section_line" style="margin-top: 20px;">
                                            <div class="T15_b">{{$t('situation')}}</div>
                                        </div>
                                        <div class="new-striped" style="margin-top: 10px;">
                                            <template v-for="(note, indexNote) in CreationGrouper.grouper_data.notes">
                                                <div style="padding-left: 5px; padding-top: 3px; padding-bottom: 3px; display: flex;" :key="'KEY22' + 'NOTE' + indexNote">
                                                    <div style="width: 100%; display: flex;">
                                                        <div class="T15" style="margin-left: 5px; margin-top: 1px;">{{note.text}}</div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </form>
                        <component v-bind:is="components.format_impartation" :info="popupFormatInfo"/>
                    </template>
                    <template v-else-if="!haveData">
                        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
                            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                        </div>
                    </template>
                </template>
            </GhNoDropSelector>
        </div>

        <div class="centered_footer" style="margin-top: 8px; padding-bottom: 8px;">
            <template v-if="haveData">
                <template v-if="$root._route.params.version && ($root._route.params.type === 'elaboration' || $root._route.params.type === 'pending')">
                    <GhButton v-if="CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.finish"
                        :datainput="{
                            id: 'btn_finish',
                            text: $root._route.params.version ? $t('enable') : $t('finish')
                        }"
                        @click="submit('finish')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="submit('save')"
                    />
                </template>
                <template v-else>
                    <GhButton v-if="!$root._route.params.version && !$root._route.params.view && CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.finish && $root._route.params.type === 'elaboration'"
                        :datainput="{
                            id: 'btn_finish',
                            text: $root._route.params.version ? $t('enable') : $t('finish')
                        }"
                        @click="submit('finish')"
                    />
                    <GhButton v-if="!$root._route.params.version && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="submit('save')"
                    />
                    <GhButton v-if="!$root._route.params.version && $root._route.params.type === 'pending' && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_enable',
                            text: $t('enable')
                        }"
                        @click="submit('enable')"
                    />
                    <GhButton v-if="!$root._route.params.version && $root._route.params.type === 'pending' && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_copy',
                            text: $t('copy')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','CreationGrouperCopy', $root._route.params.id,{'code': CreationGrouper.grouper_data.code}, $t('copy_grouper'), $t('copy_delete_grouper'), 'delete')"
                    />
                    <GhButton v-if="!$root._route.params.version && $root._route.params.type === 'pending' && !$root._route.params.view"
                        :datainput="{
                            id: 'btn_delete',
                            text: $t('delete')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','CreationGrouper', $root._route.params.id,{'code': CreationGrouper.grouper_data.code}, $t('delete_grouper'), $t('preparing_copy_grouper'), 'delete')"
                    />
                </template>
                <GhButton v-if="$root._route.params.version && $root._route.params.type !== 'elaboration' && $root._route.params.type !== 'pending'"
                    :datainput="{
                        id: 'btn_versioning',
                        text: $t('versioning')
                    }"
                    @click="setVersion"
                />
                <GhButton v-if="$root._route.params.type == 'pending' || $root._route.params.type == 'enabled' || (CreationGrouper.grouper_data !== undefined && CreationGrouper.grouper_data.finish)"
                    :datainput="{
                        id: 'btn_preview',
                        text: $t('preview')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('preview_grouper', {id:$root._route.params.id, type: 'grouper'}),'preview_grouper')"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $root._route.params.from === 'parent-grouper' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? $t('close') : $t('back')
                }"
                @click="$root._route.params.from === 'parent-grouper' || $root._route.params.version || ($root._route.params.type === undefined && $root._route.params.view) ? Global.windowClose() : router.push({name: 'create'})"
            />
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhLocation from "fe-gh-location-lib";

    import {mapGetters, mapState} from "vuex";

    import elements from "@/view/creation/templates/includes/elements.vue";
    import reference_thesauros from "@/view/base_creation/exercises/exercises/template/shared/reference_thesauros.vue";
    import support from "@/view/creation/templates/includes/support.vue";
    import format from "@/view/creation/templates/includes/format.vue";
    import associate from "@/view/base_creation/exercises/exercises/template/shared/associate.vue";
    import know_more from "@/view/creation/templates/includes/know_more.vue";
    import format_impartation from "@/view/creation/templates/popup/format_impartation.vue";
    import GhChecks from "fe-gh-checks-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    export default {
        name: "view_gropuer",
        computed:{
            ...mapState(['CreationGrouper','getFeeds','CreationGrouperElements','TabCreation']),
            ...mapGetters(['getterConditionsAssociated','getterItemsOrdered'])
        },
        components: {
            GhEasyUpload,
            GhChecks,
            GhLocation,
            GhTreeSelectorBlock,
            GhDataSelector,
            GhTextarea,
            GhInputTextField,
            GhNoDropSelector,
            GhButton
        },
        data(){
            return{
                haveData: false,
                components: {
                    reference_thesauros: reference_thesauros,
                    elements: elements,
                    format: format,
                    support: support,
                    associate: associate,
                    format_impartation: format_impartation,
                    know_more: know_more
                },
                popupFormatInfo: {
                    code: '',
                    name: '',
                    description: ''
                },
                topicFixed: [],
                remountComponent: 0,
                inputName: '',
                grouperParams: this.$root._route.params,
                version: false,
                flagAddImage: false
            }
        },
        async beforeMount(){
            if(this.$root._route.params.parent_grouper_id !== undefined){
                localStorage.setItem('is_saved_element', this.$root._route.params.id);
            }
            await this.mountedMethod();
            this.inputName = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.name !== undefined ? this.CreationGrouper.grouper_data.name : '';
            this.haveData = true;
        },
        mounted() {
            this.setRemountComponent();
        },
        async beforeDestroy(){
            await this.$store.dispatch('checkGrouperBeforeDestroy', this.grouperParams, {root: true});
        },
        watch:{
            async 'CreationGrouper.shouldDestroyComponent'(newValue) {
                if (newValue) {
                    this.haveData = false;
                    await this.mountedMethod();
                    this.haveData = true;
                }
            },
            async 'Global.state.temp_response.response'(data){
                await this.$store.dispatch('setChangeStateCondition', data,{root:true});
            }
        },
        methods:{
            setRemountComponent(){
                this.remountComponent++;
            },
            async mountedMethod(){
                const token = localStorage.getItem("token");
                if (token) {
                    this.CreationGrouper.grouper_data = [];
                    await this.$store.dispatch('cleanGrouperData', '', {root: true});
                    await this.$store.dispatch('setCleanGrouper', '', {root: true});
                    await this.$store.dispatch('setCleanSupport', '', {root: true});
                    await this.$store.dispatch('getConfigurationGrouper', {id: this.$root._route.params.grouper}, {root: true});
                    await this.$store.dispatch('getFeedTopic', '', {root: true});
                    await this.$store.dispatch('getExerciseGroupers', {id: this.$root._route.params.grouper}, {root: true});
                    await this.$store.dispatch('getFeedImpartation', '', {root: true});

                    this.CreationGrouperElements.temporal_conditions = [];

                    await this.$store.dispatch('getFeedResponsibles', '', {root: true});

                    if (this.$root._route.params.id !== undefined) {
                        await this.$store.dispatch('getCreationGrouperData', {
                            id: this.$root._route.params.id,
                            view: this.$root._route.params.view,
                            grouper_id: this.$root._route.params.parent_id
                        }, {root: true});

                        this.topicFixed = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.topic !== undefined ? this.CreationGrouper.grouper_data.topic : []

                        this.flagAddImage = this.CreationGrouper.grouper_data !== undefined && this.CreationGrouper.grouper_data.structural !== undefined ? this.CreationGrouper.grouper_data.structural : false;

                        await this.$store.dispatch('setItemsComposed', {
                            associate: this.CreationGrouper.grouper_data.associate
                        }, {root: true});
                    }

                    if (this.CreationGrouper.grouper_data.pre_saved && this.CreationGrouper.configuration_data !== undefined && this.CreationGrouper.configuration_data.referentials !== undefined && this.CreationGrouper.configuration_data.referentials.view) {
                        await this.$store.dispatch('getFeedReferenceThesauros', '', {root: true});
                        await this.$store.dispatch('getFeedExercises', '', {root: true});
                        await this.$store.dispatch('getFeedGroupers', '', {root: true});
                        await this.$store.dispatch('getFeedTopic', '', {root: true});
                    }
                }
            },
            submit(type){
                if(type === 'finish'){
                    if (this.Global.checkRequired('form_grouper') === 'OK') {
                        this.saveGrouper(type);
                    }
                }else if(type === 'enable'){
                    if (this.Global.checkRequired('form_grouper') === 'OK') {
                        this.popupFormatInfo.code = this.CreationGrouper.grouper_data.code;
                        this.popupFormatInfo.name = this.$refs.refInputName.datainput.value;
                        this.popupFormatInfo.description = this.$refs.refTextareaDescription.hiddenValue;
                        this.Global.openPopUp('popup_format_impartation');
                    }
                }else if(type === 'save'){
                    this.saveGrouper(type);
                }
            },
            saveGrouper(type){
                if(this.$root._route.params.parent_grouper_id !== undefined){
                    localStorage.setItem('is_saved_element', true);
                }
                const formData = new FormData(document.getElementById('form_grouper'));
                this.$store.dispatch('setCreationGrouperData', {
                    formData: [...formData],
                    type: type,
                    id: this.$root._route.params.id,
                    grouper: this.$root._route.params.grouper,
                    type_url: this.$root._route.params.type,
                    version: this.$root._route.params.version
                }, {root: true});
            },
            async setVersion(){
                if(this.$root._route.params.parent_grouper_id !== undefined){
                    localStorage.setItem('is_saved_element', true);
                }

                await this.$store.dispatch('setItemCreateVersion', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.path.split('/')[1]
                }, {root: true}).then((response) => {
                    if(response !== undefined){
                        if(this.$root._route.path.split('/')[1] === 'exercise'){
                            this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_id_version_float',{
                                parent_grouper_id: this.$root._route.params.parent_grouper_id,
                                parent_id: this.$root._route.params.parent_id,
                                element_id: this.$root._route.params.grouper,
                                id: response.id,
                                version: true,
                                type: response.type
                            }),'view_version', true);
                        }else{
                            this.Global.windowOpen(this.Global.openSecondWindow('config_grouper_id_version_float',{
                                parent_grouper_id: this.$root._route.params.parent_grouper_id,
                                parent_id: this.$root._route.params.parent_id,
                                grouper: this.$root._route.params.grouper,
                                id: response.id,
                                version: true,
                                type: response.type
                            }),'view_version', true);
                        }
                    }
                });
            },
            showAddImage(){
                this.flagAddImage = this.$refs.refCheckStructural.selected;
            },
            persistanceName(){
                this.inputName = this.$refs.refInputName.datainput.value;
            }
        }
    }
</script>

<style>
    #label_grouper_responsible_selector_label,#label_grouper_sector_selector_label{
        width: 150px;
        text-align: end;
    }
</style>