import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
//import axios from "axios";
//import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        records_data: '',
        data: [],
    },
    mutations: {
        loadGenericHolidays(state,payload){
            state.records_data = payload
        },
        loadGenericHolidaysData(state,payload){
            state.data = payload
        },
    },
    actions: {
        async getGenericHolidays(state){
            await axios.get(
                API_URL + "support/generic_holidays"
            ).then(
                response => {
                    state.commit('loadGenericHolidays', response.data.data);
                }
            )
        },
        async removeGenericHolidays(state, item){
            let requestLogin = API_URL + "support/generic_holidays/"+item.id;

            axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == '200') {
                        await this.dispatch('getGenericHolidays');
                        await this.dispatch('getConfigSupportCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setGenericHolidayData(state, item){
            let requestLogin = API_URL + (item.id !== undefined ? "support/generic_holidays/"+item.id : "support/generic_holidays");
            let formData = new FormData();

            item.formData.forEach((item) => {
                if(item[0] == 'generic_holiday[date]'){
                    var day = item[1].split('/')[0];
                    var month = item[1].split('/')[1];
                    formData.append('generic_holiday[day]', day);
                    formData.append('generic_holiday[month]', month);
                }else {
                    formData.append(item[0], item[1]);
                }
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        this.dispatch('getGenericHolidays')
                    }
                    router.push({name: 'configuration'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGenericHolidaysData(state, items){
            if(items.id === undefined){
                state.commit("loadGenericHolidaysData", '');
            }else {
                let requestLogin = API_URL + "support/generic_holidays/"+items.id;
                let formData = new FormData();
                formData.append("id", items.id);

                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadGenericHolidaysData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
    }
}
