import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        list_todo: [],
        list_to_validate: [],
        data_modify: '',
        data_assign: [],
        flagDataModify: false,
        flagAssignItineraryTodo: false,
        counter_todo: 0,
        counter_to_validate: 0
    },
    mutations: {
        loadListTodo(state, payload){
            state.list_todo = payload;
        },
        loadListToValidate(state, payload){
            state.list_to_validate = payload;
        },
        loadTodoCounters(state, payload){
            state.counter_todo = payload;
        },
        loadToValidateCounter(state, payload){
            state.counter_to_validate = payload;
        },
        loadDataModify(state, payload){
            state.flagDataModify = !state.flagDataModify;
            state.data_modify = payload;
        },
        loadAssignItineraryTodo(state, payload){
            state.flagAssignItineraryTodo = !state.flagAssignItineraryTodo;
            state.data_assign = payload;
        },
        loadResetAssignPerm(state, payload){
            state.flagAssignItineraryTodo = !state.flagAssignItineraryTodo;
            state.data_assign.data.find((i, k) => {
                if(i.id === payload.user_id){
                    i.permissions.find((x, w) => {
                        if(x === payload.permission_id){
                            delete state.data_assign.data[k].permissions[w];
                            state.data_assign.data[k].permissions = state.data_assign.data[k].permissions.filter(n => n);
                        }
                    });

                    delete state.data_assign.data[k].programs[payload.permission_id];
                }
            });
        },
        loadSetAssignPerm(state, payload){
            state.flagAssignItineraryTodo = !state.flagAssignItineraryTodo;
            state.data_assign.data.find((i, k) => {
                if(i.id === payload.user_id){
                    i.permissions.push(payload.permission_id);

                    let params = [];

                    payload.selected.forEach((element, key) => {
                        params[key] = {
                            id: element.id,
                            name: element.name
                        };
                    });

                    state.data_assign.data[k].programs[payload.permission_id] = params;
                }
            });
        }
    },
    getters: {
        getterDataModify(state) {
            const flagDataModify = state.flagDataModify; // eslint-disable-line no-unused-vars
            return state.data_modify;
        },
        getterAssignItineraryTodo(state) {
            const flagDataModify = state.flagAssignItineraryTodo; // eslint-disable-line no-unused-vars
            return state.data_assign;
        },
    },
    actions: {
        async getTodoCounter(state){
            await axios.get(
                API_URL + "counter_todo"
            ).then(
                response => {
                    state.commit('loadTodoCounters', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getToValidateCounter(state){
            await axios.get(
                API_URL + "counter_to_validate"
            ).then(
                response => {
                    state.commit('loadToValidateCounter', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListTodo(state){
            await axios.get(
                API_URL + "todo"
            ).then(
                response => {
                    state.commit('loadListTodo', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListToValidate(state){
            await axios.get(
                API_URL + "to-validate"
            ).then(
                response => {
                    state.commit('loadListToValidate', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getIdCompany(){
            return await axios.get(
                API_URL + "company/id"
            ).then(
                response => {
                    return response.data.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataModify(state,item){
            await axios.get(
                API_URL + "todo/end-date/" + item.id + "/" + item.agrupator_id
            ).then(
                response => {
                    state.commit('loadDataModify', {end_date:response.data.data,id:item.id,agrupator_id:item.agrupator_id});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDataModify(state, item){
            let requestLogin = API_URL + "todo/end-date/" + item.id + "/" + item.agrupator_id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response)
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getAssignItineraryTodo(state,item){
            await axios.get(
                API_URL + item.from + "/assign/itinerary/" + item.agrupator_id + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadAssignItineraryTodo', {
                            data: response.data.data,
                            id: item.id,
                            from: item.from,
                            agrupator_id: item.agrupator_id,
                            title: item.title
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async resetAssignPerm(state, item){
            state.commit('loadResetAssignPerm', item);
        },
        async setAssignPerm(state, item){
            state.commit('loadSetAssignPerm', item);
        },
        async setAssignItineraryTodo(state, item){
            let requestLogin = API_URL + item.from + "/assign/itinerary/" + item.agrupator_id + "/" + item.id;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200){
                        await this.dispatch('getContractedPrograms');
                    }

                    return response.status;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
