import ConfigHolidays from "@/view/config/support/holidays/template/holidays";

const routes = [
    {
        path: '/config/support/holidays',
        name: 'holidays',
        component: ConfigHolidays,
        props: {id: null, showMenu: true, otherTitle: 'holidays'},
        children: [
            {
                path: '/config/support/holidays/:id',
                name: 'holidays_id',
                component: ConfigHolidays,
                props: {id: null, showMenu: true,  otherTitle: 'holidays'},
            },
            {
                path: '/config/support/holidays/:id/:view/',
                name: 'holidays_view',
                component: ConfigHolidays,
                props: {id: null, showMenu: true,  otherTitle: 'holidays'},
            },
        ]
    }
];
export default routes;
