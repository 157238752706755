<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'seal',title: $t('stamp')}">
            <template v-slot:content>
                <form id="form_seal" v-if="haveData">
                    <div style="display: flex">
                        <div class="gh-content-global" style="width: 170px">
                            <div style="width: 168px; height: 168px;">
                                <img :src="image" width="168" alt="" style="border-radius: 6px; max-height: 168px;">
                            </div>
                            <div class="other-image">
                                <GhEasyUpload
                                    :extrablock="{
                                        id: 'seal_image',
                                        class: '',
                                        name_hidden: 'seal[image]',
                                        id_hidden: 'seal_image',
                                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: 'seal_image',
                                        style: 'margin-right: -15px'
                                    }"
                                    :datainput="{
                                        view: $root._route.params.view,
                                        accept: '.png, .jpg, .jpeg',
                                        text: $t(haveImage ? 'change_image' : 'attach_image'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: ConfigSeals.data.file_name,
                                        file_path: ConfigSeals.data.file_path,
                                        file_download: ConfigSeals.data.file_download,
                                        required: false
                                    }"
                                />
                            </div>
                        </div>
                        <div class="gh-content-global" style="width: 85%">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'seal_code',
                                            name: 'seal[code]',
                                            style: 'width: 200px',
                                            value: seals.code,
                                            type: 'text'
                                        }"
                                        ref="RefCode"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{seals.code}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'seal_name',
                                            name: 'seal[name]',
                                            style: 'min-width: 200px; width: 20%',
                                            value: seals.name,
                                            type: 'text'
                                        }"
                                        ref="RefName"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{seals.name}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                        }"
                                        :datainput="{
                                            id: 'seal_description',
                                            name: 'seal[description]',
                                            value: seals.description
                                        }"
                                        ref="RefDescription"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{seals.description}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTreeSelectorBlock
                                        :dataBlock="{
                                            type: 'radio',
                                            id: 'label_topic',
                                            label: $t('topic'),
                                            title: $t('topic'),
                                            text: $t('select_topic') + ':',
                                            name: 'seal[topic]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: true,
                                            checkparentdata: true,
                                            style: '',
                                            label_style: 'width: 149px',
                                            popupStyle: 'min-width: 900px; width: 55%;'
                                        }"
                                        :selected_input="ConfigSeals.data && ConfigSeals.data.topic ? ConfigSeals.data.topic : []"
                                        :items="getFeeds.feed_topic"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('topic')}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 40%;">{{ConfigSeals.data && ConfigSeals.data.topic ? ConfigSeals.data.topic[0].text : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="submitSeal"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "seal_template",
        components: {
            GhTreeSelectorBlock,
            GhEasyUpload,
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        computed: {
            ...mapState(['ConfigSeals', 'UploadFiles', 'getFeeds'])
        },
        data(){
            return {
                haveData: false,
                haveImage: false,
                image: '',
                seals: {
                    code: '',
                    name: '',
                    description: '',
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getSealsData', {id: this.$root._route.params.id}, {root: true});
                await this.$store.dispatch('getFeedTopic', '', {root: true});

                if (this.$root._route.params.id !== undefined) {
                    this.seals.code = this.ConfigSeals.data.code;
                    this.seals.name = this.ConfigSeals.data.name;
                    this.seals.description = this.ConfigSeals.data.description;
                }

                this.image = this.ConfigSeals.data.image !== null && this.ConfigSeals.data.image !== undefined ? this.ConfigSeals.data.image : require('../../../../../assets/gh_new_dessign/no-image.svg');
                this.haveImage = this.ConfigSeals.data.image !== null && this.ConfigSeals.data.image !== undefined;

                this.haveData = true;
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.saveTemporalValues();
                this.image = data.image;
                this.haveImage = true;
            }
        },
        methods: {
            saveTemporalValues(){
                this.seals.code = this.$refs['RefCode'].datainput.value;
                this.seals.name = this.$refs['RefName'].datainput.value;
                this.seals.description = this.$refs['RefDescription'].datainput.value;
            },
            async submitSeal(){
                if(this.Global.checkRequired('form_seal') === 'OK') {
                    const formData = new FormData(document.getElementById('form_seal'));
                    this.$store.dispatch('setSealData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #seal_image_easy_download_block{
        display: none !important;
    }
</style>