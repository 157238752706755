var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refName",attrs:{"datalabel":{
                    text: _vm.$t('name') + '*',
                    style:'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_name',
                    name: 'request_collaboration[personal_data][name]',
                    style: 'width:60%',
                    value: _vm.persistance.name,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refSurname",attrs:{"datalabel":{
                    text: _vm.$t('surnames') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_surname',
                    name: 'request_collaboration[personal_data][surname]',
                    style: 'width:60%',
                    value: _vm.persistance.surname,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refNif",attrs:{"datalabel":{
                    text: _vm.$t('nif_nie') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_nif',
                    name: 'request_collaboration[personal_data][nif]',
                    style: 'width:20%; min-width: 100px;',
                    value: _vm.persistance.nif,
                    type: 'text'
                }}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refFullAddress",attrs:{"datalabel":{
                    text: _vm.$t('full_address') + '*',
                    style:'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_full_address',
                    name: 'request_collaboration[personal_data][full_address]',
                    style: 'width:60%',
                    value: _vm.persistance.full_address,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refLocality",attrs:{"datalabel":{
                    text: _vm.$t('locality') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_locality',
                    name: 'request_collaboration[personal_data][locality]',
                    style: 'width:60%',
                    value: _vm.persistance.locality,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refCp",attrs:{"datalabel":{
                    text: _vm.$t('cp') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_cp',
                    name: 'request_collaboration[personal_data][cp]',
                    style: 'width:20%; min-width: 100px;',
                    value: _vm.persistance.cp,
                    type: 'text'
                }}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_country',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_country') + ':',
                    text: _vm.$t('country'),
                    label: _vm.$t('country'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_country',
                    name: 'request_collaboration[personal_data][country]'
                },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.getFeeds.feed_ho_is_spain},on:{"accept":_vm.searchCommunity}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{key:'refresh_community_' + _vm.refreshCommunity,ref:"refCommunity",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_community',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_community') + ':',
                    text: _vm.$t('community'),
                    label: _vm.$t('community'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_community',
                    name: 'request_collaboration[personal_data][community]'
                },"feed":_vm.getterCommunityByCountry !== undefined ? _vm.getterCommunityByCountry : [],"selected_input":[]},on:{"accept":_vm.searchProvince}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{key:'refresh_province_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_province',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_province') + ':',
                    text: _vm.$t('province'),
                    label: _vm.$t('province'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_province',
                    name: 'request_collaboration[personal_data][province]'
                },"feed":_vm.getterProvinceByCommunity !== undefined ? _vm.getterProvinceByCommunity : [],"selected_input":[]}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refEmail",attrs:{"datalabel":{
                    text: _vm.$t('email') + '*',
                    style: 'min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'company_email',
                    name: 'request_collaboration[personal_data][email]',
                    style: 'width: 60%',
                    value: _vm.persistance.email,
                    type: 'text',
                }}})],1),_c('div',{staticClass:"gh-row-column"},[_c('GhInputTextField',{ref:"refTelephone",attrs:{"datalabel":{
                    text: _vm.$t('telephone') + '*',
                    style: 'margin-left:20px; min-width: 150px !important; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'request_collaboration_personal_data_telephone',
                    name: 'request_collaboration[personal_data][telephone]',
                    style: 'width: 60%',
                    value: _vm.persistance.telephone,
                    type: 'int',
                }}})],1),_c('div',{staticClass:"gh-row-column"})]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refProfessionalExperience",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_professional_experience',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_professional_experience') + ':',
                    text: _vm.$t('professional_experience'),
                    label: _vm.$t('professional_experience'),
                    type: 'radio',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_professional_experience',
                    name: 'request_collaboration[personal_data][professional_experience]'
                },"feed":_vm.getFeeds.feed_professional_experience,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refSectorsExperience",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_sectors_experience',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_sectors_experience') + ':',
                    text: _vm.$t('sectors_experience'),
                    label: _vm.$t('sectors_experience'),
                    type: 'checkbox',
                    columns: 3,
                    required: false,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_sectors_experience',
                    name: 'request_collaboration[personal_data][sectors_experience][]'
                },"feed":_vm.getFeeds.feed_sectors_experience,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-column no-min-width"})]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhChecks',{attrs:{"datalabel":{
                    text: _vm.$t('disponibility_to_travel'),
                    style: '',
                    class: '',
                    id: ''
                },"datainput":{
                    required: true,
                    selected: false,
                    type: 'checkbox',
                    id: 'request_collaboration_personal_data_disponibility_to_travel',
                    value: 1,
                    style: 'margin-left: 160px;',
                    name: 'request_collaboration[personal_data][disponibility_to_travel]'
                }}})],1),_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{key:'refresh_communities_where_can_work_' + _vm.refreshCommunity,ref:"refCommunitiesWhereCanWork",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_communities_where_can_work',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_communities_where_can_work') + ':',
                    text: _vm.$t('communities_where_can_work'),
                    label: _vm.$t('communities_where_can_work'),
                    type: 'checkbox',
                    columns: 3,
                    required: false,
                    inputAll: true,
                    style: 'margin-left:20px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_communities_where_can_work',
                    name: 'request_collaboration[personal_data][communities_where_can_work][]'
                },"feed":_vm.getterCommunityByCountry !== undefined ? _vm.getterCommunityByCountry : [],"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-column"})]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column no-min-width"},[_c('GhDataSelector',{ref:"refLanguageWritingSkills",attrs:{"datablock":{
                    id: 'request_collaboration_personal_data_language_writing_skills',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_languages') + ':',
                    text: _vm.$t('language_writing_skills'),
                    label: _vm.$t('language_writing_skills'),
                    type: 'checkbox',
                    columns: 3,
                    required: false,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_request_collaboration_personal_data_language_writing_skills',
                    name: 'request_collaboration[personal_data][languages][]'
                },"feed":_vm.getFeeds.feed_languages_evaluator,"selected_input":[]}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }