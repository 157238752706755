var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'sector_benchmark',
            title: _vm.$t('benchmark') + ' - ' + (_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.country !== undefined ? _vm.ConfigBenchmark.data.country : '') + ' - ' + (_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.evaluation !== undefined ? _vm.ConfigBenchmark.data.evaluation : ''),
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_benchmark"}},[_c('input',{attrs:{"type":"hidden","name":"benchmarck[country_id]"},domProps:{"value":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.country_id !== undefined ? _vm.ConfigBenchmark.data.country_id : ''}}),_c('GhTableCommon',{staticStyle:{"margin-top":"20px"},attrs:{"extratable":{
                        id: 'table_benchmark_sectors',
                    },"header":_vm.header,"data":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.data !== undefined && _vm.ConfigBenchmark.data.data.sectors !== undefined && _vm.ConfigBenchmark.data.data.sectors.length !== 0 ? _vm.ConfigBenchmark.data.data.sectors : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('GhInputTextField',{attrs:{"datalabel":{
                                text: '',
                                style:'',
                                class: ''
                            },"datainput":{
                                required: true,
                                id: '',
                                name: 'benchmarck[sectors][' + itemProp.id +'][' + _vm.header.find(item => item.field === labelProp)['id'] +']',
                                style: '',
                                value: itemProp[labelProp],
                                type: 'float',
                                label_required: _vm.$t('column') + ': ' + _vm.header.find(item => item.field === labelProp)['text'] + ' ' + _vm.$t('row') + ': ' + itemProp['name'],
                            }}})]}}])}),_c('GhTableCommon',{staticStyle:{"margin-top":"20px"},attrs:{"extratable":{
                        id: 'table_benchmark_num_employees',
                    },"header":_vm.header,"data":_vm.ConfigBenchmark.data !== undefined && _vm.ConfigBenchmark.data.data !== undefined && _vm.ConfigBenchmark.data.data.num_employeees !== undefined && _vm.ConfigBenchmark.data.data.num_employeees.length !== 0 ? _vm.ConfigBenchmark.data.data.num_employeees : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('GhInputTextField',{attrs:{"datalabel":{
                                text: '',
                                style:'',
                                class: ''
                            },"datainput":{
                                required: true,
                                id: '',
                                name: 'benchmarck[num_employees][' + itemProp.id +'][' + _vm.header.find(item => item.field === labelProp)['id'] +']',
                                style: '',
                                value: itemProp[labelProp],
                                type: 'text',
                                label_required: _vm.$t('column') + ': ' + _vm.header.find(item => item.field === labelProp)['text'] + ' ' + _vm.$t('row') + ': ' + itemProp['name'],
                            }}})]}}])})],1)]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"15px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept',
                text: _vm.$t('accept'),
            }},on:{"click":_vm.submit}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }