<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_warning_content_text',
            title: $t('warning'),
            type: 'warning',
            style: 'width: 650px; min-width: 650px; max-width: 650px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: subTitle
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <div v-html="bodyText"/>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_close_popup_warning_content_text',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_warning_content_text');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'popup_content_text',
        props: ['subTitle', 'bodyText'],
        components: {
            GhPopUp,
            GhButton
        }
    }
</script>