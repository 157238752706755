var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_realized_action',
        title: _vm.$t('realized_action'),
        type: 'add',
        style: 'width: 50%; min-width:920px;',
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:'REALIZED_ACTION' + _vm.refreshPopup,attrs:{"id":"form_realized_action"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"right","width":"150px"}},[_vm._v(_vm._s(_vm.$t('realized_action') + '*'))]),_c('GhChecks',{ref:"RefRealizedActionCall",attrs:{"datainput":{
                            id: 'action_type_call',
                            value: 1,
                            type: 'radio',
                            name: 'action[type]',
                            style: 'cursor:pointer;',
                            required: true,
                            label_required: _vm.$t('realized_action') + '*'
                        },"datalabel":{
                            style: '',
                            class: '',
                            id: '',
                            text: ''
                        }},on:{"click":_vm.setRealizedAction}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('telephone_call')))]),_c('GhChecks',{ref:"RefRealizedActionMail",attrs:{"datainput":{
                            id: 'action_type_mail',
                            value: 2,
                            type: 'radio',
                            name: 'action[type]',
                            style: 'cursor:pointer;',
                            required: true,
                            label_required: _vm.$t('realized_action') + '*'
                        },"datalabel":{
                            style: '',
                            class: '',
                            id: '',
                            text: ''
                        }},on:{"click":_vm.setRealizedAction}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('mail_send')))])],1),(_vm.realizedAction === 1)?[_c(_vm.component.call,{tag:"component"})]:_vm._e(),(_vm.realizedAction === 2)?[_c(_vm.component.mail,{tag:"component"})]:_vm._e()],2)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.saveAction()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close',
                text: _vm.$t('close'),
            }},on:{"click":function($event){_vm.refreshPopup++; _vm.realizedAction = ''; _vm.Global.closePopUp('popup_realized_action')}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }