<template>
    <div>
        <div class="section_line" style="margin-top: 10px;">
            <div class="T15_b" style="padding-left:13px;">
                {{$t('type') + ': ' + ($root._route.params.view ? Object.values(ConfigTypeExercise.data.type[0])[1] : $parent.$parent.$refs['RefTypeExercise'].datasaved.text)}}
            </div>
        </div>
        <div class="gh-row-content" style="margin-top: 10px">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('possible_answers') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_possible_answers',
                        name: 'type_exercise[possible_answers]',
                        style: 'width: 50px',
                        value: getterPossibleAnswers.actual_possible_answer,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefPossibleAnswers"
                    @focusout="checkNumberQuestions"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('possible_answers') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleAnswers.actual_possible_answer}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('correct_answers') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_correct_answers',
                        name: 'type_exercise[correct_answers]',
                        style: 'width: 50px',
                        value: correct_answers,
                        type: 'int',
                        limitNumMin: 1,
                        limitNumMax: getterPossibleAnswers.actual_possible_answer
                    }"
                    ref="RefCorrectAnswers"
                />
                <div>
                    <GhChecks
                        style="margin-left: 10px;"
                        :datalabel="{text: $t('one_of'), style: 'width: 130px', id: 'label_type_exercise_one_of'}"
                        :datainput="{
                            required: false,
                            selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.one_of ? ConfigTypeExercise.data.config_type.one_of : false,
                            disabled: $root._route.params.view,
                            type: 'checkbox',
                            id: 'value_type_exercise_one_of',
                            value: 1,
                            name: 'type_exercise[one_of]'
                        }"
                        ref="GhCheckConfigEvidence"
                        @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                    />
                </div>
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('correct_answers') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.correct_answers}}</label>
            </template>
        </div>
        <div v-if="getterPossibleAnswers.possible_answer.length !== 0" style="padding-bottom: 10px; margin-top: 5px; padding-left: 160px">
            <GhTableCommon
                :extratable="{
                    id: 'possible_answers'
                }"
                :header="header"
                :data="getterPossibleAnswers.possible_answer"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field === 'answer'">
                        <GhInputTextField
                            :datalabel="{}"
                            :datainput="{
                                required: false,
                                id: 'type_exercise_possible_answers_' + itemProp.id,
                                name: 'type_exercise[answers]['+itemProp.id+']',
                                value: itemProp.answer,
                            }"
                            :ref="'RefPossibleAnswer' + itemProp.id"
                            @focusout="setPossibleAnswer(itemProp.id)"
                        />
                    </div>
                </template>
                <template v-slot:actions="{itemProp}">
                    <GhAction
                        :dataction="{
                            id: 'delete_possible_answer_' + itemProp['id'],
                            text: $t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                            item_prop: itemProp
                        }"
                        @click="deletePossibleAnswer(itemProp)"
                    />
                </template>
            </GhTableCommon>
        </div>
        <div class="gh-row-content" >
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_type_exercise_conditions',
                        class: 'T15_b',
                        subtitle: $t('select_conditions') + ':',
                        text: $t('conditions'),
                        label: $t('conditions'),
                        type: 'checkbox',
                        columns: 2,
                        style: '',
                        required: false
                    }"
                    :datainput="{
                        id: 'value_type_exercise_conditions',
                        name: 'type_exercise[conditions][]'
                    }"
                    :selected_input="ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? ConfigTypeExercise.data.config_type.conditions : []"
                    :feed="getFeeds.feed_condition"
                />
            </template>
            <template v-else>
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('conditions') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? Object.values(ConfigTypeExercise.data.config_type.conditions).join(', ') : ''}}</label>
                </div>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px; margin-top: -10px;">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[0] === 1,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[1] === 2,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.feedback[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('subquestion'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_subquestion'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_subquestion',
                    value: 1,
                    name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from 'fe-gh-checks-lib';

    import {mapGetters, mapState} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "type_close_n_correct",
        components: {
            GhAction, GhTableCommon,
            GhInputTextField,
            GhDataSelector,
            GhChecks
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds']),
            ...mapGetters(['getterPossibleAnswers'])
        },
        data(){
            return{
                header: [
                    {text: 'answers', field: 'answer', field_component: !this.$root._route.params.view, required: true, style: 'max-width: 10px; width: 100%; padding-right: 10px;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                correct_answers: ''
            }
        },
        mounted() {
            this.correct_answers = this.ConfigTypeExercise.data !== undefined && this.ConfigTypeExercise.data.config_type !== undefined && this.ConfigTypeExercise.data.config_type.correct_answers ? this.ConfigTypeExercise.data.config_type.correct_answers : '';
        },
        methods: {
            async checkNumberQuestions(){
                this.checkCorrectAnswers();
                await this.$store.dispatch('createTablePossibleAnswers', {
                    count: this.$refs['RefPossibleAnswers'].datainput.value
                }, {root: true});
            },
            async deletePossibleAnswer(item){
                this.checkCorrectAnswers();
                await this.getterPossibleAnswers.possible_answer.forEach((i, k) => {
                    if(i.id === item.id){
                        this.$store.dispatch('deleteTablePossibleAnswers', {
                            position: k
                        }, {root: true});
                    }
                });
            },
            async setPossibleAnswer(item){
                this.checkCorrectAnswers();
                await this.$store.dispatch('setTablePossibleAnswers', {
                    answer: this.$refs['RefPossibleAnswer' + item].datainput.value,
                    id: item
                }, {root: true});
            },
            checkCorrectAnswers(){
                this.correct_answers = (this.$refs['RefCorrectAnswers'].datainput.value >= this.getterPossibleAnswers.actual_possible_answer) || this.getterPossibleAnswers.actual_possible_answer === '' ? this.getterPossibleAnswers.actual_possible_answer - 1 : this.$refs['RefCorrectAnswers'].datainput.value;
                this.correct_answers = this.correct_answers === 0 || this.correct_answers === -1 ? '' : this.correct_answers;
            }
        }
    }
</script>