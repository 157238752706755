<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'perm_companies_' + label.id,
                    title: $t(label['name']),
                    label: PermsCompanies.counters[label.result] + ' ' + $t(label.second_text)
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import {mapState} from "vuex";

    import Companies from './companies/list_companies.vue'

    export default {
        name: 'list_groups',
        components: {
            GhNoDropSelector
        },
        computed: {
            ...mapState(['Login', 'PermsCompanies'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'companies', component: Companies, result: 'companies', show: false , second_text: 'results'},
                ],
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPermsCompanies', '', {root: true});

                this.haveData = true;

                if(this.Login.tabs.length !== 0){
                    this.Login.tabs[3]['sub-modules'][1]['sub-sections'].forEach((subroute,subkey) => {
                        if(this.data[subkey] !== undefined) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }
            }
        },
    }
</script>

<style>
    [id^="perm_companies_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }
</style>