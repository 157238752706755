var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{key:'COUNTRIES' + _vm.refreshData},[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
            id: 'countries'
        },"header":_vm.header,"data":_vm.ConfigCountries.records_data.length === 0 ? [] : _vm.ConfigCountries.records_data},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp}){return [_c('GhChecks',{ref:'RefCountry' + itemProp.id,attrs:{"datalabel":{
                    text: '',
                    style: '',
                    class: '',
                    id: ''
                },"datainput":{
                    disabled: itemProp.disabled,
                    required: false,
                    selected: itemProp.selected || _vm.actualSelecteds.find((i) => i === itemProp.id) === itemProp.id,
                    type: 'checkbox',
                    id: 'countries_' + itemProp.id,
                    value: itemProp.id,
                    name: 'countries[]'
                }},on:{"click":function($event){return _vm.checkCountry(itemProp.id)}}})]}},{key:"footer",fn:function(){return [(_vm.showButton)?_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_add_config_countries',
                        text: _vm.$t('accept')
                    }},on:{"click":_vm.acceptCountry}})],1):_vm._e()]},proxy:true}],null,false,2488821883)}):_vm._e()],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }