import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: ''
    },
    mutations: {
        loadSupportTheories(state, payload) {
            state.records_data = payload;
        },
        loadSupportTheoryData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getSupportTheories(state){
            await axios.get(
                API_URL + "theories"
            ).then(
                response => {
                    state.commit('loadSupportTheories', response.data.data);
                }
            )
        },
        async getSupportTheoryData(state, items){
            if(items.id === undefined){
                state.commit("loadSupportTheoryData", '');
            }else {
                let requestLogin = API_URL + "theory/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        console.log( response.data);
                        state.commit("loadSupportTheoryData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setSupportTheoryData(state, item){
            let requestLogin = item.id ? API_URL + "theory/"+item.id : API_URL + "theory";
            let formData = new FormData();

            formData.append('support_material[id]', item.id);
            item.formData.forEach((item) => {
                if(item[0] == 'support_material[other_image][file_path]' && item[1] == '')
                {
                    item[1] = state.state.data.other_image_path
                }
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadSupportTheoryData", response.data);
                    router.push({name: 'base_creation'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeSupportTheory(state, item){
            let requestLogin = API_URL + "delete-theory/" + item.id ;
            let formData = new FormData();

            formData.append('support_material[id]', item.id);
            formData.append('support_material[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getSupportTheories');
                        await this.dispatch('getBaseCreationSupportMaterialCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
