var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_config_group',title: _vm.$t('holidays')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"margin-top":"15px"}},[_c('GhTableCommon',{attrs:{"extratable":{
                        id: 'holidays',
                    },"header":_vm.header,"data":_vm.ConfigHolidays.data.length == 0 ? [] : _vm.ConfigHolidays.data},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                                id: 'delete_holiday',
                                text: _vm.$t('delete'),
                                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                item_prop: itemProp
                            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','TemporaryHoliday', itemProp.id,{'description': itemProp.description}, _vm.$t('delete_holiday'), _vm.$t('prepare_delete_holiday'), 'delete')}}})]}}:null,(!_vm.$root._route.params.view)?{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_user',
                                    text: _vm.$t('add')
                                }},on:{"click":function($event){return _vm.Global.openPopUp('add_new_holiday_popup');}}})],1)]},proxy:true}:null],null,true)})],1)]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
            },"type":"submit"},on:{"click":_vm.submitHoliday}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1),(!_vm.$root._route.params.view)?[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                  id: 'add_new_holiday_popup',
                  title: _vm.$t('new_holiday'),
                  type: 'add',
                  style: 'width: 60%; min-width:300px;',
               },"bodyConfiguration":{
                  id: '',
                  class: '',
                  style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;'
               },"footerConfiguration":{
                  id: '',
                  class: '',
                  style: '',
                  content: ''
               }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{key:_vm.refresh,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content",staticStyle:{"width":"100% !important"}},[_c('GhTextarea',{ref:"ref_holiday_description",attrs:{"datalabel":{
                                  text: _vm.$t('description') + '*',
                                  class: 'T15_b',
                                  styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                              },"datainput":{
                                  id: 'holiday_description',
                                  name: 'holiday[description]',
                                  required: true,
                                  value: '',
                                  style: 'width: 100%'
                              }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{ref:"ref_holiday_date",attrs:{"datalabel":{
                                  id: 'label_date_holiday',
                                  text: _vm.$t('data'),
                                  style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                              },"datainput":{
                                  id:'holiday_date',
                                  name:'holiday[date]',
                                  value: '',
                                  required: true,
                                  format: 'DD/MM',
                                  placeholder: 'DD/MM'
                              }}})],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                          id: 'button',
                          text: _vm.$t('save'),
                          class: '',
                          style: ''
                      },"extra-attr":"test"},on:{"click":function($event){_vm.setHoliday(); _vm.Global.closePopUp('add_new_holiday_popup');}}}),_c('GhButton',{attrs:{"datainput":{
                          id: 'btn_close_holiday_popup',
                          text: _vm.$t('close'),
                      }},on:{"click":function($event){return _vm.Global.closePopUp('add_new_holiday_popup');}}})]},proxy:true}],null,false,2941465994)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }