import RecomendedPrograms from "@/view/itinerary/client/recomended_programs/list_recomended_programs.vue";

const route = [
    {
        path: '/programs/recomended',
        name: 'recomended_programs',
        component: RecomendedPrograms,
        props: {id: null, showMenu: true, otherTitle: 'recomended_programs'},
    },
];

const routes = [
    ...route,
]

export default routes;