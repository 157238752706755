<template>
    <div v-if="haveData">
        <div :key="'refresh_evaluation_block_' + refreshEvaluation" style="margin-left: 15px; margin-right: 15px; margin-bottom: 15px;">
            <GhTreeActionBlock
                :dataBlock="{
                    id: 'tree_evaluation',
                    actionWithoutChildren: true,
                    actionWithChildren: true,
                    extraLabelNumber: false,
                    extraLabelText: '',
                    loadStorageOpen: true,
                    alternativeSummary: true,
                    alternativeValue: 'selected_text'
                }"
                :data="ConfigEvaluation.records_data !== undefined && ConfigEvaluation.records_data.length !== 0 ? ConfigEvaluation.records_data : []"
            >
                <template v-slot:Action="Action">
                    <div style="display: flex;">
                        <div class="T15_b" style="margin-right: 20px; width: 40px; text-align: right">
                            {{Action.Action.Action.Action.base_value}}
                        </div>
                        <div class="T15_b" style="margin-right: 20px; width: 40px; text-align: right">
                            {{Action.Action.Action.Action.base_ponderation}}
                        </div>
                        <div style="width: 150px">
                            <template v-if="Action.Action.Action.Action.is_item">
                                <GhAction
                                    :dataction="{
                                        id: 'associate_config_evaluation_basis',
                                        text: $t('associate'),
                                        icon: require(Action.Action.Action.Action.associated ? '../../../../assets/gh_new_dessign/denied.svg' : '../../../../assets/gh_new_dessign/tick.svg')
                                    }"
                                    @click="toAssociate(Action.Action.Action.Action)"
                                />
                            </template>
                            <template v-if="Action.Action.Action.Action.is_first">
                                <GhAction
                                    :dataction="{
                                        id: 'config_select_evaluation_basis',
                                        text: $t('evaluation_basis'),
                                        icon: require('../../../../assets/gh_new_dessign/edit.svg')
                                    }"
                                    @click="toEvaluationBasis(Action.Action.Action.Action)"
                                />
                            </template>
                        </div>
                    </div>
                </template>
            </GhTreeActionBlock>
        </div>

        <GhDataSelector
            :key="'EVALUATION_BASIS' + refreshElement"
            :datablock="{
                id: 'label_slector_parent_evaluation_basis',
                class: 'T15_b',
                subtitle: $t('select_evaluation_basis') + ' ' + $t('for').toLowerCase() + ': ' + itemName,
                text: $t('evaluation_basis'),
                label: $t('evaluation_basis'),
                type: 'radio',
                columns: 3,
                style: '',
                required: false,
            }"
            :datainput="{
                id: 'value_slector_parent_evaluation_basis',
                name: 'evaluation[evaluation_basis]'
            }"
            :selected_input="selected"
            :feed="getFeeds.feed_evaluation_basis_first_level"
            ref="RefEvaluationBasis"
            @accept="associateBaseEvaluation"
        />

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'associate_popup_evaluation',
                title: $t('associate'),
                type: 'add',
                style: 'width: 50%; min-width:920px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding-left: 50px !important; padding-right: 50px !important;',
                text: $t('select_associate') + ' ' + $t('for').toLowerCase() + ': ' + associateName
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <form id="form_associate_evaluation" :key="'form_associate' + refreshForm">
                        <div class="gh-row-content">
                            <GhTreeSelectorBlock
                                :dataBlock="{
                                    type: 'checkbox',
                                    id: 'label_slector_evaluation_criterion',
                                    label: $t('evaluation_criterion'),
                                    title: $t('evaluation_criterion'),
                                    text: $t('select_evaluation_criterion') + ':',
                                    name: 'evaluation[evaluation_criterion][]',
                                    id_value: 'value',
                                    name_label: 'text',
                                    child_key: 'children',
                                    required: false,
                                    checkparentdata: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                }"
                                :selected_input="selectedEvaluationBasis"
                                :items="feedEvaluationBasis"
                                ref="RefEvaluationCriterion"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('value_base') + '*',
                                    style: 'width: 160px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'evaluation_value_base',
                                    name: 'evaluation[value_base]',
                                    style: 'width: 30px',
                                    value: valueBase,
                                    type: 'float'
                                }"
                                ref="RefValueBase"
                            />
                        </div>
                        <div class="gh-row-content" id="element_evaluation_weighting_elemen">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_evaluation_weighting_element',
                                    class: 'T15_b',
                                    subtitle: $t('select_weighting_element') + ':',
                                    text: $t('weighting_element'),
                                    label: $t('weighting_element'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_evaluation_weighting_element',
                                    name: 'evaluation[weighting_element]'
                                }"
                                :selected_input="selectedWeightingElement"
                                :feed="getFeeds.feed_weighting_elements"
                                ref="RefWeightingElement"
                                @accept="checkWeightingElement"
                            />
                        </div>
                        <template v-if="showValueWeighting">
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('value_ponderation'),
                                        style: 'width: 160px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'evaluation_value_ponderation',
                                        name: 'evaluation[value_ponderation]',
                                        style: 'width: 30px',
                                        value: valuePonderation,
                                        type: 'float'
                                    }"
                                    ref="RefValuePonderation"
                                />
                            </div>
                        </template>
                    </form>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_accept_associate',
                        text: $t('accept')
                    }"
                    @click="associate"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_associate',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp( 'associate_popup_evaluation')"
                />
            </template>
        </GhPopUp>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";

    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: "list_evaluation",
        components: {
            GhInputTextField,
            GhTreeSelectorBlock,
            GhAction,
            GhPopUp,
            GhTreeActionBlock,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['ConfigEvaluation', 'getFeeds'])
        },
        data(){
            return {
                feedEvaluationBasis: [],
                selectedEvaluationBasis: [],
                selectedWeightingElement: [],
                valueBase: '',
                valuePonderation: '',
                refreshForm: 0,
                associateId: 0,
                refreshEvaluation: 0,
                haveData: false,
                selected: [],
                itemName: '',
                associateName: '',
                refreshElement: 0,
                showValueWeighting: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedEvaluationBasis', '', {root: true});
                await this.$store.dispatch('getFeedEvaluationBasisOnlyFirstLevel', '', {root: true});
                await this.$store.dispatch('getFeedWeightingElements', '', {root: true});
                await this.$store.dispatch('getEvaluation', '', {root: true});
                this.haveData = true;
            }
        },
        methods:{
            async toAssociate(item){
                this.associateId = item.id;
                this.associateName = item.name;

                await this.$store.dispatch('getAssociateEvaluation', {
                    id: item.id,
                }, {root: true}).then((response) => {
                    if(response !== undefined) {
                        if(response.status === 200){
                            this.feedEvaluationBasis = response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.feed !== undefined ? response.data.data.feed : [];
                            this.selectedEvaluationBasis = response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.evaluation_basis !== undefined ? response.data.data.evaluation_basis : [];
                            this.selectedWeightingElement = response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.weigthing_elements !== undefined ?  response.data.data.weigthing_elements : [];
                            this.valueBase = response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.value_base !== undefined ?  response.data.data.value_base : '';
                            this.valuePonderation = response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.value_weigthing !== undefined ?  response.data.data.value_weigthing : '';

                            this.showValueWeighting = Object.keys(this.selectedWeightingElement).length !== 0;
                        }
                    }
                });

                this.refreshForm++;
                this.Global.openPopUp('associate_popup_evaluation');

                let that = this;
                setTimeout(function(){
                    that.findLeafNodes(that.feedEvaluationBasis);
                },200);
            },
            toEvaluationBasis(item){
                this.associateId = item.id;
                this.selected = item.selected;
                this.itemName = item.name;
                this.refreshElement++;

                setTimeout(() => {
                    this.$refs.RefEvaluationBasis.statusPopup();
                    this.Global.openPopUp('label_slector_parent_evaluation_basis_data_selector_popup');
                },10);
            },
            async associate(){
                if(this.Global.checkRequired('form_associate_evaluation') === 'OK') {
                    const formData = new FormData(document.getElementById('form_associate_evaluation'));
                    await this.$store.dispatch('setAssociateEvaluation', {
                        formData: [...formData],
                        id: this.associateId
                    }, {root: true});
                    await this.$store.dispatch('getEvaluation', '', {root: true})
                    this.refreshEvaluation++;
                    this.Global.closePopUp('associate_popup_evaluation');
                }
            },
            async associateBaseEvaluation(){
                await this.$store.dispatch('setAssociateEvaluation', {
                    selected: Object.keys(this.$refs.RefEvaluationBasis.checkdata)[0],
                    id: this.associateId
                }, {root: true});
                await this.$store.dispatch('getEvaluation', '', {root: true})
                this.refreshEvaluation++;
            },
            findLeafNodes(nodes) {
                let leaves = [];

                nodes.forEach(node => {
                    if (!node.children || node.children.length === 0) {
                        leaves.push(node);
                        const childElement = document.getElementById(node.value + '-label_slector_evaluation_criterion');

                        if (childElement) {
                            const parentElement = childElement.parentElement;
                            const grandParentElement = parentElement ? parentElement.parentElement : null;

                            if (grandParentElement) {

                                grandParentElement.style.marginLeft = '-10px';
                                setTimeout(() => {
                                    grandParentElement.removeChild(parentElement);
                                }, 100);
                            }
                        }
                    } else {
                        leaves = leaves.concat(this.findLeafNodes(node.children));
                    }
                });

                return leaves;
            },
            checkWeightingElement(){
                console.log(this.$refs['RefValueBase']);
                this.showValueWeighting = Object.keys(this.$refs['RefWeightingElement'].checkdata).length !== 0;
                this.valueBase = this.$refs['RefValueBase'] !== undefined ? this.$refs['RefValueBase'].datainput.value : '';
                this.valuePonderation = this.$refs['RefValuePonderation'] !== undefined ? this.$refs['RefValuePonderation'].datainput.value : '';
            }
        }
    }
</script>

<style>
[id^="tree_evaluation-"] > div > .table-cell_action {
    margin-top:-2px;
}
#label_evaluation_weighting_element_selector_label{
    width: 160px;
    text-align: right;
}
#label_slector_evaluation_criterion_selector_label{
    width: 160px;
    margin-left: -34px;
    text-align: right;
}
#label_slector_parent_evaluation_basis_data_block_selector_operators, #label_slector_parent_evaluation_basis_selector_label, #label_slector_parent_evaluation_basis_data_select_SUMMARY{
    display: none !important;
}

#element_evaluation_weighting_elemen > div > div {
    margin-bottom: unset !important;
}
</style>