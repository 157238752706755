var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
            id: 'enabled'
        },"header":_vm.header,"data":_vm.CreationGrouper.enabled_data !== undefined && _vm.CreationGrouper.enabled_data.length !== 0 ? _vm.CreationGrouper.enabled_data : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'view_grouper_id_view', params: {grouper: _vm.id_grouper, id: itemProp.id, view: true,type:'enabled'}}}},[_c('a',{staticClass:"table-cell_link T13"},[_c('b',[_vm._v(_vm._s(itemProp[labelProp]))])])])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'versioning_config_grouper',
                    text: _vm.$t('versioning'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg')
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','VersionCreationGrouper', itemProp.id,{'code': itemProp.code,'name': itemProp.name, 'description': itemProp.description, 'responsible': itemProp.responsible, 'topic': itemProp.topic, 'impartation': itemProp.impartation}, _vm.$t('versioning_grouper'), _vm.$t('preparing_versioning_grouper'), 'delete')}}}),_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_config_grouper',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg')
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','ToHistoricCreationGrouper', itemProp.id,{'code': itemProp.code,'name': itemProp.name, 'description': itemProp.description, 'responsible': itemProp.responsible, 'topic': itemProp.topic, 'impartation': itemProp.impartation}, _vm.$t('delete_grouper'), _vm.$t('preparing_delete_grouper'), 'delete')}}})]}}],null,false,197292372)}):_vm._e()],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }