import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        data: ''
    },
    mutations: {
        loadAroundData(state, payload) {
            state.data = payload;
        }
    },
    actions: {
        async getAroundData(state){
            let requestLogin = API_URL + "user/company/environment";
            let formData = new FormData();
            await axios({
                method: "get",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadAroundData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setAroundData(state, item){
            let requestLogin = API_URL + "user/company/environment";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadAroundData", response.data);
                    state.dispatch("SET_LOGOUT");
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
