import EvaluationList from "@/view/evaluation/list_evaluations";
import Evaluation from './template/route';

const route = [
    {
        path: '/evaluations',
        name: 'evaluation',
        component: EvaluationList,
        props: {showMenu: true},
    }
];

const routes = [
    ...route,
    ...Evaluation
];

export default routes;