<template>
    <div v-if="haveData">
        <GhTableCommon
            v-if="haveData"
            :extratable="{
                id: 'actions_state_' + id
            }"
            :header="header"
            :data="getterListActionsByType[id] === undefined || getterListActionsByType[id].results === undefined || getterListActionsByType[id].results.length === 0 ? [] : getterListActionsByType[id].results"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <template v-if="labelProp === 'company_name' || labelProp === 'cif'">
                    <router-link :to="{name: 'actions_company_view', params: { id: itemProp.id, view: true}}">
                        <a :style="itemProp.flag === 1 ? 'color: red; font-weight: bold;' : (itemProp.flag === 2 ? 'color: red;' : '')" class="table-cell_link T13"> {{itemProp[labelProp]}} </a>
                    </router-link>
                </template>
                <template v-else>
                    <span :style="itemProp.flag === 1 ? 'color: red; font-weight: bold;' : (itemProp.flag === 2 ? 'color: red;' : '')"> {{itemProp[labelProp]}} </span>
                </template>
            </template>
            <template v-slot:actions="{itemProp}">
                <template v-if="getterListActionsByType[id].actions !== undefined && getterListActionsByType[id].actions.action">
                    <router-link :to="{name: 'actions_company_id', params: {id:itemProp.id}}">
                        <GhAction
                            :dataction="{
                                id: 'execute_action',
                                text: $t('action'),
                                icon: require('../../../assets/gh_new_dessign/edit.svg'),
                            }"
                        />
                    </router-link>
                </template>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib"
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: "list_companies",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterListActionsByType'])
        },
        props:['id'],
        data(){
            return {
                haveData:false,
                header: [
                    {text: 'company_name', field: 'company_name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'cif', field: 'cif', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 100px; width: 7%;'},
                    {text: 'sector', field: 'sector', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'n_employees', field: 'n_employees', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 100px; width: 7%;'},
                    {text: 'state', field: 'state', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 19.25%;'},
                    {text: 'last_login', field: 'last_login', urlRedirect: true, sorting: true, typeSort: 'date', style: 'min-width: 80px; max-width: 85px; width: 7%;'},
                    {text: 'n_actions', field: 'n_actions', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 7%;'},
                    {text: 'last_action', field: 'last_action', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 80px; max-width: 80px; width: 14.25%;'},
                    {text: 'date', field: 'date', urlRedirect: true, sorting: true, typeSort: 'date', style: 'min-width: 80px; max-width: 85px; width: 85px;'},
                    {text: '', field: 'actions', style: ''},
                ],
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getListCompanyState', {
                    'id': this.id
                }, {root: true});

                if(this.getterListActionsByType[this.id] !== undefined && this.getterListActionsByType[this.id].actions !== undefined && this.getterListActionsByType[this.id].actions.action === false){
                    delete this.header[9];
                    this.header = this.header.filter(n => n);
                }

                this.haveData = true;
            }
        },
    }
</script>
