<template>
    <div :style="$root._route.params.windowed ? 'margin-top: -3px' : ''">
        <GhLocation v-if="$root._route.params.windowed"
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('example')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector :datablock="{id: 'example',title: $t('example')}">
                <template v-slot:content>
                    <form id="form_example" v-if="haveData">
                        <component v-bind:is="from_example" />
                    </form>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <div class="centered_footer" style="margin-top: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_save',
                        text: $t('save')
                    }"
                    @click="submitExample"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $root._route.params.windowed ? $t('close') : $t('back'),
                    }"
                    @click="$root._route.params.windowed ? close() : router.go(-1)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import from_example from './form_example';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    import GhLocation from "fe-gh-location-lib";
    export default {
        name: "example_template",
        components: {
            GhLocation,
            from_example,
            GhNoDropSelector,
            GhButton
        },
        data(){
            return {
                haveData: false,
                from_example: from_example
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getExampleData', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async submitExample(){
                if(this.Global.checkRequired('form_example') === 'OK') {
                    const formData = new FormData(document.getElementById('form_example'));
                    this.$store.dispatch('setExampleData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            close(){
                window.close();
            }
        },
    }
</script>