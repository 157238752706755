<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('evaluation')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'evaluation',
                    title: $t('evaluation'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_evaluation" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <div style="display: flex; width: 100%;">
                                    <div style="display: flex; margin-top: 1px; text-align: right;" >
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 149px; min-width: 149px; text-align: right;" >{{'documents'}}</label>
                                    </div>
                                    <template v-if="TabEvaluation.evalData !== undefined">
                                        <div class=" T15" style="width: 100%;" v-if="TabEvaluation.evalData.files !== undefined && TabEvaluation.evalData.files.length !== 0">
                                            <div v-for="(item, index) in TabEvaluation.evalData.files" :key="index">
                                                <div style="display: flex;" :style="index % 2 == 0 ? 'background-color: #FFFFFF;' : 'background-color: #E9F7FF;'">
                                                    <div class="gh_text_field-label T15_b gh_hidden_list-inline_content-value" data-row-index="1" style="width: 100%;">
                                                        <span :title="item.file_name">{{item.file_name}}</span>
                                                    </div>
                                                    <div class=" T13" style="display: flex;">
                                                        <div class="T13" style="width: 100px;">
                                                            <GhAction :dataction="{
                                                                id: 'evaluation_download_' + item.id,
                                                                href: item.file_path,
                                                                text: $t('download'),
                                                                icon: require('../../../assets/gh_new_dessign/descargar.svg')
                                                            }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('evaluation_results') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'evaluation_description',
                                        name: 'evaluation[description]',
                                        value: TabEvaluation.evalData !== undefined ? TabEvaluation.evalData.description : '',
                                        required: true
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('range') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'evaluation_range',
                                        name: 'evaluation[range]',
                                        style: 'width: 10%',
                                        value: TabEvaluation.evalData !== undefined ? TabEvaluation.evalData.range : '',
                                        type: 'float'
                                    }"
                                />
                            </div>
                            <div class="gh-row-content" style="margin-bottom: -10px;">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'evaluation_level',
                                        class: 'T15_b',
                                        subtitle: $t('select_level') + ':',
                                        text: $t('level') + '*',
                                        label: $t('level'),
                                        type: 'radio',
                                        columns: 3,
                                        style: 'margin-left:110px;',
                                        required: true,
                                    }"
                                    :datainput="{
                                        id: 'value_evaluation_level',
                                        name: 'evaluation[level]'
                                    }"
                                    :selected_input="TabEvaluation.evalData !== undefined ? TabEvaluation.evalData.level : []"
                                    :feed="getFeeds.feed_level"
                                />
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <template v-if="TabEvaluation.evalData !== undefined">
                    <GhButton v-if="TabEvaluation.evalData.finish"
                        :datainput="{
                            id: 'btn_save_evaluation',
                            class: 'T19 container-md_button_content',
                            text: $t('finish')
                        }"
                        @click="saveEvaluation(true)"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_save_evaluation',
                        class: 'T19 container-md_button_content',
                        text: $t('save')
                    }"
                    @click="saveEvaluation(false)"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_evaluation',
                        class: 'T19 container-md_button_content',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";
    import {loadLanguageAsync} from "@/main";

    export default {
        name: "edit_evaluation",
        components: {
            GhLocation,
            GhNoDropSelector,
            GhAction,
            GhTextarea,
            GhInputTextField,
            GhDataSelector,
            GhButton,
        },
        computed: {
            ...mapState(['getFeeds', 'TabEvaluation'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
                loadLanguageAsync(lang);
                await this.$store.dispatch('getFeedLevel');
                await this.$store.dispatch('getEvaluationDataById', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async saveEvaluation(state){
                const formData = new FormData(document.getElementById('form_evaluation'));
                if(state) {
                    if (this.Global.checkRequired('form_evaluation') == 'OK') {
                        this.$store.dispatch('setEvaluationData', {
                            formData: [...formData],
                            id: this.$root._route.params.id,
                            state: state
                        }, {root: true}).then((item) => {
                            this.Global.windowClose();
                            this.$store.dispatch('getEvaluationSections', '', {root: true});
                            return item;
                        });
                    }
                }else{
                    this.$store.dispatch('setEvaluationData', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        state: state
                    }, {root: true}).then((item) => {
                        return item;
                    });
                }
            }
        }
    }
</script>