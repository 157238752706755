<template>
    <div style="width: 100% !important;">
        <div class="range-container" ref="container">
            <div class="range-value T-text" ref="value">{{ value }}</div>
        </div>
        <div class="input-container">
            <input :id="datainput.id + '_range_input_slide'"
                   :name="datainput.name"
                   class="range"
                   type="range"
                   :min="datainput.min"
                   :max="datainput.max"
                   v-model="value"
                   v-bind="$attrs"
                   :list="datainput.id + '_values'"
                   @change="$emit('input-change')"
                   :class="datainput.class"
                   :style="datainput.style ? datainput.style : 'width: 100%'">
        </div>
    </div>
</template>

<script>
export default {
    name: "GhCEInputRange",
    props: ['datalabel', 'datainput', 'data'],
    data() {
        return {
            value: this.datainput.value ? this.datainput.value : this.datainput.min
        }
    },
    watch: {
        value() {
            this.updateValuePosition();
            this.$emit('input', this.value);
        },
    },
    mounted() {
        this.updateValuePosition();
    },
    methods: {
        updateValuePosition() {
            const containerWidth = this.$refs.container.clientWidth;
            const thumbWidth = this.$refs.value.clientWidth;
            const ratio = (parseInt(this.value) - parseInt(this.datainput.min)) / (parseInt(this.datainput.max) - parseInt(this.datainput.min));
            const thumbPosition = ratio * (containerWidth - thumbWidth);
            this.$refs.value.style.left = `${thumbPosition + (thumbWidth / 2)}px`;
        },
    }
}
</script>

<style>
    .range-container {
        position: relative;
    }

    .range-value {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: #fff;
        background-color: #0275d8;
        padding: 2px 6px;
        border-radius: 5px;
        white-space: nowrap;
        pointer-events: none;
    }

    input[type=range]::-webkit-slider-thumb {
        /* Estilos para WebKit */
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: #0275d8;
        cursor: pointer;
        -webkit-appearance: none;
        border: none !important;
    }

    input[type=range]::-moz-range-thumb {
        /* Estilos para Firefox */
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: #0275d8;
        border: none !important;
        cursor: pointer;
    }

    input[type=range]::-ms-thumb {
        /* Estilos para Edge */
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        background: #0275d8;
        border: none !important;
        cursor: pointer;
    }

    input[type=range]::-webkit-slider-runnable-track.left {
        width: 100%;
        height: 0.3rem;
        cursor: pointer;
        animate: 0.2s;
        background: #F5F5F5;
        border-radius: 5px;
    }

    /* Estilos para el recorrido derecho en Chrome */
    input[type=range]::-webkit-slider-runnable-track.right {
        width: 100%;
        height: 0.3rem;
        cursor: pointer;
        animate: 0.2s;
        background: #F5F5F5;
        border-radius: 5px;
    }

    input[type=range]::-moz-range-track {
        /* Track styles para Firefox */
        width: 100%;
        height: 0.3rem;
        cursor: pointer;
        animate: 0.2s;
        background: #F5F5F5;
        border: none !important;
        border-radius: 5px;
    }

    input[type=range]::-ms-track {
        /* Track styles para Edge */
        width: 100%;
        height: 0.3rem;
        cursor: pointer;
        animate: 0.2s;
        background: #F5F5F5;
        border: none !important;
        color: transparent;
    }

    /* Estilos de progreso para diferentes navegadores */
    input[type=range]::-moz-range-progress {
        background-color: #0275d8;
        border: none !important;
    }

    input[type=range]::-webkit-slider-thumb::before {
        content: attr(value); /* Mostrar el valor asignado */
        position: absolute;
        top: -25px; /* Ajustar la posición vertical según sea necesario */
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: #fff;
        background-color: #0275d8;
        padding: 2px 6px;
        border-radius: 5px;
        white-space: nowrap;
    }

    /* Estilos adicionales para asegurar la visibilidad del valor */
    input[type=range]::-webkit-slider-thumb::before,
    input[type=range]::-moz-range-thumb::before,
    input[type=range]::-ms-thumb::before {
        visibility: hidden;
    }

    input[type=range]:hover::-webkit-slider-thumb::before,
    input[type=range]:hover::-moz-range-thumb::before,
    input[type=range]:hover::-ms-thumb::before,
    input[type=range]:active::-webkit-slider-thumb::before,
    input[type=range]:active::-moz-range-thumb::before,
    input[type=range]:active::-ms-thumb::before {
        visibility: visible;
    }
</style>
