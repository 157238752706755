var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-popup-ellipsis-title"},[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"RefPopupConfirm",attrs:{"generalConfiguration":{
            id: 'popup_confirm',
            title: _vm.$t('reset'),
            type: 'warning',
            style: 'font-size: 20px; width: 400px; min-width: 400px; max-width: 400px;overflow-y: auto !important;'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"T21 centered",staticStyle:{"margin-top":"15px","margin-left":"20px","margin-right":"20px","margin-bottom":"15px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button_accept_popup_confirm',
                    text: _vm.$t('accept'),
                    class: 'button-questionnaire-secondary'
                }},on:{"click":function($event){return _vm.$emit('accept')}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'button_close_popup_confirm',
                    text: _vm.$t('close'),
                    class: 'button-questionnaire-secondary'
                }},on:{"click":function($event){return _vm.Global.closePopUp('popup_confirm');}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }