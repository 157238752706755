import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        data_actions_indicators:[],
        header_actions_indicators:[],
    },
    mutations: {
        loadActionsIndicators(state,payload){
            if(payload == ''){
                state.data_actions_indicators = [];
            }else{
                state.data_actions_indicators.push(payload);
            }
        },
        loadHeaderActionsIndicators(state,payload){
            if(payload == ''){
                state.header_actions_indicators = [];
            }else{
                state.header_actions_indicators.push(payload);
            }
        }
    },
    actions: {
        async getActionsIndicators(state, items){
            state.commit('loadHeaderActionsIndicators', '');
            state.commit('loadActionsIndicators', '');
            await axios.get(
                items.type == 'actions' ? API_URL + "actions/indicators" : API_URL + "support/indicators/monthly-report"
            ).then(
                response => {
                    var array_header = new Array();
                    var incidence = {
                        'text':'incidences',
                        'field':'priority_name',
                        'style':'min-width: 160px; width: 22.40%;',
                    };
                    var total = {
                        'text':'total',
                        'field':'total',
                        'style':'width: 22.40%;',
                    };
                    var solved_in_place = {
                        'text':'resolved_on_time',
                        'field':'resolved_on_time_percentage',
                        'style':'width: 22.40%;',
                    };
                    var solved_out_place = {
                        'text':'resolved_after_deadline',
                        'field':'resolved_out_off_time_percentage',
                        'style':'width: 22.40%;',
                    };
                    var average_time = {
                        'text':'half_time',
                        'field':'time_between_open_close',
                        'style':'width: 22.40%;',
                    };
                    array_header.push(incidence);
                    array_header.push(total);
                    array_header.push(solved_in_place);
                    array_header.push(solved_out_place);
                    array_header.push(average_time);

                    state.commit('loadHeaderActionsIndicators', array_header);
                    state.commit('loadActionsIndicators', response.data.data.global);

                    response.data.data.groups.map((i) => {
                        array_header = new Array();
                        incidence = {
                            'text':i.name,
                            'field':'priority_name',
                            'style':'min-width: 160px; width: 22.40%;',
                        };
                        total = {
                            'text':'total',
                            'field':'total',
                            'style':'width: 22.40%;',
                        };
                        solved_in_place = {
                            'text':'resolved_on_time',
                            'field':'resolved_on_time_percentage',
                            'style':'width: 22.40%;',
                        };
                        solved_out_place = {
                            'text':'resolved_after_deadline',
                            'field':'resolved_out_off_time_percentage',
                            'style':'width: 22.40%;',
                        };
                        average_time = {
                            'text':'half_time',
                            'field':'time_between_open_close',
                            'style':'width: 22.40%;',
                        };
                        array_header.push(incidence);
                        array_header.push(total);
                        array_header.push(solved_in_place);
                        array_header.push(solved_out_place);
                        array_header.push(average_time);

                        state.commit('loadHeaderActionsIndicators', array_header);
                        state.commit('loadActionsIndicators', i.data);
                    });
                }
            )
        },
    }
}
