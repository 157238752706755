<template>
    <div style="padding-bottom: 10px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'referential_' + label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigReferential.counters[label['result']]
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>
<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import list_thesauros from "@/view/config/referential/thesaurus/list_thesauros.vue";

    import {mapState} from "vuex";

    export default {
        name: "list_referential",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigReferential','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'thesaurus', component: list_thesauros, result: 'thesaurus', show: false , second_text: 'results'}
                ],
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getConfigReferentialCounters', '', {root: true});

                if (this.Login.tabs.length !== 0) {
                    this.Login.tabs[4]['sub-modules'][2]['sub-sections'].forEach((subroute, subkey) => {
                        if (this.data[subkey] !== undefined) {
                            this.data[subkey].show = subroute.have_permission;
                        }
                    });
                }
            }
        },
    }
</script>
