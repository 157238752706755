import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        sections: [],
        data: [],
        tutorData: []
    },
    mutations: {
        loadTutorSections(state, payload){
            state.sections = payload;
            payload.map(item => {
                state.data[item.id] = [];
            });
        },
        loadTutorSectionData(state, payload){
            state.data[payload.id] = payload.data.data;
        },
        loadTutorData(state, payload){
            state.tutorData = payload;
        }
    },
    actions: {
        async getTutorSections(state){
            let request = API_URL + 'tutor';

            await axios({
                method: 'get',
                url: request,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(
                response => {
                    state.commit('loadTutorSections', response.data.data);
                },
                error => {
                    console.log(error);
                }
            );
        },
        async getTutorByIdSection(state, item){
            let request = API_URL + 'tutor/section/'+item.id;

            await axios({
                method: 'get',
                url: request,
            }).then(
                response => {
                    state.commit('loadTutorSectionData', {id: item.id, data: response.data});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTutorDataById(state, item){
            let request = API_URL + 'tutor/'+item.id;

            await axios({
                method: 'get',
                url: request,
            }).then(
                response => {
                    state.commit('loadTutorData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setTutorData(state, item){
            let request = item.id ? API_URL + "tutor/"+item.id : API_URL + "tutor";

            let formData = new FormData();

            formData.append('tutor[id]', item.id);
            formData.append('tutor[finish]', item.state);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });


            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};