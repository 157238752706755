var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{key:'KEY61' + _vm.refreshElement},[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('referential')))])]),_c('div',{staticClass:"grid-container-2"},_vm._l((_vm.selectors),function(item,index){return _c('div',{key:'KEY62' + index,staticClass:"grid-item min-width-460 references",style:(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && !_vm.props.view[item.key] && (item.key === 'groupers' || item.key === 'exercises') ? 'height: 18px;' : !_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && item.key !== 'exercises' && item.key !== 'groupers' && _vm.props.view.exercises && _vm.props.view.groupers ? 'margin-left: 34px;' : '')},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && !_vm.props.view[item.key])?[(item.key !== 'topics' && item.type == 'normal')?_c('GhDataSelector',{attrs:{"datablock":{
                        id: _vm.props.name + '_select_' + item.key,
                        class: 'T15_b',
                        subtitle:  _vm.$t('select') + ' ' + _vm.$t(item.key).toLowerCase() + ':',
                        text: _vm.$t(item.key) ,
                        label: _vm.$t(item.key),
                        type: 'checkbox',
                        columns: 3,
                        style: '',
                        required: false
                    },"datainput":{
                        id: 'value_' + _vm.props.name + '_' + item.key,
                        name: _vm.props.name + '[' + item.key + '][]'
                    },"selected_input":_vm.props.selecteds !== undefined && _vm.props.selecteds[item.key] !== undefined ? _vm.props.selecteds[item.key] : [],"feed":item.key == 'topics' ? _vm.getFeeds.feed_topic : item.key == 'exercises' ? _vm.getFeeds.feed_exercises : item.key == 'groupers' ? _vm.getFeeds.feed_groupers : []}}):_c('GhTreeSelectorBlock',{ref:item.key === 'topics' ? 'refTopicsReferentials' : '',refInFor:true,attrs:{"dataBlock":{
                        type: 'checkbox',
                        id: _vm.props.name + '_select_' + item.key,
                        label: _vm.$t(item.key),
                        title: _vm.$t(item.key),
                        text: _vm.$t('select') + ' ' + _vm.$t(item.key).toLowerCase() + ':',
                        name: _vm.props.name + '[' + item.key + '][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: true,
                        style: '',
                        label_style: _vm.props.name !== 'search' ? 'width: 149px' : '',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    },"disabled_input":item.key === 'topics' && _vm.props.fixTopics !== undefined ? _vm.props.fixTopics : [],"selected_input":item.key === 'thesauros' && _vm.props.selecteds !== undefined && _vm.props.selecteds.thesauros !== undefined ? _vm.props.selecteds.thesauros : item.key === 'topics' && _vm.props.selecteds !== undefined && _vm.props.selecteds.topics !== undefined ? _vm.props.selecteds.topics : [],"items":item.key !== 'topics' ? _vm.getFeeds.feed_reference_thesauros : _vm.getFeeds.feed_topic}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px!important","min-width":"150px!important","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.key) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.props.selecteds !== undefined && _vm.props.selecteds[item.key] !== undefined ? (item.key == 'thesauros' ? _vm.thesaurosView : item.key == 'topics' ? _vm.topicsView : _vm.props.selecteds[item.key] !== undefined && _vm.props.selecteds[item.key] !== null ? Object.values(_vm.props.selecteds[item.key]).join(', ') : '') : ''))])])]],2)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }