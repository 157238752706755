var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_assign',
        title: _vm.titlePopup,
        type: 'add',
        style: 'width: 35% !important; min-width:500px;'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 90%; min-height: 8vh;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:_vm.reloadForm,attrs:{"id":"form_popup_assign"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-top":"unset","margin-top":"-5px"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhMultipleSearch',{attrs:{"datalabel":{
                            text: _vm.$t('leader') + '*',
                            style: 'min-width: 150px; width: 150px; text-align: right',
                            class: 'T15_b',
                            id: 'label_assign_leader'
                        },"datainput":{
                            required: true,
                            id: 'assign_leader_user',
                            name: 'assign[leader][name]',
                            nameS:'assign[leader][surname]',
                            nameId:'assign[leader][id]',
                            style: 'width: 166px',
                            value: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.leader !== undefined ? _vm.getterCompanyAssociatedByItinerary.leader.name : '',
                            valueId: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.leader !== undefined ? _vm.getterCompanyAssociatedByItinerary.leader.id : '',
                            valueS: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.leader !== undefined ? _vm.getterCompanyAssociatedByItinerary.leader.surname : '',
                            placeholder: _vm.$t('name'),
                            placeholderS: _vm.$t('surnames'),
                            result_1: 'first_name',
                            result_2: 'last_name',
                            result_id: 'id',
                            href: 'getLeaderUserSearch'
                        }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'label_assign_start_date',
                            text: _vm.$t('start_date'),
                            style: 'text-align: right; padding-top: 2px; width: 150px; min-width: 150px; text-align: right;'
                        },"datainput":{
                            id: 'input_assign_start_date',
                            name: 'assign[start_date]',
                            required: true,
                            value: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.start_date !== undefined && _vm.getterCompanyAssociatedByItinerary.start_date !== null ? _vm.getterCompanyAssociatedByItinerary.start_date : ''
                        }}}),_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'label_assign_end_date',
                            text: _vm.$t('end_date'),
                            style: 'text-align: right; padding-top: 2px; width: 90px; min-width: 90px; text-align: right;'
                        },"datainput":{
                            id: 'input_assign_end_date',
                            name: 'assign[end_date]',
                            required: true,
                            value: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.end_date !== undefined && _vm.getterCompanyAssociatedByItinerary.start_date !== null ? _vm.getterCompanyAssociatedByItinerary.end_date : ''
                        }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                            label: {
                                style: 'margin-right: 10px; text-align: right; width: 150px; min-width: 150px;'
                            },
                            ResultSearch: 'result_search',
                            nameStore: 'getFeeds',
                            imagelocation: require('../../../../../assets/gh_new_dessign/floating.svg'),
                            id: 'tutor',
                            text: _vm.$t('tutorships'),
                            textlocation: _vm.$t('add_user'),
                            actionresult: 'loadResultSearchByType',
                            nameResult: 'assign[tutorships]',
                            supportPermision: true,
                        },"mainSelector":{
                            name: 'TutorshipSearch',
                            formId: 'user',
                            actionsearch: 'getSearchUsersLeader'
                        },"tableResolt":{
                            header: _vm.header,
                            data: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.tutorships !== undefined ? _vm.getterCompanyAssociatedByItinerary.tutorships : []
                        },"tableSelected":{
                            headersummary: _vm.headersummary,
                            selected: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.tutorships !== undefined ? _vm.getterCompanyAssociatedByItinerary.tutorships : []
                        }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('support')))]),_c('GhChecks',{attrs:{"datalabel":{text: '',style:'',class:'',id:''},"datainput":{
                            selected: _vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.support !== undefined ? _vm.getterCompanyAssociatedByItinerary.support === 1 : '',
                            type: 'checkbox',
                            id: 'input_assign_support',
                            value: 1,
                            name: 'assign[support]'
                        }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'weighting_group',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_weighting_group') + ':',
                            text: _vm.$t('weighting_group'),
                            label: _vm.$t('weighting_group'),
                            type: 'checkbox',
                            columns: 3,
                            required: false,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_weighting_group',
                            name: 'assign[company][weighting_group][]'
                        },"feed":_vm.getterWeightingGroupItineraryCompany !== undefined ? _vm.getterWeightingGroupItineraryCompany : [],"selected_input":_vm.getterCompanyAssociatedByItinerary && _vm.getterCompanyAssociatedByItinerary.feed_weighting_groups !== undefined ? _vm.getterCompanyAssociatedByItinerary.feed_weighting_groups : []}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept_popup_order',
                text: _vm.$t('accept')
            }},on:{"click":function($event){return _vm.acceptAssign()}}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_accept_popup_order',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.closeAndClean()}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }