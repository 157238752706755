var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'metrics_popup',
        title: _vm.$t('add_metric'),
        type: 'add',
        style: 'width: 50%; min-width:920px;',
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important;'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_metrics_data"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                            text: _vm.$t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'metrics_name',
                            name: 'metrics[name]',
                            style: 'min-width: 200px; width: 20%',
                            value: '',
                            type: 'text',
                        }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                            text: _vm.$t('description') + '*',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                            class: 'T15_b',
                        },"datainput":{
                            id: 'metrics_description',
                            name: 'metrics[description]',
                            value: ''
                        }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_metrics_accept_popup',
                text: _vm.$t('accept')
            }},on:{"click":_vm.submitMetrics}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_metrics_close_popup',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('metrics_popup');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }