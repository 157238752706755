import ConfigGoodPractices from "@/view/base_creation/support_material/good_practices/template/good_practices.vue";

const routes = [
    {
        path: '/config/creation/good_practices',
        name: 'config_good_practices',
        component: ConfigGoodPractices,
        props: {id: null, showMenu: true, otherTitle: 'good_practices'},
        children: [
            {
                path: '/config/creation/good_practices/:id',
                name: 'config_good_practices_id',
                component: ConfigGoodPractices,
                props: {id: null, showMenu: true, otherTitle: 'good_practices'},
            },
            {
                path: '/config/creation/good_practices/:id/:view',
                name: 'config_good_practices_id_view',
                component: ConfigGoodPractices,
                props: {id: null, view: true, showMenu: true, otherTitle: 'good_practices'},
            }
        ]
    },
    {
        path: '/config/creation/good_practices/:id/:view/:windowed',
        name: 'config_good_practices_id_view_windowed',
        component: ConfigGoodPractices,
        props: {id: null, view: true, windowed: true, showMenu: false, background: true, otherTitle: 'good_practices'},
    }
];

export default routes;