var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_warning_content_text',
        title: _vm.$t('warning'),
        type: 'warning',
        style: 'width: 650px; min-width: 650px; max-width: 650px;'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: _vm.subTitle
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.bodyText)}})])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_warning_content_text',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_warning_content_text');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }