<template>
    <div class="gh-content-global" :style="$route.matched[0].props.default.questionnaireBackground !== undefined ? 'margin-left: 5px;' : 'padding-top: 0px;'">
        <template v-if="read">
            <template v-for="(itemDoneEvaluation, indexDoneEvaluation) in getterQuestionnaireEvaluation.evaluation">
                <div :key="'ROW_EVALUATED' + indexDoneEvaluation" style="width: 100%">
                    <template v-for="(itemE, indexE) in itemDoneEvaluation.childrens">
                        <div class="gh-row-content" :key="'INDEXELEMENT' + indexE" style="width: 100%">
                            <div>
                                <div style="width: 100%;">
                                    <label class="gh_text_field-label T-subtitle-second" style="margin-right: 4px;">{{itemDoneEvaluation.name + ': ' + ' '}}</label>
                                    <label v-for="(itemEvaluation, indexEvaluation) in itemE.name" :key="'EVALUATED' + indexDoneEvaluation + indexEvaluation" :class="indexEvaluation === 0 ? 'gh_text_field-label T-subtitle-second' : 'T-text'" :style="indexEvaluation !== 0 ? 'padding-top: 2px;' : ''">
                                        {{itemEvaluation + (indexEvaluation === 0 ? '' : ';')}}
                                    </label>
                                </div>
                                <div style="width: 100px">
                                    <label class="gh_text_field-label T-subtitle-second" style="float: right;"> {{itemE.punctuation}} </label>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" :key="'INDEXELEMENT2' + indexE" style="width: 100%; margin-top: -5px;">
                            <div>
                                <div style="width: 100%;">
                                    <label class="gh_text_field-label T-text" style="margin-right: 4px;">{{itemE.result_description }}</label>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <div class="separator-line" style="margin-top: 10px; margin-bottom: 10px;"></div>
        </template>
        <div v-for="(itemValorationEvaluation, indexValorationEvaluation) in getterQuestionnaireEvaluation.valorations" :key="'VALORATION' + indexValorationEvaluation + refreshValorations">
            <div class="gh-row-content" style="width: 100%">
                <div>
                    <div class="radio-check-new-style" style="width: 80%; display: flex">
                        <label class="gh_text_field-label T-subtitle-second">{{$t('improvement_opportunities')}}</label>
                        <GhChecks
                            :datalabel="{
                                text: $t('na'),
                                style: 'font-size: 14px !important;',
                                class: 'T-text',
                                id: ''
                            }"
                            :datainput="{
                                disabled: $root._route.params.view || read,
                                required: true,
                                selected: itemValorationEvaluation.improvement_opportunities === 1,
                                type: 'checkbox',
                                id: 'evaluate_' + indexValorationEvaluation + '_improvement_opportunities_' + indexValorationEvaluation,
                                value: 1,
                                name: 'evaluate[' + indexValorationEvaluation + '][improvement_opportunities]'
                            }"
                            :ref="'RefCheckImprovementOpportunities' + indexValorationEvaluation"
                            @click="updateContentValoration(indexValorationEvaluation)"
                        />
                    </div>
                    <div style="width: 20%; text-align-last: right;">
                        <template v-if="getterQuestionnaireEvaluation.valorations.length !== 1">
                            <GhAction
                                class="color-light-blue T-subtitle-second underline-light-blue"
                                :dataction="{
                                    id: 'remove_more_valorations_' + indexValorationEvaluation,
                                    text: $t('delete'),
                                    icon: require('../../../assets/gh_new_dessign/trash-blue.svg')
                                }"
                                @click="removeValoration(indexValorationEvaluation)"
                            />
                        </template>
                    </div>
                </div>
            </div>
            <div class="gh-row-content" v-if="itemValorationEvaluation.improvement_opportunities !== 1">
                <template v-if="read">
                    <label class="gh_text_field-label T-text">{{itemValorationEvaluation.description_improvement_opportunities}}</label>
                </template>
                <template v-else>
                    <GhTextarea
                        style="margin-top:5px;"
                        :datalabel="{
                            text: '',
                            class: 'T-subtitle-second',
                            styles: ''
                        }"
                        :datainput="{
                            id: 'evaluate_' + indexValorationEvaluation + '_description_improvement_opportunities' + indexValorationEvaluation,
                            name: 'evaluate[' + indexValorationEvaluation + '][description_improvement_opportunities]',
                            required: true,
                            value: itemValorationEvaluation.description_improvement_opportunities,
                            class: 'new-format-textarea'
                        }"
                        :ref="'RefTextareaDescriptionImprovementOpportunities' + indexValorationEvaluation"
                        @keyup="updateContentValoration(indexValorationEvaluation)"
                    />
                </template>
            </div>
            <div class="gh-row-content radio-check-new-style">
                <label class="gh_text_field-label T-subtitle-second">{{$t('hard_points')}}</label>
                <GhChecks
                    :datalabel="{
                        text: $t('na'),
                        style: 'font-size: 14px !important;',
                        class: 'T-text',
                        id: ''
                    }"
                    :datainput="{
                        disabled: $root._route.params.view || read,
                        required: true,
                        selected: itemValorationEvaluation.hard_points === 1,
                        type: 'checkbox',
                        id: 'evaluate_' + indexValorationEvaluation + '_hard_points_' + indexValorationEvaluation,
                        value: 1,
                        name: 'evaluate[' + indexValorationEvaluation + '][hard_points]'
                    }"
                    :ref="'RefCheckHardPoints' + indexValorationEvaluation"
                    @click="updateContentValoration(indexValorationEvaluation)"
                />
            </div>
            <div class="gh-row-content" v-if="itemValorationEvaluation.hard_points !== 1">
                <template v-if="read">
                    <label class="gh_text_field-label T-text">{{itemValorationEvaluation.description_hard_points}}</label>
                </template>
                <template v-else>
                    <GhTextarea
                        :datalabel="{
                            text: '',
                            class: 'T-subtitle-second',
                            styles: ''
                        }"
                        :datainput="{
                            id: 'evaluate_' + indexValorationEvaluation + '_description_hard_points_' + indexValorationEvaluation,
                            name: 'evaluate[' + indexValorationEvaluation + '][description_hard_points]',
                            required: true,
                            value: itemValorationEvaluation.description_hard_points,
                            class: 'new-format-textarea'
                        }"
                        :ref="'RefTextareaDescriptionHardPoints' + indexValorationEvaluation"
                        @keyup="updateContentValoration(indexValorationEvaluation)"
                    />
                </template>
            </div>
        </div>
        <div v-if="!$root._route.params.view && !read" class="gh-row-content" style="margin-left: 7px;">
            <template v-if="getterQuestionnaireEvaluation.valorations[getterQuestionnaireEvaluation.valorations.length - 1].hard_points !== 1 || getterQuestionnaireEvaluation.valorations[getterQuestionnaireEvaluation.valorations.length - 1].improvement_opportunities !== 1">
                <GhAction
                    class="color-light-blue T-subtitle-second underline-light-blue"
                    :dataction="{
                        id: 'add_more_valorations',
                        text: $t('add_more'),
                        icon: require('../../../assets/gh_new_dessign/add_plus.svg')
                    }"
                    @click="addValoration"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'template_oportunity_hard_points',
        props: ['read'],
        components: {
            GhChecks,
            GhTextarea,
            GhAction
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return {
                refreshValorations: 0
            }
        },
        methods: {
            addValoration(){
                this.$store.dispatch('setLineValoration', '', {root: true});
            },
            removeValoration(key){
                this.$store.dispatch('setRemoveLineValoration', {
                    key: key
                }, {root: true});

                this.refreshValorations++;
            },
            updateContentValoration(key){
                let params = {
                    improvement_opportunities: this.$refs['RefCheckImprovementOpportunities' + key][0].selected ? 1 : '',
                    hard_points: this.$refs['RefCheckHardPoints' + key][0].selected ? 1 : '',
                    description_hard_points: this.$refs['RefTextareaDescriptionHardPoints' + key] === undefined || this.$refs['RefTextareaDescriptionHardPoints' + key][0] === undefined ? '' : this.$refs['RefTextareaDescriptionHardPoints' + key][0].hiddenValue,
                    description_improvement_opportunities: this.$refs['RefTextareaDescriptionImprovementOpportunities' + key] === undefined || this.$refs['RefTextareaDescriptionImprovementOpportunities' + key][0] === undefined ? '' : this.$refs['RefTextareaDescriptionImprovementOpportunities' + key][0].hiddenValue,
                }

                this.$store.dispatch('updateContentValoration', {
                    key: key,
                    params: params
                }, {root: true});
            }
        }
    }
</script>

<style>
    .new-format-textarea{
        padding: 10px !important;
        height: 60px !important;
        margin-top: -5px;
    }

    #add_more_valorations > img {
        margin-top: 0px;
    }

    [id^="remove_more_valorations_"] > img {
        margin-top: 1px;
    }
</style>