import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";

Vue.use(vuex);

export default {
    state: {
        sections: [],
        data: [],
        refreshProgramsAssociate: false,
        refreshProgramsAssociateCompany: false
    },
    mutations: {
        loadProgramsAssociate(state, payload){
            state.refreshProgramsAssociate = !state.refreshProgramsAssociate;
            state.sections = payload;
        },
        loadProgramsAssociateCompany(state, payload){
            state.refreshProgramsAssociateCompany = !state.refreshProgramsAssociateCompany;

            if(state.data[payload.itinerary] === undefined){
                state.data[payload.itinerary] = [];
            }

            state.data[payload.itinerary] = payload.data;
        }
    },
    getters: {
        getterProgramsAssociate(state){
            const dummy = state.refreshProgramsAssociate; // eslint-disable-line no-unused-vars
            return state.sections;
        },
        getterProgramsAssociateCompany(state){
            const dummy = state.refreshProgramsAssociateCompany; // eslint-disable-line no-unused-vars
            return state.data;
        }
    },
    actions: {
        async getProgramsAssociate(state){
            await axios.get(
                API_URL + "assign/programs_associate"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadProgramsAssociate', response.data.data);
                    }
                }
            )
        },
        async getProgramsAssociateCompany(state, item){
            await axios.get(
                API_URL + "assign/programs_associate/" + item.itinerary
            ).then(
                response => {
                    if(response.status === 200) {
                        let params = {
                            'itinerary': item.itinerary,
                            'data': response.data.data
                        }

                        state.commit('loadProgramsAssociateCompany', params);
                    }
                }
            )
        }
    }
}
