<template>
    <div>
        <template v-if="haveData">
<!--            <template v-if="getterPermissionEvaluator">-->
<!--                <router-link :to="{name: 'assign_evaluator'}">-->
<!--                    <GhButton-->
<!--                        style="margin-top: 15px;"-->
<!--                        :datainput="{-->
<!--                            id: 'btn_add_new_evaluator',-->
<!--                            class: 'T19 container-md_button_content',-->
<!--                            text: $t('add_new_evaluator')-->
<!--                        }"-->
<!--                    />-->
<!--                </router-link>-->
<!--            </template>-->

            <div v-for="(label, labelIndex) in data" :key="'SECTION_EVALUATORS' + labelIndex">
                <GhNoDropSelector
                    :datablock="{
                        id: 'evaluators_' + label.id,
                        title: $t(label['name']),
                        second_title: ''
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component"/>
                    </template>
                </GhNoDropSelector>
            </div>

            <template v-if="showSectionResults">
                <GhNoDropSelector
                    :datablock="{
                        id: 'result_evaluators',
                        title: loaderResults ? $t('searching') + '...' : getterEvaluatorsResults.length === 0 ? $t('no_results_found') : getterEvaluatorsResults.length + ' ' + $t('results'),
                        second_title: ''
                    }"
                >
                    <template v-slot:content>
                        <template v-if="loaderResults">
                            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                            </div>
                        </template>
                        <template v-else>
                            <component v-bind:is="results"/>
                        </template>
                    </template>
                </GhNoDropSelector>
            </template>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from "fe-gh-action-lib";

    import search_evaluators from "./view_evaluators/search_evaluators.vue";
    import results from "./view_evaluators/result_evaluators.vue";

    import {mapGetters} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'list_evaluators',
        components: {
            GhButton,
            GhDropSelector,
            GhNoDropSelector,
            GhAction
        },
        computed: {
            ...mapGetters(['getterEvaluatorsResults', 'getterPermissionEvaluator'])
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'view_evaluators', component: search_evaluators, result: '', show: true , second_text: ''}
                ],
                results: results,
                showSectionResults: false,
                loaderResults: true,
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionEvaluator', '', {root: true});
            this.haveData = true;
        }
    }
</script>