<template>
    <GhCECard
        :key="'CARD' + keyCard"
        :block="{
            image: '',
            progressBar: true,
            colors: [
                '#0099CC',
                '#005392'
            ],
            colorsHover: [
                '#B7C0C5',
                '#B7C0C5'
            ]
        }"
        :data="itemCard"
    >
        <template v-slot:extraContent>
            <template v-if="showButton">
                <div class="centered" style="padding-top: 15px; padding-bottom: 15px">
                    <GhButton
                        class="button-questionnaire-secondary"
                        :datainput="{
                            id: 'btn_assign_' + itemCard.id,
                            text: itemCard.finished ? $t('finished') : itemCard.assigned ? $t('assigned') : $t('assign'),
                            style: 'margin-right: 0px!important'
                        }"
                        :disabled="itemCard.finished"
                        @click="assign(itemCard, $event)"
                    />
                </div>
            </template>
        </template>
    </GhCECard>
</template>

<script>
    import GhCECard from "@/components/GhCECard.vue";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'program_card',
        props: ['keyCard', 'itemCard', 'showButton'],
        components:{
            GhCECard,
            GhButton
        },
        methods: {
            async assign(item, event){
                event.preventDefault();
                event.stopPropagation();

                var id_company = '';


                await this.$store.dispatch('getIdCompany', '', {root: true}).then(reponse => {
                    id_company = reponse;
                });

                await this.$store.dispatch('getTreeItinerary', {
                    id: item.id
                }, {root: true});

                await this.$store.dispatch('getPermissionsAssign', '', {root: true});

                await this.$store.dispatch('getAssignItineraryTodo', {
                    id: id_company,
                    from: 'program',
                    agrupator_id: item.id,
                    title: item.name
                }, {root: true});

                this.$parent.$parent.$parent.$parent.refreshAssign++;

                this.Global.openPopUp('popup_assign');
            }
        }
    }
</script>