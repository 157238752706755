var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_start_date',
                text: _vm.$t('start_date'),
                style: 'min-width: 115px  !important;  width: 115px !important; text-align: right;'
            },"datainput":{
                id: 'search_start_date',
                name: 'historical[start_date]',
                value: ''
            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_end_date',
                text: _vm.$t('end_date'),
                style: 'min-width: 141px  !important;  width: 141px !important; text-align: right;'
            },"datainput":{
                id: 'search_start_date',
                name: 'historical[end_date]',
                value: ''
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                id: 'label_config_support_category_name',
                text: _vm.$t('user') + ':',
                style: 'width: 93px; min-width: 93px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                id: 'input_config_support_category_name',
                name: 'historical[user]',
                style: 'width: 15%',
                type: 'text',
                value:''
            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                id: 'label_config_support_category_name',
                text: _vm.$t('company') + ':',
                style: ' text-align: right; width:114px; max-width:114px;',
                class: 'T15_b'
            },"datainput":{
                id: 'input_config_support_category_name',
                name: 'historical[company]',
                style: 'width: 15%',
                type: 'text',
                value:''
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                id: 'label_action_call_state_client',
                class: 'T15_b',
                subtitle: _vm.$t('select_client_status') + ':',
                text: _vm.$t('client_status'),
                label: _vm.$t('client_status'),
                type: 'checkbox',
                columns: 2,
                style:'margin-left:38px;'
            },"datainput":{
                id: 'value_action_state_client',
                name: 'historical[state][]'
            },"feed":_vm.getFeeds.feed_state_client}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }