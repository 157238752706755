<template>
    <div>
        <template v-if="haveData">
            <GhSubTabProgressBar v-bind:items="$router.options.GhSubTabsQuestionary"
                :dataBlock="{
                    id: 'initial_tabs',
                    calldispatch: 'getSpecificQuizz',
                    chart: MainItinerary.chart_quizz !== undefined ? MainItinerary.chart_quizz : []
                }"
                ref="RefSubTabProgressBar"
            />

            <div class="content_page">
                <GhNoDropSelector
                    :datablock="{
                        id: 'drop_selector_section_initial',
                        title: $t(MainItinerary.data_quizz.from)
                    }"
                >
                    <template v-slot:content>
                        <div class="gh-content-global" style="margin-top: 20px;" v-if="MainItinerary.data_quizz !== undefined && MainItinerary.data_quizz.id !== undefined">
                            <form id="form_quizz" ref="form">
                                <component :key="refreshQuizz" v-bind:is="quizz" :dataQuizz="MainItinerary"/>
                            </form>
                        </div>
                    </template>
                </GhNoDropSelector>
            </div>

            <GhPopUp v-show="false" :key="video.refresh"
                :generalConfiguration="{
                    id: 'video_quizz_popup',
                    title: video.name,
                    type: 'info',
                    style: 'width: 1024px; min-width: 1024px'
                }"
                :bodyConfiguration="{id: 'video_body_quizz_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''}"
                :footerConfiguration="{id: 'video_footer_quizz_popup', class: '', style: ''}"
            >
                <template v-slot:body>
                    <video width="1024" height="400" controls>
                        <source :src="video.url">
                    </video>
                </template>
                <template v-slot:footer>
                    <GhButton
                        :datainput="{
                            id: 'video_close_btn_popup',
                            text: $t('close'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="closeVideo()"
                    />
                </template>
            </GhPopUp>
            <div class="centered" style="margin-top: 10px;">
                <template v-if="stateQuestion !== 3">
                    <GhButton v-if="(stateQuestion === 2 && MainItinerary.finished.finish) || (stateQuestion === 1 && !pendingChildrens)"
                        :datainput="{
                            id: 'btn_end',
                            text: $t('finish'),
                            class: '',
                            style: ''
                        }"
                        @click="finish();"
                    />
                    <GhButton v-if="stateQuestion === 0"
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save'),
                            class: '',
                            style: ''
                        }"
                        @click="save();"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_back',
                        text: $t('back'),
                        class: '',
                        style: ''
                    }"
                    @click="$router.go(-1)"
                />
            </div>
            <div class="gh_text_field-label T15_b centered" style="padding-top: 10px; height: 20px; color: green;">
                <span v-if="MainItinerary.finished.finish">{{$t('all_itineraries_exercises_finish')}}</span>
                <span v-else-if="stateQuestion === 2">{{$t('exercise_finish')}}</span>
                <span v-else-if="pendingChildrens">{{$t('to_finish_exercise')}}</span>
            </div>
        </template>
        <template v-else-if="!haveData">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import GhSubTabProgressBar from "fe-gh-subtab-progress-bar-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    import quizz from "@/view/base_creation/exercises/exercises/template/quizz.vue";

    export default {
        name: "list_quizz",
        components:{
            GhSubTabProgressBar,
            GhNoDropSelector,
            GhPopUp,
            GhButton
        },
        data(){
            return{
                refreshChildren: 0,
                questionId: '',
                stateQuestion: 0,
                pendingChildrens: false,
                haveData: false,
                haveDataRefreshed: false,
                toUpdate: true,
                video: {
                    name: '',
                    url: '',
                    refresh: 0
                },
                quizz: quizz,
                refreshQuizz: 0
            }
        },
        computed: {
            ...mapState(["MainItinerary","ListQuizz", "InitialItinerary"]),
        },
        watch:{
            'MainItinerary.actual_page'(){
                this.refreshQuizz++;
            },
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            },
            'refreshQuizz'(){
                if(this.$refs.RefSubTabProgressBar !== undefined) {
                    this.$refs.RefSubTabProgressBar.$forceUpdate();
                    this.$refs.RefSubTabProgressBar.$children.forEach((i, k) => {
                        this.$refs.RefSubTabProgressBar.$children[k].$forceUpdate();
                    });
                }
            }
        },
        async mounted() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getStartQuizz', '', {root: true});
                var r = document.querySelector(':root');
                var color = '#DDDDDD';

                r.style.setProperty('--body_color', color);
                var that = this;
                setTimeout(function () {
                    that.haveData = true;
                    that.haveDataRefreshed = true;
                }, 200);
            }
        },
        updated() {
            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);
            this.haveData = true;
            this.haveDataRefreshed = true;
        },
        methods:{
            handleMounted(){
                this.refreshChildren++;
            },
            finish(){
                this.MainItinerary.actual_page = this.setLabelRequired();

                if(this.Global.checkRequired('form_quizz') === 'OK') {
                    const formData = new FormData(document.getElementById('form_quizz'));
                    this.$store.dispatch('setQuizzData', {
                        formData: [...formData],
                        id: this.questionId,
                        finish: true,
                    }, {root: true}).then((response) => {
                        if(response === 'OK'){
                            this.refreshQuizz++;
                        }
                    });
                }
            },
            save(){
                this.MainItinerary.actual_page =  this.setLabelRequired();

                const formData = new FormData(document.getElementById('form_quizz'));
                this.$store.dispatch('setQuizzData', {
                    formData: [...formData],
                    id: this.questionId,
                    finish: false,
                }, {root: true}).then((response) => {
                    if(response === 'OK'){
                        this.refreshQuizz++;
                    }
                });
            },
            setLabelRequired(){
                var actualPage = this.$refs.form.children[0].__vue__.$refs.quiz.actualPage;
                var text = this.MainItinerary.data_quizz.questionary[actualPage].text;

                var tagHTML = 'input';
                var extraName = '';
                if(this.MainItinerary.data_quizz.questionary[actualPage].type === 'textarea'){
                    tagHTML = 'textarea';
                }else if(this.MainItinerary.data_quizz.questionary[actualPage].type === 'checkbox'){
                    extraName = '[]';
                }

                document.querySelectorAll(tagHTML + '[name="quizz[' + this.MainItinerary.data_quizz.questionary[actualPage].type + '][' + this.MainItinerary.data_quizz.questionary[actualPage].id + ']' + extraName + '"]').forEach((item) => {
                    item.setAttribute('label_required', this.stripHtml(text));
                });

                return actualPage;
            },
            stripHtml(html){
                let tmp = document.createElement("DIV");
                tmp.innerHTML = html;
                var text = tmp.textContent || tmp.innerText || "";
                tmp.remove();
                return text;
            },
            closeVideo(){
                this.video.name = '';
                this.video.url = '';
                this.video.refresh++;
                this.Global.closePopUp('video_quizz_popup');
            }
        }
    }
</script>

<style>
.gh_delete_confirm-content{
  margin-right: 0px !important;
}
</style>