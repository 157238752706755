import AssignationBase from "@/view/assign/assignation_base/section_assignation_base.vue";

const routes = [
    {
        path: '/assignation_base/:itinerary/:id',
        name: 'assignation_base',
        component: AssignationBase,
        props: {itinerary: null, id: null, showMenu: true},
        children: [
            {
                path: '/assignation_base/:itinerary/:id/:view',
                name: 'assignation_base_view',
                component: AssignationBase,
                props: {itinerary: null, id: null, view: true, showMenu: true}
            }
        ]
    }
];

export default routes;