<template>
    <div v-if="haveData">
        <div class="T14 link table-header">{{$t('perms')}}</div>
        <form id="form_associated_users">
            <div style="margin-right: 10px" :key="refreshAssociatedUsers">
                <div class="gh-row-content" style="padding-bottom: 3px !important; width: 100%; flex-wrap: wrap; padding-left: 10px; padding-top: 2px;" v-for="(item,index) in getFeeds.feed_perms" :key="index" :style="index % 2 ? '' : 'background-color: #d9edfc;'">
                    <div>
                        <div style="width: calc((100% - 240px) - (((100% - 280px) * 12) / 15))" v-for="(itemChildren,indexChildren) in item" :key="indexChildren" :title="itemChildren.description">
                            <GhChecks
                                :datalabel="{
                                    text: itemChildren.name,
                                    style: '',
                                    class: '',
                                    id: ''
                                }"
                                :datainput="{
                                    disabled: $root._route.params.view,
                                    required: false,
                                    selected: '',
                                    type: 'checkbox',
                                    id: itemChildren.id,
                                    value: itemChildren.id,
                                    name: 'associated[perms][]'
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div style="background-color: #c5d1df; height: 46px;">
            <div class="centered" style="padding-top: 9px;">
                <GhButton
                    :datainput="{
                        id: 'btn_accept',
                        text: $t('accept')
                    }"
                    @click="acceptAssociatedUsers"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_clean',
                        text: $t('clean')
                    }"
                    @click="cleanAssociatedUsers"
                />
            </div>
        </div>

        <template v-if="getterUsersAssociated !== undefined && getterUsersAssociated.users !== undefined">
            <GhTableCommon style="margin-top: 30px;"
                :extratable="{
                    id: 'profiles',
                }"
                :header="header"
                :data="getterUsersAssociated.users"
            />

            <div class="gh-row-content" style="margin-top: 10px;">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('groups') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterUsersAssociated.groups}}</label>
            </div>
            <div class="gh-row-content">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('profiles') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterUsersAssociated.profiles}}</label>
            </div>
        </template>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'list_associated_users',
        components: {
            GhChecks,
            GhTableCommon,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterUsersAssociated'])
        },
        data(){
            return {
                refreshAssociatedUsers: 0,
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'surnames', field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'email', field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
                ],
                haveData: false,
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPerms', '', {root: true});
                this.$store.dispatch('cleanAssociatedUsers', '', {root: true});
                this.haveData = true;
            }
        },
        methods:{
            cleanAssociatedUsers(){
                this.$store.dispatch('cleanAssociatedUsers', '', {root: true});
                this.refreshAssociatedUsers++;
            },
            async acceptAssociatedUsers(){
                const formData = new FormData(document.getElementById('form_associated_users'));
                await this.$store.dispatch('checkAssociatedUsers', {
                    formData: [...formData],
                }, {root: true});
            }
        }
    }
</script>