import router from "../../routes/router";
import Vue from "vue";
import vuex from "vuex";
// import {LoginUrl} from "@/common/config";
// import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        TabItem: '',
        TabActive: '',
        TabCustom: '',
        TabHeader: '',
        TabLoading: ''
    },

    mutations: {
        LOAD_TAB_ITEM(state, payload) {
            state.TabItem = payload
        },
        LOAD_HEADER_TAB(state, payload) {
            state.TabHeader = payload
        },
        LOAD_TAB_Custom(state, payload) {
            state.TabCustom = payload
        },
        LOAD_TAB_Active(state, payload) {
            state.TabActive = payload
        },
        LOAD_TAB(state, payload) {
            state.TabLoading = payload
        },
    },

    actions: {
        GhTableItem(state, item) {
            router.options.routes.forEach((items) => {
                if (items.tabname == item[1].value) {
                    state.commit("LOAD_TAB_ITEM", items.name);
                }
            });
        },
        GhTabActive(state, item) {
            document.getElementById(item[0].id).className = "nav-element link T3 tab-active"
            router.options.GhTabs.forEach((items) => {
                if (`C-${items.id}` != item[0].id) {
                    document.getElementById(`C-${items.id}`).className = "nav-element  link T1 tab-inactive"
                }
                if (`C-${items.id}` == item[0].id) {
                    state.commit("LOAD_TAB_Active", items.value);
                }
            });
        },
        GhBarCustomView(state, item) {
            router.options.GhCustomTabs.forEach((items) => {
                if (items.tabname == item[1].value) {
                    state.commit("LOAD_TAB_Custom", items.name);
                } else {
                    state.commit("LOAD_TAB_Custom", '');
                }
            });
        },
        GhHeaderDisplay(state, item) {
            router.options.GhHeader.forEach((items) => {
                if (items.tabname == item.value) {
                    state.commit("LOAD_HEADER_TAB", items.name);
                }
            })
        },
        removeTabActive(state){
            state.commit("LOAD_TAB_Active", '');
        }
    }
}