import BillingList from "@/view/billing/list_billing";

const routes = [
    {
        path: '/list_billing',
        name: 'billing',
        component: BillingList,
        props: {showMenu: true},
    }
];

export default routes;