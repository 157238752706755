var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.data),function(label,labelIndex){return _c('div',{key:labelIndex},[(label.show)?_c('GhDropSelector',{attrs:{"datablock":{
                id: 'billings_'+label.id,
                title: _vm.$t(label['name']),
                second_title: _vm.$t(label['second_text']),
                num_results: label['result'],
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}),(_vm.HistoricBilling && _vm.HistoricBilling.haveResult == true)?_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'result_search_billing',
            title: typeof _vm.HistoricBilling.resultTitle == 'number' ? _vm.HistoricBilling.resultTitle + ' ' + _vm.$t('results') : _vm.$t(_vm.HistoricBilling.resultTitle),
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.result_billing,{tag:"component"})]},proxy:true},{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'export_result_data', class: 'T15_action', text: _vm.$t('export_results')
                }},on:{"click":_vm.exportResult}})]},proxy:true}],null,false,1899118649)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }