import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
import Global from "@/store/Global";
Vue.use(vuex);

function flattenArray(arr) {
    return arr.reduce((acc, curr) => {
        acc.push(curr);
        if (curr.children && curr.children.length > 0) {
            acc = acc.concat(flattenArray(curr.children));
        }
        return acc;
    }, []);
}

function searchElement(array, id) {
    for (let i = 0; i < array.length; i++) {
        const element = array[i];

        if (element.id === id) {
            element.condition = true;
            return element;
        }

        if (element.children && element.children.length > 0) {
            const findElement = searchElement(element.children, id);
            if (findElement) {
                findElement.condition = true;
                return findElement;
            }
        }
    }

    return null;
}

export default{
    state: {
        type_search_elements: '',
        search_result: [],
        refreshItemsComposed: false,
        items_composed: [],
        refreshItemsOrdered: false,
        items_ordered: [],
        refreshElementsConditionAssociate: false,
        selected_condition_associate: [],
        refreshDependencyGrouper: false,
        dependency_grouper: [],
        refreshInfoConditions: false,
        info_conditions: [],
        elements_order: [],
        default_support: [],
        refreshDefaultSupport: false,
        refreshInfoCondition: false,
        pending_confirm_delete: [],
        windowModify: [],
        refreshPunctuation: false,
        punctuation: [],
        showPunctuation: false
    },
    mutations: {
        loadSearchType(state, payload){
            state.type_search_elements = payload;
        },
        loadSearchResult(state, payload){
            state.search_result = payload;
        },
        loadItemsComposedOrdered(state, payload){
            state.refreshItemsComposed = !state.refreshItemsComposed;
            state.items_composed = payload;

            state.refreshItemsOrdered = !state.refreshItemsOrdered;
            state.items_ordered = flattenArray(payload);
        },
        loadDependencyGrouper(state, payload){
            state.refreshDependencyGrouper = !state.refreshDependencyGrouper;
            state.dependency_grouper = payload;
        },
        loadCleanGrouper(state){
            state.items_composed = [];
            state.items_ordered = [];
        },
        loadCleanSupport(state){
            state.default_support = [];
        },
        loadInfoCondition(state, payload){
            state.refreshInfoConditions = !state.refreshInfoConditions;
            state.info_conditions = payload;
        },
        loadConditionAssociate(state, payload){
            state.refreshElementsConditionAssociate = !state.refreshElementsConditionAssociate;
            state.selected_condition_associate = payload;
        },
        loadGetterDefaultSupport(state, payload){
            state.refreshDefaultSupport = !state.refreshDefaultSupport;
            state.default_support = payload;
        },
        loadPendingConfirmDelete(state, payload){
            state.pending_confirm_delete = payload;
        },
        loadPunctuations(state, payload){
            state.refreshPunctuation = !state.refreshPunctuation;
            state.punctuation = payload;
        },
        loadShowPunctuations(state, payload){
            state.showPunctuation = payload;
        }
    },
    getters: {
        getterItemsComposed(state){
            const dummy = state.refreshItemsComposed; // eslint-disable-line no-unused-vars
            return state.items_composed;
        },
        getterItemsOrdered(state){
            const dummy = state.refreshItemsOrdered; // eslint-disable-line no-unused-vars
            return state.items_ordered;
        },
        getterConditionAssociate(state){
            const dummy = state.refreshElementsConditionAssociate; // eslint-disable-line no-unused-vars
            return state.selected_condition_associate;
        },
        getterDependencyGrouper(state){
            const dummy = state.refreshDependencyGrouper;  // eslint-disable-line no-unused-vars
            return state.dependency_grouper;
        },
        getterInfoConditions(state){
            const dummy = state.refreshInfoCondition; // eslint-disable-line no-unused-vars
            return state.info_conditions;
        },
        getterDefaultSupport(state){
            const dummy = state.refreshDefaultSupport; // eslint-disable-line no-unused-vars
            return state.default_support;
        },
        getterPunctuations(state){
            const dummy = state.refreshPunctuation; // eslint-disable-line no-unused-vars
            return state.punctuation;
        }
    },
    actions: {
        async getSearchType(state,item){
            let  request = API_URL + "search/exercise-grouper/" + item.id;
            await axios({
                url: request,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadSearchType", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchResult(state, item){
            let request = API_URL + "search/exercise-grouper/" + item.type_id + "/" + item.grouper_id + "/selecteds";

            await axios({
                url: request,
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadSearchResult', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setItemsComposed(state, item){
            state.commit('loadItemsComposedOrdered', item.associate);
        },
        async getItemsComposed(state, item){
            let  request = API_URL + "grouper/items-composed/" + item.grouper + "/" + item.id;
            await axios({
                url: request,
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('setItemsComposed', {associate: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async destroyVersionNotSaved(state, item){
            let  request = API_URL + "grouper/destroy/version-not-saved/" + item.id;
            await axios({
                method: "post",
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                error => {
                    console.log(error);
                }
            )
        },
        async setNewItemsComposed(state, item){
            let request = API_URL + "grouper/new-item-composed/" + item.id;
            let formData = new FormData();

            formData.append('items_composed[grouper_id]', item.block);
            item.data.forEach((i) => {
                formData.append('items_composed[id][]', i.id);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('setItemsComposed', {associate: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setCleanGrouper(state){
            state.commit('loadCleanGrouper');
        },
        async setCleanSupport(state){
            state.commit('loadCleanSupport');
        },
        async setReorderItems(state, item) {
            let request = API_URL + "grouper/reordered";
            let formData = new FormData();

            formData.append('items_composed[grouper_id]', item.grouper);
            formData.append('items_composed[id]', item.id);
            item.formData.forEach((i) => {
                formData.append('items_composed[order][' + i[0] + ']', i[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('setItemsComposed', {associate: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )

            Global.closePopUp('popup_order');
            Global.closePopUp('popup_warning_reset_condition');
        },
        async removeAssociateItem(state, item){
            if(state.getters.getterItemsOrdered.find((i) => i.condition) === undefined){
                await state.dispatch('acceptRemoveAssociateItem', item.id);
            }else{
                state.commit('loadPendingConfirmDelete', item.id);
                Global.openPopUp('popup_warning_reset_condition');
            }
        },
        async acceptRemoveAssociateItem(state, item){
            let request = API_URL + "grouper/item/delete";
            let formData = new FormData();

            formData.append('grouper[grouper_id]', item.grouper_id);
            formData.append('grouper[id]', item.id);
            formData.append('grouper[element_id]', item.element_id);
            formData.append('grouper[item_id]', item.item_id);

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        state.commit('loadPendingConfirmDelete', []);
                        await state.dispatch('setItemsComposed', {associate: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        clearPendingConfirmDelete(state){
            state.commit('loadPendingConfirmDelete', []);
        },
        async getInfoCondition(state, item){
            let  request = API_URL + "grouper/condition-info/" + item.element_id + "/" + item.item_id;
            await axios({
                url: request,
            }).then(
                response => {
                    if(response.status === 200){
                        state.commit('loadInfoCondition', response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDependencyGrouper(state, item){
            let request = API_URL + "grouper/condition/" + item.id + "/" + item.item_id + "/" + item.element_id + "/" + item.grouper_id;

            await axios({
                method: "get",
                url: request,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadDependencyGrouper", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setDependencyGrouper(state, item){
            let request = API_URL + "grouper/condition";
            let formData = new FormData();
            formData.append('dependency[id]', item.element.id);
            formData.append('dependency[item_id]', item.element.item_id);
            formData.append('dependency[element_id]', item.element.element_id);
            formData.append('dependency[grouper_id]', item.element.grouper_id);

            item.formData.forEach((i, k) => {
                formData.append('dependency[elements_conditions][' + k + ']', i.value);
            });

            item.dependency.forEach((i, k) => {
                if(i.operator !== undefined) {
                    formData.append('dependency[conditions][' + k + '][operator]', i.operator);
                }

                i.selecteds.forEach((x,y) => {
                    formData.append('dependency[conditions][' + k + '][selecteds][' + y + '][condition]', x.condition);
                    formData.append('dependency[conditions][' + k + '][selecteds][' + y + '][counter]', x.counter);
                    if(x.operator !== undefined) {
                        formData.append('dependency[conditions][' + k + '][selecteds][' + y + '][operator]', x.operator);
                    }
                });
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await state.dispatch('setItemsComposed', {associate: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setConditionAssociate(state, item){
            state.commit('loadConditionAssociate', item);
        },
        async setChangeStateCondition(state, item){
            searchElement(state.state.items_composed, item.element.item_id);
        },
        async getDefaultSupport(state,item){
            let  request = API_URL + "grouper/support/" + item.id;
            await axios({
                url: request
            }).then(
                response => {
                    if(response.status === 200) {
                        this.commit('loadMergeDisabledSupport', response.data.data);
                        state.commit("loadGetterDefaultSupport", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTypeModify(state, item){
            let request = API_URL + "grouper/type/" + item.element_id + "/" + item.item_id;
            return await axios({
                url: request,
            }).then(
                response => {
                    if(response.status === 200){
                        return response;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getImpartationType(state, item){
            let request = API_URL + "grouper/impartation";
            let data = {};

            item.items.forEach((i,j) => {
                data['impartation[' + j + ']'] = i;
            });

            return await axios({
                method: "get",
                url: request,
                params: data,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getModelReport(state,item){
            let request = API_URL + "grouper/model-report/" + item.id;

            return await axios({
                method: "get",
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPunctuation(state, item){
            state.commit("loadShowPunctuations", false);
            await axios(
                API_URL + "grouper/score/" + item.grouper + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200){
                        state.commit("loadPunctuations", response.data.data);
                        state.commit("loadShowPunctuations", true);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPunctuation(state, item){
            let request = API_URL + "grouper/score/" + item.grouper + "/" + item.id;
            let formData = new FormData();

            item.formData.forEach((i) => {
                formData.append(i[0], i[1]);
            });

            return await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        }
    },
};
