var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_start_date',
                text: _vm.$t('select_period_from_date'),
                style: 'min-width: 115px  !important;  width: 246px !important; text-align: right;'
            },"datainput":{
                id: 'search_start_date',
                name: 'indicators[start_date][]',
                value: ''
            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_search_end_date',
                text: _vm.$t('to_date'),
                style: 'min-width: 141px  !important;  width: 141px !important; text-align: right;'
            },"datainput":{
                id: 'search_start_date',
                name: 'indicators[end_date][]',
                value: ''
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                id: 'label_config_support_category_name',
                text: _vm.$t('user') + ':',
                style: 'width: 93px; min-width: 93px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                id: 'input_config_support_category_name',
                name: 'indicators[user][]',
                style: 'width: 15%',
                type: 'text',
                value:''
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }