<template>
    <div>
        <div v-for="(i,index) in data" :key="i.id" style="">
            <GhTreeContent
                :dataBlock="{
                    id: dataBlock.id,
                    actionWithoutChildren: dataBlock.actionWithoutChildren,
                    actionWithChildren: dataBlock.actionWithChildren,
                    levelJustify: 0,
                    interspersed: index % 2,
                    extraLabelNumber: dataBlock.extraLabelNumber,
                    extraTableClass: dataBlock.extraTableClass,
                    extraLabelText: (dataBlock.extraLabelText != '' && dataBlock.extraLabelText !== undefined) ? dataBlock.extraLabelText : '',
                    alternativeSummary: (dataBlock.alternativeSummary != '' && dataBlock.alternativeSummary !== undefined) ? dataBlock.alternativeSummary : false,
                    alternativeValue: (dataBlock.alternativeValue != '' && dataBlock.alternativeValue !== undefined) ? dataBlock.alternativeValue : '',
                    alternativeStyleSummary: (dataBlock.alternativeStyleSummary != '' && dataBlock.alternativeStyleSummary !== undefined) ? dataBlock.alternativeStyleSummary : ''
                }"
                :data="i"
                :header="header"
                :class="index % 2 ? 'striped_tree' : 'no_striped_tree'"
            >
                <template v-slot:field_redirect="{itemProp, labelProp}">
                    <div>
                        <slot name="field_redirect" v-bind:itemProp="itemProp" v-bind:labelProp="labelProp"></slot>
                    </div>
                </template>
                <template v-slot:actions="{itemProp}">
                    <div>
                        <slot name="actions" v-bind:itemProp="itemProp.itemProp"></slot>
                    </div>
                </template>
            </GhTreeContent>
        </div>
    </div>
</template>

<script>
    import GhTreeContent from "@/components/GhTreeContent.vue";
    export default {
        name: "GhTreeContentBlock",
        props: ['data','header','dataBlock'],
        data() {
            return {
                treeContentBlock: true,
            }
        },
        components:{
            GhTreeContent,
        }
    }
</script>