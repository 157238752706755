var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh_data_selector-operator tree_row gh_colspan-15 link",staticStyle:{"padding-left":"5px","padding-top":"3px","padding-bottom":"3px","display":"flex"},attrs:{"id":`${_vm.dataBlock.id}-${_vm.data.id}`}},[_c('div',{staticClass:"label_level T14",staticStyle:{"white-space":"nowrap"},style:(_vm.nodeMargin)},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]),_c('div',{class:_vm.toggleChildrenClass,staticStyle:{"white-space":"nowrap","margin-left":"10px"},on:{"click":function($event){_vm.data.data ? _vm.ShowOrHiddenTable() : _vm.ShowOrHidden()}}},[_vm._v(" "+_vm._s(_vm.ChildrenMoreOrLess)+" ")]),(_vm.data.data && (_vm.dataBlock.extraLabelNumber || _vm.dataBlock.extraLabelText))?_c('div',{staticClass:"T14 gh_hidden_list-extra_label label_span-8",staticStyle:{"color":"black !important","font-size":"13px !important","margin-top":"1px"}},[_vm._v(" "+_vm._s(_vm.data.data.length)+" "+_vm._s(_vm.$t(_vm.dataBlock.extraLabelText))+" ")]):_vm._e()]),(_vm.data.data)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren),expression:"showChildren"}],class:_vm.dataBlock.extraTableClass,attrs:{"id":`children-data-${_vm.dataBlock.id}-${_vm.data.id}`,"state":_vm.showChildren}},[_c('GhTableCommon',{attrs:{"extratable":{
                id: 'table_'+_vm.dataBlock.id + _vm.data.id,
            },"header":_vm.header,"data":_vm.data.data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',[_vm._t("field_redirect",null,{"itemProp":itemProp,"labelProp":labelProp})],2)]}},{key:"actions",fn:function({itemProp}){return [_c('div',[_vm._t("actions",null,{"itemProp":{itemProp}})],2)]}}],null,true)})],1):_vm._e(),(_vm.hasChildren)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren),expression:"showChildren"}],attrs:{"id":`children-data-${_vm.dataBlock.id}-${_vm.data.id}`,"state":_vm.showChildren}},_vm._l((_vm.data.children),function(child,indexChildren){return _c('div',{key:child.id},[_c('GhTreeContent',{class:_vm.dataBlock.interspersed ? (indexChildren%2 ? 'striped_tree' : 'no_striped_tree') : (indexChildren%2 ? 'no_striped_tree' : 'striped_tree'),attrs:{"dataBlock":{
                    id: _vm.dataBlock.id,
                    actionWithoutChildren: _vm.dataBlock.actionWithoutChildren,
                    actionWithChildren: _vm.dataBlock.actionWithChildren,
                    levelJustify: _vm.dataBlock.levelJustify + 20,
                    interspersed: _vm.dataBlock.interspersed ? indexChildren%2 : !indexChildren%2,
                    extraLabelNumber: _vm.dataBlock.extraLabelNumber,
                    extraLabelText: _vm.dataBlock.extraLabelText,
                    extraTableClass: _vm.dataBlock.extraTableClass
                },"data":child,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',[_vm._t("field_redirect",null,{"itemProp":itemProp,"labelProp":labelProp})],2)]}},{key:"actions",fn:function({itemProp}){return [_c('div',[_vm._t("actions",null,{"itemProp":itemProp})],2)]}}],null,true)})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }