var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'evaluation_basis',title: _vm.$t('evaluation_basis')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_evaluation_basis"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefOrder",attrs:{"datalabel":{
                                    text: _vm.$t('order') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'evaluation_basis_order',
                                    name: 'evaluation_basis[order]',
                                    style: 'width: 200px',
                                    value: _vm.content.order,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('order') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigEvaluationBasis.data.order))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'evaluation_basis_name',
                                    name: 'evaluation_basis[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.content.name,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigEvaluationBasis.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                    text: _vm.$t('description'),
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                },"datainput":{
                                    id: 'evaluation_basis_description',
                                    name: 'evaluation_basis[description]',
                                    value: _vm.content.description
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigEvaluationBasis.data.description))])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{ref:"RefLastItem",attrs:{"datalabel":{
                                text: _vm.$t('last_item'),
                                style: '',
                                id: 'label_evaluation_basis_last_item'
                            },"datainput":{
                                required: false,
                                type: 'checkbox',
                                id: 'value_evaluation_basis_last_item',
                                value: 1,
                                name: 'evaluation_basis[last_item]',
                                selected: _vm.ConfigEvaluationBasis.data.last_item === 1,
                                disabled: _vm.$root._route.params.view
                            }},on:{"click":_vm.checkLastItem}})],1),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefRange",attrs:{"datalabel":{
                                    text: _vm.$t('range') + (_vm.lastItem ? '*' : ''),
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: _vm.lastItem,
                                    id: 'evaluation_basis_range',
                                    name: 'evaluation_basis[range]',
                                    style: 'width: 80px',
                                    value: _vm.content.range,
                                    limitNumMax: 100,
                                    type: 'int'
                                }},on:{"keyup":_vm.checkRange}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('range') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigEvaluationBasis.data.range))])]],2)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitEvaluationBasis}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }