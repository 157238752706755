<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'all_programs_' + label.id,
                    title: $t(label['name']),
                    label: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>
    </div>
</template>

<script>
import GhNoDropSelector from "fe-gh-no-dropselector-lib";

import {mapState} from "vuex";

import all_programs from './all_programs/all_programs.vue';

export default {
    name: 'list_all_programs',
    components: {
        GhNoDropSelector
    },
    computed: {
        ...mapState(['AllPrograms'])
    },
    data(){
        return {
            data: [
                {id: 1, name: 'all_programs', component: all_programs, result: '', show: true , second_text: ''},
            ],
            haveData: false
        }
    },
    async beforeMount() {
        this.haveData = true;
    },
}
</script>

<style>
    [id^="all_programs_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }
</style>