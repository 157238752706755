<template>
    <div class="float_window" style="margin-top: -5px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: false,
                showLoader: 'OK',
                text: $t('define_conditions')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'drop_conditions',
                    title: $t('conditions')
                }"
            >
                <template v-slot:content v-if="haveData">
                    <div class="gh-content-global">
                        <form id="elements_conditions">
                            <div class="gh-row-content" v-for="(item, key) in getterInfoConditions" :key="'KEY21' + key">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t(key) + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item}}</label>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                                    <GhTreeSelectorBlock
                                        :dataBlock="{
                                            type: 'checkbox',
                                            id: 'activity',
                                            label: $t('elements_conditions'),
                                            title: $t('elements_conditions'),
                                            text: $t('select_elements_conditions') + ':',
                                            name: 'conditions[elements][]',
                                            id_value: 'value',
                                            name_label: 'text',
                                            child_key: 'children',
                                            required: false,
                                            checkparentdata: false,
                                            style: 'margin-left: 16px; width:100%',
                                            popupStyle: 'min-width: 900px;width: 55%;'
                                        }"
                                        ref="refElementsConditions"
                                        :selected_input="[]"
                                        :items="getFeeds.feed_elements_conditions"
                                        @accept="showConditions"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('elements_conditions') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterDependencyGrouper.elements_conditions !== undefined ? getterDependencyGrouper.elements_conditions.map((item) => item.text).join(', ') : ''}}</label>
                                </template>
                            </div>
                        </form>
                        <div v-if="selectorConditions || (($root._route.params.view === true || $root._route.params.view === 'true') && getterDependencyGrouper.elements_conditions !== undefined)" class="gh-row-content">
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px !important; white-space: normal; text-align: right;">{{$t('conditions')}}</label>
                            <GhAction style="margin-right: 23px;"
                                :dataction="{
                                    id: 'action_edit_dependences',
                                    text: $t('[ + ]')
                                }"
                                @click="Global.openPopUp('popup_dependencies');"
                            />
                            <label v-if="dependency[0].selecteds.length !== 0" class="gh_text_field-label T15_b" :id="'number_dependency'">{{dependency.length + ' ' + $t('dependences')}}</label>
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>

            <div class="centered_footer" style="margin-top: 8px; padding-bottom: 8px;">
                <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                    <GhButton
                        :datainput="{
                            id: 'btn_save',
                            text: $t('save')
                        }"
                        @click="saveCondition"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_dependencies',
                title: $t('dependency_definition'),
                type: 'warning',
                style: 'width: 80%; min-width: 1000px'
            }"
            :bodyConfiguration="{
                id: 'popup_rename_body',
                class: '',
                style: 'padding:2px;',
                text: $t('click_on_the_elements_to_define_the_dependency') + ':'
            }"
            :footerConfiguration="{}"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <div class="dependencies-elements">
                        <template v-for="(item, index) in elementsConditions">
                            <div class="gh-row-content" :key="'KEY12' + 'elements_conditions_' + index">
                                <label class="gh_text_field-label T15_b" style="margin-left: 5px;" :id="'number_dependency_' + index">{{countToAlphabet(index) + ':'}}</label>
<!--                                <GhAction style="margin-left: 10px; margin-top: -1px;" :style="'color: ' + returnColor(answer) + ' !important;'"-->
                                <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                                    <GhAction style="margin-left: 10px; margin-top: -1px;"
                                        :dataction="{
                                            id: 'action_select_dependency_' + index,
                                            text: item.text
                                        }"
                                        @click="activeDependency ? checkDependency(index,item) : ''"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.text}}</label>
                                </template>
                            </div>
                        </template>
                    </div>
                    <div v-if="buttons1 && ($root._route.params.view === false || $root._route.params.view === 'false')" class="gh-row-content centered">
                        <GhButton
                            :datainput="{id: 'btn_minnor', text: '<', class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="popupConditionInputs('<',1)"
                        />
                        <GhButton
                            :datainput="{id: 'btn_mayor', text: '>', class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="popupConditionInputs('>',1)"
                        />
                        <GhButton
                            :datainput="{id: 'btn_equal', text: '=', class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="popupConditionInputs('=',1)"
                        />
                        <GhButton
                            :datainput="{id: 'btn_minnor_mayor', text: '<>', class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="popupConditionInputs('<>',2)"
                        />
                        <GhButton
                            :datainput="{id: 'btn_mayor_minnor', text: '><', class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="popupConditionInputs('><',2)"
                        />
                        <GhButton
                            :datainput="{id: 'btn_evidence', text: $t('evidence'), class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="addCondition('evidence')"
                        />
                        <GhButton
                            :datainput="{id: 'btn_na', text: $t('na'), class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="addCondition('na')"
                        />
                    </div>
                    <div v-if="buttons2 && ($root._route.params.view === false || $root._route.params.view === 'false')" class="gh-row-content centered">
                        <GhButton
                            :datainput="{id: 'btn_and', text: $t('and').toUpperCase(), class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="operatorCondition('and')"
                        />
                        <GhButton
                            :datainput="{id: 'btn_or', text: $t('or').toUpperCase(), class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="operatorCondition('or')"
                        />
                        <GhButton
                            :datainput="{id: 'btn_not', text: $t('not').toUpperCase(), class: 'T19 container-md_button_content', style: ' display: flex;'}"
                            @click="operatorCondition('not')"
                        />
                    </div>
                    <div class="dependencies-table" style="margin-top: 15px;" :key="'KEY13' + 'dependency_table' + refreshTableDependency">
                        <table id="table_dependencies">
                            <template v-for="(item, index) in dependency">
                                <tr :key="'KEY14' + 'TR-'+index" :class="'striped_white'">
                                    <td style="width: auto; padding-right: 10px; text-align: end;" class="T15_b border_w table-cell">{{$t('dependency') + ' ' + (index + 1) + ':'}}</td>
                                    <td style="width: 85%" class="T15_b border_w table-cell">
                                        <template v-for="(i, k) in item.selecteds">
                                            <span :style="'color: ' + i.color + ';'" :key="'KEY15' + 'W' + k">{{countToAlphabet(i.counter)}}</span>
                                            <span :key="'KEY16' + 'V' + k">{{' ' + (i.condition === 'evidence' || i.condition === 'na' ? $t(i.condition) : i.condition) + ' '}}</span>
                                            <span v-if="i.operator !== undefined && i.operator !== ''" :key="'KEY17' + 'O' + k">{{' ' + $t(i.operator).toUpperCase() + ' '}}</span>
                                        </template>
                                    </td>
                                    <td style="width: 15%">
                                        <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                                            <GhAction v-if="item.active" style="margin-left: 10px; margin-top: -1px;"
                                                :dataction="{
                                                    id: 'action_end_dependency_' + index,
                                                    text: $t('end'),
                                                    icon: require('@/assets/svg/tick.svg'),
                                                }"
                                                @click="endDependency(index)"
                                            />
                                            <GhAction v-if="!item.active" style="margin-left: 10px; margin-top: -1px;"
                                                :dataction="{
                                                    id: 'action_edit_dependency_' + index,
                                                    text: $t('modify'),
                                                    icon: require('@/assets/gh_new_dessign/edit.svg'),
                                                }"
                                                @click="editDependency(index)"
                                            />
                                            <GhAction v-if="dependency.length > 1" style="margin-left: 10px; margin-top: -1px;"
                                                :dataction="{
                                                    id: 'action_delete_dependency_' + index,
                                                    text: $t('delete'),
                                                    icon: require('@/assets/gh_new_dessign/trash.svg'),
                                                }"
                                                @click="deleteDependence = index;Global.deleteConfirm('confirm_delete_condition','Dependencies', item.id, {'dependency': index + 1}, $t('delete_dependency'), $t('preparing_delete_dependency'), 'remove');"
                                            />
                                        </template>
                                    </td>
                                </tr>
                                <tr v-if="item.operator !== undefined && item.operator !== ''" :key="'KEY18' + 'operator_' + index"  :class="'striped'">
                                    <td colspan="100%" class="T15_b border_w table-cell" style="padding-left: 40px;">
                                        {{$t(item.operator).toUpperCase()}}
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                            <GhAction style="margin-top: 10px; margin-left: 5px;"
                                :dataction="{
                                    id: 'action_new_dependencies',
                                    text: $t('add_dependency'),
                                    icon: require('../../../../assets/svg/add_plus.svg')
                                }"
                                @click="Global.openPopUp('popup_operators')"
                            />
                        </template>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <template v-if="$root._route.params.view === false || $root._route.params.view === 'false'">
                    <GhButton
                        :datainput="{
                            id: 'button_save_popup_dependencies',
                            text: $t('save'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="saveDependency"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_dependencies',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="closeDependency"
                />
            </template>
        </GhPopUp>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_inputs_values',
                title: $t('enter_values'),
                type: 'warning',
                style: 'width: 400px!important; min-width: 400px!important;'
            }"
            :bodyConfiguration="{
                id: 'popup_rename_body',
                class: '',
                style: 'padding:2px;'
            }"
            :footerConfiguration="{}"
        >
            <template v-slot:body>
                <form id="form_check_inputs">
                    <div class="gh-content-global" :key="'KEY19' + 'inputs_' + refreshInputs">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: textInputs.firstInput + '*',
                                    style: 'width: 40px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'first_input',
                                    name: 'inputs[first]',
                                    style: 'width: 200px',
                                    value: '',
                                    type: 'float'
                                }"
                            />
                        </div>
                        <div v-if="showInput2" class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: textInputs.secondInput + '*',
                                    style: 'width: 40px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'second_input',
                                    name: 'inputs[second]',
                                    style: 'width: 200px',
                                    value: '',
                                    type: 'float'
                                }"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_save_popup_dependencies',
                        text: $t('save'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="addCondition(conditionType)"
                />
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_inputs',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('popup_inputs_values');refreshInputs++;"
                />
            </template>
        </GhPopUp>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_operators',
                title: $t('select_condition'),
                type: 'warning',
                style: 'width: 400px!important; min-width: 400px!important;'
            }"
            :bodyConfiguration="{
                id: 'popup_rename_body',
                class: '',
                style: 'padding:2px;'
            }"
            :footerConfiguration="{}"
        >
            <template v-slot:body>
                <form id="form_operators">
                    <div class="gh-content-global" :key="'KEY20' + 'operators_' + refreshOperators">
                        <div class="gh-row-content centered">
                            <GhChecks
                                :datalabel="{text:$t('and').toUpperCase(),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:true,type:'radio',id:'',value:'and',name:'operator'}"
                            />
                            <GhChecks
                                :datalabel="{text:$t('or').toUpperCase(),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'radio',id:'',value:'or',name:'operator'}"
                            />
                            <GhChecks
                                :datalabel="{text:$t('not').toUpperCase(),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'radio',id:'',value:'not',name:'operator'}"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <template v-if="haveData">
                    <GhButton
                        :datainput="{
                            id: 'button_save_popup_operators',
                            text: $t('accept'),
                            class: 'T19 container-md_button_content',
                            style: ' display: flex;'
                        }"
                        @click="addDependency()"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_operators',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: 'display: flex;'
                    }"
                    @click="Global.closePopUp('popup_operators');refreshOperators++;"
                />
            </template>
        </GhPopUp>

        <GhDeleteConfirm
            :visible_fields="Global.state.state_confirm.item_confirm"
            :id_popup="'confirm_delete_condition'"
            :content_warning="Global.state.state_confirm.subheader_confirm"
            v-on:function="deleteCondition"
        />

    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import {mapGetters, mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhAction from "fe-gh-action-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDeleteConfirm from "fe-gh-delete-confirm-lib";

    export default {
        name: "template_conditions",
        components: {
            GhDeleteConfirm,
            GhChecks,
            GhInputTextField,
            GhButton,
            GhPopUp,
            GhAction,
            GhTreeSelectorBlock,
            GhNoDropSelector,
            GhLocation
        },
        computed:{
            ...mapState(['CreationGrouperElements','getFeeds']),
            ...mapGetters(['getterInfoConditions','getterConditionsAssociated', 'getterConditionAssociate', 'getterDependencyGrouper'])
        },
        data(){
            return{
                haveData: false,
                selectorConditions: false,
                buttons1: false,
                buttons2: false,
                refreshTableDependency: 0,
                refreshInputs: 0,
                indexDependence: 0,
                activeDependence: 0,
                deleteDependence: 0,
                refreshOperators: 0,
                colors: [
                    '#E81212',
                    '#E84612',
                    '#E88612',
                    '#E8C112',
                    '#C4E812',
                    '#73E812',
                    '#12E87A',
                    '#12E8C4',
                    '#12A7E8',
                    '#125DE8',
                    '#5312E8',
                    '#9D12E8',
                    '#E8128A'
                ],
                textInputs: {
                    firstInput: '',
                    secondInput: '',
                },
                showInput2: false,
                activeDependency: true,
                conditionType: '',
                elementsConditions: [],
                dependency: [
                    {
                        'id': Math.floor(Math.random() * 100000000000),
                        'active': true,
                        'selecteds': []
                    },
                ],
                stateDependency: [
                    {
                        'id': Math.floor(Math.random() * 100000000000),
                        'active': true,
                        'selecteds': []
                    },
                ],
                selecteds: [],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getElementsConditions', this.getterConditionAssociate,{root:true});
            await this.$store.dispatch('getDependencyGrouper', this.getterConditionAssociate, {root: true});

            this.haveData = true;
        },
        watch:{
            'haveData'(){
                if(this.$root._route.params.view === false || this.$root._route.params.view === 'false') {
                    var that = this;
                    setTimeout(function () {
                        if (that.getterDependencyGrouper.elements_conditions !== undefined && that.getterDependencyGrouper.elements_conditions !== null) {
                            that.$refs.refElementsConditions.datasaved = that.getterDependencyGrouper.elements_conditions;
                            that.$refs.refElementsConditions.afterData();
                            that.$refs.refElementsConditions.save();
                            that.showConditions();

                            that.stateDependency = that.getterDependencyGrouper.dependency;
                            that.dependency = that.getterDependencyGrouper.dependency;
                        }
                    }, 200);
                }else{
                    this.getterDependencyGrouper.elements_conditions.forEach((item) => {
                        var params = {
                            id: item.value,
                            text: item.text
                        }

                        this.elementsConditions.push(params);
                    });
                }
            }
        },
        methods:{
            showConditions(){
                this.selectorConditions = this.$refs.refElementsConditions.datasaved.length === 0 ? false : true;
                this.elementsConditions = [];
                this.$refs.refElementsConditions.datasaved.forEach((item) => {
                    var params = {
                        id: item.value,
                        text: item.text
                    }

                    this.elementsConditions.push(params);
                });
            },
            saveDependency(){
                this.Global.closePopUp('popup_dependencies');
                this.dependency.forEach((item) => {
                    item.active = false;
                });
                this.stateDependency = this.dependency;
            },
            closeDependency(){
                this.Global.closePopUp('popup_dependencies');
                this.dependency = this.stateDependency;
            },
            checkDependency(index){
                if(!this.buttons2){
                    this.indexDependence = index;
                    this.buttons1 = true;
                    this.buttons2 = false;
                }
            },
            popupConditionInputs(type,numeric){
                this.showInput2 = numeric === 1 ? false : true;
                this.conditionType = type;

                if(numeric === 1){
                    this.textInputs.firstInput = type;
                }else{
                    this.textInputs.firstInput = type.split('')[0];
                    this.textInputs.secondInput = type.split('')[1];
                }

                this.Global.openPopUp('popup_inputs_values');
            },
            addCondition(type){
                var params = {};
                var text = '';
                var counter = 0;
                var formData = '';
                switch(type){
                    case '<':
                    case '>':
                    case '=':
                    case '<>':
                    case '><':
                        if(this.Global.checkRequired('form_check_inputs') === 'OK') {
                            formData = new FormData(document.getElementById('form_check_inputs'));
                            formData.forEach((item) => {
                                text += (counter === 0 ? this.textInputs.firstInput : this.textInputs.secondInput) + ' ' + item + ' ';
                                counter++;
                            });

                            text = text.substring(0, text.length - 1)

                            params = {
                                counter: this.indexDependence,
                                condition: text
                            }

                            this.dependency[this.activeDependence].selecteds.push(params);

                            this.buttons1 = false;
                            this.buttons2 = true;
                            this.Global.closePopUp('popup_inputs_values');
                            this.refreshInputs++;
                        }
                    break;
                    case 'evidence':
                    case 'na':
                        params = {
                            counter: this.indexDependence,
                            condition: type
                        }

                        this.dependency[this.activeDependence].selecteds.push(params);

                        this.buttons1 = false;
                        this.buttons2 = true;
                    break;
                }
            },
            operatorCondition(type){
                this.dependency[this.activeDependence].selecteds[this.dependency[this.activeDependence].selecteds.length - 1]['operator'] = type;
                this.buttons2 = false;
            },
            endDependency(index){
                this.dependency[index].active = false;
                this.buttons1 = false;
                this.buttons2 = false;
                this.activeDependency = false;
            },
            editDependency(index){
                this.dependency.forEach((item) => {
                    item.active = false;
                });
                this.dependency[index].active = true;
                this.activeDependency = true;
                this.activeDependence = index;
                if(this.dependency[index].selecteds.length === 0){
                    this.buttons1 = false;
                    this.buttons2 = false;
                }else if(this.dependency[index].selecteds[this.dependency[index].selecteds.length - 1].operator === undefined || this.dependency[index].selecteds[this.dependency[index].selecteds.length - 1].operator === ''){
                    this.buttons1 = false;
                    this.buttons2 = true;
                }
            },
            addDependency(){
                if(this.Global.checkRequired('form_operators') === 'OK') {
                    var rand = Math.floor(Math.random() * 100000000000);
                    var params = {
                        'id': rand,
                        'active': false,
                        'selecteds': []
                    }

                    const formData = new FormData(document.getElementById('form_operators'));
                    var operator = '';
                    formData.forEach((item) => {
                        operator = item ;
                    });

                    this.dependency[this.dependency.length - 1]['operator'] = operator;
                    this.dependency.push(params);
                    this.refreshTableDependency++;
                    this.Global.closePopUp('popup_operators');
                    this.refreshOperators++;
                }
            },
            deleteCondition(){
                if(this.dependency[this.deleteDependence + 1] === undefined) delete this.dependency[this.deleteDependence - 1]['operator'];
                delete this.dependency[this.deleteDependence];
                this.dependency = this.dependency.filter((n) => n);
                this.Global.closePopUp('confirm_delete_condition');
            },
            countToAlphabet(count){
                const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                if (count >= 0 && count < 26) {
                    return alphabet.charAt(count);
                } else {
                    return 'Z';
                }
            },
            async saveCondition(){
                var elements_conditions = [];
                this.$refs.refElementsConditions.datasaved.forEach((item) => {
                    elements_conditions.push(item);
                });

                await this.$store.dispatch('setDependencyGrouper', {
                    element: this.getterConditionAssociate,
                    formData: elements_conditions,
                    dependency: this.stateDependency
                }, {root: true});

                window.opener.UpdatedSelect({
                    element: this.getterConditionAssociate
                });

                this.Global.windowClose();
            }
        }
    }
</script>

<style>
    #action_edit_dependences{
        text-decoration: none!important;
    }

    .dependencies-elements, .dependencies-table{
        overflow-y: auto;
        height: auto;
        max-height: 200px;
    }

    #activity_selector_label{
        width: 134px;
        text-align: right;
    }
</style>