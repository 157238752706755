<template>
    <div>
        <GhNoDropSelector
            :datablock="{
                id: 'drop_benchmark',
                title: $t('benchmark'),
            }"
        >
            <template v-slot:content>
                <component v-bind:is="Benchmarck"/>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import Benchmarck from '@/view/config/benchmark/benchmark/list_benchmark.vue'

    export default {
        name: 'list_benchmark',
        components: {
            GhNoDropSelector
        },
        data(){
            return{
                Benchmarck:Benchmarck
            }
        }
    }
</script>