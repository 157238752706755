var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'billing',
        },"header":_vm.header,"data":_vm.TabBilling.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){return _vm.openBilling(itemProp.id)}}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_company_'+itemProp['id'],
                    text: _vm.$t('paid'),
                }},on:{"click":function($event){return _vm.openBilling(itemProp.id)}}}),_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_config_bibliography',
                    text: _vm.$t('delete'),
                    icon: require('../../../assets/gh_new_dessign/trash.svg'),
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Billing', itemProp.id,{'company': itemProp.business, 'reference': itemProp.code}, _vm.$t('delete_pending_payment'), _vm.$t('preparing_delete_pending_payment'), 'delete')}}})]}}],null,false,3198881214)}),_c(_vm.billing_popup,{tag:"component"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }