import Vue from "vue";
import vuex from "vuex";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);
export default {
    state: {
        searchData: [],
        resultTitle: 'No se ha encontrado ningún resultado',
        haveResult: false,
    },
    mutations: {
        loadBillingData(state,payload){
            state.searchData = payload
        },
        loadBillingResult(state, payload){
            state.resultTitle = payload
        },
        loadBillingHaveResult(state, payload){
            state.haveResult = payload
        }
    },
    actions: {
        initialStateHistorical(state){
            state.commit("loadBillingHaveResult", false);
        },
        destroyedHistoricalResultData(state){
            state.commit("loadBillingData", '');
            state.commit('loadBillingResult', 'No se ha encontrado ningún resultado');
            state.commit("loadBillingHaveResult", false);
        },
        async searchBillingData(state,items){
            let requestLogin = API_URL +'list_historic_billing';

            let params = {};

            items.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    state.commit("loadBillingData", response.data.data);
                    state.commit("loadBillingResult", response.data.data.length);
                    state.commit("loadBillingHaveResult", true);
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportBillingResult(state,items){
            let requestLogin = API_URL + 'exports/excel';
            let params = {};

            items.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
