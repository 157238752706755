<template>
    <GhTableCommon v-if="haveData"
        :extratable="{
            id: 'excluded_evaluators',
        }"
        :header="header"
        :data="getterExcludedEvaluators"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'assign_excluded_evaluators_' + itemProp.id,
                    text: $t('assign'),
                    icon: require('../../../../assets/gh_new_dessign/tick.svg')
                }"
                @click="assignAction(itemProp.id)"
            />
        </template>
    </GhTableCommon>
</template>

<script>
import GhTableCommon from "fe-gh-table-common-lib";
import GhAction from "fe-gh-action-lib";

import {mapGetters} from "vuex";

export default {
    name: 'excluded_evaluators',
    components: {
        GhAction,
        GhTableCommon
    },
    computed: {
        ...mapGetters(['getterExcludedEvaluators'])
    },
    data(){
        return {
            header: [
                {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                {text: 'surnames', field: 'surnames', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                {text: '', field: 'actions', sorting: false}
            ],
            haveData: false
        }
    },
    async beforeMount(){
        const token = localStorage.getItem("token");
        if (token) {
            await this.$store.dispatch('getExcludedEvaluators', {
                id: this.$root._route.params.id,
                itinerary: this.$root._route.params.itinerary,
            }, {root: true});

            if(this.$root._route.params.view){
                delete this.header[2];
                this.header = this.header.filter(n => n);
            }

            this.haveData = true;
        }
    },
    methods: {
        assignAction(id){
            this.Global.windowOpen(this.Global.openSecondWindow('assign_evaluator_by_assign', {
                id: id,
                view: true,
                windowed: true,
                assigned: true
            }),'window_evaluator');

            var then = this;

            window.UpdatedSelect = async function(e){
                if(e !== undefined) {
                    const newItem = {
                        'id': id,
                        'full_name': e.name + ' ' + e.surnames
                    };

                    if(e.type === 'leader'){
                        then.$parent.$parent.evaluator = [];
                        const idExists = then.$parent.$parent.leader.some(leader => leader.id === newItem.id);

                        if (!idExists) {
                            then.$parent.$parent.leader.push(newItem);
                        }

                        then.$parent.$parent.leaderText = e.name + ' ' + e.surnames;
                    }else{
                        const idExists = then.$parent.$parent.evaluator.some(evaluator => evaluator.id === newItem.id);

                        if (!idExists) {
                            then.$parent.$parent.evaluator.push(newItem);
                        }

                        then.$parent.$parent.evaluatorText = '';
                        then.$parent.$parent.evaluator.forEach((i) => {
                            then.$parent.$parent.evaluatorText += i.full_name + ', ';
                        });
                    }
                }
            }
        }
    }
}
</script>