var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_config_generic_holidays',title: _vm.$t('generic_holidays')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_generic_holidays"}},[_c('div',{key:_vm.refresh,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content",staticStyle:{"width":"100% !important"}},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description') + '*',
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                },"datainput":{
                                    id: 'generic_holiday_description',
                                    name: 'generic_holiday[description]',
                                    required: true,
                                    value: _vm.ConfigGenericHolidays.data.description !== undefined ? _vm.ConfigGenericHolidays.data.description : '',
                                    style: 'width: 100%'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigGenericHolidays.data && _vm.ConfigGenericHolidays.data.description !== undefined ? _vm.ConfigGenericHolidays.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{attrs:{"datalabel":{
                                    id: 'label_date_generic_holiday',
                                    text: _vm.$t('data'),
                                    style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                },"datainput":{
                                    id:'generic_holiday_date',
                                    name:'generic_holiday[date]',
                                    value: _vm.date,
                                    required: true,
                                    format: 'DD/MM',
                                    placeholder: 'DD/MM'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('data') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigGenericHolidays.data && _vm.ConfigGenericHolidays.data.event_date !== undefined ? _vm.ConfigGenericHolidays.data.event_date : ''))])]],2)])])]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
            },"type":"submit"},on:{"click":_vm.submitGenericHoliday}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }