var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getterTypeExercise.support !== undefined && (_vm.getterTypeExercise.support.metrics.view || _vm.getterTypeExercise.support.examples.view || _vm.getterTypeExercise.support.bibliography.view || _vm.getterTypeExercise.support.videography.view || _vm.getterTypeExercise.support.support_material.view || _vm.getterTypeExercise.support.good_practices.view) && _vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"8px"}},[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"5px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('support_material_2')))])]),(_vm.supports.length !== 0)?_c('div',{staticClass:"grid-container-2"},_vm._l((_vm.supports),function(supportItem,supportKey){return _c('div',{key:'KEY64' + supportKey,staticClass:"grid-item min-width-490"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhSearchWindow',{ref:'ref' + supportItem.id,refInFor:true,attrs:{"dataBlock":{
                        label: {
                            style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                        },
                        ResultSearch: 'result_search_supports',
                        nameStore: 'getFeeds',
                        imagelocation: require('../../../../../../assets/gh_new_dessign/floating.svg'),
                        id: supportItem.id,
                        text: _vm.$t(supportItem.name) + (_vm.getterTypeExercise.support !== undefined  && _vm.getterTypeExercise.support[supportItem.id] !== undefined && _vm.getterTypeExercise.support[supportItem.id].required ? '*' : ''),
                        textlocation: _vm.$t('add') + ' ' + _vm.$t(supportItem.name),
                        actionresult: 'loadResultSearchSupports',
                        nameResult: 'specialty[' + supportItem.id + ']',
                        supportPermision: false,
                        loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif'),
                        required: _vm.getterTypeExercise.support !== undefined  && _vm.getterTypeExercise.support[supportItem.id] !== undefined ? _vm.getterTypeExercise.support[supportItem.id].required : false
                    },"mainSelector":{
                        name: 'support_search',
                        formId: 'search',
                        actionsearch: 'getSearchSupports'
                    },"tableResolt":{
                        header: _vm.header[supportItem.id],
                        actionsSearch: _vm.actionsTable[supportItem.id],
                        data: []
                    },"tableSelected":{
                        headersummary: _vm.summary[supportItem.id],
                        actionsSearch: _vm.actionsTable[supportItem.id],
                        actionsSelected: _vm.actionsTable[supportItem.id],
                        disabled: [],
                        selected: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data[supportItem.id] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data[supportItem.id] : [],
                    }}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px!important","min-width":"150px!important","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(supportItem.id) + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data[supportItem.id] !== undefined ? Object.values(_vm.viewSummary[supportItem.id]).join(', ') : ''))])])]],2)}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }