<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_calendar',
            title: $t('modify'),
            type: 'info',
            style: 'width: 35%; min-width: 430px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_modify_end_date">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <GhCalendar
                            :datalabel="{
                                id: 'label_modify_end_date',
                                text: $t('end_date'),
                                style: 'text-align: right; padding-top: 2px; width: 90px; min-width: 90px; text-align: right;'
                            }"
                            :datainput="{
                                id: 'input_modify_end_date',
                                name: 'end_date',
                                required: true,
                                value: getterDataModify.end_date,
                            }"
                        />
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_modify_calendar',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="submitModify"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_modify_calendar',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_calendar');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import {mapGetters} from "vuex";

    export default {
        name: "popup_calendar",
        components: {
            GhCalendar,
            GhPopUp,
            GhButton
        },
        computed:{
            ...mapGetters(['getterDataModify'])
        },
        methods:{
            async submitModify(){
                if(this.Global.checkRequired('form_modify_end_date') === 'OK') {
                    const formData = new FormData(document.getElementById('form_modify_end_date'));

                    await this.$store.dispatch('setDataModify', {
                        formData: [...formData],
                        id: this.getterDataModify.id,
                        agrupator_id: this.getterDataModify.agrupator_id
                    }, {root: true});

                    this.$parent.refreshSections++;
                    this.Global.closePopUp('popup_calendar');
                }
            },
        }
    }
</script>