<template>
    <GhTableReport
        :extratable="{
            id: 'report'
        }"
        :header="header"
        :data="data_supervised_evaluated.content !== undefined && data_supervised_evaluated.content.length != 0 ? data_supervised_evaluated.content : []"
    />
</template>

<script>
    import GhTableReport from 'fe-gh-table-report-lib';
    export default {
        name: "result_content",
        props: ['data_supervised_evaluated'],
        components: {
            GhTableReport
        },
        data(){
            return{
                header: [
                    {text: '', field: 'text', align: 'left', style: 'width: 60%;'},
                    {text: '', field: 'evaluate', align: 'right', style: 'width: 20%'},
                    {text: '', field: 'supervise', align: 'right', style: 'width: 20%'},
                ]
            }
        },
        beforeMount() {
            this.header[0].text = this.$t(this.data_supervised_evaluated.header[0]);
            this.header[1].text = this.$t(this.data_supervised_evaluated.header[1]);
            this.header[2].text = this.$t(this.data_supervised_evaluated.header[2]);
        }
    }
</script>