import ListReferential from "@/view/config/referential/list_referential.vue";

import Thesaurus from './thesaurus/route';

const route = [
    {
        path: '/config_referential',
        name: 'config_referential',
        component: ListReferential,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Thesaurus
]

export default routes;