var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('associate')))])]),_c('div',[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'radio',
                    id: 'label_associate_groupers',
                    label: _vm.$t('groupers'),
                    title: _vm.$t('groupers'),
                    text: _vm.$t('select') + ' ' + _vm.$t('groupers').toLowerCase() + ':',
                    name: _vm.props.name + '[associate][grouper][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: false,
                    style: '',
                    label_style: 'width: 149px',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"selected_input":_vm.props.selecteds.groupers,"items":_vm.getFeeds.feed_groupers_elaboration_pending}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groupers') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.groupersView))])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }