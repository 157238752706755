<template>
    <div style="padding-bottom: 8px;" v-if="getterTypeExercise.know_more !== undefined && haveData">
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('know_more')}}</div>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <GhTextarea
                    style="margin-top: -5px; width: 100%;"
                    :datalabel="{
                        text: $t('description'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'textarea_exercise_know_more_description',
                        name: 'exercise[know_more][description]',
                        value: BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.know_more !== undefined && BaseCreationExerciseTable.exercise_data.know_more.description !== undefined ? BaseCreationExerciseTable.exercise_data.know_more.description : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('@/assets/img/text/subscript.svg'),
                            superscript: require('@/assets/img/text/superscript.svg'),
                            bold: require('@/assets/img/text/text-bold.svg'),
                            italic: require('@/assets/img/text/text-italic.svg'),
                            underline: require('@/assets/img/text/text-underline.svg')
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data.know_more !== undefined && BaseCreationExerciseTable.exercise_data.know_more.description !== undefined ? BaseCreationExerciseTable.exercise_data.know_more.description : ''"></label>
            </template>
        </div>
        <template v-if="getterTypeExercise.know_more !== undefined && getterTypeExercise.know_more.theory !== undefined && getterTypeExercise.know_more.theory.view">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                    <GhSearchWindow
                        :dataBlock="{
                            label: {
                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                            },
                            ResultSearch: 'result_search_supports',
                            nameStore: 'getFeeds',
                            imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                            id: 'theory',
                            text: $t('theory') + (getterTypeExercise.know_more !== undefined  && getterTypeExercise.know_more['theory'] !== undefined && getterTypeExercise.know_more['theory'].required ? '*' : ''),
                            textlocation: $t('add') + ' ' + $t('theory'),
                            actionresult: 'loadResultSearchSupports',
                            nameResult: 'specialty[theory]',
                            supportPermision: false,
                            loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif'),
                            required: getterTypeExercise.know_more !== undefined  && getterTypeExercise.know_more['theory'] !== undefined ? getterTypeExercise.know_more['theory'].required : false
                        }"
                        :mainSelector="{
                            name: 'support_search',
                            formId: 'search',
                            actionsearch: 'getSearchSupports'
                        }"
                        :tableResolt="{
                            header: header['theory'],
                            actionsSearch: actionsTable['theory'],
                            data: []
                        }"
                        :tableSelected="{
                            headersummary: summary['theory'],
                            actionsSearch: actionsTable['theory'],
                            actionsSelected: actionsTable['theory'],
                            disabled: BaseCreationExerciseTable.exercise_data !== undefined  && BaseCreationExerciseTable.exercise_data['theory'] !== undefined ? BaseCreationExerciseTable.exercise_data['theory'] : [],
                            selected: BaseCreationExerciseTable.exercise_data !== undefined  && BaseCreationExerciseTable.exercise_data['theory'] !== undefined ? BaseCreationExerciseTable.exercise_data['theory'] : []
                        }"
                        :ref="'reftheory'"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('theory') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{BaseCreationExerciseTable.exercise_data !== undefined && BaseCreationExerciseTable.exercise_data['theory'] !== undefined ? Object.values(viewSummary['theory']).join(', ') : ''}}</label>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import GhTextarea from "fe-gh-textarea-lib";
    import {mapGetters, mapState} from "vuex";
    import GhSearchWindow from "fe-gh-float-window-lib";

    export default {
        name: 'know_more_exercise_section',
        components: {
            GhSearchWindow,
            GhTextarea
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable']),
            ...mapGetters(['getterTypeExercise'])
        },
        data(){
            return{
                haveData: false,
                actionsTable: {
                    theory: [
                        {nameaction: 'view', function: 'seeTheory', icon: require('@/assets/gh_new_dessign/view.svg')}
                    ],
                },
                header: {
                    theory: [
                        {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: 'Personas', sorting: false, style: 'min-width: 16px; width: 16px;'},
                        {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                        {text: 'title', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                        {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                        {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                        {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                        {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                        {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                        {text: '', field: 'actions', sorting: false},
                    ],
                },
                summary: {
                    theory: {
                        0: 'name'
                    },
                },
                viewSummary: {
                    theory: [],
                }
            }
        },
        beforeMount(){
            if(this.$root._route.params.view || this.$root._route.params.type === 'enabled'){
                Object.entries(this.summary).forEach((item) => {
                    if(this.BaseCreationExerciseTable.exercise_data !== undefined && this.BaseCreationExerciseTable.exercise_data[item[0]] !== undefined){
                        this.BaseCreationExerciseTable.exercise_data[item[0]].forEach((subItem) => {
                            this.viewSummary[item[0]].push(subItem[item[1][0]]);
                        });
                    }
                });
            }
            this.haveData = true;
        },
        mounted(){
            var that = this;
            window.seeTheory = function(items){
                that.Global.windowOpen(that.Global.openSecondWindow('config_theory_id_view_windowed',{id: items.id, view: true, windowed: true}),'config_theory_windowed');
            };
        }
    }
</script>