var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'generic_holidays_table',
        },"header":_vm.header,"data":_vm.ConfigGenericHolidays.records_data.length == 0 ? [] : _vm.ConfigGenericHolidays.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'generic_holidays_view', params: {id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13",attrs:{"href":itemProp[labelProp]}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'generic_holidays_id', params: { id: itemProp.id, view: false}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_conf_supp_generic_holiday',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }}})],1),_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_conf_supp_generic_holiday',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    item_prop: itemProp
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','GenericHolidays', itemProp.id,{'description': itemProp.description,'date': itemProp.event_date}, _vm.$t('delete_generic_holiday'), _vm.$t('preparing_delete_generic_holiday') + ':', 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'generic_holidays'}}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_conf_supp_generic_holiday',
                            text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,1181616852)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }