var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'company_file',
            title: _vm.$t('company_file'),
            num_results: '',
            label: ''
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c(_vm.component.CompanyFile,{tag:"component",attrs:{"id":_vm.$root._route.params.id}})]:[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]]},proxy:true}])}),_c('GhDropSelector',{attrs:{"datablock":{
            id: 'historic_company',
            title: _vm.$t('historic')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.component.CompanyHistoric,{tag:"component",attrs:{"id":_vm.$root._route.params.id}})]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_realized_action',
                text: _vm.$t('realized_action'),
            }},on:{"click":function($event){return _vm.Global.openPopUp('popup_realized_action')}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_mail',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1),(!_vm.$root._route.params.view)?[_c(_vm.component.PopupRealizedAction,{tag:"component"})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }