var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_calendar',
        title: _vm.$t('modify'),
        type: 'info',
        style: 'width: 35%; min-width: 430px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_modify_end_date"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'label_modify_end_date',
                            text: _vm.$t('end_date'),
                            style: 'text-align: right; padding-top: 2px; width: 90px; min-width: 90px; text-align: right;'
                        },"datainput":{
                            id: 'input_modify_end_date',
                            name: 'end_date',
                            required: true,
                            value: _vm.getterDataModify.end_date,
                        }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_modify_calendar',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":_vm.submitModify}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_modify_calendar',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_calendar');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }