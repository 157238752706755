import SearchList from "@/view/search/list_search";
import search_groupers from "@/view/search/search_content/view_search_groupers.vue";
import search_exercises from "@/view/search/search_content/view_search_exercise.vue";

const routes = [
    {
        path: '/search',
        name: 'searcher',
        component: SearchList,
        props: {showMenu: true}
    },
    {
        path: '/search_groupers',
        name: 'search_groupers',
        component: search_groupers,
        props: {showMenu: true}
    },
    {
        path: '/search_exercises',
        name: 'search_exercises',
        component: search_exercises,
        props: {showMenu: true}
    },
];

export default routes;