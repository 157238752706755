import ConfigExample from "@/view/base_creation/support_material/examples/template/example.vue";

const routes = [
    {
        path: '/config/creation/example',
        name: 'config_example',
        component: ConfigExample,
        props: {id: null, showMenu: true, otherTitle: 'example'},
        children: [
            {
                path: '/config/creation/example/:id',
                name: 'config_example_id',
                component: ConfigExample,
                props: {id: null, showMenu: true, otherTitle: 'example'},
            },
            {
                path: '/config/creation/example/:id/:view',
                name: 'config_example_id_view',
                component: ConfigExample,
                props: {id: null, view: true, showMenu: true, otherTitle: 'example'},
            }
        ]
    },
    {
        path: '/config/creation/example/:id/:view/:windowed',
        name: 'config_example_id_view_windowed',
        component: ConfigExample,
        props: {id: null, view: true, windowed: true, showMenu: false, background: true, otherTitle: 'example'},
    }
];

export default routes;