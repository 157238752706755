import ConfigGrouper from "@/view/config/creation/groupers/template/grouper.vue";

const routes = [
    {
        path: '/config/creation/grouper',
        name: 'config_grouper',
        component: ConfigGrouper,
        props: {id: null, showMenu: true, otherTitle: 'grouper'},
        children: [
            {
                path: '/config/creation/grouper/:id',
                name: 'config_grouper_id',
                component: ConfigGrouper,
                props: {id: null, showMenu: true, otherTitle: 'grouper'},
            },
            {
                path: '/config/creation/grouper/:id/:view',
                name: 'config_grouper_id_view',
                component: ConfigGrouper,
                props: {id: null, view: true, showMenu: true, otherTitle: 'grouper'},
            }
        ]
    }
];

export default routes;