<template>
    <div v-if="haveData">
        <input type="hidden" name="search_associate[type_search]" :value="$root._route.params.idSearch">
        <div class="gh-row-content">
            <GhInputTextField
                :datalabel="{
                    text: $t('code'),
                    style: 'margin-left: 35px; padding-right: 10px;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'search_associate_code',
                    name: 'search_associate[code]',
                    style: 'width: 20%',
                    value: '',
                    type: 'text'
                }"
            />
        </div>
        <div class="gh-row-content" v-if="CreationGrouperElements.type_search_elements === 'grouper'">
            <GhInputTextField
                :datalabel="{
                    text: $t('name'),
                    style: 'margin-left: 35px; padding-right: 10px;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'search_associate_name',
                    name: 'search_associate[name]',
                    style: 'width: 20%',
                    value: '',
                    type: 'text'
                }"
            />
        </div>
        <div class="gh-row-content" v-if="CreationGrouperElements.type_search_elements === 'grouper'">
            <GhTextarea
                :datalabel="{
                    text: $t('description'),
                    class: 'T15_b',
                    styles: 'margin-left: 35px; padding-right: 10px;'
                }"
                :datainput="{
                    id: 'search_associate_description',
                    name: 'search_associate[description]',
                    value: '',
                    required: false
                }"
            />
        </div>
        <div class="gh-row-content" v-if="CreationGrouperElements.type_search_elements === 'exercise'">
            <GhTextarea
                :datalabel="{
                    text: $t('statement'),
                    class: 'T15_b',
                    styles: 'margin-left: 35px; padding-right: 10px;'
                }"
                :datainput="{
                    id: 'search_associate_statement',
                    name: 'search_associate[statement]',
                    value: '',
                    required: false
                }"
            />
        </div>
        <div class="gh-row-content" v-if="CreationGrouperElements.type_search_elements === 'exercise'">
            <GhTreeSelectorBlock
                :dataBlock="{
                    type: 'checkbox',
                    id: 'search_associate_exercise_type',
                    label: $t('type_of_exercise'),
                    title: $t('type_of_exercise'),
                    text: $t('select_type_of_exercise') + ':',
                    name: 'search_associate[type_of_exercise][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: false,
                    style: '',
                    label_style: 'white-space: nowrap;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                }"
                :selected_input="[]"
                :items="getFeeds.feed_type"
            />
        </div>
        <component
            v-bind:is="reference_thesauros"
            :props="{
                name:'search',
                selecteds:{
                    groupers: [],
                    topics: [],
                    exercises: [],
                    thesauros: []
                },
                view:{
                    groupers: false,
                    topics: false,
                    exercises: false,
                    thesauros: false,
                }
            }"
        />
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px; padding-top: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import reference_thesauros from "@/view/base_creation/exercises/exercises/template/shared/reference_thesauros.vue";

    export default {
        name: "associate_search",
        components:{
            GhTreeSelectorBlock,
            GhInputTextField,
            GhTextarea,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds','CreationGrouperElements'])
        },
        data(){
            return {
                haveData: false,
                reference_thesauros: reference_thesauros
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedTopic', '', {root: true});
                await this.$store.dispatch('getFeedType', '', {root: true});
                await this.$store.dispatch('getSearchType', {id: this.$root._route.params.idSearch}, {root: true});
                await this.$store.dispatch('getFeedReferenceThesauros', '', {root: true});
                await this.$store.dispatch('getFeedExercises', '', {root: true});
                await this.$store.dispatch('getFeedGroupers', '', {root: true});
                await this.$store.dispatch('getFeedTopic', '', {root: true});

                this.haveData = true;
            }
        }
    }
</script>
