import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: {
            user: [],
            permisions: [],
            assignments: []
        }
    },
    mutations: {
        loadUserData(state, payload){
            state.records_data.user = payload;
        },
        loadPermissionsUserData(state, payload){
            state.records_data.permisions = payload;
        },
        loadAssignmentsUserData(state, payload){
            state.records_data.assignments = payload;
        }
    },
    actions: {
        async getUserData(state){
            let request = API_URL + 'user';

            await axios({
                method: 'get',
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit('loadUserData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserPermissionsData(state){
            let request = API_URL + 'user/permissions';

            await axios({
                method: 'get',
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit('loadPermissionsUserData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserAssignmentsData(state){
            let request = API_URL + 'user/assignments';

            await axios({
                method: 'get',
                url: request,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit('loadAssignmentsUserData', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};