<template>
    <div>
        <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('associate')}}</div>
        </div>

        <div>
            <template v-if="!$root._route.params.view && $root._route.params.type !== 'enabled' && $root._route.params.type !== 'enabled'">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'label_associate_groupers',
                        label: $t('groupers'),
                        title: $t('groupers'),
                        text: $t('select') + ' ' + $t('groupers').toLowerCase() + ':',
                        name: props.name + '[associate][grouper][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: false,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="props.selecteds.groupers"
                    :items="getFeeds.feed_groupers_elaboration_pending"
                />
            </template>
            <template v-else>
                <div style="display: flex;">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('groupers') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{groupersView}}</label>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import {mapState} from "vuex";

    export default {
        name: "shared_associate",
        props:['props'],
        components: {
            GhTreeSelectorBlock
        },
        computed: {
            ...mapState(['getFeeds']),
        },
        data(){
            return{
                groupersView: '',
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedGroupersElaborationPending', '', {root: true});

                if (this.props !== undefined && this.props.selecteds !== undefined && this.props.selecteds['groupers'] !== undefined && this.props.selecteds['groupers'].length !== 0) {
                    this.props.selecteds['groupers'].forEach((groupers) => {
                        this.groupersView += groupers.text + ', ';
                    });

                    if (this.groupersView !== '') this.groupersView = this.groupersView.slice(0, -2);
                }
            }
        }
    }
</script>
