var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
                id: 'elaboration'
            },"header":_vm.header,"data":_vm.CreationGrouper.elaboration_data !== undefined && _vm.CreationGrouper.elaboration_data.length !== 0 ? _vm.CreationGrouper.elaboration_data : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'view_grouper_id_view', params: {grouper: _vm.id_grouper, id: itemProp.id, view: true, type: 'elaboration'}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'view_grouper_id', params: {grouper: _vm.id_grouper, id: itemProp.id, type: 'elaboration'}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_config_grouper',
                            text: _vm.$t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }}})],1),_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_config_grouper',
                        text: _vm.$t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','CreationGrouper', itemProp.id,{'name': itemProp.name, 'description': itemProp.description, 'responsible': itemProp.responsible, 'topic': itemProp.topic, 'impartation': itemProp.impartation}, _vm.$t('delete_grouper'), _vm.$t('preparing_delete_grouper'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_add_config_grouper',
                                text: _vm.$t('add')
                            }},on:{"click":function($event){return _vm.newGrouper(_vm.id_grouper, 'elaboration')}}})],1)]},proxy:true}],null,false,808464590)}):_vm._e()],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }