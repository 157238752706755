var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTreeContentBlock',{staticStyle:{"margin-bottom":"15px","margin-left":"15px","margin-right":"15px"},attrs:{"dataBlock":{
            id: 'enabled',
            actionWithoutChildren: true,
            actionWithChildren: true,
            extraLabelNumber: false,
            extraLabelText: '',
            extraTableClass: 'class-table-exercise'
        },"data":_vm.BaseCreationExerciseTable.records_data_enabled.length === 0 ? [] : _vm.BaseCreationExerciseTable.records_data_enabled,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'enabled'}}}},[_c('a',{staticClass:"table-cell_link T13"},[_c('b',[_vm._v(_vm._s(itemProp[labelProp]))])])])]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_config_exercises',
                    text: _vm.$t('versioning'),
                    icon: require('../../../../assets/gh_new_dessign/return-blue.svg')
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','ExerciseVersion', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'topic': itemProp.topic}, _vm.$t('versioning_exercise'), _vm.$t('versioning_delete_exercise'), 'delete')}}}),_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_config_exercises',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg')
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','ExerciseToHistoric', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'topic': itemProp.topic}, _vm.$t('delete_exercise'), _vm.$t('preparing_delete_exercise'), 'delete')}}})]}}],null,false,1908567927)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }