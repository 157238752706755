var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
        id: 'preferential_evaluators',
    },"header":_vm.header,"data":_vm.getterPreferentialEvaluators},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'assign_preferential_evaluators_' + itemProp.id,
                text: _vm.$t('assign'),
                icon: require('../../../../assets/gh_new_dessign/tick.svg')
            }},on:{"click":function($event){return _vm.assignAction(itemProp.id)}}})]}}],null,false,1245586674)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }