import Vue from "vue";
import vuex from "vuex";
import router from "../../../routes/router"
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        tabs_quizz: [],
        chart_quizz: [],
        data_quizz: [],
        actual_page: [],
        id_start: [],
        support: [],
        finished: false,
        text_main: '',
        array_quizz: [],
    },
    mutations:{
        loadQuizzTabs(state, payload){
            state.tabs_quizz = payload;
        },
        loadQuizzChart(state, payload){
            state.chart_quizz = payload;
        },
        loadQuizzData(state, payload){
            state.data_quizz = payload;
        },
        loadActualPage(state, payload){
            state.actual_page = payload;
        },
        loadSetStart(state, payload){
            state.id_start = payload;
        },
        loadQuizzSupport(state, payload){
            state.support = payload;
        },
        LOAD_ARRAY_QUIZZ(state,payload){
            state.array_quizz = payload;
        },
        LOAD_IF_FINISH(state,payload){
            state.finished = payload;
        },
        LOAD_TEXT_MAIN(state,payload){
            state.text_main = payload;
        }
    },
    actions:{
        async getInitialQuizzes(state,item){
            await state.commit("loadSetStart", item);
            await router.push({ name: 'quizz_detail'});
        },
        async getQuizzIsFinished(state){
            await axios.get(
                API_URL + "itinerary/initial/state"
            ).then(
                async response => {
                    await state.commit("LOAD_IF_FINISH", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStartQuizz(state){
            let requestUrl =  API_URL + 'itinerary/initial/quizz';
            await axios({
                method: "get",
                url: requestUrl,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if (response.status === 200) {
                        var actual_page = 0;

                        if(response.data.data.tabs !== undefined) {
                            response.data.data.tabs.forEach((i, k) => {
                                response.data.data.tabs[k]['uuid'] = i.id;
                                response.data.data.tabs[k]['id'] = k + 1;
                            });
                        }

                        await state.commit("loadQuizzTabs", response.data.data.tabs);
                        await state.commit("loadQuizzChart", response.data.data.chart);

                        router.options.GhSubTabsQuestionary = response.data.data.tabs;

                        for(var x=0;x < router.options.GhSubTabsQuestionary.length;x++){
                            if(router.options.GhSubTabsQuestionary[x].uuid === state.state.id_start){
                                actual_page = x;
                            }
                        }

                        await state.commit("loadQuizzData", response.data.data.data);
                        await state.commit("loadQuizzSupport", response.data.data.support);
                        await state.commit("loadActualPage", actual_page);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSpecificQuizz(state,item){
            var tab_data = '';
            let enter = false;
            for(var i=0;i < router.options.GhSubTabsQuestionary.length;i++){
                if(router.options.GhSubTabsQuestionary[i].value === item.value){
                    tab_data = router.options.GhSubTabsQuestionary[i];
                    enter = true;
                }
            }

            if(!enter){
                for(var x=0;x < router.options.GhSubTabsQuestionary.length;x++){
                    if(router.options.GhSubTabsQuestionary[x].uuid === state.state.id_start){
                        tab_data = router.options.GhSubTabsQuestionary[x];
                        enter = true;
                    }
                }
            }


            let selected = '';
            state.state.data_quizz.questionary.forEach((i, k) => {
                if(i.id === tab_data.uuid){
                    selected = k;
                }
            });

            await state.commit("loadActualPage", selected);
        },
        async setQuizzData(state, item){
            let requestLogin = API_URL + (state.state.finished.finish ? 'itinerary/initial/quizz' : 'itinerary/initial/quizz/' + item.id);
            let formData = new FormData();

            formData.append('quizz[finish]', item.finish);
            formData.append('quizz[activity_id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(state.state.finished.finish){
                            await router.push({name: 'result_quizz'});
                        }else {
                            await state.dispatch('getQuizzIsFinished', '', {root: true});
                            state.state.data_quizz.questionary.forEach((i, k) => {
                                if(i.id === item.id){
                                    state.state.data_quizz.questionary[k] = response.data.data.data;
                                    state.commit("loadQuizzChart", response.data.data.chart);
                                }
                            });

                            return 'OK';
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMainQuizz(state){
            await axios.get(
                API_URL + "itinerary/initial"
            ).then(
                async response => {
                    await state.commit('LOAD_ARRAY_QUIZZ', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getMainText(state){
            let requestLogin = API_URL + "text_main"

            await axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_TEXT_MAIN", response.data.data.text);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async cleanMainItinerary(state){
            state.commit('loadQuizzTabs', []);
            state.commit('loadQuizzChart', []);
            state.commit('loadQuizzData', []);
            state.commit('loadActualPage', []);
            state.commit('loadSetStart', []);
            state.commit('loadQuizzSupport', []);
            state.commit('LOAD_ARRAY_QUIZZ', []);
            state.commit('LOAD_IF_FINISH', false);
            state.commit('LOAD_TEXT_MAIN', '');
        }
    }
}
