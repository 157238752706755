var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('type') + ': ' + (_vm.$root._route.params.view ? Object.values(_vm.ConfigTypeExercise.data.type[0])[1] : _vm.$parent.$parent.$refs['RefTypeExercise'].datasaved.text))+" ")])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefPossibleAnswers",attrs:{"datalabel":{
                    text: _vm.$t('possible_answers') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_possible_answers',
                    name: 'type_exercise[possible_answers]',
                    style: 'width: 50px',
                    value: _vm.getterPossibleAnswers.actual_possible_answer,
                    type: 'int',
                    limitNumMin: 1
                }},on:{"focusout":_vm.checkNumberQuestions}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('possible_answers') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPossibleAnswers.actual_possible_answer))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCorrectAnswers",attrs:{"datalabel":{
                    text: _vm.$t('correct_answers') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_correct_answers',
                    name: 'type_exercise[correct_answers]',
                    style: 'width: 50px',
                    value: _vm.correct_answers,
                    type: 'int',
                    limitNumMin: 1,
                    limitNumMax: _vm.getterPossibleAnswers.actual_possible_answer
                }}}),_c('div',[_c('GhChecks',{ref:"GhCheckConfigEvidence",staticStyle:{"margin-left":"10px"},attrs:{"datalabel":{text: _vm.$t('one_of'), style: 'width: 130px', id: 'label_type_exercise_one_of'},"datainput":{
                        required: false,
                        selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.one_of ? _vm.ConfigTypeExercise.data.config_type.one_of : false,
                        disabled: _vm.$root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_one_of',
                        value: 1,
                        name: 'type_exercise[one_of]'
                    }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('correct_answers') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.correct_answers))])]],2),(_vm.getterPossibleAnswers.possible_answer.length !== 0)?_c('div',{staticStyle:{"padding-bottom":"10px","margin-top":"5px","padding-left":"160px"}},[_c('GhTableCommon',{attrs:{"extratable":{
                id: 'possible_answers'
            },"header":_vm.header,"data":_vm.getterPossibleAnswers.possible_answer},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp, labelProp}){return [(labelProp.field === 'answer')?_c('div',[_c('GhInputTextField',{ref:'RefPossibleAnswer' + itemProp.id,attrs:{"datalabel":{},"datainput":{
                            required: false,
                            id: 'type_exercise_possible_answers_' + itemProp.id,
                            name: 'type_exercise[answers]['+itemProp.id+']',
                            value: itemProp.answer,
                        }},on:{"focusout":function($event){return _vm.setPossibleAnswer(itemProp.id)}}})],1):_vm._e()]}},{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_possible_answer_' + itemProp['id'],
                        text: _vm.$t('delete'),
                        icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                        item_prop: itemProp
                    }},on:{"click":function($event){return _vm.deletePossibleAnswer(itemProp)}}})]}}],null,false,3640189382)})],1):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_type_exercise_conditions',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_conditions') + ':',
                    text: _vm.$t('conditions'),
                    label: _vm.$t('conditions'),
                    type: 'checkbox',
                    columns: 2,
                    style: '',
                    required: false
                },"datainput":{
                    id: 'value_type_exercise_conditions',
                    name: 'type_exercise[conditions][]'
                },"selected_input":_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.conditions ? _vm.ConfigTypeExercise.data.config_type.conditions : [],"feed":_vm.getFeeds.feed_condition}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('conditions') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.conditions ? Object.values(_vm.ConfigTypeExercise.data.config_type.conditions).join(', ') : ''))])])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px","margin-top":"-10px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[0] === 1,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[1] === 2,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.feedback[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion',
                value: 1,
                name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }