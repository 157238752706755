import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: ''
    },
    mutations: {
        loadLanguages(state, payload) {
            state.records_data = payload;
        },
        loadLanguagesData(state, payload) {
            state.data = payload;
        }
    },
    actions: {
        async getEvaluatorLanguagesList(state){
            await axios.get(
                API_URL + "evaluator/languages"
            ).then(
                response => {
                    state.commit('loadLanguages', response.data.data);
                }
            )
        },
        async getEvaluatorLanguagesData(state, items){
            if(items.id === undefined){
                state.commit("loadLanguagesData", '');
            }else {
                let requestLogin = API_URL + "evaluator/languages/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadLanguagesData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setEvaluatorLanguagesData(state, item){
            let requestLogin = item.id ? API_URL + "evaluator/languages/" + item.id : API_URL + "evaluator/languages";
            let formData = new FormData();

            formData.append('language[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadLanguagesData", response.data);
                    router.push({name: 'configuration'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeEvaluatorLanguages(state, item){
            let requestLogin = API_URL + "evaluator/languages/" + item.id ;
            let formData = new FormData();

            formData.append('language[id]', item.id);
            formData.append('language[type]', item.type);

            await axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        await this.dispatch('getEvaluatorLanguagesList');
                        await this.dispatch('getConfigEvaluatorsCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
