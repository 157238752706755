var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhDropSelector',{attrs:{"datablock":{
            id: 'admin_companies',
            title: _vm.$t('companies_2'),
            second_title: _vm.$t('results'),
            num_results: _vm.ListCompanies.list_companies.length,
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'companies',
                },"header":_vm.header,"data":_vm.ListCompanies.list_companies},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_company_'+itemProp['id'],
                            text: _vm.$t('modify'),
                            icon: require('../../assets/gh_new_dessign/edit.svg'),
                        }},on:{"click":function($event){return _vm.edit_tabs_company(itemProp);}}})]}}],null,false,239675726)})]},proxy:true}],null,false,3068117012)}),_c('GhPopup',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'edit_tabs_companies',
            title: _vm.$t('assign_tabs'),
            type: 'add',
            style: 'width: 35%; min-width:400px;',
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 30vh; min-height: 4vh; overflow-y: auto;'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_edit_tabs_company"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{key:_vm.refreshSelector,attrs:{"datablock":{
                                id: 'tabs_company',
                                class: 'T15_b',
                                subtitle: _vm.$t('selection_tabs') + ':',
                                text: _vm.$t('tabs')+'*',
                                label: _vm.$t('tabs'),
                                type: 'checkbox',
                                columns: 2,
                                required: true,
                                style: 'margin-left:84px;',
                                popupStyle: 'min-width: 35%;max-width: 35%;',
                            },"datainput":{
                                id: 'value_tabs_company',
                                name: 'tabs[]'
                            },"feed":_vm.getFeeds.feed_tabs,"selected_input":_vm.ListCompanies.selected_companies}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_system_popup',
                    text: _vm.$t('save'),
                    class: '',
                    style: ''
                }},on:{"click":function($event){return _vm.save_edit_tabs();}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_system_popup',
                    text: _vm.$t('close'),
                }},on:{"click":function($event){return _vm.Global.closePopUp('edit_tabs_companies');}}})]},proxy:true}],null,false,3076036932)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }