<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'billing',
            }"
            :header="header"
            :data="TabBilling.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <a class="table-cell_link T13" @click="openBilling(itemProp.id)"> {{itemProp[labelProp]}} </a>
            </template>
            <template v-slot:actions="{itemProp}">
                <GhAction
                    :dataction="{
                        id: 'edit_company_'+itemProp['id'],
                        text: $t('paid'),
                    }"
                    @click="openBilling(itemProp.id)"
                />
                <GhAction
                    :dataction="{
                        id: 'delete_config_bibliography',
                        text: $t('delete'),
                        icon: require('../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','Billing', itemProp.id,{'company': itemProp.business, 'reference': itemProp.code}, $t('delete_pending_payment'), $t('preparing_delete_pending_payment'), 'delete')"
                />
            </template>
        </GhTableCommon>

        <component v-bind:is="billing_popup"/>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import billing_popup from "@/view/billing/billing/popup/billing";

    export default {
        name: "list_billing",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['TabBilling']),
        },
        data(){
            return {
                header: [
                    {text: 'reference', field: 'code', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 10%;'},
                    {text: 'company', field: 'business', urlRedirect: true, sorting: true, typeSort: 'string', style: 'min-width: 150px; width: 79%;'},
                    {text: 'amount', field: 'price', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 5%;'},
                    {text: 'pay_day', field: 'date', sorting: true, typeSort: 'string', style: 'min-width: 80px; width: 6%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false,
                billing_popup: billing_popup
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getBilling', '', {root: true});
                this.$parent.$parent._data.data[0].result = this.TabBilling.records_data.length;
                this.haveData = true;
            }
        },
        methods: {
            async openBilling(id){
                await this.$store.dispatch('getBillingData', {id: id}, {root: true});
                this.Global.openPopUp('billing_popup');
            }
        }
    }
</script>