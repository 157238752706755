<template>
    <div v-if="getterTypeExercise.subquestion !== undefined && getterTypeExercise.subquestion.view && haveData">
        <div class="section_line" style="margin-top: 5px; margin-bottom: 10px;">
            <div class="T15_b">{{$t('subquestions')}}</div>
        </div>
        <GhTableCommon :key="'KEY63' + refreshQuestions"
            style="margin-bottom: 10px;"
            :extratable="{
                id: 'subquestions'
            }"
            :header="$root._route.params.parent_grouper_id !== undefined && $root._route.params.parent_id !== undefined ? viewHeader : header"
            :data="getterSubquestions"
        >
            <template v-slot:actions="{itemProp}">
                <template v-if="$root._route.params.type === undefined || $root._route.params.type === 'elaboration'">
                    <GhAction v-if="itemProp.enabled"
                        :dataction="{
                            id: 'edit_config_exercises',
                            text: $t('versioning'),
                            icon: require('../../../../../../assets/gh_new_dessign/return-blue.svg')
                        }"
                        @click="Global.deleteConfirm('delete_confirm','ParentExerciseVersion', itemProp.id,{'code': itemProp.code, 'statement': itemProp.statement, 'type_of_exercise': itemProp.type_of_exercise}, $t('versioning_exercise'), $t('versioning_delete_exercise'), 'delete')"
                    />
                    <GhAction v-else-if="!itemProp.enabled"
                        :dataction="{
                            id: 'edit_config_exercises',
                            text: $t('modify'),
                            icon: require('../../../../../../assets/gh_new_dessign/edit.svg')
                        }"
                        @click="modifyExercise(itemProp)"
                    />
                </template>
            </template>
            <template v-slot:footer v-if="!$root._route.params.view && $root._route.params.type !== 'enabled'">
                <template v-if="$root._route.params.type === undefined || $root._route.params.type === 'elaboration'">
                    <div class="centered">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_exercises',
                                text: $t('add')
                            }"
                            @click="searchExercises(getterSubquestions)"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_add_new_exercises',
                                text: $t('add_new')
                            }"
                            @click="addNewExercise()"
                        />
                    </div>
                </template>
            </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'subquestion_section',
        components: {
            GhAction,
            GhTableCommon,
            GhButton
        },
        computed: {
            ...mapState(['BaseCreationExerciseTable']),
            ...mapGetters(['getterTypeExercise', 'getterSubquestions'])
        },
        data(){
            return {
                haveData: false,
                refreshQuestions: 0,
                viewHeader: [
                    {text: 'code', field: 'code', sorting: true, keyFormat: 'style', typeSort: 'string', style: 'min-width: 130px; max-width: 130px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 70%;'},
                    {text: 'type_of_exercise', field: 'type_of_exercise', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 30%;'},
                ],
                header: [
                    {text: 'code', field: 'code', sorting: true, keyFormat: 'style', typeSort: 'string', style: 'min-width: 130px; max-width: 130px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 70%;'},
                    {text: 'type_of_exercise', field: 'type_of_exercise', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 30%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                searchHeader: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, keyFormat: 'style', typeSort: 'code', style: 'min-width: 130px; max-width: 130px; width: 130px;'},
                    {text: 'statement', field: 'statement', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 70%;'},
                    {text: 'type_of_exercise', field: 'type_of_exercise', sorting: true, typeSort: 'string', style: 'min-width: 200px; max-width: 200px; width: 30%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                actionsTable: [
                    {nameaction: 'view', function: 'seeExercise', icon: require('@/assets/gh_new_dessign/view.svg')}
                ],
                timer: []
            }
        },
        watch: {
            'getterTypeExercise'() {
                this.haveData = true;
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.BaseCreationExerciseTable.exercise_data.subquestion !== undefined) {
                    await this.$store.dispatch('setTemporalSubquestionExercises', {data: this.BaseCreationExerciseTable.exercise_data.subquestion}, {root: true});
                    this.haveData = true;
                }
            }
        },
        mounted(){
            var that = this;

            window.seeExercise = function(items){
                that.Global.windowOpen(
                    that.Global.openSecondWindow(
                        'config_exercise_view',
                        {
                            id: items.id,
                            view: true,
                            type: items.type,
                            from: 'parent-exercise'
                        }
                    ),'config_example_windowed');
            };
        },
        methods: {
            searchExercises(exercises){
                var then = this;
                var params = {
                    idblock: 'search_exercises',
                    actionsSearch: then.actionsTable,
                    actionsSelected: then.actionsTable,
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    dataDisabled : [],
                    allmarkoption: false,
                    supportPermision: true,
                    textuserselected: this.$t('selected_exercises'),
                    img: require('../../../../../../assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('no_filter_has_been_applied'),
                    textNoResult: this.$t('no_results_found'),
                    nameSlot: 'exercises_search',
                    nameStore: 'getFeeds',
                    ResultSearch: 'result_search_exercises',
                    dataSelected: exercises,
                    actionresult: 'loadResultSearchExercises',
                    textlocation: this.$t('add_exercises'),
                    headersearch: JSON.stringify(this.searchHeader),
                    headerselected: JSON.stringify(this.searchHeader),
                    formId: 'search',
                    actionsearch: 'getSearchExercises',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                }

                localStorage.setItem('Params_' + 'search_exercises', JSON.stringify(params));

                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchView',{ idblock: params.idblock }),'exercises_target', true);

                window.UpdatedSelect = function (e) {
                    then.$store.dispatch('setTemporalSubquestionExercises', {data: e}, {root: true});
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {
                    then.Global.state.window_open = false;
                }
                window.events = function () {}
            },
            modifyExercise(prop){
                var then = this;
                console.log(prop);
                this.Global.windowOpen(
                    this.Global.openSecondWindow(
                        'config_exercise_modify',
                        {
                            id: prop.id,
                            type: prop.type,
                            from: 'parent-exercise'
                        }
                    ),
                    'edit_exercise' + Math.random(),
                    true
                );

                window.UpdatedSelect = async function(e){
                    await then.$store.dispatch('getExerciseById', {id: e.id}, {root: true});
                }

                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            addNewExercise(){
                var then = this;
                this.Global.windowOpen(
                    this.Global.openSecondWindow(
                        'config_exercise_children',
                        {
                            children: 'children',
                        }
                    ),
                    'new_exercise' + Math.random(),
                    true
                );

                window.UpdatedSelect = async function(e){
                    await then.$store.dispatch('getExerciseById', {id: e.id}, {root: true});
                }

                this.timer = setInterval(this.checkChildWindow, 1000);
            },
            checkChildWindow() {
                if (this.Global.state.window.closed) {
                    this.Global.state.window_open = false;
                    clearInterval(this.timer);
                }
            }
        }
    }
</script>

<style>
    #list_subquestions {
        min-width: 990px !important;
    }
</style>