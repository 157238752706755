var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [] ? 'margin-top: 12px;' : '')},[(this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [])?_c('GhTableCommon',{attrs:{"extratable":{
            id:'result_search_data'
        },"data":this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [] && this.$store.state.TabSearch.searchData.length !== undefined ? this.$store.state.TabSearch.searchData : [],"header":this.$store.state.TabSearch.idSubTabActive === 1 ? _vm.header_exercise : _vm.header_grouper},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'see_'+itemProp['id'],
                        text: _vm.$t('see'),
                        icon: require('@/assets/gh_new_dessign/view.svg'),
                        item_prop: itemProp
                    }},on:{"click":function($event){_vm.TabSearch.idSubTabActive === 1 ? _vm.Global.windowOpen(_vm.Global.openSecondWindow('config_exercise_id_view_search',{id: itemProp.id, view: true}),'view_search') : _vm.Global.windowOpen(_vm.Global.openSecondWindow('view_grouper_id_view_search',{grouper: _vm.TabSearch.idSubTabActive, id: itemProp.id, view: true}),'view_search')}}})]}}],null,false,2381346274)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }