<template>
    <div>
        <div
            :id="`${dataBlock.id}-${data.id}`"
            class="gh_data_selector-operator tree_row gh_colspan-15 link"
            style="padding-left:5px; padding-top: 3px; padding-bottom: 3px; display: flex;"
        >
            <div class="label_level T14"
                 :style="nodeMargin"
                 style="white-space: nowrap;"
            >
                {{data.name}}
            </div>
            <div
                :class="toggleChildrenClass"
                style="white-space: nowrap;margin-left: 10px;"
                @click="data.data ? ShowOrHiddenTable() : ShowOrHidden()"
            >
                {{ChildrenMoreOrLess}}
            </div>
            <div v-if="data.data && (dataBlock.extraLabelNumber || dataBlock.extraLabelText)" class="T14 gh_hidden_list-extra_label label_span-8" style="color:black !important; font-size: 13px !important;margin-top: 1px;">
                {{data.data.length}} {{$t(dataBlock.extraLabelText)}}
            </div>
        </div>
        <div
            :id="`children-data-${dataBlock.id}-${data.id}`"
            v-if="data.data"
            v-show="showChildren"
            v-bind:state="showChildren"
            :class="dataBlock.extraTableClass"
        >
            <GhTableCommon
                :extratable="{
                    id: 'table_'+dataBlock.id + data.id,
                }"
                :header="header"
                :data="data.data"
            >
                <template v-slot:field_redirect="{itemProp, labelProp}" >
                    <div>
                        <slot name="field_redirect" v-bind:itemProp="itemProp" v-bind:labelProp="labelProp"></slot>
                    </div>
                </template>
                <template v-slot:actions="{itemProp}" >
                    <div>
                        <slot name="actions"
                              v-bind:itemProp="{itemProp}"
                        >
                        </slot>
                    </div>
                </template>
            </GhTableCommon>
        </div>
        <div
            :id="`children-data-${dataBlock.id}-${data.id}`"
            v-if="hasChildren"
            v-show="showChildren"
            v-bind:state="showChildren"
        >
            <div v-for="(child,indexChildren) in data.children" :key="child.id">
                <GhTreeContent
                    :dataBlock="{
                        id: dataBlock.id,
                        actionWithoutChildren: dataBlock.actionWithoutChildren,
                        actionWithChildren: dataBlock.actionWithChildren,
                        levelJustify: dataBlock.levelJustify + 20,
                        interspersed: dataBlock.interspersed ? indexChildren%2 : !indexChildren%2,
                        extraLabelNumber: dataBlock.extraLabelNumber,
                        extraLabelText: dataBlock.extraLabelText,
                        extraTableClass: dataBlock.extraTableClass
                    }"
                    :data="child"
                    :header="header"
                    :class="dataBlock.interspersed ? (indexChildren%2 ? 'striped_tree' : 'no_striped_tree') : (indexChildren%2 ? 'no_striped_tree' : 'striped_tree')"
                >
                    <template v-slot:field_redirect="{itemProp, labelProp}" >
                        <div>
                            <slot name="field_redirect" v-bind:itemProp="itemProp" v-bind:labelProp="labelProp">
                            </slot>
                        </div>
                    </template>
                    <template v-slot:actions="{itemProp}" >
                        <div>
                            <slot name="actions" v-bind:itemProp="itemProp">
                            </slot>
                        </div>
                    </template>
                </GhTreeContent>
            </div>
        </div>
    </div>
</template>
<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    export default {
        name: "GhTreeContent",
        props: ['data','header','dataBlock'],
        data() {
            return {
                showChildren: false,
                storageOpen: false,
            }
        },
        components:{
            GhTableCommon,
        },
        mounted() {
            this.storageOpen = localStorage[`${this.dataBlock.id}-${this.data.id}`] !== undefined ? localStorage[`${this.dataBlock.id}-${this.data.id}`] : this.storageOpen;

            if(this.storageOpen == true || this.storageOpen == 'true'){
                this.ShowOrHidden();
                this.storageOpen = this.storageOpen == false || this.storageOpen == 'false' ? true : false;
                localStorage[`${this.dataBlock.id}-${this.data.id}`] = this.storageOpen;
            }
        },
        computed:{
            ChildrenMoreOrLess(){
                return this.showChildren ? '[ - ]' : '[ + ]';
            },
            toggleChildrenClass() {
                return this.showChildren ? 'gh_data_selector-less T19' : 'gh_data_selector-more T19';
            },
            hasChildren() {
                const { children } = this.data;
                return children && children.length > 0;
            },
            showhidden(){
                return this.showChildren ? 'display:flex;':'display:none;';
            },
            nodeMargin(){
                return {
                    'margin-left': `${this.dataBlock.levelJustify}px`
                }
            },
        },
        methods:{
            updateStyleChildren(block){
                var no_striped_tree = 'no_striped_tree';
                var striped_tree = 'striped_tree';
                for(var i=0;i<block.$children.length;i++){
                    var checkClass = block.$children[i].$el.className;
                    if(block.$children[i].$el.tagName != 'TABLE'){
                        block.$children[i].$el.className = checkClass == no_striped_tree ? striped_tree : no_striped_tree;
                        if(block.$children[i].$children.length > 0){
                            this.updateStyleChildren(block.$children[i]);
                        }
                    }
                }
            },
            updateStyle(block){
                var no_striped_tree = 'no_striped_tree';
                var striped_tree = 'striped_tree';
                var canChange = false;
                for(var i=0;i<block.$parent.$children.length;i++){
                    var checkId = block.$parent.$children[i].$el.children[0].id
                    if(checkId != `${block.dataBlock.id}-${block.data.id}`){
                        var checkClass = block.$parent.$children[i].$el.className;
                        if(canChange){
                            block.$parent.$children[i].$el.className = checkClass == no_striped_tree ? striped_tree : no_striped_tree;

                            var length = 0;
                            for(var t=0;t<block.$parent.$children[i].$children.length;t++){
                                if(block.$parent.$children[i].$children[t].$el.tagName != 'TABLE'){
                                    ++length;
                                }
                            }

                            if(length > 0){
                                this.updateStyleChildren(block.$parent.$children[i]);
                            }
                        }
                    }else{
                        canChange = true;
                    }
                }
                if(!block.$parent.treeContentBlock){
                    this.updateStyle(block.$parent);
                }
            },
            closeChildren(block){
                for(var i=0;i<block.$children.length;i++){
                    if(block.$children[i].$children.length > 0){
                        if(block.$children[i].showChildren){
                            block.$children[i].ShowOrHidden();
                            this.closeChildren(block.$children[i]);
                        }
                    }
                }
            },
            ShowOrHidden(){
                this.showChildren = !this.showChildren
                this.storageOpen = this.storageOpen == false || this.storageOpen == 'false' ? true : false;
                localStorage[`${this.dataBlock.id}-${this.data.id}`] = this.storageOpen;

                var length = 0;
                for(var i=0;i<this.$children.length;i++){
                    if(this.$children[i].$el.tagName != 'TABLE'){
                        ++length;
                    }
                }

                if(length%2){
                    this.updateStyle(this);
                }

                if(!this.showChildren){
                    this.closeChildren(this);
                }
            },
            ShowOrHiddenTable(){
                this.showChildren = !this.showChildren
                this.storageOpen = this.storageOpen == false || this.storageOpen == 'false' ? true : false;
                localStorage[`${this.dataBlock.id}-${this.data.id}`] = this.storageOpen;
            }
        },
    }
</script>
