import ConfigSchedule from "@/view/config/support/schedule/template/schedule";

const routes = [
    {
        path: '/config/support/schedule',
        name: 'schedule',
        component: ConfigSchedule,
        props: {id: null, showMenu: true, otherTitle: 'schedule'},
        children: [
            {
                path: '/config/support/schedule/:id',
                name: 'schedule_id',
                component: ConfigSchedule,
                props: {id: null, showMenu: true,  otherTitle: 'schedule'},
            },
            {
                path: '/config/support/schedule/:id/:view/',
                name: 'schedule_view',
                component: ConfigSchedule,
                props: {id: null, showMenu: true,  otherTitle: 'schedule'},
            },
        ]
    }
];
export default routes;
