var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_config_support_flow', title: _vm.$t('flow')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_support_flow"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"GhFlowName",attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style:'width: 150px; text-align: right; min-width: 150px !important;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'workflow_name',
                                        name: 'workflow[name]',
                                        style: 'width: 100%',
                                        value: _vm.flowName,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.flowName))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"GhFlowDescription",attrs:{"datalabel":{
                                        text: _vm.$t('description') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'workflow_description',
                                        name: 'workflow[description]',
                                        required: true,
                                        value: _vm.flowDescription
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.flowDescription))])]],2),_vm._l((_vm.getWorkflowTasksGroups),function(task,indexTask){return _c('div',{key:'P' + indexTask},[(task.name !== undefined)?[_c('div',{class:indexTask !== 0 ? ((indexTask % 2 + (_vm.getWorkflowTasksGroups[indexTask - 1] !== undefined && _vm.getWorkflowTasksGroups[indexTask - 1].children !== undefined ? _vm.getWorkflowTasksGroups[indexTask - 1].children.length % 2 : 0)) % 2 ? 'striped_tree' : 'no_striped_tree') : (indexTask % 2 ? 'striped_tree' : 'no_striped_tree'),staticStyle:{"padding-left":"5px","padding-top":"3px","padding-bottom":"3px","display":"flex"}},[_c('div',{staticStyle:{"width":"80%","display":"flex"}},[_c('div',{staticClass:"T14"},[_vm._v(_vm._s(task.name))]),_c('div',{staticClass:"gh_data_selector-more T19",staticStyle:{"white-space":"nowrap","margin-left":"10px","cursor":"pointer"},on:{"click":function($event){_vm.actualIndexTask = indexTask; _vm.checkFeedGroupsByTask();}}},[_vm._v("[ + ]")])])]),(task.groups !== undefined)?_vm._l((task.groups),function(group,indexGroup){return _c('div',{key:'C' + indexGroup},[_c('div',{class:indexTask % 2 ? (indexGroup % 2 ? 'striped_tree' : 'no_striped_tree') : (indexGroup % 2 ? 'no_striped_tree' : 'striped_tree'),staticStyle:{"padding-left":"25px","padding-top":"3px","padding-bottom":"3px","display":"flex"}},[_c('div',{staticStyle:{"width":"80%","display":"flex"}},[_c('div',{staticClass:"T14"},[_vm._v(_vm._s(group.name))])])])])}):_vm._e()]:_vm._e()],2)}),(!_vm.$root._route.params.view)?_c('div',{staticClass:"centered",staticStyle:{"padding-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_tasks',
                                    text: _vm.$t('tasks')
                                }},on:{"click":_vm.resetTasks}})],1):_vm._e()],2)]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setFlow()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('back')
                }},on:{"click":function($event){return _vm.router.go(-1)}}})],1),_c('GhDataSelector',{key:_vm.refreshGroups,ref:"selectorDataAssignmentGroup",attrs:{"datablock":{
                id: 'workflow_assignment_group',
                class: 'T15_b',
                subtitle: _vm.$t('select_assignment_group') + ':',
                text: _vm.$t('assignment_group'),
                label: _vm.$t('assignment_group'),
                type: 'radio',
                columns: 3,
                style: 'margin-left: 93px; display: none;',
                required: false,
            },"datainput":{
                id: 'workflow_assignment_group',
                name: 'workflow[workflow_assignment_group][]'
            },"selected_input":_vm.selectedGroups,"feed":_vm.getFeeds.feed_support_asigned},on:{"accept":function($event){return _vm.addGroups()}}}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                id: 'task_workflow_popup',
                title: _vm.$t('tasks'),
                type: 'add',
                style: 'width: 35% !important; min-width:450px;'
            },"bodyConfiguration":{
                id: '',
                class: 'subtitle_extra_popup',
                style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;',
                text: _vm.$t('select_task') + ':'
            },"footerConfiguration":{
                id: '',
                class: '',
                style: '',
                content: ''
            }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_popup_tasks"}},[_c('div',{key:_vm.refreshTasks,staticStyle:{"padding-left":"35px","overflow":"auto","max-height":"250px"}},[_c('div',{staticClass:"gh-content-global"},_vm._l((_vm.getFeeds.feed_support_workflow_tasks),function(item,key){return _c('div',{key:key,staticClass:"gh-row-content",staticStyle:{"margin-right":"5px"}},[_c('div',[_c('div',{staticStyle:{"width":"100%"}},[_c('GhChecks',{ref:"selectorDataTask",refInFor:true,attrs:{"datalabel":{text: item.name, style: '', class: '', id: ''},"datainput":{
                                                disabled: _vm.getFeeds.feed_mandatory_task[item.id] !== undefined ? true : false,
                                                required: false,
                                                selected: _vm.getFeeds.feed_mandatory_task[item.id] !== undefined ? true : _vm.selectedTasks[item.id],
                                                type: 'checkbox',
                                                id: 'workflow_task_value_' + item.id,
                                                value: item.id,
                                                name: 'workflow[task][values][]'
                                            }},on:{"click":function($event){return _vm.checkTasks(item.id, 'check')}}})],1)])])}),0)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_accept_task_workflow_popup',
                        text: _vm.$t('accept'),
                    }},on:{"click":function($event){return _vm.addTasks()}}}),_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_close_task_workflow_popup',
                        text: _vm.$t('close'),
                    }},on:{"click":function($event){return _vm.Global.closePopUp('task_workflow_popup')}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }