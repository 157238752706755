<template>
    <div :id="'_float_window'"  v-if="haveData==true">
        <div class="content_page" >
            <GhNoDropSelector
                :datablock="{
                    id: 'crm_support_incidence',
                    title: $t('assignment') ,
                }"
            >
                <template v-slot:content>
                    <form id="form_assignment">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_group_assignment_name',
                                            text: $t('assignment_group') + '*',
                                            style: 'width: 150px; text-align: right; min-width: 150px !important;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_group_assignment_name',
                                            name: 'group[name]',
                                            style: 'max-height: 17px;',
                                            type: 'text',
                                            value: name,
                                            required: true,
                                        }"
                                        ref="refInputName"
                                        @keyup="persistanceInputs('name')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 138px; min-width: 138px; text-align: right;">{{ $t('assignment_group') }}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 100%;">{{ ConfigAssignment.data && ConfigAssignment.data.name ? ConfigAssignment.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description') ,
                                            class: 'T15',
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'textarea_group_assignment_description',
                                            name: 'group[description]',
                                            value:  description,
                                        }"
                                        ref="refInputDescription"
                                        @keyup="persistanceInputs('description')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px;  width: 138px; min-width: 138px; text-align: right;">{{ $t('description')}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 100%;">{{ ConfigAssignment.data && ConfigAssignment.data.description ? ConfigAssignment.data.description : '' }}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_group_assignment_email',
                                            text: $t('mail'),
                                            style: 'width: 150px; text-align: right; min-width: 150px !important;',
                                            class: 'T15'
                                        }"
                                        :datainput="{
                                            id: 'input_group_assignment_email',
                                            name: 'group[email]',
                                            style: 'max-height: 17px;',
                                            type: 'text',
                                            value: email,
                                        }"
                                        ref="refInputEmail"
                                        @keyup="persistanceInputs('email')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 138px; min-width: 138px; text-align: right;">{{ $t('mail')}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 100%;">{{ ConfigAssignment.data && ConfigAssignment.data.email ? ConfigAssignment.data.email : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <div style="margin-right: 40px;">
                                    <GhDropDownField
                                        :dataBlock="{
                                            id: 'drop_users_assignment',
                                            style:'width: 100%; margin-right: 0px;',
                                            title: $t('people'),
                                            subTitle: $root._route.params.id ? ConfigAssignment.data && ConfigAssignment.data.users && ConfigAssignment.data.users.length !== 0 ? ConfigAssignment.data.users.length + ' ' + $t('Resultados') :  0 + ' ' + $t('Resultados'):users.length!==0? users.length+' '+ $t('Resultados'):0+' '+ $t('Resultados'),
                                            isDrop: true
                                        }"
                                    >
                                        <template v-slot:content>
                                            <GhTableCommon v-if="haveData"
                                                :extratable="{ id: 'conf_supp_assignment_users' }"
                                                :header="header"
                                                :data="$root._route.params.id ? ConfigAssignment.data && ConfigAssignment.data.users && ConfigAssignment.data.users.length !== 0 ? ConfigAssignment.data.users : [] : users"
                                            >
                                                <template v-slot:footer v-if="!$root._route.params.view">
                                                    <div class="centered">
                                                        <GhButton
                                                            :datainput="{
                                                                id: 'btn_add_assignment_users',
                                                                text: $t('add_users')
                                                            }"
                                                            @click="searchUsers($root._route.params.id ? ConfigAssignment.data && ConfigAssignment.data.users && ConfigAssignment.data.users.length !== 0 ? ConfigAssignment.data.users : [] : users)"
                                                        />
                                                    </div>
                                                </template>
                                            </GhTableCommon>
                                        </template>
                                    </GhDropDownField>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view"
                    :datainput="{
                        id: 'btn_close',
                        class: 'T19 container-md_button_content',
                        text: $t('save')
                    }"
                    @click="setAssignment()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        class: 'T19 container-md_button_content',
                        text: $t('back')
                    }"
                    @click="router.go(-1)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhTextarea from 'fe-gh-textarea-lib'
    import GhButton from 'fe-gh-button-lib'
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhDropDownField from 'fe-gh-drop-down-field-lib'
    import {mapState} from "vuex";
    export default {
        name: "assignment_template",
        components:{
            GhNoDropSelector,
            GhInputTextField,
            GhButton,
            GhTextarea,
            GhDropDownField,
            GhTableCommon
        },
        data(){
            return{
                haveData:false,
                users:[],
                name: '',
                description: '',
                email: '',
                header: [
                    {text: 'name', field: 'first_name', sorting: true,typeSort: 'string', style:'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surnames', field: 'last_name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
                headerSearch: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'name', field: 'first_name', sorting: true,typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                    {text: 'surnames', field: 'last_name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 50%;'},
                ],
            }
        },
        computed:{
            ...mapState(['ConfigAssignment'])
        },
        methods:{
            persistanceInputs(type){
                if(type == 'name'){
                    this.name = this.$refs.refInputName.$el.lastChild.value;
                }else if(type == 'description'){
                    this.description = this.$refs.refInputDescription.$el.lastChild.value;
                }else{
                    this.email = this.$refs.refInputEmail.$el.lastChild.value;
                }
            },
            setAssignment(){
                if(this.Global.checkRequired('form_assignment') == 'OK') {
                    const formData = new FormData(document.getElementById('form_assignment'));
                    this.$store.dispatch('setAssignmentData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            searchUsers(data){
                var then=this;
                var params = {
                    idblock: 'users',
                    actionsSearch: '',
                    actionsSelected: '',
                    actionsNoDropSelected: '',
                    actionsNoDropSearch: '',
                    allmarkoption: false,
                    supportPermision: true,
                    textuserselected: this.$t('selected_users'),
                    img: require('../../../../../assets/gh_new_dessign/floating.svg'),
                    textNofilter: this.$t('no_filter_has_been_applied'),
                    textNoResult: this.$t('no_results_found'),
                    nameSlot: 'view_assignment',
                    nameStore: 'ConfigAssignment',
                    ResultSearch: 'searchData',
                    dataSelected: data,
                    actionresult: 'loadSearchAssignmentUsers',
                    textlocation: this.$t('add_users'),
                    headersearch: JSON.stringify(this.headerSearch),
                    headerselected: JSON.stringify(this.headerSearch),
                    formId: 'user',
                    actionsearch: 'getSearchAssignmentUsers',
                    checkfilterselected: 1,
                    hiddencheck: false,
                    type: 'checkbox',
                }

                localStorage.setItem('Params_' + 'users', JSON.stringify(params));
                this.Global.windowOpen(this.Global.openSecondWindow('GhContentWindowSearchView',{ idblock: params.idblock }),'usersS');
                window.UpdatedSelect = function (e) {
                    if(then.$root._route.params.id == undefined){
                        then.users = e;
                        then.$store.state.ConfigAssignment.records.added = e;
                    }else{
                        then.$store.dispatch('setTemporalAssignmentUsers', {data: e}, {root: true});
                    }
                }

                window.reloadwindow = function () {}
                window.closewindow = function () {}
                window.events = function () {}
            },
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getAssignmentData', {id: this.$root._route.params.id}, {root: true});
                this.name = this.ConfigAssignment !== undefined && this.ConfigAssignment.data !== undefined && this.ConfigAssignment.data.name ? this.ConfigAssignment.data.name : '';
                this.description = this.ConfigAssignment !== undefined && this.ConfigAssignment.data !== undefined && this.ConfigAssignment.data.description ? this.ConfigAssignment.data.description : '';
                this.email = this.ConfigAssignment !== undefined && this.ConfigAssignment.data !== undefined && this.ConfigAssignment.data.email ? this.ConfigAssignment.data.email : '';
                this.haveData = true;
            }
        },
    }
</script>

<style>
    #drop_users_assignment > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_users_assignment > div > div > .definition-field_header-text {
        width: 150px;
        display: flex;
        flex-flow: row-reverse;
    }
</style>
