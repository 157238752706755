var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"RefPopUpPunctuation",attrs:{"generalConfiguration":{
        id: 'popup_punctuation',
        title: _vm.$t('punctuation'),
        type: 'add',
        style: 'width: 80% !important; min-width:1024px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 90%; min-height: 8vh;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.CreationGrouperElements.showPunctuation)?[_c('form',{attrs:{"id":"form_percentage"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('maximum_base_score') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterPunctuations.max_base))]),(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefMaxAssigned",attrs:{"datalabel":{
                                    text: _vm.$t('maximum_base_assigned'),
                                    style: '',
                                    class: ''
                                },"datainput":{
                                    required: true,
                                    id: 'max_assigned_value',
                                    name: 'punctuation[max_assigned]',
                                    style: 'width: 35px;',
                                    value: _vm.maxAssigned
                                },"maxlength":"3"},on:{"blur":function($event){return _vm.calcPunctuation()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('maximum_base_assigned') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.maxAssigned))])]],2),_c('div',{key:'KEY11' + _vm.refreshTable,staticClass:"gh-row-content",staticStyle:{"overflow-x":"auto","max-height":"300px"}},[_c('GhTableCommon',{staticStyle:{"min-width":"500px"},attrs:{"extratable":{
                                id: 'punctuations_table'
                            },"header":_vm.header,"data":_vm.data},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp,labelProp}){return [(labelProp.field === 'percentage')?_c('GhInputTextField',{ref:'RefInputPercentage' + itemProp.id,staticStyle:{"margin-left":"-4px","margin-right":"4px"},attrs:{"datalabel":{},"datainput":{
                                        required: true,
                                        id: 'row_order_' + itemProp.id,
                                        name: 'punctuation[percentage][' + itemProp.id + ']',
                                        value: itemProp.percentage,
                                        type: 'int',
                                        limitNumMin: 0,
                                        limitNumMax: 100,
                                        label_required: _vm.$t('percentage') + '*'
                                    }},on:{"blur":function($event){return _vm.calcPunctuation(itemProp.id)}}}):_vm._e()]}}],null,false,2661500780)})],1)])])]:(!_vm.CreationGrouperElements.showPunctuation)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(!_vm.$root._route.params.view)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept_popup_punctuation',
                    text: _vm.$t('accept')
                }},on:{"click":_vm.acceptPunctuation}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_close_popup_punctuation',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_punctuation');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }