import ConfigSeal from "@/view/config/creation/seals/template/seal.vue";

const routes = [
    {
        path: '/config/creation/seal',
        name: 'config_seal',
        component: ConfigSeal,
        props: {id: null, showMenu: true, otherTitle: 'stamp'},
        children: [
            {
                path: '/config/creation/seal/:id',
                name: 'config_seal_id',
                component: ConfigSeal,
                props: {id: null, showMenu: true, otherTitle: 'stamp'},
            },
            {
                path: '/config/creation/seal/:id/:view',
                name: 'config_seal_id_view',
                component: ConfigSeal,
                props: {id: null, view: true, showMenu: true, otherTitle: 'stamp'},
            }
        ]
    }
];

export default routes;