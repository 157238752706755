import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        data: [],
        flagAllPrograms: false
    },
    mutations: {
        loadAllProgramsData(state, payload){
            state.flagAllPrograms = !state.flagAllPrograms;
            state.data = payload;
        }
    },
    getters: {
        getterAllPrograms(state) {
            const flagAllPrograms = state.flagAllPrograms; // eslint-disable-line no-unused-vars
            return state.data;
        },
    },
    actions: {
        async getAllPrograms(state){
            let requestLogin = API_URL + "programs/all";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit("loadAllProgramsData", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};