import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        records_data: ''
    },
    mutations: {
        loadDataPay(state, payload) {
            state.records_data = payload;
        },
    },
    actions: {
        async getDataPay(state, item){
            await axios.get(
                API_URL + "pay/" + item.type
            ).then(
                response => {
                    state.commit('loadDataPay', response.data.data);
                }
            )
        },
        async setPay(state, item) {
            let requestLogin = API_URL + "pay/" + item.type;
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                data: formData,
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    return response;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
