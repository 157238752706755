<template>
    <div>
        <div class="section_line" style="margin-top: 10px;">
            <div class="T15_b" style="padding-left:13px;">{{$t('type') + ': ' + ($root._route.params.view ? Object.values(ConfigTypeExercise.data.type[0])[1] : $parent.$parent.$refs['RefTypeExercise'].datasaved.text)}}</div>
        </div>
        <div class="gh-row-content" style="margin-top: 10px">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('questions') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_possible_questions',
                        name: 'type_exercise[possible_questions]',
                        style: 'width: 50px',
                        value: getterPossibleQuestions.actual_possible_questions,
                        type: 'int',
                        limitNumMin: 1
                    }"
                    ref="RefQuestions"
                    @focusout="checkNumberPossibleQuestions"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('questions') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPossibleQuestions.actual_possible_questions}}</label>
            </template>
        </div>
        <div class="gh-row-content" >
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_type_exercise_type_answer',
                        class: 'T15_b',
                        subtitle: $t('select_type_answer') + ':',
                        text: $t('type_answer') + '*',
                        label: $t('type_answer'),
                        type: 'radio',
                        columns: 2,
                        style: '',
                        required: true
                    }"
                    :datainput="{
                        id: 'value_type_exercise_type_answer',
                        name: 'type_exercise[type_answer]'
                    }"
                    :selected_input="ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.type_answer ? ConfigTypeExercise.data.config_type.type_answer : []"
                    :feed="getFeeds.feed_type_answers"
                />
            </template>
            <template v-else>
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type_answer') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.type_answer ? Object.values(ConfigTypeExercise.data.config_type.type_answer).join(', ') : ''}}</label>
                </div>
            </template>
        </div>
        <div v-if="getterPossibleQuestions.possible_questions.length !== 0" :style="$root._route.params.view ? '' : 'margin-top: -5px;'" style="padding-bottom: 10px; margin-top: 5px; padding-left: 160px">
            <GhTableCommon
                :extratable="{
                    id: 'possible_questions'
                }"
                :header="header"
                :data="getterPossibleQuestions.possible_questions"
                ref="RefTableQuestion"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field === 'question'">
                        <GhInputTextField
                            :datalabel="{}"
                            :datainput="{
                                required: false,
                                id: 'type_exercise_possible_questions_' + itemProp.id,
                                name: 'type_exercise[questions]['+itemProp.id+']',
                                value: itemProp.question,
                            }"
                            :ref="'RefPossibleQuestion' + itemProp.id"
                            @focusout="setPossibleQuestion(itemProp.id)"
                        />
                    </div>
                </template>
                <template v-slot:actions="{itemProp}">
                    <GhAction
                        :dataction="{
                            id: 'delete_possible_answer_' + itemProp['id'],
                            text: $t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                            item_prop: itemProp
                        }"
                        @click="deletePossibleQuestion(itemProp)"
                    />
                </template>
            </GhTableCommon>
        </div>
        <div class="gh-row-content" :style="getterPossibleQuestions.possible_questions.length !== 0 ? '' : $root._route.params.view ? '' : 'margin-top: -10px;'">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_type_exercise_conditions',
                        class: 'T15_b',
                        subtitle: $t('select_conditions') + ':',
                        text: $t('conditions'),
                        label: $t('conditions'),
                        type: 'checkbox',
                        columns: 2,
                        style: '',
                        required: false
                    }"
                    :datainput="{
                        id: 'value_type_exercise_conditions',
                        name: 'type_exercise[conditions][]'
                    }"
                    :selected_input="ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? ConfigTypeExercise.data.config_type.conditions : []"
                    :feed="getFeeds.feed_condition"
                />
            </template>
            <template v-else>
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('conditions') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? Object.values(ConfigTypeExercise.data.config_type.conditions).join(', ') : ''}}</label>
                </div>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;" :style="$root._route.params.view ? '' : 'margin-top: -10px;'">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[0] === 1,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[1] === 2,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.feedback[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('subquestion'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_subquestion'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_subquestion',
                    value: 1,
                    name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhChecks from "fe-gh-checks-lib";

    import {mapState, mapGetters} from "vuex";

    export default {
        name: 'type_questionnaire',
        components: {
            GhInputTextField,
            GhDataSelector,
            GhTableCommon,
            GhAction,
            GhChecks
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds']),
            ...mapGetters(['getterPossibleQuestions'])
        },
        data(){
            return{
                header: [
                    {text: 'questions', field: 'question', field_component: !this.$root._route.params.view, required: true, style: 'max-width: 10px; width: 100%; padding-right: 10px;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        mounted(){
            if(this.$root._route.params.view){
                this.header = [
                    {text: 'questions', field: 'question', field_component: !this.$root._route.params.view, required: true, style: 'max-width: 10px; width: 100%; padding-right: 10px;'},
                ];
            }
        },
        methods: {
            async checkNumberPossibleQuestions(){
                await this.$store.dispatch('createTablePossibleQuestions', {
                    count: this.$refs['RefQuestions'].datainput.value
                }, {root: true});
            },
            async setPossibleQuestion(item){
                await this.$store.dispatch('setTablePossibleQuestions', {
                    question: this.$refs['RefPossibleQuestion' + item].datainput.value,
                    id: item
                }, {root: true});
            },
            async deletePossibleQuestion(item){
                await this.getterPossibleQuestions.possible_questions.forEach((i, k) => {
                    if(i.id === item.id){
                        this.$store.dispatch('deleteTablePossibleQuestions', {
                            position: k
                        }, {root: true});
                    }
                });
            }
        }
    }
</script>

<style>
    #label_type_exercise_type_answer_selector_label {
        width: 150px;
        text-align: end;
    }
</style>