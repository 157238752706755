<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'metrics_popup',
            title: $t('add_metric'),
            type: 'add',
            style: 'width: 50%; min-width:920px;',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important;'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_metrics_data">
                <div class="gh-content-global" style="padding-bottom: 0px;">
                    <div class="gh-row-content">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('name') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'metrics_name',
                                name: 'metrics[name]',
                                style: 'min-width: 200px; width: 20%',
                                value: '',
                                type: 'text',
                            }"
                        />
                    </div>
                    <div class="gh-row-content">
                        <GhTextarea
                            :datalabel="{
                                text: $t('description') + '*',
                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                class: 'T15_b',
                            }"
                            :datainput="{
                                id: 'metrics_description',
                                name: 'metrics[description]',
                                value: ''
                            }"
                        />
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_metrics_accept_popup',
                    text: $t('accept')
                }"
                @click="submitMetrics"
            />
            <GhButton
                :datainput="{
                    id: 'btn_metrics_close_popup',
                    text: $t('close')
                }"
                @click="Global.closePopUp('metrics_popup');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from 'fe-gh-popup-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhButton from 'fe-gh-button-lib';
    export default {
        name: "metrics_popup",
        components: {
            GhPopUp,
            GhInputTextField,
            GhTextarea,
            GhButton
        },
        methods: {
            async submitMetrics(){
                if(this.Global.checkRequired('form_metrics_data') == 'OK') {
                    const formData = new FormData(document.getElementById('form_metrics_data'));

                    this.$store.dispatch('setTemporalMetrics', {
                        formData: [...formData],
                    }, {root: true});

                  this.Global.closePopUp('metrics_popup');
                }
            }
        }
    }
</script>