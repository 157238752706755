import ConfigEvaluationBasis from "@/view/config/creation/evaluation_basis/template/evaluation_basis.vue";

const routes = [
    {
        path: '/config/creation/evaluation-basis',
        name: 'config_evaluation_basis',
        component: ConfigEvaluationBasis,
        props: {id: null, showMenu: true, otherTitle: 'evaluation_basis'},
        children: [
            {
                path: '/config/creation/evaluation-basis/:id',
                name: 'config_evaluation_basis_id',
                component: ConfigEvaluationBasis,
                props: {id: null, showMenu: true, otherTitle: 'evaluation_basis'},
            },
            {
                path: '/config/creation/evaluation-basis/:id/:view',
                name: 'config_evaluation_basis_id_view',
                component: ConfigEvaluationBasis,
                props: {id: null, view: true, showMenu: true, otherTitle: 'evaluation_basis'},
            }
        ]
    }
];

export default routes;