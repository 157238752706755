var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTreeContentBlock',{staticStyle:{"margin-bottom":"15px","margin-left":"15px","margin-right":"15px"},attrs:{"dataBlock":{
            id: 'pending',
            actionWithoutChildren: true,
            actionWithChildren: true,
            extraLabelNumber: false,
            extraLabelText: '',
            extraTableClass: 'class-table-exercise'
        },"data":_vm.BaseCreationExerciseTable.records_data_pending.length === 0 ? [] : _vm.BaseCreationExerciseTable.records_data_pending,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true, type: 'pending'}}}},[_c('a',{staticClass:"table-cell_link T13"},[_c('i',[_vm._v(_vm._s(itemProp[labelProp]))])])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id', params: {id: itemProp.id,type: 'pending'}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_config_exercises',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg')
                    }}})],1),_c('GhAction',{attrs:{"dataction":{
                    id: 'see_config_exercises',
                    text: _vm.$t('see'),
                    icon: require('../../../../assets/gh_new_dessign/view.svg')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('preview_exercise', {id:itemProp.id,type:'exercise'}),'preview_exercise')}}})]}}],null,false,2795761311)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }