var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.$store.state.ActionIndicators.searchData && _vm.$store.state.ActionIndicators.searchData != [] ? 'margin-top: 12px;' : '')},_vm._l((_vm.ActionIndicators.searchData),function(item,itemIndex){return _c('div',{key:itemIndex},[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_result_indicators' + item.id,
                style: 'margin-left: 20px; margin-bottom: 10px; margin-top:10px;',
                title: item.name + '' + item.date_start + ' - ' + '' + item.date_end,
                subTitle: '',
                isDrop: false
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"margin-right":"20px"}},[_c(_vm.view_result_action_indicators,{tag:"component",attrs:{"dataTable":item.data,"parentId":item.id,"where":'list_result_action_indicators'}})],1)]},proxy:true}],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }