<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{id:'table_todo'}"
            :data="TabTodo.list_todo"
            :header="header"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <div :style="itemProp.color_end_date !== '' ? 'color:' + itemProp.color_end_date : ''" class="T15_b">
                    {{itemProp[labelProp]}}
                </div>
            </template>
            <template v-slot:actions="{itemProp}">
                <GhAction v-if="itemProp.modify"
                    :dataction="{
                        id: 'modify_todo_' + itemProp.id,
                        text: $t('modify'),
                        icon: require('@/assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="modify(itemProp)"
                />
                <router-link :to="{name: 'initial_itinerary_window', params: {type: 'initial', id: itemProp.agrupator_id}}">
                    <GhAction v-if="itemProp.realize"
                        :dataction="{
                            id: 'realize_todo_' + itemProp.id,
                            text: $t('realize'),
                            icon: require('@/assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
                <GhAction v-if="itemProp.show"
                    :dataction="{
                        id: 'view_todo_' + itemProp.id,
                        text: $t('view'),
                        icon: require('@/assets/gh_new_dessign/view.svg'),
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('initial_itinerary_window',{type:'initial',id:itemProp.agrupator_id}),'initial_questionary')"
                />
            </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_todo",
        components:{
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['TabTodo']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'company', field: 'company', sorting: true,typeSort: 'string', style: 'width:21%;'},
                    {text: 'itinerary', field: 'itinerary', sorting: true, typeSort: 'string', style: 'width:21%;'},
                    {text: 'evaluators', field: 'evaluators', sorting: true,typeSort: 'string', style: 'width:17%;'},
                    {text: 'start_date', field: 'start_date', sorting: true,typeSort: 'string', style: 'max-width: 80px;min-width: 80px;'},
                    {text: 'end_date', field: 'end_date', urlRedirect: true, sorting: true,typeSort: 'string', style: 'max-width: 80px;min-width: 80px;'},
                    {text: 'state', field: 'state', sorting: true,typeSort: 'string', style: 'width:15%;'},
                    {text: '', field: 'actions', style: ''},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListTodo', '', {root: true});
            this.haveData = true;
        },
        methods:{
            async modify(item){
                await this.$store.dispatch('getDataModify', {id:item.id,agrupator_id:item.agrupator_id}, {root: true});
                this.Global.openPopUp('popup_calendar');
            }
        }
    }
</script>