var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_warning_reset_condition',
        title: _vm.$t('warning'),
        type: 'warning',
        style: 'width: 28%; min-width: 430px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{staticClass:"T15_b"},[_vm._v(" "+_vm._s(Object.entries(_vm.CreationGrouperElements.pending_confirm_delete).length !== 0 ? _vm.$t('delete_item_removes_all_defined_conditions') : _vm.$t('performing_new_sort_removes_all_defined_conditions'))+" ")])])])]),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"T15"},[_vm._v(_vm._s(_vm.$t('are_you_sure')))])])]},proxy:true},{key:"footer",fn:function(){return [(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_popup_warning_reset_condition',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.acceptWarning()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_warning_reset_condition',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){_vm.Global.closePopUp('popup_warning_reset_condition'); _vm.cleanPendingConfirmDelete()}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }