import ConfigProfessionalExperienceSector from "@/view/config/evaluators/professional_experience_sector/template/professional_experience_sector.vue";

const routes = [
    {
        path: '/config/evaluator/professional-experience-sector',
        name: 'config_professional_experience_sector',
        component: ConfigProfessionalExperienceSector,
        props: {id: null, showMenu: true, otherTitle: 'professional_experience_sector'},
        children: [
            {
                path: '/config/evaluator/professional-experience-sector/:id',
                name: 'config_professional_experience_sector_id',
                component: ConfigProfessionalExperienceSector,
                props: {id: null, showMenu: true, otherTitle: 'professional_experience_sector'},
            },
            {
                path: '/config/evaluator/professional-experience-sector/:id/:view',
                name: 'config_professional_experience_sector_id_view',
                component: ConfigProfessionalExperienceSector,
                props: {id: null, view: true, showMenu: true, otherTitle: 'professional_experience_sector'},
            }
        ]
    }
];

export default routes;