import ListCompanies from "@/view/gh_admin/list_companies";

const routes = [
    {
        path: '/admin',
        name: 'gh_admin',
        component: ListCompanies,
        props: {showMenu: true}
    },
];

export default routes;