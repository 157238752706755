<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'generic_holidays_table',
            }"
            :header="header"
            :data="ConfigGenericHolidays.records_data.length == 0 ? [] : ConfigGenericHolidays.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'generic_holidays_view', params: {id: itemProp.id, view: true}}"><a :href="itemProp[labelProp]" class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'generic_holidays_id', params: { id: itemProp.id, view: false}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_conf_supp_generic_holiday',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_conf_supp_generic_holiday',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }"
                    @click="Global.deleteConfirm('delete_confirm','GenericHolidays', itemProp.id,{'description': itemProp.description,'date': itemProp.event_date}, $t('delete_generic_holiday'), $t('preparing_delete_generic_holiday') + ':', 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'generic_holidays'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_conf_supp_generic_holiday',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: "list_generic_holidays",
        data(){
            return{
                haveData:false,
                header: [
                  {text: this.$t('description'), field: 'description', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 80%;'},
                  {text: this.$t('date'), field: 'event_date', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                  {text: '', field: 'actions', sorting: false,style: 'width:10%;'},
                ],
            }
        },
        components:{
            GhButton,
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['ConfigGenericHolidays'])
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getGenericHolidays', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>