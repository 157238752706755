import ConfigClubCategory from "@/view/config/evaluators/club_category/template/club_category.vue";

const routes = [
    {
        path: '/config/evaluator/club-category',
        name: 'config_club_category',
        component: ConfigClubCategory,
        props: {id: null, showMenu: true, otherTitle: 'club_category'},
        children: [
            {
                path: '/config/evaluator/club-category/:id',
                name: 'config_club_category_id',
                component: ConfigClubCategory,
                props: {id: null, showMenu: true, otherTitle: 'club_category'},
            },
            {
                path: '/config/evaluator/club-category/:id/:view',
                name: 'config_club_category_id_view',
                component: ConfigClubCategory,
                props: {id: null, view: true, showMenu: true, otherTitle: 'club_category'},
            }
        ]
    }
];

export default routes;