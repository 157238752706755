var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData == true)?_c('div',[_c('form',{key:_vm.refresh,attrs:{"id":"form_search_actions_indicators"}},[_c('div',{staticClass:"gh-content-global"},[_c(_vm.ViewIndicators,{tag:"component"}),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datainput":{
                        id: 'radio_and',
                        value: 'and',
                        type: 'radio',
                        name: 'indicators[criterio]',
                        style: 'margin-left:48px; cursor:pointer;',
                        selected:true,
                    },"datalabel":{
                        style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                        class: 'T15 input_label',
                        id: 'label_and',
                        text: _vm.$t('all_search_criteria_entered')
                    }}}),_c('GhChecks',{attrs:{"datainput":{
                        id: 'radio_or',
                        value: 'or',
                        type: 'radio',
                        name: 'indicators[criterio]',
                        style: 'cursor:pointer;',
                    },"datalabel":{
                        style: 'margin-left: 4px;',
                        class: 'T15 input_label',
                        id: 'label_or',
                        text: _vm.$t('any_search_criteria_entered')
                    }}})],1)],1)]),_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_save', class: 'T19 container-md_button_content', text: _vm.$t('accept')}},on:{"click":function($event){return _vm.search()}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_return',class: 'T19 container-md_button_content', text: _vm.$t('clean')}},on:{"click":function($event){return _vm.clean()}}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }