import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        subTabActive: '',
        nameSubTabActive: '',
        idSubTabActive: '',
        actual_assign: '',
        reload: 0
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadNameSubTabIdActive(state,payload){
            state.idSubTabActive = payload
        },
        loadActualAssign(state,payload){
            state.actual_assign = payload
        },
        loadReloadTab(state){
            state.reload++;
        }
    },
    actions: {
        async getAssignTabActive(state, item) {
            var valRouter = await router.options.routes.find((i) => (item.call == i.name && item.call)  || i.name == item.value);

            state.commit("loadSubActiveItem", valRouter.component != '' ? valRouter.component : '');
            state.commit("loadNameSubActiveItem", valRouter.name != '' ? valRouter.name : '');
            state.commit("loadReloadTab");

            state.commit("loadNameSubTabIdActive", item.id);
            state.commit("loadActualAssign", item.value);
        }
    }
}
