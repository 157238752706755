<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'pending',
            }"
            :header="header"
            :data="CreationGrouper.pending_data !== undefined && CreationGrouper.pending_data.length !== 0 ? CreationGrouper.pending_data : []"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'view_grouper_id_view', params: {grouper: id_grouper, id: itemProp.id, view: true,type:'pending'}}"><a class="table-cell_link T13"><i>{{itemProp[labelProp]}}</i></a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'view_grouper_id', params: {grouper: id_grouper,id: itemProp.id,type:'pending'}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_config_grouper',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_config_grouper',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','ToHistoricCreationGrouper', itemProp.id,{'code': itemProp.code,'name': itemProp.name, 'description': itemProp.description, 'responsible': itemProp.responsible, 'topic': itemProp.topic, 'impartation': itemProp.impartation}, $t('delete_grouper'), $t('preparing_delete_grouper'), 'delete')"
                />
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_pending",
        components: {
            GhAction,
            GhTableCommon
        },
        props:['id_grouper'],
        computed:{
            ...mapState(['CreationGrouper','TabCreation']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: 'responsible', field: 'responsible', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'impartation', field: 'impartation', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        watch:{
            async 'TabCreation.actual_grouper'(){
                await this.$store.dispatch('getPendingByGrouper',{id:this.id_grouper},{root:true});
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPendingByGrouper', {id: this.id_grouper}, {root: true});
                this.haveData = true;
            }
        }
    }
</script>