import CompaniesGroup from "@/view/perms/companies/companies/template/companies.vue";

const route = [
    {
        path: '/perms/company-group/:id',
        name: 'permission_company_group_id',
        component: CompaniesGroup,
        props: {id: null, showMenu: true, otherTitle: 'company_group'},
    },
];

const routes = [
    ...route,
]

export default routes;