<template>
    <div class="gh-popup-ellipsis-title" :key="'popup_evaluate_' + refreshEvaluate">
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'popup_evaluate',
                title: getterQuestionnaireEvaluation.name,
                type: '',
                style: 'font-size: 20px; width: 1000px; min-width: 1000px; max-width: 1000px;overflow-y: auto !important;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: ''
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
            ref="RefPopupEvaluate"
        >
            <template v-slot:body>
                <component v-bind:is="evaluate" :read="true"/>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button_close_popup_evaluate',
                        text: $t('close'),
                        class: 'button-questionnaire-secondary'
                    }"
                    @click="Global.closePopUp('popup_evaluate');refreshEvaluate++;"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import evaluate from '@/view/shared/template/oportunity_hard_points.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'popup_evaluate',
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return{
                evaluate: evaluate,
                refreshEvaluate: 0
            }
        },
        watch: {
            'getterQuestionnaireEvaluation'(data){
                if(this.$refs.RefPopupEvaluate.$el.children[0].childNodes[0].children[0].children[0] !== undefined) {
                    this.$refs.RefPopupEvaluate.$el.children[0].childNodes[0].children[0].children[0].remove();
                    this.$refs.RefPopupEvaluate.$el.children[0].childNodes[0].children[0].children[0].title = data.name;
                    this.$refs.RefPopupEvaluate.$el.children[0].childNodes[0].children[0].style.setProperty("background-color", "#1B82C5", "important");
                }
            }
        }
    }
</script>

<style>
    .gh-popup-ellipsis-title > div > div > header > .modal-header > .collapsible-title{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
    }
</style>