var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.data),function(i,index){return _c('div',{key:i.id},[_c('GhTreeContent',{class:index % 2 ? 'striped_tree' : 'no_striped_tree',attrs:{"dataBlock":{
                id: _vm.dataBlock.id,
                actionWithoutChildren: _vm.dataBlock.actionWithoutChildren,
                actionWithChildren: _vm.dataBlock.actionWithChildren,
                levelJustify: 0,
                interspersed: index % 2,
                extraLabelNumber: _vm.dataBlock.extraLabelNumber,
                extraTableClass: _vm.dataBlock.extraTableClass,
                extraLabelText: (_vm.dataBlock.extraLabelText != '' && _vm.dataBlock.extraLabelText !== undefined) ? _vm.dataBlock.extraLabelText : '',
                alternativeSummary: (_vm.dataBlock.alternativeSummary != '' && _vm.dataBlock.alternativeSummary !== undefined) ? _vm.dataBlock.alternativeSummary : false,
                alternativeValue: (_vm.dataBlock.alternativeValue != '' && _vm.dataBlock.alternativeValue !== undefined) ? _vm.dataBlock.alternativeValue : '',
                alternativeStyleSummary: (_vm.dataBlock.alternativeStyleSummary != '' && _vm.dataBlock.alternativeStyleSummary !== undefined) ? _vm.dataBlock.alternativeStyleSummary : ''
            },"data":i,"header":_vm.header},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('div',[_vm._t("field_redirect",null,{"itemProp":itemProp,"labelProp":labelProp})],2)]}},{key:"actions",fn:function({itemProp}){return [_c('div',[_vm._t("actions",null,{"itemProp":itemProp.itemProp})],2)]}}],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }