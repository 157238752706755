var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                id: 'action_type_call_result',
                class: 'T15_b',
                subtitle: _vm.$t('select_call_result') + ':',
                text: _vm.$t('call_result') + '*',
                label: _vm.$t('call_result'),
                type: 'radio',
                columns: 3,
                required: true,
                style: '',
                popupStyle: 'min-width: 900px;width: 55%;'
            },"datainput":{
                id: 'value_action_type_call_result',
                name: 'action[call_result]'
            },"feed":_vm.getFeeds.feed_state_phone_number,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"-10px"}},[_c('GhTextarea',{ref:"RefComment",attrs:{"datalabel":{
                text: _vm.$t('comment') + '*',
                class: 'gh_text_field-label T15_b',
                styles: 'text-align: right; width: 150px !important; min-width: 150px !important;',
            },"datainput":{
                id: 'action_comment',
                name: 'action[comment]',
                value: '',
                styles: 'width: 100%',
                required: true
            },"minlength":"10"}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }