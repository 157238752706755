var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[(_vm.getterAdvice.user !== undefined && _vm.getterAdvice.user !== '')?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T-subtitle-second",staticStyle:{"margin-left":"12px"}},[_vm._v(_vm._s(_vm.$t('evaluator') + ': ' + _vm.getterAdvice.user))])]):_vm._e(),(_vm.getterAdvice !== undefined && _vm.getterAdvice.hard_points !== undefined && _vm.getterAdvice.hard_points.length !== 0)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"content-star",staticStyle:{"margin-top":"-3px"}}),_c('label',{staticClass:"gh_text_field-label T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('hard_points')))])]),_vm._l((_vm.getterAdvice.hard_points),function(item,index){return [_c('div',{key:'hard_points_' + index,staticClass:"gh-row-content",class:_vm.edit ? 'radio-check-new-style' : '',style:(_vm.edit ? 'margin-left: 11px;' : '')},[(_vm.edit)?[_c('GhChecks',{attrs:{"datainput":{
                            id: 'radio_and',
                            value: index,
                            type: 'checkbox',
                            name: 'hard_points[]',
                            selected: _vm.$parent.$parent.$parent.selectedItems !== undefined && _vm.$parent.$parent.$parent.selectedItems.hard_points !== undefined && _vm.$parent.$parent.$parent.selectedItems.hard_points.includes(index),
                            disabled: false
                        },"datalabel":{class: '', id: '', text: ''}}})]:_vm._e(),_c('label',{staticClass:"gh_text_field-label T-text",style:(_vm.edit ? 'margin-left: 10px;' : 'margin-left: 20px;')},[_vm._v(_vm._s(item))])],2)]}),(_vm.getterAdvice !== undefined && _vm.getterAdvice.improvement_opportunities !== undefined && _vm.getterAdvice.improvement_opportunities.length !== 0)?[_c('div',{staticClass:"separador"})]:_vm._e()]:_vm._e(),(_vm.getterAdvice !== undefined && _vm.getterAdvice.improvement_opportunities !== undefined && _vm.getterAdvice.improvement_opportunities.length !== 0)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"content-warning",staticStyle:{"margin-top":"-3px"}}),_c('label',{staticClass:"gh_text_field-label T-subtitle-second"},[_vm._v(_vm._s(_vm.$t('improvement_opportunities')))])]),_vm._l((_vm.getterAdvice.improvement_opportunities),function(item,index){return [_c('div',{key:'improvement_opportunities_' + index,staticClass:"gh-row-content",class:_vm.edit ? 'radio-check-new-style' : '',style:(_vm.edit ? 'margin-left: 11px;' : '')},[(_vm.edit)?[_c('GhChecks',{attrs:{"datainput":{
                            id: 'radio_and',
                            value: index,
                            type: 'checkbox',
                            name: 'improvement_opportunities[]',
                            selected: _vm.$parent.$parent.$parent.selectedItems !== undefined && _vm.$parent.$parent.$parent.selectedItems.improvement_opportunities !== undefined && _vm.$parent.$parent.$parent.selectedItems.improvement_opportunities.includes(index),
                            disabled: false
                        },"datalabel":{class: '', id: '', text: ''}}})]:_vm._e(),_c('label',{staticClass:"gh_text_field-label T-text",style:(_vm.edit ? 'margin-left: 10px;' : 'margin-left: 20px;')},[_vm._v(_vm._s(item))])],2)]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }