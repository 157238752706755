<template>
    <div v-if="haveData" :key="'COUNTRIES' + refreshData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'countries'
            }"
            :header="header"
            :data="ConfigCountries.records_data.length === 0 ? [] : ConfigCountries.records_data"
        >
            <template v-slot:field_component="{itemProp}">
                <GhChecks
                    :datalabel="{
                        text: '',
                        style: '',
                        class: '',
                        id: ''
                    }"
                    :datainput="{
                        disabled: itemProp.disabled,
                        required: false,
                        selected: itemProp.selected || actualSelecteds.find((i) => i === itemProp.id) === itemProp.id,
                        type: 'checkbox',
                        id: 'countries_' + itemProp.id,
                        value: itemProp.id,
                        name: 'countries[]'
                    }"
                    :ref="'RefCountry' + itemProp.id"
                    @click="checkCountry(itemProp.id)"
                />
            </template>
            <template v-slot:footer>
                <div class="centered" v-if="showButton">
                    <GhButton
                        :datainput="{
                            id: 'btn_add_config_countries',
                            text: $t('accept')
                        }"
                        @click="acceptCountry"
                    />
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: "list_countries",
        components: {
            GhChecks,
            GhTableCommon,
            GhButton
        },
        computed: {
            ...mapState(['ConfigCountries'])
        },
        data(){
            return {
                haveData: false,
                actualSelecteds: [],
                showButton: false,
                refreshData: 0,
                header: [
                    {text: '', field: 'id', field_component: true, sorting: false, style: 'min-width: 18px; max-width: 18px; width: 18px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'}
                ]
            }
        },
        watch: {
            'ConfigCountries.defaultSelecteds'(data){
                this.actualSelecteds = [];
                data.forEach((i) => {
                    this.actualSelecteds.push(i);
                });

                this.showButton = false;

                this.refreshData++;
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCountriesList', '', {root: true});

                this.ConfigCountries.defaultSelecteds.forEach((i) => {
                    this.actualSelecteds.push(i);
                });

                this.haveData = true;
            }
        },
        methods: {
            checkCountry(id){
                let checked = this.$refs['RefCountry' + id].datainput.value;
                if(!this.$refs['RefCountry' + id].selected){
                    this.actualSelecteds.forEach((i, k) => {
                        if(i === checked){
                            delete this.actualSelecteds[k];
                        }
                    });
                }else{
                    this.actualSelecteds.push(checked);
                }

                this.actualSelecteds = this.actualSelecteds.filter(n => n);

                this.showButton = !this.arraysEqual(this.actualSelecteds, this.ConfigCountries.defaultSelecteds);
            },
            arraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length) {
                    return false;
                }

                for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i] !== arr2[i]) {
                        return false;
                    }
                }

                return true;
            },
            async acceptCountry(){
                await this.$store.dispatch('setCountriesData', {
                    ids: this.actualSelecteds
                }, {root: true});
            }
        }
    }
</script>