import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadListSituationData(state, payload){
            state.records_data = payload;
        }
    },
    actions: {
        async getListSituation(state,items){
            let formData = new FormData();
            items.id!==undefined?formData.append("id", items.id):'';
            await axios.get(
                API_URL + "list_situation"

            ).then(
                response => {
                    state.commit('loadListSituationData', response.data.data);
                }
            )
        },
    }
};
