<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <GhSubTab v-bind:items="$router.options.GhSubTabsPerms" :dataBlock="{id: 'perms_tab_', calldispatch: 'getPermsTabActive'}"/>
        <component :is="viewLoad" :key="refreshView"/>
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";

    import {mapState} from "vuex";

    export default {
        name: "list_perms",
        components:{
            GhSubTab,
        },
        computed:{
            ...mapState(['TabPerms']),
        },
        watch:{
            '$i18n.locale'(){
                this.haveData = false;
                this.Global.changeLanguage();

                var that = this;

                setTimeout(function (){
                    that.haveData = true;
                },200);
            },
            'TabPerms.reload'(){
                this.viewLoad = this.TabPerms.subTabActive;
                this.refreshView++;
            }
        },
        data(){
            return{
                haveData: false,
                viewLoad: null,
                refreshView: 0
            }
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>
