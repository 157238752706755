<template>
    <div :style="$root._route.params.windowed ? 'margin-top: -3px' : ''">
        <GhLocation v-if="$root._route.params.windowed"
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'OK',
                text: $root._route.params.assigned ? $t('assignation') : $t('evaluator'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'evaluator',
                    title: (!$root._route.params.view ? (($root._route.params.id === undefined ? $t('add') : $t('modify')) + ' ' + $t('evaluator').toLowerCase()) : $t('evaluator'))
                }"
            >
                <template v-slot:content>
                    <template v-if="haveData">
                        <form id="form_evaluator">
                            <component :is="evaluator"/>
                        </form>
                    </template>
                    <template v-else-if="!haveData">
                        <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                    </template>
                </template>
            </GhNoDropSelector>
            <template v-if="$root._route.params.view && !$root._route.params.assigned">
                <div v-for="(label, labelIndex) in getterEvaluatorEvaluationsSection" :key="'SECTION_EVALUATIONS' + labelIndex">
                    <GhDropSelector
                        :datablock="{
                            id: 'evaluations_' + label.id,
                            title: $t(label['name']),
                            second_title: $t('results'),
                            num_results: label.count,
                        }"
                    >
                        <template v-slot:content>
                            <component v-bind:is="sectionContent" :itinerary="label.id" />
                        </template>
                    </GhDropSelector>
                </div>
            </template>

            <div class="centered_footer" style="margin-top: 8px; padding-bottom: 20px;">
                <GhButton v-if="!$root._route.params.view"
                    :datainput="{
                        id: 'btn_finish',
                        text: $t('finish'),
                    }"
                    @click="submitEvaluator"
                />
                <template v-if="$root._route.params.assigned">
                    <GhButton
                        :datainput="{
                            id: 'btn_assign_leader',
                            text: $t('assign_leader'),
                        }"
                        @click="submitAssign('leader')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_assign_evaluator',
                            text: $t('assign_evaluator'),
                        }"
                        @click="submitAssign('evaluator')"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_return',
                        text: $root._route.params.windowed ? $t('close') : $t('back'),
                    }"
                    @click="$root._route.params.windowed ? close() : router.go(-1)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";

    import {mapGetters} from "vuex";

    import evaluator from "./template/evaluator.vue";
    import sectionContent from "./template/conent_section_evaluations.vue";

    export default {
        name: 'view_evaluator',
        components: {
            GhLocation,
            GhNoDropSelector,
            GhDropSelector,
            GhButton
        },
        computed: {
            ...mapGetters(['getterEvaluatorEvaluationsSection', 'getterEvaluatorData'])
        },
        data(){
            return {
                evaluator: evaluator,
                sectionContent: sectionContent,
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getHoIsSpain', '', {root: true});
                await this.$store.dispatch('getCountry', '', {root: true});
                await this.$store.dispatch('getFeedCategoryClub', '', {root: true});
                await this.$store.dispatch('getFeedTypeEvaluation', '', {root: true});
                await this.$store.dispatch('getFeedEvaluatorModality', '', {root: true});
                await this.$store.dispatch('getProfessionalActivitySectorData', '', {root: true});
                await this.$store.dispatch('getGradeExperienceData', '', {root: true});
                await this.$store.dispatch('getProfessionalExperienceSectorData', '', {root: true});

                if(this.$root._route.params.id !== undefined) {
                    await this.$store.dispatch('getEvaluatorData', {
                        id: this.$root._route.params.id
                    }, {root: true});

                    if(this.$root._route.params.view && !this.$root._route.params.assigned){
                        await this.$store.dispatch('getEvaluatorEvaluationsSectionData', {
                            id: this.$root._route.params.id
                        }, {root: true});
                    }
                }

                this.haveData = true;
            }
        },
        methods: {
            submitAssign(type){
                let that = this;
                window.opener.UpdatedSelect({
                    type: type,
                    name: that.getterEvaluatorData.name,
                    surnames: that.getterEvaluatorData.surnames,
                });

                this.Global.windowClose();
            },
            async submitEvaluator(){
                if(this.Global.checkRequired('form_evaluator') === 'OK') {
                    const formData = new FormData(document.getElementById('form_evaluator'));
                    await this.$store.dispatch('setEvaluatorData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});

                    if(this.$root._route.params.id !== undefined){
                        this.Global.windowClose();
                    }
                }
            },
            close(){
                this.Global.windowClose();
            }
        }
    }
</script>