import ConfigExercise from "@/view/base_creation/exercises/exercises/template/exercise.vue";
import SearchSupports from "@/view/base_creation/exercises/exercises/template/search/search_supports.vue";
import SearchExercises from "@/view/base_creation/exercises/exercises/template/search/search_exercises.vue";
import Questionnaire from "@/view/shared/questionnaire/body.vue";

const routes = [
    {
        path: '/config/exercise/exercise',
        name: 'config_exercise',
        component: ConfigExercise,
        props: {id: null, showMenu: true, otherTitle: 'exercise'},
        children: [
            {
                path: '/config/exercise/exercise/:id/:type',
                name: 'config_exercise_id',
                component: ConfigExercise,
                props: {id: null, showMenu: true, otherTitle: 'exercise'},
            },
            {
                path: '/config/exercise/exercise/:id/:view/:type',
                name: 'config_exercise_id_view',
                component: ConfigExercise,
                props: {id: null, view: true, showMenu: true, otherTitle: 'exercise'},
            }
        ]
    },
    {
        path: '/config/exercise/:from/:id/:type',
        name: 'config_exercise_modify',
        component: ConfigExercise,
        props: {id: null, type: null, from: null, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/config/exercise/:from/:id/:view/:type',
        name: 'config_exercise_view',
        component: ConfigExercise,
        props: {id: null, type: null, from: null, view: true, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/config/exercise-grouper/:from/:grouper_id/:from_id',
        name: 'config_exercise_from_grouper',
        component: ConfigExercise,
        props: {from: null, grouper_id: null, from_id: null, showMenu: false, background: true, otherTitle: 'exercise'},
        children: [
            {
                path: '/config/exercise-grouper/:from/:grouper_id/:from_id/:id/:type',
                name: 'config_exercise_from_grouper_id',
                component: ConfigExercise,
                props: {from: null, grouper_id: null, from_id: null, id: null, type: null, showMenu: false, background: true, otherTitle: 'exercise'}
            }
        ]
    },
    {
        path: '/config/exercise/:children',
        name: 'config_exercise_children',
        component: ConfigExercise,
        props: {children: null, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/config/exercise/:children/:id/:type',
        name: 'config_exercise_children_id_type',
        component: ConfigExercise,
        props: {children: null, id: null, type: null, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/search/exercise/:id/:view',
        name: 'config_exercise_id_view_search',
        component: ConfigExercise,
        props: {id: null, view: true, showMenu: false, background:true, otherTitle: 'exercise'},
    },
    {
        path: '/exercise/:parent_grouper_id/:parent_id/:element_id/:id/:version/:type',
        name: 'config_exercise_id_version_float',
        component: ConfigExercise,
        props: {
            parent_grouper_id: null,
            parent_id: null,
            element_id: null,
            id: null,
            version: false,
            type: true,
            showMenu: false,
            background:true,
            otherTitle: 'exercise'
        },
    },
    {
        path: '/support-search',
        name: 'support_search',
        component: SearchSupports,
        props: {showMenu: true}
    },
    {
        path: '/exercises-search',
        name: 'exercises_search',
        component: SearchExercises,
        props: {showMenu: true}
    },
    {
        path: '/preview-exercise/:id/:type',
        name: 'preview_exercise',
        component: Questionnaire,
        props: {id: null, type: null, showMenu: false, background:true, questionnaireBackground: true, otherTitle: 'exercise'},
    },
    {
        path: '/preview-grouper/:id/:type',
        name: 'preview_grouper',
        component: Questionnaire,
        props: {id: null, type: null, showMenu: false, background:true, questionnaireBackground: true, otherTitle: 'exercise'},
    },
];

export default routes;