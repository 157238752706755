var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":'_float_window'}},[_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'crm_support_incidence',
                title: _vm.$t('category'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_category"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_config_support_category_name',
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; text-align: right; min-width: 150px !important;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_config_support_category_name',
                                        name: 'category[name]',
                                        style: 'width: 100%',
                                        type: 'text',
                                        value: _vm.ConfigCategories.data && _vm.ConfigCategories.data.name ? _vm.ConfigCategories.data.name : '',
                                        required: true,
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigCategories.data && _vm.ConfigCategories.data.name ? _vm.ConfigCategories.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'category_flow',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_flow') + ':',
                                        text: _vm.$t('flow'),
                                        label: _vm.$t('flow'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: false,
                                    },"datainput":{
                                        id: 'category_flow',
                                        name: 'category[flow]'
                                    },"selected_input":_vm.ConfigCategories.data && _vm.ConfigCategories.data.flow ? _vm.ConfigCategories.data.flow : [],"feed":_vm.getFeeds.feed_support_workflows}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('flow') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigCategories.data && _vm.ConfigCategories.data.flow ? Object.values(_vm.ConfigCategories.data.flow).join() : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'category_priority',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_priority') + ':',
                                        text: _vm.$t('priority'),
                                        label: _vm.$t('priority'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: false,
                                    },"datainput":{
                                        id: 'category_priority',
                                        name: 'category[priority]'
                                    },"selected_input":_vm.ConfigCategories.data && _vm.ConfigCategories.data.priority ? _vm.ConfigCategories.data.priority : [],"feed":_vm.getFeeds.feed_support_priority}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigCategories.data && _vm.ConfigCategories.data.priority ? Object.values(_vm.ConfigCategories.data.priority).join() : ''))])]],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setCategorie()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('back')
                }},on:{"click":function($event){return _vm.back()}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }