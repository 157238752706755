<template>
    <div style="display: flex;">
        <div class="gh-content-global" style="width: 170px">
            <div style="width: 168px; height: 168px;">
                <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
            </div>
            <div class="other-image">
                <GhEasyUpload
                    :extrablock="{
                        id: 'example_other_image',
                        class: '',
                        name_hidden: 'example[other_image]',
                        id_hidden: 'example_other_image',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'example_other_image',
                        style: 'margin-right: -15px'
                    }"
                    :datainput="{
                        view: $root._route.params.view,
                        accept: '.png, .jpg, .jpeg',
                        text: $t(haveImage ? 'change_image' : 'attach_image'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: example.other_image.file_name,
                        file_path: example.other_image.file_path,
                        file_download: example.other_image.file_download,
                        required: false
                    }"
                />
            </div>
        </div>
        <div class="gh-content-global" style="width: 100%">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('code') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'example_code',
                            name: 'example[code]',
                            style: 'width: 200px',
                            value: example.code,
                            type: 'text'
                        }"
                        ref="RefCode"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.code}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'example_title',
                            name: 'example[name]',
                            style: 'min-width: 625px; width: 40%',
                            value: example.name,
                            type: 'text'
                        }"
                        ref="RefName"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.name}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('author'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                                                required: false,
                                                id: 'example_author',
                                                name: 'example[author]',
                                                style: 'min-width: 625px; width: 40%',
                                                value: example.author,
                                                type: 'text'
                                            }"
                        ref="RefAuthor"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('author') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.author}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('editor'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'example_editor',
                            name: 'example[editor]',
                            style: 'min-width: 625px; width: 40%',
                            value: example.editor,
                            type: 'text'
                        }"
                        ref="RefEditor"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('editor') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.editor}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhCalendar
                        :datalabel="{
                            id: 'label_example_publication_date',
                            text: $t('publication_date'),
                            style: 'width:150px; min-width:150px; text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_example_publication_date',
                            name: 'example[publication_date]',
                            value: example.publication_date
                        }"
                        ref="RefPublicationDate"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('publication_date') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.publication_date}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('references'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'example_references',
                            name: 'example[references]',
                            style: 'min-width: 625px; width: 40%',
                            value: example.references,
                            type: 'text'
                        }"
                        ref="RefReferences"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('references') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.references}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('link'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'example_link',
                            name: 'example[link]',
                            style: 'min-width: 625px; width: 40%',
                            value: example.link,
                            type: 'text'
                        }"
                        ref="RefLink"
                        @keyup="setLink()"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('link') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.link}}</label>
                </template>
            </div>
            <div class="gh-row-content" >
                <template v-if="!$root._route.params.view">
                    <GhTreeSelectorBlock
                        :dataBlock="{
                            type: 'radio',
                            id: 'label_topic',
                            label: $t('topic'),
                            title: $t('topic'),
                            text: $t('select_topic') + ':',
                            name: 'example[topic]',
                            id_value: 'value',
                            name_label: 'text',
                            child_key: 'children',
                            required: true,
                            checkparentdata: true,
                            style: '',
                            label_style: 'width: 149px',
                            popupStyle: 'min-width: 900px; width: 55%;'
                        }"
                        :selected_input="example.topic"
                        :items="getFeeds.feed_topic"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('topic') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.topic[0].text}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhTextarea
                        :datalabel="{
                            text: $t('description'),
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                        }"
                        :datainput="{
                            id: 'example_description',
                            name: 'example[description]',
                            value: example.description,
                            styles: 'padding-top: 2px;'
                        }"
                        ref="RefDescription"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{example.description}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <label class="gh_text_field-label T15_b" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('document') + ':'}}</label>
                <div>
                    <GhEasyUpload
                        :extrablock="{
                            id: 'example',
                            class: '',
                            name_hidden: 'example[document]',
                            id_hidden: 'example',
                            icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                        }"
                        :datalabel="{
                            id: 'label_import_block',
                            style: 'margin-right: -15px'
                        }"
                        :datainput="{
                            view: $root._route.params.view,
                            accept: '.pdf, .png, .jpg, .jpeg',
                            text: $t('attach') + ' (PDF/Img)',
                            show_image: false,
                            delete: false,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: example.attached.file_name,
                            file_path: example.attached.file_path,
                            file_download: example.attached.file_download,
                            required: example.link !== '' ? false : true
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    export default {
        name: "form_example",
        components: {
            GhCalendar,
            GhTreeSelectorBlock,
            GhInputTextField,
            GhTextarea,
            GhEasyUpload
        },
        computed: {
            ...mapState(['BaseCreationSupportMaterialExamples', 'getFeeds', 'UploadFiles'])
        },
        data(){
            return {
                haveData: false,
                haveImage: false,
                example: {
                    code: '',
                    name: '',
                    author: '',
                    editor: '',
                    publication_date: '',
                    references: '',
                    link: '',
                    topic: [],
                    description: '',
                    attached: {
                        image: '',
                        file_name: '',
                        file_path: '',
                        file_download: ''
                    },
                    other_image: {
                        image: '',
                        file_name: '',
                        file_path: '',
                        file_download: ''
                    }
                },
                image: ''
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                if (this.$root._route.params.id !== undefined) {
                    this.example.code = this.BaseCreationSupportMaterialExamples.data.code;
                    this.example.name = this.BaseCreationSupportMaterialExamples.data.name;
                    this.example.author = this.BaseCreationSupportMaterialExamples.data.author;
                    this.example.editor = this.BaseCreationSupportMaterialExamples.data.editor;
                    this.example.publication_date = this.BaseCreationSupportMaterialExamples.data.publication_date;
                    this.example.references = this.BaseCreationSupportMaterialExamples.data.references;
                    this.example.link = this.BaseCreationSupportMaterialExamples.data.link;
                    this.example.topic = this.BaseCreationSupportMaterialExamples.data.topic;
                    this.example.description = this.BaseCreationSupportMaterialExamples.data.description;
                    this.example.attached.image = this.BaseCreationSupportMaterialExamples.data.attached.image;
                    this.example.attached.file_name = this.BaseCreationSupportMaterialExamples.data.attached.file_name;
                    this.example.attached.file_path = this.BaseCreationSupportMaterialExamples.data.attached.file_path;
                    this.example.attached.file_download = this.BaseCreationSupportMaterialExamples.data.attached.file_download;
                    this.example.other_image.image = this.BaseCreationSupportMaterialExamples.data.other_image.image;
                    this.example.other_image.file_name = this.BaseCreationSupportMaterialExamples.data.other_image.file_name;
                    this.example.other_image.file_path = this.BaseCreationSupportMaterialExamples.data.other_image.file_path;
                    this.example.other_image.file_download = this.BaseCreationSupportMaterialExamples.data.other_image.file_download;
                }

                if (this.example.other_image.image !== '') {
                    this.image = this.example.other_image.image;
                } else if (this.example.attached.image !== '') {
                    this.image = this.example.attached.image;
                } else {
                    this.image = require('../../../../../assets/gh_new_dessign/no-image.svg');
                }

                this.haveImage = this.example.attached.image !== '' && this.example.other_image.image !== '';
                this.haveData = true;
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.setLink();
                this.image = data.image;
                this.haveImage = true;
            }
        },
        methods: {
            setLink(){
                this.example.code = this.$refs['RefCode'].datainput.value;
                this.example.name = this.$refs['RefName'].datainput.value;
                this.example.author = this.$refs['RefAuthor'].datainput.value;
                this.example.editor = this.$refs['RefEditor'].datainput.value;
                this.example.references = this.$refs['RefReferences'].datainput.value;
                this.example.link = this.$refs['RefLink'].datainput.value;
                this.example.description = this.$refs['RefDescription'].datainput.value;
            }
        }
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #example_other_image_easy_download_block{
        display: none !important;
    }
</style>