var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_401',
        title: _vm.$t('session_timeout') + '!',
        type: 'info',
        style: 'width: 400px; min-width: 400px; max-width: 400px;'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('div',[_c('div',{staticClass:"T15_b",staticStyle:{"margin-left":"35px"}},[_vm._v(" "+_vm._s(_vm.$t('you_have_been_logged_out_due_to_inactivity'))+" ")])])])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_session_timeout',
                text: _vm.$t('login'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.acceptLogin()}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }