var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'users',
        },"header":_vm.header,"data":_vm.getterListPermissionUsers.length === 0 ? [] : _vm.getterListPermissionUsers},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'permission_users_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [(itemProp.show)?[_c('router-link',{attrs:{"to":{name: 'permission_users_id', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_users_' + itemProp['id'],
                            text: _vm.$t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }}})],1),_c('GhAction',{attrs:{"dataction":{
                        id: 'btn_delete_users_' + itemProp['id'],
                        text: _vm.$t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','PermsUser', itemProp.id,{'name': itemProp.firstname, 'surnames': itemProp.surname, 'group': itemProp.group}, _vm.$t('delete_user'), _vm.$t('preparing_delete_user'), 'delete')}}})]:_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'permission_users'}}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_user',
                            text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,3011627427)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }