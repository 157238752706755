<template>
    <div :id="'_float_window'">
        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'crm_support_incidence',
                    title: $t('category'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_category" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_config_support_category_name',
                                            text: $t('name') + '*',
                                            style: 'width: 150px; text-align: right; min-width: 150px !important;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_config_support_category_name',
                                            name: 'category[name]',
                                            style: 'width: 100%',
                                            type: 'text',
                                            value: ConfigCategories.data && ConfigCategories.data.name ? ConfigCategories.data.name : '',
                                            required: true,
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigCategories.data && ConfigCategories.data.name ? ConfigCategories.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'category_flow',
                                            class: 'T15_b',
                                            subtitle: $t('select_flow') + ':',
                                            text: $t('flow'),
                                            label: $t('flow'),
                                            type: 'radio',
                                            columns: 3,
                                            style: '',
                                            required: false,
                                        }"
                                        :datainput="{
                                            id: 'category_flow',
                                            name: 'category[flow]'
                                        }"
                                        :selected_input="ConfigCategories.data && ConfigCategories.data.flow ? ConfigCategories.data.flow : []"
                                        :feed="getFeeds.feed_support_workflows"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('flow') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigCategories.data && ConfigCategories.data.flow ? Object.values(ConfigCategories.data.flow).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'category_priority',
                                            class: 'T15_b',
                                            subtitle: $t('select_priority') + ':',
                                            text: $t('priority'),
                                            label: $t('priority'),
                                            type: 'radio',
                                            columns: 3,
                                            style: '',
                                            required: false,
                                        }"
                                        :datainput="{
                                            id: 'category_priority',
                                            name: 'category[priority]'
                                        }"
                                        :selected_input="ConfigCategories.data && ConfigCategories.data.priority ? ConfigCategories.data.priority : []"
                                        :feed="getFeeds.feed_support_priority"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('priority') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ConfigCategories.data && ConfigCategories.data.priority ? Object.values(ConfigCategories.data.priority).join() : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view && haveData"
                    :datainput="{
                        id: 'btn_close',
                        class: 'T19 container-md_button_content',
                        text: $t('save')
                    }"
                    @click="setCategorie()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close',
                        class: 'T19 container-md_button_content',
                        text: $t('back')
                    }"
                    @click="back()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhButton from 'fe-gh-button-lib'
    import GhInputTextField from 'fe-gh-inputtextfield-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    export default {
        name: "category_template",
        components:{
            GhDataSelector,
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        data(){
            return{
                haveData:false,
            }
        },
        computed:{
            ...mapState(['ConfigCategories', 'getFeeds'])
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFeedWorkflow', '', {root: true});
                await this.$store.dispatch('getFeedSupportPriority', '', {root: true});
                this.$root._route.params.id ? await this.$store.dispatch('getCategoriesData', {id: this.$root._route.params.id}, {root: true}) : '';
                this.haveData = true;
            }
        },
        methods:{
            back(){
                this.router.go(-1);
                this.$store.dispatch('cleanCategoryData',{},{root: true});
            },
            setCategorie(){
                if(this.Global.checkRequired('form_category') == 'OK') {
                    const formData = new FormData(document.getElementById('form_category'));
                    this.$store.dispatch('setCategoryData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
        }
    }
</script>

<style>
    #category_flow_selector_label,#category_priority_selector_label{
        width: 150px;
        text-align: end;
    }
</style>