import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default {
    state: {
        indicators_data: '',
        searchData:[],
        resultTitle: 'No se ha encontrado ningún resultado',
        haveResult:false,
    },
    mutations: {
        loadActionIndicatorsData(state,payload){
            state.searchData = payload
        },
        loadIndicatorsData(state, payload) {
            state.indicators_data = payload;
        },
        loadActionIndicatorsDataResult(state, payload){
            state.resultTitle = payload;
        },
        loadActionIndicatorsDataHaveResult(state, payload){
            state.haveResult = payload;
        }
    },
    actions: {
        initialActionStateIndicators(state){
            state.commit("loadActionIndicatorsDataHaveResult", false);
        },
        destroyedActionIndicatorsResultData(state){
            state.commit("loadActionIndicatorsData", '');
            state.commit('loadActionIndicatorsDataResult', 'No se ha encontrado ningún resultado');
            state.commit("loadActionIndicatorsDataHaveResult", false);
        },
        async searchActionsIndicatorsData(state,items){
            let requestLogin = API_URL +'commercial-management/indicators/search';

            let params = {};
            let counts = {
                start_date: 0,
                end_date: 0,
                user: 0,
            };

            const keyMap = {
                'indicators[start_date][]': 'start_date',
                'indicators[end_date][]': 'end_date',
                'indicators[user][]': 'user',
            }

            items.formData.forEach((item) => {
                let _key = item[0];
                if (keyMap[_key] !== undefined) {
                    let countKey = keyMap[_key];
                    params[`indicators[${countKey}][${counts[countKey]}]`] = item[1];
                    counts[countKey]++;
                } else {
                    params[_key] = item[1];
                }
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    if(response.status == 200) {
                        state.commit("loadActionIndicatorsData", response.data.data);
                        state.commit("loadActionIndicatorsDataResult", response.data.data.length);
                        state.commit("loadActionIndicatorsDataHaveResult", true);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}