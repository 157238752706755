import ConfigGradeExperience from "@/view/config/evaluators/grade_experience/template/grade_experience.vue";

const routes = [
    {
        path: '/config/evaluator/grade-experience',
        name: 'config_grade_experience',
        component: ConfigGradeExperience,
        props: {id: null, showMenu: true, otherTitle: 'grade_experience'},
        children: [
            {
                path: '/config/evaluator/grade-experience/:id',
                name: 'config_grade_experience_id',
                component: ConfigGradeExperience,
                props: {id: null, showMenu: true, otherTitle: 'grade_experience'},
            },
            {
                path: '/config/evaluator/grade-experience/:id/:view',
                name: 'config_grade_experience_id_view',
                component: ConfigGradeExperience,
                props: {id: null, view: true, showMenu: true, otherTitle: 'grade_experience'},
            }
        ]
    }
];

export default routes;