<template>
    <div :style=" this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            v-if="this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != []"
            :extratable="{
                id:'result_search_data'
            }"
            :data="this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [] && this.$store.state.TabSearch.searchData.length !== undefined ? this.$store.state.TabSearch.searchData : []"
            :header="this.$store.state.TabSearch.idSubTabActive === 1 ? header_exercise : header_grouper">
                <template v-slot:actions="{itemProp}">
                    <GhAction
                        :dataction="{
                            id: 'see_'+itemProp['id'],
                            text: $t('see'),
                            icon: require('@/assets/gh_new_dessign/view.svg'),
                            item_prop: itemProp
                        }"
                        @click="TabSearch.idSubTabActive === 1 ? Global.windowOpen(Global.openSecondWindow('config_exercise_id_view_search',{id: itemProp.id, view: true}),'view_search') : Global.windowOpen(Global.openSecondWindow('view_grouper_id_view_search',{grouper: TabSearch.idSubTabActive, id: itemProp.id, view: true}),'view_search')"
                    />
                </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    export default {
        name: "result_search",
        components:{
            GhAction,
            GhTableCommon
        },
        computed:{
            ...mapState(['TabSearch']),
        },
        data(){
            return{
                header_exercise: [
                    {text: 'date', field: 'date', sorting: true, typeSort: 'date', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'questions', field: 'questions', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'answers', field: 'answers', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'groupers', field: 'groupers', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'exercises', field: 'exercises', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'topics', field: 'topics', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'thesauros', field: 'thesauros', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                header_grouper: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 25%;'},
                    {text: 'date', field: 'date', sorting: true, typeSort: 'code', style: 'min-width: 120px; max-width: 120px; width: 120px;'},
                    {text: 'impartation', field: 'impartation', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'groupers', field: 'groupers', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'exercises', field: 'exercises', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'topics', field: 'topics', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'thesauros', field: 'thesauros', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedResultData');
        },
    }
</script>