var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-3px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: true,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('tutorships')
        },"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')}}}),_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'tutor',
                title: _vm.$t('tutorships'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_tutor"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"149px","min-width":"149px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('petition') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"50%","min-width":"100px"}},[_vm._v(_vm._s(_vm.TabTutor.tutorData !== undefined ? _vm.TabTutor.tutorData.petition : ''))])]),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('answer') + '*',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    class: 'T15_b'
                                },"datainput":{
                                    id: 'tutor_answer',
                                    name: 'tutor[answer]',
                                    value: _vm.TabTutor.tutorData !== undefined ? _vm.TabTutor.tutorData.answer : '',
                                    required: true
                                }}})],1)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(_vm.TabTutor.tutorData !== undefined)?[(_vm.TabTutor.tutorData.finish)?_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_save_tutor',
                        class: 'T19 container-md_button_content',
                        text: _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.saveTutor(true)}}}):_vm._e()]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save_tutor',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.saveTutor(false)}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_tutor',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.windowClose()}}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }