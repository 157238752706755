<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_order',
            title: $t('items'),
            type: 'add',
            style: 'width: 35% !important; min-width:80%'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 90%; min-height: 8vh;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_popup_order">
                <div style="padding-left: 35px; overflow: auto; max-height: 250px;">
                    <div class="gh-content-global" :key="'KEY9' + refreshOrder">
                        <div class="gh-row-content">
                            <div>
                                <div class="T15_b" style="margin-right: 15px; width: 59px;">
                                    {{$t('order')}}
                                </div>
                                <div class="T15_b" style="width: 100%">
                                    {{$t('items')}}
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" v-for="(item, key) in getterItemsOrdered" :key="'KEY10' + key" style="margin-right: 5px;">
                            <div>
                                <div style="margin-right: 15px;">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: '',
                                            style: '',
                                            class: ''
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'order_item_' + item.id,
                                            name: item.id,
                                            style: 'width: 50px;',
                                            label_required: item.name,
                                            value: item.order
                                        }"
                                        :ref="'RefInputOrder' + item.id"
                                        @keyup="formatInput($event, 'RefInputOrder' + item.id)"
                                    />
                                </div>
                                <div style="width: 100%">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px;">{{item.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <template v-if="!$root._route.params.view">
                <GhButton
                    :datainput="{
                        id: 'btn_accept_popup_order',
                        text: $t('accept')
                    }"
                    @click="acceptOrder()"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_close_popup_order',
                    text: $t('close')
                }"
                @click="Global.closePopUp('popup_order'); refreshOrder++"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: "popup_order",
        components: {
            GhPopUp,
            GhInputTextField,
            GhButton
        },
        computed:{
            ...mapGetters(['getterItemsOrdered'])
        },
        data(){
            return{
                refreshOrder: 0
            }
        },
        methods: {
            async acceptOrder(){
                if(this.Global.checkRequired('form_popup_order') === 'OK') {
                    if(this.getterItemsOrdered.find((i) => i.condition) === undefined){
                        const formData = new FormData(document.getElementById('form_popup_order'));
                        await this.$store.dispatch('setReorderItems', {
                            grouper: this.$root._route.params.grouper,
                            id: this.$root._route.params.id,
                            formData: [...formData]
                        }, {root: true});
                    }else {
                        this.Global.openPopUp('popup_warning_reset_condition');
                    }
                }
            },
            formatInput(event, ref) {
                let value = event.target.value;
                value = value.replace(',', '.');
                value = value.replace(/[^0-9.]/g, '');
                value = value.replace(/(\.\.*)/g, '.');
                value = value.replace(',', '.');
                this.$refs[ref][0].datainput.value = value;
                this.$refs[ref][0].$forceUpdate();
            }
        }
    }
</script>