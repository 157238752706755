import ListSupportMaterial from "@/view/base_creation/support_material/list_support_material.vue";

import Bibliografia from './bibliography/route';
import Ejemplo from './examples/route';
import MaterialDeSoporte from './support_material/route';
import GoodPractices from './good_practices/route';
import Theory from './theory/route';

const route = [
    {
        path: '/base-creation/support-material',
        name: 'base_creation_support_material',
        component: ListSupportMaterial,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Bibliografia,
    ...Ejemplo,
    ...MaterialDeSoporte,
    ...GoodPractices,
    ...Theory
]

export default routes;