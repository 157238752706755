<template>
    <div v-if="haveData">
        <GhTableCommon
            :extratable="{
                id: 'flows_table',
            }"
            :header="header"
            :data="ConfigFlows.records_data.length == 0 ? [] : ConfigFlows.records_data"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'flow_id', params: {id: itemProp.id}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_conf_supp_flow',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }"
                    />
                </router-link>
                <GhAction v-if="itemProp.delete"
                    :dataction="{
                        id: 'delete_conf_supp_categories',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','Flow', itemProp.id,{'name': itemProp.name}, $t('delete_flow'), $t('preparing_delete_flow') + ':', 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'flow'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_flow',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "list_flows",
        data(){
            return{
                haveData: false,
                header: [
                    {text: 'flow', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 60%;'},
                    {text: 'category', field: 'category', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false, style: 'width:10%;'},
                ],
            }
        },
        components:{
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed:{
            ...mapState(['ConfigFlows'])
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getFlows', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>