var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('input',{attrs:{"type":"hidden","name":"search_supports[type]"},domProps:{"value":_vm.$root._route.params.idblock}}),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                text: _vm.$t('statement'),
                class: 'T15_b',
                styles: 'margin-left: 35px; padding-right: 10px;'
            },"datainput":{
                id: 'search_exercises_statement',
                name: 'search_exercises[statement]',
                value: '',
                required: false
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('div',{staticClass:"min-width-300"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'checkbox',
                    id: 'search_supports_exercise_type',
                    label: _vm.$t('type_of_exercise'),
                    title: _vm.$t('type_of_exercise'),
                    text: _vm.$t('select_type_of_exercise') + ':',
                    name: 'search_supports[type_of_exercise][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: false,
                    style: '',
                    label_style: 'white-space: nowrap;',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"selected_input":[],"items":_vm.getFeeds.feed_type}})],1),_c('div',{staticClass:"min-width-300"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'checkbox',
                    id: 'search_supports_topic',
                    label: _vm.$t('topic'),
                    title: _vm.$t('topic'),
                    text: _vm.$t('select_topic') + ':',
                    name: 'search_supports[topic][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: true,
                    checkparentdata: true,
                    style: '',
                    label_style: 'width: 149px',
                    popupStyle: 'min-width: 900px; width: 55%;'
                },"selected_input":[],"items":_vm.getFeeds.feed_topic}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }