var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
            id: 'companies_by_itinerary_' + _vm.id_itinerary
        },"header":_vm.header,"data":_vm.ItineraryAssign.companies},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(itemProp.assigned)?[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_assign_company_' + itemProp.id,
                        text: _vm.$t('modify'),
                        icon: require('../../../../../assets/gh_new_dessign/edit.svg'),
                    }},on:{"click":function($event){return _vm.setAssign(itemProp.id, itemProp.name)}}})]:[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_assign_company_' + itemProp.id,
                        text: _vm.$t('assign'),
                        icon: require('../../../../../assets/gh_new_dessign/tick.svg'),
                    }},on:{"click":function($event){return _vm.setAssign(itemProp.id, itemProp.name)}}})]]}}],null,false,382595776)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }