var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centered"},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"80%","min-width":"1024px"}},[_c('GhQuiz',{ref:"quiz",attrs:{"dataBlock":{
                id: 'quizz_test',
                text: _vm.TabTodo.data.title,
                icon: {
                    more: require('../../../assets/gh_new_dessign/arrowdown.svg'),
                    less: require('../../../assets/gh_new_dessign/arrowup.svg'),
                    info: require('../../../assets/gh_new_dessign/info-blue.svg')
                },
                name: 'quizz',
                actualPage: _vm.TabTodo.actual_page,
                answer_question:{
                    width: '600'
                }
            },"data":_vm.TabTodo.data},scopedSlots:_vm._u([{key:"evidenceSlot",fn:function({itemProp, keyProp}){return [_c('GhEasyUpload',{class:itemProp.readonly ? 'disabled_attach' : '',attrs:{"extrablock":{
                        id: 'quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                        class: '',
                        style: 'flex-direction: row !important;',
                        name_hidden: 'quizz[attach][' + itemProp.id + ']',
                        id_hidden: 'example',
                        icon_attach: ''
                    },"datalabel":{
                        id: 'label_quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                        style: ''
                    },"datainput":{
                        text: _vm.$t('attach_here_evidence'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: itemProp.file_name,
                        file_path: itemProp.file_path,
                        file_download: itemProp.file_download,
                        required: itemProp.evidence_required,
                    }}})]}},{key:"extraSlot",fn:function({itemProp, keyProp}){return [_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.$parent.$parent.stateQuestion = itemProp.state))]),_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.$parent.$parent.pendingChildrens = itemProp.pending_childrens !== undefined ? itemProp.pending_childrens : false))]),(_vm.TabTodo.support !== undefined && _vm.TabTodo.support[itemProp.id] !== undefined && _vm.TabTodo.support[itemProp.id].title !== undefined)?[_c('div',{staticClass:"section_line"},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.TabTodo.support[itemProp.id].title))])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"50%"}},_vm._l((_vm.TabTodo.support[itemProp.id].elements.slice(0, Math.ceil(_vm.TabTodo.support[itemProp.id].elements.length / 2))),function(element,keyElement){return _c('div',{key:'E1' + keyElement + keyProp,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(element.title))])]),_vm._l((element.materials),function(material,keyMaterial){return _c('div',{key:'M' + keyMaterial,staticClass:"gh-row-content",staticStyle:{"padding-left":"15px","padding-top":"5px"}},[(material.img !== undefined && material.img !== '')?[_c('img',{staticClass:"opimg",staticStyle:{"cursor":"pointer","margin-right":"5px","margin-top":"-2px","border-radius":"6px"},attrs:{"src":material.img,"height":"75"},on:{"click":function($event){return _vm.getSupportContent(material)}}}),_c('div',[_c('div',{staticStyle:{"display":"grid","align-content":"center"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"text-align":"left","margin-top":"-5px","margin-left":"10px"}},[_vm._v(_vm._s(material.title))]),_c('GhAction',{staticStyle:{"padding-left":"10px"},attrs:{"dataction":{
                                                        id: 'edit_config_responsible',
                                                        text: material.type === 'download' ? _vm.$t('download') : (material.type === 'link' ? _vm.$t('access_link') : _vm.$t('see')),
                                                        icon: '',
                                                    }},on:{"click":function($event){return _vm.getSupportContent(material)}}})],1)])]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"text-align":"left","margin-left":"0px"}},[_vm._v(_vm._s(material.title))]),_c('GhAction',{staticStyle:{"padding-left":"10px","margin-top":"-2px"},attrs:{"dataction":{
                                                id: 'edit_config_responsible',
                                                text: material.type === 'download' ? _vm.$t('download') : (material.type === 'link' ? _vm.$t('access_link') : _vm.$t('see')),
                                                icon: '',
                                            }},on:{"click":function($event){return _vm.getSupportContent(material)}}})]],2)})],2)}),0),_c('div',{staticStyle:{"width":"50%","padding-top":"12px"}},_vm._l((_vm.TabTodo.support[itemProp.id].elements.slice(Math.ceil(_vm.TabTodo.support[itemProp.id].elements.length / 2))),function(element,keyElement){return _c('div',{key:'E2' + keyElement + keyProp,staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"left"}},[_vm._v(_vm._s(element.title))])]),_vm._l((element.materials),function(material,keyMaterial){return _c('div',{key:'M' + keyMaterial,staticClass:"gh-row-content",staticStyle:{"padding-left":"15px","padding-top":"5px"}},[(material.img !== undefined && material.img !== '')?[_c('img',{staticClass:"opimg",staticStyle:{"cursor":"pointer","margin-right":"5px","margin-top":"-2px","border-radius":"6px"},attrs:{"src":material.img,"height":"75"},on:{"click":function($event){return _vm.getSupportContent(material)}}}),_c('div',[_c('div',{staticStyle:{"display":"grid","align-content":"center"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"text-align":"left","margin-top":"-5px","margin-left":"10px"}},[_vm._v(_vm._s(material.title))]),_c('GhAction',{staticStyle:{"padding-left":"10px"},attrs:{"dataction":{
                                                        id: 'edit_config_responsible',
                                                        text: material.type === 'download' ? _vm.$t('download') : (material.type === 'link' ? _vm.$t('access_link') : _vm.$t('see')),
                                                        icon: '',
                                                    }},on:{"click":function($event){return _vm.getSupportContent(material)}}})],1)])]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"text-align":"left","margin-left":"0px"}},[_vm._v(_vm._s(material.title))]),_c('GhAction',{staticStyle:{"padding-left":"10px","margin-top":"-2px"},attrs:{"dataction":{
                                                id: 'edit_config_responsible',
                                                text: material.type === 'download' ? _vm.$t('download') : (material.type === 'link' ? _vm.$t('access_link') : _vm.$t('see')),
                                                icon: '',
                                            }},on:{"click":function($event){return _vm.getSupportContent(material)}}})]],2)})],2)}),0)])]:_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }