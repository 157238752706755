<template>
    <div v-if="haveData == true">
        <GhNoDropSelector :datablock="{id: 'company_file',title: $t('company_file')}">
            <template v-slot:content>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.name}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('social_reason') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_social_reason}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 100px;">{{TabBusiness.records_data.cif}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('full_address') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.direction}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_country}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.email}}</label>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.telephone}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.text_activity}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_employees') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_num_employee}}</label>
                    </div>
                </div>
            </template>
        </GhNoDropSelector>

        <div v-for="(label, labelIndex) in TabBusiness.records_data.groups" :key="labelIndex + '_' + counter">
            <GhDropSelector
                :datablock="{
                    id: 'section_' + label.id,
                    title: $t(label.name),
                    second_title: $t('results'),
                    num_results: TabTodo.list_groups_todo['group_' + label.id].length,
                }"
            >
                <template v-slot:content>
                    <GhTableCommon
                        :extratable="{
                            id: 'table_validators',
                        }"
                        :header="header"
                        :data="TabTodo.list_groups_todo['group_' + label.id].length === 0 ? [] : TabTodo.list_groups_todo['group_' + label.id]"
                    >

                        <template v-slot:actions="{itemProp}">
                            <GhAction
                                :dataction="{
                                    id: 'edit_' + label.id + '_' + itemProp['id'],
                                    text: $t('modify'),
                                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                                }"
                                @click="relatedWithUser(label.id, label.name, itemProp);"
                            />
                            <GhAction
                                :dataction="{
                                    id: 'desasign_' + label.id + '_' + itemProp['id'],
                                    text: $t('delete'),
                                    icon: require('../../../assets/gh_new_dessign/trash.svg'),
                                }"
                                @click="deleteUser(itemProp, label);"
                            />
                        </template>
                        <template v-slot:footer>
                            <div class="centered">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_' + label.id,
                                        text: $t('add')
                                    }"
                                    @click="relatedWithUser(label.id, label.name);"
                                />
                            </div>
                        </template>

                    </GhTableCommon>
                </template>
            </GhDropSelector>
        </div>

        <GhPopup v-show="false"
            :generalConfiguration="{
                id: 'edit_users',
                title: $t('assign') + ' ' + labelPopup,
                type: 'add',
                style: 'width: 35%; min-width:400px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 30vh; min-height: 4vh; overflow-y: auto;'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <form id="form_edit_tabs_company" :key="refreshPopup">
                    <input type="hidden" id="assign_company_search" name="assign[company_id]" :value="$root._route.params.id">
                    <input type="hidden" id="assign_group_search" name="assign[group_id]" :value="group_id">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhMultipleSearch
                                :datalabel="{
                                    text: $t('user') + '*',
                                    style: 'min-width: 95px; width: 95px; text-align: right',
                                    class: 'T15_b',
                                    id: 'label_assign'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'assign_user',
                                    name:'assign[user][name]',
                                    nameS:'assign[user][surname]',
                                    nameId:'assign[user][id]',
                                    style: 'width: 40%',
                                    value: userName,
                                    valueId: userId,
                                    valueS: userSurname,
                                    placeholder: $t('name'),
                                    placeholderS: $t('surnames'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: dispatchUser
                                }"
                                ref="multisearchUser"
                            />
                        </div>
                  </div>
                  <input v-if="userTmp" type="hidden" id="assign_temporal_search" name="assign[temporal]" :value="userTmp">
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_add_user_popup',
                        text: $t('save'),
                        class: '',
                        style: ''
                    }"
                    @click="asignUser();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_user_popup',
                        text: $t('close'),
                    }"
                    @click="close();"
                />
            </template>
        </GhPopup>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="save();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
          </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhPopup from "fe-gh-popup-lib";
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    export default {
        name: "company_assing",
        components:{
            GhNoDropSelector,
            GhDropSelector,
            GhTableCommon,
            GhPopup,
            GhMultipleSearch,
            GhButton,
            GhAction,
        },
        computed: {
            ...mapState(['TabBusiness','TabTodo']),
        },
        data(){
            return{
                haveData: false,
                refreshPopup: 0,
                labelPopup: '',
                userName: '',
                userTmp: '',
                list:'',
                userSurname: '',
                userId: '',
                dispatchUser: 'getUserSearchByGroupAndCompany',
                validate_id: '',
                soporte_id: '',
                comercial_id: '',
                tutor_id: '',
                group_id: '',
                counter: 1,
                header:[
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: 'surnames', field: 'surnames', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: 'telephone', field: 'telephone', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: 'mail', field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getBusinessData', {id: this.$root._route.params.id}, {root: true});

                for (var i = 0; i < this.TabBusiness.records_data.groups.length; i++) {
                    await this.$store.dispatch('getListGroupsBusinessData', {
                        company_id: this.TabBusiness.records_data.client_id,
                        group_id: this.TabBusiness.records_data.groups[i].id
                    }, {root: true});
                }

                this.haveData = true;
            }
        },
        methods:{
            save(){
                this.$store.dispatch('setCompanyAssign', {'id':this.$root._route.params.id, 'params': this.TabBusiness.records_data},{root:true});
                this.$store.state.TabBusiness.records_data = '';
                this.back();
            },
            back(){
                this.$router.go(-1);
            },
            close(){
                this.userId = '';
                this.userName = '';
                this.userSurname = '';
                this.userTmp = '';
                this.Global.closePopUp('edit_users');
            },
            relatedWithUser(id_group, label, item){
                this.group_id = id_group;

                if(item === undefined){
                    this.userName = '';
                    this.userSurname = '';
                    this.userId = '';
                    this.userTmp = '';
                }else{
                    this.userName = item.name;
                    this.userSurname = item.surnames;
                    this.userId = item.id;
                    this.userTmp = item.temporal === undefined ? '' : item.temporal;
                }

                this.labelPopup = label;
                this.refreshPopup++;
                this.Global.openPopUp('edit_users');
            },
            async asignUser(){
                if(this.Global.checkRequired('form_edit_tabs_company') == 'OK') {
                    const formData = new FormData(document.getElementById('form_edit_tabs_company'));
                    await this.$store.dispatch(this.userId == '' ? 'assignTemporalUser' : 'modifyTemporalUser', {
                        'id': [...formData][3][1],
                        'list': this.group_id,
                        'temporal': this.userTmp,
                        'oldId': this.userId !== '' ? this.userId : '',
                    }, {root: true});

                    this.counter++;
                    this.Global.closePopUp('edit_users');
                }
            },
            deleteUser(itemProp, label){
                this.Global.deleteConfirm('delete_confirm','DesAssigmentTemporal', {'id':itemProp.id,'temporal':itemProp.temporal},{'name':itemProp.name,'surnames':itemProp.surnames}, this.$t('unassign_user'), this.$t('delete_unassign_user')+':', label.id);
            }
        }
    }
</script>
