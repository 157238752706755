<template>
    <div :style="dataTable && dataTable != [] ? 'margin-top: 12px;' : ''" v-if="haveData">
        <GhTableCommon
            :extratable="{ id:'result_action_historical_data'}"
            :data="dataTable"
            :header="header"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'actions_company_view', params: {id:itemProp.id,view:true}}">
                    <GhAction
                        :dataction="{
                            id: 'eject_support_pending_requests_groups',
                            text: $t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg'),
                        }"
                    />
                </router-link>
            </template>
        </GhTableCommon>
    </div>
</template>
<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from 'fe-gh-action-lib'
    import {mapState} from "vuex";
    export default {
        name: "list_result_action_indicators",
        props: ['dataTable', 'parentId', 'where'],
        computed:{
            ...mapState(['ActionIndicators'])
        },
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                header: [
                  {text: 'sections', field: 'sections', contentAlt: 'user', title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 474px; width: 474px;'},
                  {text: 'actual_num', field: 'actual_num', sorting: true,typeSort: 'string', style: 'width:21%;'},
                  {text: 'total_num', field: 'total_num', sorting: true, typeSort: 'string', style: 'width:21%;'},
                  {text: 'num_total_percent', field: 'num_total_percent', sorting: true, typeSort: 'string', style: 'width:21%;'},
                  {text: 'num_out_of_time', field: 'num_out_of_time', sorting: true,typeSort: 'string', style: 'width:17%;'},
                  {text: 'percent_out_of_time', field: 'percent_out_of_time', sorting: true,typeSort: 'string', style: 'width:15%;'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            // await this.$store.dispatch('searchActionsIndicatorsData', '', {root: true});
            this.haveData = true;
        },
    }
</script>
