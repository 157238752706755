import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";

Vue.use(vuex);

export default {
    state: {
        data: [],
        preferentialEvaluators: [],
        excludedEvaluators: [],
        refreshAssignationBase: false,
        refreshPreferentialEvaluators: false,
        refreshExcludedEvaluators: false,
    },
    mutations: {
        loadAssignationBase(state, payload){
            state.refreshAssignationBase = !state.refreshAssignationBase;
            state.data = payload;
        },
        loadPreferentialEvaluators(state, payload){
            state.refreshPreferentialEvaluators = !state.refreshPreferentialEvaluators;
            state.preferentialEvaluators = payload;
        },
        loadExcludedEvaluators(state, payload){
            state.refreshExcludedEvaluators = !state.refreshExcludedEvaluators;
            state.excludedEvaluators = payload;
        },
    },
    getters: {
        getterAssignationBase(state){
            const dummy = state.refreshAssignationBase; // eslint-disable-line no-unused-vars
            return state.data;
        },
        getterPreferentialEvaluators(state){
            const dummy = state.refreshPreferentialEvaluators; // eslint-disable-line no-unused-vars
            return state.preferentialEvaluators;
        },
        getterExcludedEvaluators(state){
            const dummy = state.refreshExcludedEvaluators; // eslint-disable-line no-unused-vars
            return state.excludedEvaluators;
        },
    },
    actions: {
        async getPreferentialEvaluators(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/preferential"
            ).then(
                response => {
                    state.commit('loadPreferentialEvaluators', response.data.data);
                }
            )
        },
        async getExcludedEvaluators(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/excluded"
            ).then(
                response => {
                    state.commit('loadExcludedEvaluators', response.data.data);
                }
            )
        },
        async getAssignationBase(state, item){
            await axios.get(
                API_URL + "assign/assignation_base/" + item.itinerary + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadAssignationBase', response.data.data);
                    }
                }
            )
        },
        async getValuesTypeEvaluation(state, item){
            return await axios.get(
                API_URL + "assign/assignation_base/type_evaluation/" + item.id
            ).then(
                response => {
                    return response;
                }
            )
        },
        async getGlobalScore(state, item){
            return await axios.get(
                API_URL + "assign/assignation_base/global_score/" + item.value_range + "/" + item.percentage
            ).then(
                response => {
                    return response;
                }
            )
        },
        async setAssignationBase(state, item){
            let requestLogin = API_URL + "assign/assignation_base/" + item.itinerary + "/" + item.id + "/" + item.type
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(item.type !== 'save') {
                            router.push({name: 'assignment'});
                        }else{
                            await this.dispatch('getAssignationBase', {
                                id: item.id,
                                itinerary: item.itinerary
                            });
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}
