var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'type_evaluation', title: _vm.$t('type_evaluation')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_type_evaluation"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.code))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.name))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.description))])]),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('punctuation'),
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'type_evaluation_punctuation',
                                    name: 'type_evaluation[punctuation]',
                                    style: 'min-width: 50px; width: 50px',
                                    value: _vm.ConfigTypeEvaluation.data.from,
                                    type: 'int'
                                },"maxlength":"4"}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('evaluator'),
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'type_evaluation_evaluator',
                                    name: 'type_evaluation[evaluator]',
                                    style: 'min-width: 50px; width: 50px',
                                    value: _vm.ConfigTypeEvaluation.data.evaluator,
                                    type: 'int'
                                },"maxlength":"4"}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('days'),
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: false,
                                    id: 'type_evaluation_days',
                                    name: 'type_evaluation[days]',
                                    style: 'min-width: 50px; width: 50px',
                                    value: _vm.ConfigTypeEvaluation.data.days,
                                    type: 'int'
                                },"maxlength":"4"}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('punctuation') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.score))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('evaluator')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.evaluator))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('days')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeEvaluation.data.days))])]],2)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitTypeEvaluation}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }