<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'recomended_programs_' + label.id,
                    title: $t(label['name']),
                    label: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import recomended_programs from './recomended_programs/recomended_programs.vue';

    export default {
        name: 'list_recomended_programs',
        components: {
            GhNoDropSelector
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'recomended_programs', component: recomended_programs, result: '', show: true , second_text: ''},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>

<style>
    [id^="recomended_programs_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }
</style>