<template>
    <div v-if="haveData">
        <div class="gh-row-content">
            <GhDataSelector
                :datablock="{
                    id: 'action_type_call_result',
                    class: 'T15_b',
                    subtitle: $t('select_call_result') + ':',
                    text: $t('call_result') + '*',
                    label: $t('call_result'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: '',
                    popupStyle: 'min-width: 900px;width: 55%;'
                }"
                :datainput="{
                    id: 'value_action_type_call_result',
                    name: 'action[call_result]'
                }"
                :feed="getFeeds.feed_state_phone_number"
                :selected_input="[]"
            />
        </div>
        <div class="gh-row-content" style="margin-top: -10px;">
            <GhTextarea
                :datalabel="{
                    text: $t('comment') + '*',
                    class: 'gh_text_field-label T15_b',
                    styles: 'text-align: right; width: 150px !important; min-width: 150px !important;',
                }"
                :datainput="{
                    id: 'action_comment',
                    name: 'action[comment]',
                    value: '',
                    styles: 'width: 100%',
                    required: true
                }"
                minlength="10"
                ref="RefComment"
            />
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapState} from "vuex";
    import GhTextarea from "fe-gh-textarea-lib";

    export default {
        name: 'action_call',
        components: {
            GhTextarea,
            GhDataSelector
        },
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getStatePhoneNumber', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>

<style>
    #action_type_call_result_selector_label{
        text-align: right;
        width: 150px;
    }
</style>