var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('type') + ': ' + (_vm.$root._route.params.view ? Object.values(_vm.ConfigTypeExercise.data.type[0])[1] : _vm.$parent.$parent.$refs['RefTypeExercise'].datasaved.text))+" ")])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('value_a'),
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: false,
                    id: 'value_type_exercise_value_1',
                    name: 'type_exercise[value_a]',
                    style: 'width: 100px',
                    value: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.value_a !== undefined ? _vm.ConfigTypeExercise.data.config_type.value_a : '',
                    type: 'int',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_a') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.value_a !== undefined ? _vm.ConfigTypeExercise.data.config_type.value_a : ''))])],(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('value_b'),
                    style: 'width: 160px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: false,
                    id: 'value_type_exercise_value_b',
                    name: 'type_exercise[value_b]',
                    style: 'width: 100px',
                    value: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.value_b !== undefined ? _vm.ConfigTypeExercise.data.config_type.value_b : '',
                    type: 'int',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_b') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.value_b !== undefined ? _vm.ConfigTypeExercise.data.config_type.value_b : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_type_exercise_conditions',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_conditions') + ':',
                    text: _vm.$t('conditions'),
                    label: _vm.$t('conditions'),
                    type: 'checkbox',
                    columns: 2,
                    style: '',
                    required: false
                },"datainput":{
                    id: 'value_type_exercise_conditions',
                    name: 'type_exercise[conditions][]'
                },"selected_input":_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.conditions ? _vm.ConfigTypeExercise.data.config_type.conditions : [],"feed":_vm.getFeeds.feed_condition}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('conditions') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type && _vm.ConfigTypeExercise.data.config_type.conditions ? Object.values(_vm.ConfigTypeExercise.data.config_type.conditions).join(', ') : ''))])])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"},style:(_vm.$root._route.params.view ? '' : 'margin-top: -10px;')},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[0] === 1,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[1] === 2,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('feedback'),
                style: 'width: 130px',
                id: 'label_type_exercise_feedback'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_feedback',
                value: 1,
                name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.feedback[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion',
                value: 1,
                name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                disabled: _vm.$root._route.params.view
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }