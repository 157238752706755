<template>
    <GhPopUp v-show="false"
        ref="RefPopUpPunctuation"
        :generalConfiguration="{
            id: 'popup_punctuation',
            title: $t('punctuation'),
            type: 'add',
            style: 'width: 80% !important; min-width:1024px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 90%; min-height: 8vh;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <template v-if="CreationGrouperElements.showPunctuation">
                <form id="form_percentage">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('maximum_base_score') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterPunctuations.max_base}}</label>
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('maximum_base_assigned'),
                                        style: '',
                                        class: ''
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'max_assigned_value',
                                        name: 'punctuation[max_assigned]',
                                        style: 'width: 35px;',
                                        value: maxAssigned
                                    }"
                                    ref="RefMaxAssigned"
                                    maxlength="3"
                                    @blur="calcPunctuation()"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px;">{{$t('maximum_base_assigned') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{maxAssigned}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" style="overflow-x: auto;max-height: 300px;" :key="'KEY11' + refreshTable">
                            <GhTableCommon
                                style="min-width: 500px;"
                                :extratable="{
                                    id: 'punctuations_table'
                                }"
                                :header="header"
                                :data="data"
                            >
                                <template v-slot:field_component="{itemProp,labelProp}">
                                    <GhInputTextField v-if="labelProp.field === 'percentage'"
                                        style="margin-left: -4px; margin-right: 4px;"
                                        :datalabel="{}"
                                        :datainput="{
                                            required: true,
                                            id: 'row_order_' + itemProp.id,
                                            name: 'punctuation[percentage][' + itemProp.id + ']',
                                            value: itemProp.percentage,
                                            type: 'int',
                                            limitNumMin: 0,
                                            limitNumMax: 100,
                                            label_required: $t('percentage') + '*'
                                        }"
                                        :ref="'RefInputPercentage' + itemProp.id"
                                        @blur="calcPunctuation(itemProp.id)"
                                    />
                                </template>
                            </GhTableCommon>
                        </div>
                    </div>
                </form>
            </template>
            <template v-else-if="!CreationGrouperElements.showPunctuation">
                <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                    <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </div>
            </template>
        </template>
        <template v-slot:footer>
            <template v-if="!$root._route.params.view">
                <GhButton
                    :datainput="{
                        id: 'btn_accept_popup_punctuation',
                        text: $t('accept')
                    }"
                    @click="acceptPunctuation"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'btn_close_popup_punctuation',
                    text: $t('close')
                }"
                @click="Global.closePopUp('popup_punctuation');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTableCommon from "fe-gh-table-common-lib";

    export default {
        name: 'view_punctuation',
        components: {
            GhTableCommon,
            GhInputTextField,
            GhButton,
            GhPopUp
        },
        computed: {
            ...mapGetters(['getterPunctuations']),
            ...mapState(['CreationGrouperElements'])
        },
        data(){
            return {
                data: [],
                maxAssigned: 0,
                refreshTable: 0,
                header: [
                    {text: '%', field: 'percentage', field_component: true, style: 'min-width: 50px; max-width: 50px; width: 50px;'},
                    {text: 'base_s', field: 'max_base', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: 'base_a', field: 'max_assigned', style: 'min-width: 80px; max-width: 80px; width: 80px;'},
                    {text: 'grouper', field: 'grouper', style: 'min-width: 100px; max-width: 100px; width: 100%;'}
                ]
            }
        },
        watch: {
            'getterPunctuations'(data){
                this.data = data.data;
                this.maxAssigned = data.max_assigned;
            }
        },
        methods: {
            calcPunctuation(id){
                if(id !== undefined) {
                    this.data.forEach((i, k) => {
                        if (i.id === id) {
                            this.data[k].percentage = this.$refs['RefInputPercentage' + id].datainput.value;
                            this.data[k].max_assigned = (this.data[k].percentage / 100) * this.$refs['RefMaxAssigned'].datainput.value;
                        }
                    });
                }else{
                    this.maxAssigned = this.$refs['RefMaxAssigned'].datainput.value;
                    this.data.forEach((i, k) => {
                        this.data[k].max_assigned = (i.percentage / 100) * this.$refs['RefMaxAssigned'].datainput.value;
                    });
                }

                this.refreshTable++;
            },
            async acceptPunctuation(){
                if(this.Global.checkRequired('form_percentage') === 'OK') {
                    let countPercentage = 0;
                    this.data.forEach((i) => {
                        countPercentage += parseInt(i.percentage);
                    });
                    if(countPercentage <= 100) {
                        const formData = new FormData(document.getElementById('form_percentage'));
                        await this.$store.dispatch('setPunctuation', {
                            grouper: this.$root._route.params.grouper,
                            id: this.$root._route.params.id,
                            formData: [...formData]
                        }, {root: true}).then((response) => {
                            if (response.status === 200) {
                                this.Global.closePopUp('popup_punctuation');
                            }
                        });
                    }else{
                        this.Global.state.alert_required.requireds = [
                            this.$t('percentage_exceeds_100')
                        ];
                        this.Global.openPopUp('warning_confirm');
                    }
                }
            }
        }
    }
</script>