<template>
    <div v-if="haveData == true">
        <form id="form_search_actions_indicators" :key="refresh">
            <div class="gh-content-global">
                <component v-bind:is="ViewIndicators" />
                <div class="gh-row-content" style="">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'indicators[criterio]',
                            style: 'margin-left:48px; cursor:pointer;',
                            selected:true,
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            name: 'indicators[criterio]',
                            style: 'cursor:pointer;',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('accept')}" @click="search()" />
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()" />
        </div>
    </div>
</template>
<script>
    import ViewIndicators from "@/view/actions/indicators/search_content/search_action_indicators.vue";
    import GhChecks from "fe-gh-checks-lib";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: "list_actions_historical",
        data(){
            return{
                ViewIndicators:ViewIndicators,
                refresh:0,
                haveData:false,
            }
        },
        components:{
            GhChecks,
            GhButton
        },
        async beforeMount(){
            this.haveData = true;
        },
        methods: {
            async search(){
                const formData = new FormData(document.getElementById('form_search_actions_indicators'));
                await this.$store.dispatch('searchActionsIndicatorsData',{formData: [...formData]},{root:true});
            },
            clean(){
                this.$store.dispatch('destroyedActionIndicatorsResultData','',{root:true});
                document.getElementById("form_search_actions_indicators").reset();
                this.refresh++;
            },
        }
    }
</script>
