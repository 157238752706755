import User from "@/view/perms/users/users/template/users.vue";

const route = [
    {
        path: '/perms/user',
        name: 'permission_users',
        component: User,
        props: {id: null, showMenu: true, otherTitle: 'user'},
        children: [
            {
                path: '/perms/user/:id',
                name: 'permission_users_id',
                component: User,
                props: {id: null, showMenu: true, otherTitle: 'user'},
            },
            {
                path: '/perms/user/:id/:view',
                name: 'permission_users_id_view',
                component: User,
                props: {id: null, view: true, showMenu: true, otherTitle: 'user'},
            },
        ]
    },
];

const routes = [
    ...route,
]

export default routes;