var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticClass:"T14 link table-header"},[_vm._v(_vm._s(_vm.$t('perms')))]),_c('form',{attrs:{"id":"form_associated_users"}},[_c('div',{key:_vm.refreshAssociatedUsers,staticStyle:{"margin-right":"10px"}},_vm._l((_vm.getFeeds.feed_perms),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content",staticStyle:{"padding-bottom":"3px !important","width":"100%","flex-wrap":"wrap","padding-left":"10px","padding-top":"2px"},style:(index % 2 ? '' : 'background-color: #d9edfc;')},[_c('div',_vm._l((item),function(itemChildren,indexChildren){return _c('div',{key:indexChildren,staticStyle:{"width":"calc((100% - 240px) - (((100% - 280px) * 12) / 15))"},attrs:{"title":itemChildren.description}},[_c('GhChecks',{attrs:{"datalabel":{
                                text: itemChildren.name,
                                style: '',
                                class: '',
                                id: ''
                            },"datainput":{
                                disabled: _vm.$root._route.params.view,
                                required: false,
                                selected: '',
                                type: 'checkbox',
                                id: itemChildren.id,
                                value: itemChildren.id,
                                name: 'associated[perms][]'
                            }}})],1)}),0)])}),0)]),_c('div',{staticStyle:{"background-color":"#c5d1df","height":"46px"}},[_c('div',{staticClass:"centered",staticStyle:{"padding-top":"9px"}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept',
                    text: _vm.$t('accept')
                }},on:{"click":_vm.acceptAssociatedUsers}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_clean',
                    text: _vm.$t('clean')
                }},on:{"click":_vm.cleanAssociatedUsers}})],1)]),(_vm.getterUsersAssociated !== undefined && _vm.getterUsersAssociated.users !== undefined)?[_c('GhTableCommon',{staticStyle:{"margin-top":"30px"},attrs:{"extratable":{
                id: 'profiles',
            },"header":_vm.header,"data":_vm.getterUsersAssociated.users}}),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groups') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterUsersAssociated.groups))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('profiles') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterUsersAssociated.profiles))])])]:_vm._e()],2):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }