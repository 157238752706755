<template>
    <div v-if="haveData">
        <GhNoDropSelector :datablock="{id: 'new_edit_group',title: $t('group')}">
            <template v-slot:content>
                <form id="group_perms">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'name',
                                        name: 'group[name]',
                                        style: 'width: 40%',
                                        value: getterGroups.name,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('name')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterGroups && getterGroups.name ? getterGroups.name : ''}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" style="margin-bottom: 15px;">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        class: 'T15_b',
                                        styles: 'min-width: 150px !important; width: 150px !important; text-align: right; margin-right: 11px;',
                                    }"
                                    :datainput="{
                                        id: 'description',
                                        name: 'group[description]',
                                        required: false,
                                        value: getterGroups.description,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('description')}}</label>
                                <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterGroups && getterGroups.description ? getterGroups.description : ''}}</label>
                            </template>
                        </div>
                        <div style="display: flex; width: 100%">
                            <div class="T15_b" style="width: 151px;text-align: right;margin-right: 11px;">
                                {{$t('perms')}}
                            </div>
                            <div style="display: block; width: 82%">
                                <div class="gh-row-content" style="padding-bottom: 3px !important; width: 100%;flex-wrap: wrap;padding-left: 5px;padding-top: 2px;" v-for="(item,index) in getFeeds.feed_perms" :key="index" :style="index%2 ? '' : 'background-color: #d9edfc;'">
                                    <div>
                                        <div style="width: calc((100% - 240px) - (((100% - 280px) * 12) / 15))" v-for="(itemChildren,indexChildren) in item" :key="indexChildren" :title="itemChildren.description">
                                            <GhChecks
                                                :datalabel="{text:itemChildren.name,style:'',class:'',id:''}"
                                                :datainput="{disabled:$root._route.params.view ? true : false,required:false,selected:getterGroups.length === 0 ? false : getterGroups.perms.includes(itemChildren.id),type:'checkbox',id:itemChildren.id,value:itemChildren.id,name:'group[perms][]'}"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="!getterGroups.predefined">
                            <div class="gh-row-content" style="margin-top: 15px; height: 18px;">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_profiles',
                                            class: 'T15_b',
                                            subtitle: $t('select_profile') + ':',
                                            text: $t('profiles'),
                                            label: $t('profiles'),
                                            type: 'checkbox',
                                            columns: 3,
                                            required: false,
                                            style: '',
                                            popupStyle: 'min-width: 900px;width: 55%;',
                                        }"
                                        :datainput="{
                                            id: 'profiles',
                                            name: 'group[profiles][]'
                                        }"
                                        :feed="getFeeds.feed_profiles"
                                        :selected_input="getterGroups && getterGroups.profiles ? getterGroups.profiles : []"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('profiles') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 40%;">{{getterGroups && getterGroups.profiles ? Object.values(getterGroups.profiles).join() : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhSearchWindow
                                        :dataBlock="{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'result_search_data_users',
                                            nameStore: 'PermsUsersUsers',
                                            imagelocation: require('../../../../../assets/gh_new_dessign/floating.svg'),
                                            id: 'users',
                                            text: $t('users'),
                                            textlocation: $t('add_users'),
                                            actionresult: 'loadResultSearchUsers',
                                            nameResult: 'group[users]',
                                            supportPermision: true
                                        }"
                                        :mainSelector="{
                                            name: 'Formsearchusers',
                                            formId: 'group',
                                            actionsearch: 'getSearchUsers'
                                        }"
                                        :tableResolt="{
                                            header: header,
                                            data: getterGroups && getterGroups.users && getterGroups.users.summary ? getterGroups.users.summary : [],
                                        }"
                                        :tableSelected="{
                                            headersummary: headersummary,
                                            selected: getterGroups && getterGroups.users && getterGroups.users.summary ? getterGroups.users.summary : []
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style=" margin-right: 10px; width: 150px; text-align: right;">{{ $t('users') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style=" width: 40%;">{{summary}}</label>
                                </template>
                            </div>
                        </template>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div  class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="submitPermsGroup();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: "new_edit",
        components:{
            GhNoDropSelector,
            GhTextarea,
            GhInputTextField,
            GhChecks,
            GhButton,
            GhDataSelector,
            GhSearchWindow
        },
        computed: {
            ...mapState(['getFeeds','PermsGroupGroups']),
            ...mapGetters(['getterGroups'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: this.$t('people'), sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'first_name', sorting: true,typeSort: 'string', style: 'max-width: 60px; width: 50%;'},
                    {text: this.$t('surname'), field: 'last_name', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                ],
                headersummary: {
                    0: 'first_name',
                    1: 'last_name'
                },
                summary: ''
            }
        },
        async mounted(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getPerms', '', {root: true});
                await this.$store.dispatch('getProfiles', '', {root: true});
                await this.$store.dispatch('getPermsGroup', {id: this.$root._route.params.id}, {root: true});

                if (this.getterGroups !== undefined && this.getterGroups.users !== undefined) {
                    this.getterGroups.users.summary.forEach((item) => {
                        this.summary += item.first_name + ' ' + item.last_name + ',';
                    });

                    this.summary = this.summary.slice(0, -1);
                }
                this.haveData = true;
            }
        },
        methods:{
            async submitPermsGroup(){
                if(this.Global.checkRequired('group_perms') === 'OK') {
                    const formData = new FormData(document.getElementById('group_perms'));
                    this.$store.dispatch('setPermsGroup', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            back(){
                this.$router.go(-1);
            }
        }
    }
</script>

<style>
    #label_profiles_selector_label{
        width: 150px;
        text-align: end;
    }
</style>
