var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'seal',title: _vm.$t('stamp')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_seal"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                    id: 'seal_image',
                                    class: '',
                                    name_hidden: 'seal[image]',
                                    id_hidden: 'seal_image',
                                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: 'seal_image',
                                    style: 'margin-right: -15px'
                                },"datainput":{
                                    view: _vm.$root._route.params.view,
                                    accept: '.png, .jpg, .jpeg',
                                    text: _vm.$t(_vm.haveImage ? 'change_image' : 'attach_image'),
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: _vm.ConfigSeals.data.file_name,
                                    file_path: _vm.ConfigSeals.data.file_path,
                                    file_download: _vm.ConfigSeals.data.file_download,
                                    required: false
                                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"85%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'seal_code',
                                        name: 'seal[code]',
                                        style: 'width: 200px',
                                        value: _vm.seals.code,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.seals.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'seal_name',
                                        name: 'seal[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: _vm.seals.name,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.seals.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;'
                                    },"datainput":{
                                        id: 'seal_description',
                                        name: 'seal[description]',
                                        value: _vm.seals.description
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.seals.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                        type: 'radio',
                                        id: 'label_topic',
                                        label: _vm.$t('topic'),
                                        title: _vm.$t('topic'),
                                        text: _vm.$t('select_topic') + ':',
                                        name: 'seal[topic]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: true,
                                        style: '',
                                        label_style: 'width: 149px',
                                        popupStyle: 'min-width: 900px; width: 55%;'
                                    },"selected_input":_vm.ConfigSeals.data && _vm.ConfigSeals.data.topic ? _vm.ConfigSeals.data.topic : [],"items":_vm.getFeeds.feed_topic}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('topic')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"40%"}},[_vm._v(_vm._s(_vm.ConfigSeals.data && _vm.ConfigSeals.data.topic ? _vm.ConfigSeals.data.topic[0].text : ''))])]],2)])])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitSeal}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }