var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('div',{staticClass:"card-container"},[_vm._l((_vm.ToPay.payments),function(itemCard,keyCard){return [_c('div',{key:'KEY'+ keyCard},[_c('GhCECard',{key:'CARD' + keyCard,staticStyle:{"cursor":"default"},attrs:{"block":{
                            image: '',
                            progressBar: true,
                            colors: [
                                '#0099CC',
                                '#005392'
                            ],
                            colorsHover: [
                                '#B7C0C5',
                                '#B7C0C5'
                            ]
                        },"data":itemCard}})],1)]})],2)]),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"20px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_contract_elements',
                text: _vm.$t('contract_elements')
            }},on:{"click":_vm.openContractElement}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('back')}},on:{"click":function($event){return _vm.back();}}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }