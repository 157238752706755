import ConfigSupportMaterial from "@/view/base_creation/support_material/support_material/template/support_material.vue";

const routes = [
    {
        path: '/config/creation/support_material',
        name: 'config_support_material',
        component: ConfigSupportMaterial,
        props: {id: null, showMenu: true, otherTitle: 'support_material_2'},
        children: [
            {
                path: '/config/creation/support_material/:id',
                name: 'config_support_material_id',
                component: ConfigSupportMaterial,
                props: {id: null, showMenu: true, otherTitle: 'support_material_2'},
            },
            {
                path: '/config/creation/support_material/:id/:view',
                name: 'config_support_material_id_view',
                component: ConfigSupportMaterial,
                props: {id: null, view: true, showMenu: true, otherTitle: 'support_material_2'},
            }
        ]
    },
    {
        path: '/config/creation/support_material/:id/:view/:windowed',
        name: 'config_support_material_id_view_windowed',
        component: ConfigSupportMaterial,
        props: {id: null, view: true, windowed: true, showMenu: false, background: true, otherTitle: 'support_material_2'},
    }
];

export default routes;