import ListUsers from "@/view/perms/users/list_users.vue";
import User from './users/route';

const route = [
    {
        path: '/perms_user',
        name: 'perms_user',
        component: ListUsers,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...User
]

export default routes;