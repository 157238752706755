<template>
    <div v-if="haveData">
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'support_material',
            }"
            :header="header"
            :data="BaseCreationSupportMaterialSupportMaterial.records_data.length === 0 ? [] : BaseCreationSupportMaterialSupportMaterial.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_support_material_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'config_support_material_id', params: {id: itemProp.id}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_config_support_material',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg')
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_config_support_material',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg')
                    }"
                    @click="Global.deleteConfirm('delete_confirm','SupportMaterial', itemProp.id,{'code': itemProp.code, 'name': itemProp.name, 'author': itemProp.author, 'editor': itemProp.editor, 'publication_date': itemProp.publication_date, 'references': itemProp.references, 'topic': itemProp.topic}, $t('delete_example'), $t('preparing_delete_example'), 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'config_support_material'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_config_support_material',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
    </div>
    <div v-else-if="!haveData">
        <div class="centered" style="padding-bottom: 10px;">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
        </div>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_support_material",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['BaseCreationSupportMaterialSupportMaterial'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: 'code', field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'author', field: 'author', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'editor', field: 'editor', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'publication_date', field: 'publication_date', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'references', field: 'references', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: 'topic', field: 'topic', sorting: true, typeSort: 'string', style: 'min-width: 85px; max-width: 85px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getSupportMaterials', '', {root: true});
                this.haveData = true;
            }
        }
    }
</script>