<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_warning_reset_condition',
            title: $t('warning'),
            type: 'warning',
            style: 'width: 28%; min-width: 430px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global">
                <div class="gh-row-content">
                    <div>
                        <div class="T15_b">
                            {{Object.entries(CreationGrouperElements.pending_confirm_delete).length !== 0 ? $t('delete_item_removes_all_defined_conditions') : $t('performing_new_sort_removes_all_defined_conditions')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="centered">
                <div class="T15">{{$t('are_you_sure')}}</div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'button_accept_popup_warning_reset_condition',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="acceptWarning()"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_popup_warning_reset_condition',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_warning_reset_condition'); cleanPendingConfirmDelete()"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapState} from "vuex";

    export default {
        name: 'popup_warning_reset_condition',
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapState(['CreationGrouperElements'])
        },
        methods: {
            async acceptWarning(){
                if(Object.entries(this.CreationGrouperElements.pending_confirm_delete).length !== 0){
                    await this.$store.dispatch('acceptRemoveAssociateItem', this.CreationGrouperElements.pending_confirm_delete, {root: true});
                    this.Global.closePopUp('popup_warning_reset_condition');
                }else {
                    const formData = new FormData(document.getElementById('form_popup_order'));
                    await this.$store.dispatch('setReorderItems', {
                        grouper: this.$root._route.params.grouper,
                        id: this.$root._route.params.id,
                        formData: [...formData]
                    }, {root: true});
                }
            },
            cleanPendingConfirmDelete(){
                this.$store.dispatch('clearPendingConfirmDelete', '', {root: true});
            }
        }
    }
</script>