<template>
    <div>
        <div class="gh_position-line" style="min-width: 1024px;">
            <div class="gh-content-questionnaire" style="padding-left: 80px; padding-right: 80px;">
                <div class="gh-row-content-without-padding">
                    <div class="ellipsis" style="width: 100%; display: flex; margin-right: 60px;" :title="titleBreadCrum">
                        <div v-for="(item, key) in tabs" :key="'BREAD_CRUMB' + key">
                            <div style="display: flex;">
                                <template v-if="key !== 0">
                                    <div class="gh_margin-arrow">
                                        <img height="12" width="10" :src="block.svg.arrowRight" alt="" style="margin-top: 10px;">
                                    </div>
                                </template>
                                <div class="bread-crumb color-light-blue T-header" style="margin-top: -4px;width: max-content;" @click="showIndex ? openParent() : openBreadCrumbIndex(),resetBreadCrum(),resetTitleBreadCrum()">{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <template v-if="!showMap">
                        <slot name="contentInsideMenu"/>
                    </template>
                    <template v-else-if="showMap">
                        <div class="cursor-pointer" style="margin-top: 4px;z-index: 9999" @click="closeMap()">
                            <img :src="block.svg.close" alt="">
                        </div>
                    </template>
                </div>
                <div class="gh-padding-index-questionnaire gh_position-index" v-if="showIndex" :key="'INDEX_REFRESHER' + refreshIndex" ref="indexContainer">
                    <div class="overflow-index">
                        <div class="margin-index-questionnaire">
                            <div v-for="(itemIndex, keyIndex) in index" :key="'INDEX' + keyIndex + refreshIndex" v-show="itemIndex.show || itemIndex.level === 0">
                                <div :style="itemIndex.level !== 0 ? 'display: flex; padding-left: ' + (itemIndex.level * 25) + 'px;' : 'display: flex; padding-left: 5px;'">
                                    <div style="display: flex; cursor: pointer; width: 100%;">
                                        <template v-if="itemIndex.parent_id === null && itemIndex.have_childrens">
                                            <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                                <img height="18" width="18" style="margin-top: 5px;" :src="itemIndex.active ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                            </div>
                                        </template>
                                        <div style="display: flex; width: 100%;">
                                            <template v-if="itemIndex.parent_id !== null">
                                                <div class="gh-pointer" :style="'color:' + block.pointer.colors[itemIndex.state]" :title="$t(block.pointer.title[itemIndex.state])">
                                                    ⬤
                                                </div>
                                            </template>
                                            <span @click="checkQuestion(itemIndex); $emit('setQuestion', itemIndex, keyIndex)" :class="itemIndex.parent_id !== null ? itemIndex.current ? 'T-subtitle-second' : 'T-text' : 'T-subtitle'" class="ellipsis" style="display: table-cell; margin-top: 2px; white-space: normal; padding-bottom: 8px;" :title="itemIndex.name.replace(/<[^>]*>/g, '')" v-html="itemIndex.name" :style="itemIndex.parent_id !== null ? itemIndex.current ? '' : '' : 'color: #1B82C5; width: 100% !important;'"/>
                                            <template v-if="itemIndex.parent_id !== null && itemIndex.have_childrens">
                                                <div class="gh_margin-arrow" @click="showOrHideIndex(itemIndex, keyIndex)">
                                                    <img height="18" width="18" style="margin-top: 3px;" :src="itemIndex.active ? block.svg.arrowUp : block.svg.arrowDown" alt="">
                                                </div>
                                            </template>
                                            <template v-if="itemIndex.completed !== null">
                                                <span class="T-small-text gh_margin-spaces" style="display: flex; white-space: nowrap; margin-top: 6px; margin-left: 10px;">{{itemIndex.completed + ' ' + $t('completed').toLowerCase()}}</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gh-padding-index-questionnaire gh_position-map" v-if="showMap" :key="'MAP' + refreshMap" ref="mapContainer">
                    <slot name="extraContent"/>
                </div>
            </div>
        </div>
        <div class="gh-content-global-questionnaire" :style="dataBody.evaluator ? 'padding-top: 20px' : ''">
            <template v-if="dataBody.evaluator">
                <div class="flex-direction-reverse">
                    <slot name="buttonEvaluator"/>
                </div>
            </template>
            <template v-if="dataBody !== undefined && dataBody.visible !== undefined && dataBody.visible.actions">
                <div class="flex-direction-reverse">
                    <slot name="bodyHeaderActions"/>
                </div>
            </template>
            <template v-if="dataBody !== undefined && dataBody.visible !== undefined && dataBody.visible.actions && dataBody.is_structural">
                <div class="separator-line"></div>
            </template>
            <template v-if="dataBody.is_structural">
                <div style="width: 100%;">
                    <div class="card-container" :style="dataBody !== undefined && dataBody.visible !== undefined && dataBody.visible.actions ? 'padding-top: 40px;' : 'padding-top: 10px;'">
                        <template v-for="(itemCard, keyCard) in dataBody.cards">
                            <div @click="cardUpdateBreadCrum(itemCard);$emit('setQuestion', itemCard, keyCard);" :key="'KEY'+ keyCard">
                                <GhCECard
                                    :key="'CARD' + keyCard"
                                    :block="{
                                        image: block.svg.noImg,
                                        progressBar: true,
                                        colors: [
                                            '#0099CC',
                                            '#005392'
                                        ],
                                        colorsHover: [
                                            '#B7C0C5',
                                            '#B7C0C5'
                                        ]
                                    }"
                                    :data="itemCard"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="!dataBody.is_structural && dataBody.length !== 0">
                <div class="question-body" :style="dataBody !== undefined && dataBody.visible !== undefined && dataBody.visible.actions ? 'margin-top: 20px;' : 'margin-top: 10px;'" :key="'QUESTIONNARY' + refreshContent">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.levels[dataBody.level > 5 ? 5 : dataBody.level]" alt="">
                        </div>
                        <div class="T-subtitle" v-html="dataBody.statement" style="margin-left: 10px; margin-top: 2px;"></div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <form id="form_result_questionnaire">
                        <div class="gh-answer-content">
                            <template v-if="dataBody.type === 1">
                                <template v-if="dataBody.evaluator">
                                    <label class="gh_text_field-label T-text">{{dataBody.answer}}</label>
                                </template>
                                <template v-else>
                                    <GhTextarea
                                        :datalabel="{
                                            text: '',
                                            class: '',
                                            styles: '',
                                        }"
                                        :datainput="{
                                            id: 'answer',
                                            name: 'answer[answer]',
                                            required: false,
                                            value: dataBody.answer,
                                            class: 'padding-element-questionnary T-text'
                                        }"
                                        :disabled="dataBody.disabled || $root._route.params.view"
                                        maxlength="4000"
                                        ref="RefTextarea"
                                        @keyup="setTextarea()"
                                    />
                                </template>
                            </template>
                            <template v-if="dataBody.type === 2">
                                <template v-if="dataBody.type_answer === 1">
                                    <div>
                                        <GhCECheckSelectBox
                                            :block="{
                                                type: 'radio',
                                                name: 'answer[answer]'
                                            }"
                                            :disabled="dataBody.evaluator || dataBody.disabled || $root._route.params.view"
                                            :answers="dataBody.answers"
                                            :defaultSelected="dataBody.selected"
                                            ref="RefCheckSelectorBox"
                                            @input="checkSelectorBox"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="width: 100%;margin-top: 20px;">
                                        <GhCEInputRange
                                            :datainput="{
                                                id: 'answer',
                                                name: 'answer[answer]',
                                                value: dataBody.selected,
                                                min: dataBody.min,
                                                max: dataBody.max,
                                            }"
                                            :disabled="dataBody.evaluator || dataBody.disabled || $root._route.params.view"
                                            ref="RefSelectorRange"
                                            @input="checkSelectorRange"
                                        />
                                    </div>
                                </template>

                                <template v-if="!dataBody.evaluator">
                                    <template v-if="dataBody.actions.have_legend">
                                        <slot name="answerLegend"/>
                                    </template>
                                </template>
                            </template>
                            <template v-if="dataBody.type === 3 || dataBody.type === 4">
                                <div v-for="(answers, keyAnswers) in dataBody.answers" :key="'ANSWER' + keyAnswers" class="input-contorn-selector" style="margin-bottom: 10px;" @click="checkInput(keyAnswers)" :style="selecteds.includes(keyAnswers) ? 'border: 1px solid #1B82C5;' : ''">
                                    <GhChecks
                                        :datainput="{
                                            id: 'radio_and',
                                            value: keyAnswers,
                                            type: dataBody.type === 3 ? 'radio' : 'checkbox',
                                            name: dataBody.type === 3 ? 'answer[answer]' : 'answer[answer][]',
                                            selected: selecteds.includes(keyAnswers),
                                            disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                        }"
                                        :datalabel="{class: '', id: '', text: ''}"
                                        :ref="'RefCheck' + keyAnswers"
                                        @click="checkInput(keyAnswers)"
                                    />
                                    <label class="gh_text_field-label T-text" style="margin-left: 10px; cursor: pointer;">{{answers}}</label>
                                </div>
                            </template>
                            <template v-if="dataBody.type === 6">
                                <div style="padding-bottom: 10px;">
                                    <div class="double-bootom-border" style="display: flex;">
                                        <div style="width: 100%">
                                            {{'‎'}}
                                        </div>
                                        <template v-if="dataBody.type_answer !== 3 && dataBody.type_answer !== 4">
                                            <div style="width: 80px; text-align: center;">
                                                <label class="T-subtitle-second">{{dataBody.type_answer === 1 ? $t('yes') : $t('true')}}</label>
                                            </div>
                                            <div style="width: 80px; text-align: center;">
                                                <label class="T-subtitle-second">{{dataBody.type_answer === 1 ? $t('no') : $t('false')}}</label>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-for="(answers, keyAnswers) in dataBody.questions" :key="'ANSWER' + keyAnswers">
                                        <div class="gh-row-content" style="border-bottom: 1px solid #AFAFAF; padding-bottom: 4px; padding-top: 8px; width: 100%;">
                                            <div>
                                                <div style="width: 100%">
                                                    <label class="T-text">{{answers.name}}</label>
                                                </div>
                                                <template v-if="dataBody.type_answer !== 4">
                                                    <div class="centered" :class="dataBody.type_answer === 3 ? 'radio-check-ok' : 'radio-check-new-style'" style="width: 80px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_one_' + answers.id,
                                                                value: 1,
                                                                type: 'radio',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 1,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireOne' + answers.id"
                                                            @click="checkInputQuestionnaire(answers.id)"
                                                        />
                                                    </div>
                                                    <div class="centered" :class="dataBody.type_answer === 3 ? 'radio-check-ko' : 'radio-check-new-style'" style="width: 80px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_two_' + answers.id,
                                                                value: 2,
                                                                type: 'radio',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 2,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireTwo' + answers.id"
                                                            @click="checkInputQuestionnaire(answers.id)"
                                                        />
                                                    </div>
                                                </template>
                                                <template v-else-if="dataBody.type_answer === 4">
                                                    <div class="radio-check-new-style" style="width: 25px;">
                                                        <GhChecks
                                                            :datainput="{
                                                                id: 'radio_and',
                                                                value: 1,
                                                                type: 'checkbox',
                                                                name: 'answer[answer][' + answers.id + ']',
                                                                selected: selecteds['ID' + answers.id] === 1,
                                                                disabled: dataBody.evaluator || dataBody.disabled || $root._route.params.view
                                                            }"
                                                            :datalabel="{class: '', id: '', text: ''}"
                                                            :ref="'RefCheckQuestionnaireOnly' + answers.id"
                                                            @click="checkInputQuestionnaire( answers.id)"
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="dataBody.evidence && !dataBody.evaluator">
                                <div class="container-evidences">
                                    <label class="T-text" style="color: black; margin-right: 5px; line-height: 2;" v-html="dataBody.before_download"></label>
                                    <a :href="dataBody.template" v-if="dataBody.before_download !== undefined && dataBody.before_download !== ''">
                                        <GhButton
                                            class="button-questionnaire-secondary"
                                            :datainput="{
                                                id: 'btn_download_evidence',
                                                text: $t('download'),
                                                style: 'display: unset !important'
                                            }"
                                        />
                                    </a>
                                    <label class="gh_text_field-label T-text" style="color: black; line-height: 2;" v-html="dataBody.after_download"></label>
                                    <GhCEMultiUpload
                                        :block="{
                                            id: 'evidence',
                                            text: 'upload_document',
                                            class: 'T19 container-md_button_content button-questionnaire-primary'
                                        }"
                                        :datainput="{
                                            dispatch: block.evidence.dispatch,
                                            accept: block.evidence.accept,
                                            name: 'answer[evidence]',
                                            remove: block.evidence.remove,
                                            removeText: block.evidence.removeText,
                                            removeSvg: block.evidence.removeSvg
                                        }"
                                        :disabled="dataBody.disabled || $root._route.params.view"
                                        :dataFiles="dataBody.files"
                                        ref="RefEvidence"
                                        @evidenceLoad="checkEvidenceLoad"
                                    ></GhCEMultiUpload>
                                </div>

                            </template>
                        </div>
                    </form>

                    <div class="centered" :style="dataBody.evidence && !dataBody.evaluator ? 'margin-top: 10px' : ''" v-if="!dataBody.evaluator && !dataBody.disabled && !$root._route.params.view">
                        <GhButton
                            style="margin-bottom: 20px; margin-top: -10px;"
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_save_questionnaire',
                                text: $t('save')
                            }"
                            :title="disabledSave ? $t('to_save_need_action') : $t('save')"
                            :disabled="disabledSave"
                            @click="$emit('setSave', dataBody.id, dataBody.is_autoevaluation, dataBody.is_proposed)"
                        />
                        <GhButton
                            style="margin-bottom: 20px; margin-top: -10px;"
                            :datainput="{
                                id: 'button_download_content_',
                                text: $t('next'),
                                class: 'T19 container-md_button_content button-questionnaire-primary',
                            }"
                            :title="disabledFinish ? $t('to_finish_need_all_requireds') : $t('next')"
                            :disabled="disabledFinish"
                            @click="$emit('setFinish', dataBody.id, dataBody.is_autoevaluation, dataBody.is_proposed)"
                        />
                    </div>
                </div>
            </template>
            <template v-if="dataMaterials.length !== 0">
                <div class="question-body" :key="'MATERIALS' + refreshContent" :style="dataBody.is_structural ? 'margin-top:40px;' : ''">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.material" alt="">
                        </div>
                        <div style="margin-left: 10px; margin-top: 2px;">{{$t('support_material')}}</div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <div class="gh-answer-content gh-answer-content2">
                        <div v-for="keyRowMaterial in Math.ceil(dataMaterials.length / 4)" class="row-material" :key="'MATERIAL_ROW' + keyRowMaterial">
                            <template v-for="columnIndex in 4">
                                <div
                                    :key="columnIndex"
                                    class="column-material"
                                    v-if="dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)] !== undefined"
                                    @click="$emit('getMaterial', dataBody.id, dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].slug)"
                                >
                                    <div class="gh-row-content T-subtitle-second">
                                        {{ dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].name }}
                                    </div>
                                    <div class="gh-row-content T-text">
                                        {{ dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)].description }}
                                    </div>
                                </div>
                                <div :key="columnIndex" v-if="dataMaterials[(keyRowMaterial - 1) * 4 + (columnIndex - 1)] === undefined"></div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="dataBody.evidence && dataBody.evaluator">
                <div class="question-body" :key="'EVIDENCES' + refreshContent">
                    <div class="gh-statement-content T-subtitle">
                        <div style="width: 20px; margin-top: 3px;">
                            <img width="20" height="20" :src="block.svg.material" alt="">
                        </div>
                        <div style="margin-left: 10px; margin-top: 2px;">{{$t('evidence')}}</div>
                    </div>
                    <div class="separator-line" style="margin-top: unset;"></div>
                    <div class="gh-answer-content gh-answer-content2" style="display: flex;">
                        <div class="card-container-evidence" style="width: 100%">
                            <div v-for="(answers, keyAnswers) in dataBody.files" class="row-material" :key="'EVIDENCE_ROW' + keyAnswers" style="display: grid">
                                <div style="display: flex">
                                    <div class="ellipsis" style="width: 40%; padding-right: 10px;">
                                        <label class="gh_text_field-label T-subtitle-second ellipsis">{{answers.file_name}}</label>
                                    </div>
                                    <div style="width: 60%">
                                        <GhAction
                                            class="color-light-blue T-subtitle-second underline-light-blue"
                                            :dataction="{
                                                id: 'action_download_file_' + keyAnswers,
                                                text: $t('download_file'),
                                                icon: block.svg.download,
                                                href: answers.file_download
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import GhCECard from "@/components/GhCECard.vue";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhCECheckSelectBox from "@/components/GhCECheckSelectBox.vue";
    import GhCEInputRange from "@/components/GhCEInputRange.vue";
    import GhCEMultiUpload from "@/components/GhCEMultiUpload.vue";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'gh_ce_questionnaire_constructor',
        props: ['dataIndex', 'dataBody', 'dataMaterials', 'nextQuestionnary', 'block'],
        components: {
            GhAction,
            GhCECard,
            GhTextarea,
            GhChecks,
            GhCECheckSelectBox,
            GhCEInputRange,
            GhCEMultiUpload,
            GhButton
        },
        data(){
            return {
                defaultIndex: this.dataIndex,
                defaultBody: this.dataBody,
                tabs : [],
                index: [],
                showIndex: false,
                showMap: false,
                current: {
                    item: [],
                    key: null
                },
                selecteds: [],
                afterChanges: [],
                refreshContent: 0,
                refreshIndex: 0,
                refreshMap: 0,
                titleBreadCrum: '',
                disabledSave: true,
                disabledFinish: true,
                activePopup: false
            }
        },
        mounted(){
            this.tabs.push({
                id: this.defaultIndex.parent.id,
                name: this.defaultIndex.parent.name
            });

            this.titleBreadCrum = this.defaultIndex.parent.name;

            document.addEventListener('click', this.handleClickOutside);
            document.addEventListener('click', this.handleClickOutsideMap);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
            document.removeEventListener('click', this.handleClickOutsideMap);
        },
        watch: {
            'dataBody'(data){
                if(data.type !== null && data.type !== undefined) {
                    let temp_selected = '';
                    if(data.type === 1){
                        temp_selected = data.answer;
                        this.disabledSave = data.answer === '';
                    }

                    if(data.type === 2){
                        temp_selected = data.selected;
                        this.disabledSave = data.selected === '';
                    }

                    if(data.type === 3 || data.type === 4) {
                        this.selecteds = data.selecteds;
                        temp_selected = data.selecteds;
                        this.disabledSave = this.selecteds.length === 0;
                    }

                    if(data.type === 5){
                        this.disabledSave = false;
                    }

                    if(data.type === 6){
                        let selecteds = [];

                        Object.entries(data.selecteds).forEach((i) => {
                            selecteds['ID' + i[0]] = i[1];
                        });

                        this.afterChanges = selecteds;
                        this.selecteds = selecteds;
                        temp_selected = selecteds;
                        this.disabledSave = Object.entries(this.selecteds).length === 0;
                    }

                    if(data.evidence_required){
                        if(temp_selected.length === 0){
                            this.disabledFinish = true
                        }else {
                            this.disabledFinish = data.files.length === 0;
                        }
                    }else{
                        this.disabledFinish = this.disabledSave;
                    }

                    this.refreshContent++;
                }
            },
            'dataIndex'(data){
                this.defaultIndex = data;
                this.index = this.defaultIndex.childrens;

                if(this.index !== undefined) {
                    if (this.nextQuestionnary.length !== 0) {
                        this.index.forEach((i) => {
                            if (i.id === this.nextQuestionnary.id) {
                                this.current.item = i;
                            }
                        });

                        const currentItem = this.index.find(item => item.id === this.current.item.id);

                        if (currentItem) {
                            this.currentItem(currentItem);
                        }
                    }

                    const index = this.index.findIndex(item => item.id === this.current.item.id);

                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                        this.$set(this.index[index], 'current', true);
                        this.current.key = index;
                    }

                    if (this.nextQuestionnary.length !== 0) {
                        this.$emit('watcherFinish', this.current.item);
                        this.$emit('resetNextQuestionnaire');
                        this.refreshContent++;
                    }
                }

                this.refreshIndex++;
            }
        },
        methods: {
            openParent(){
                this.index.forEach((i, k) => {
                    this.$set(this.index[k], 'show', false);
                    this.$set(this.index[k], 'active', false);
                    this.$set(this.index[k], 'current', false);
                });

                this.current = {
                    item: [],
                    key: null
                };

                this.$emit('checkParent');

                this.closeBreadCrumbIndex();
            },
            openBreadCrumbIndex(){
                this.index = this.defaultIndex.childrens;

                if (this.current.key !== null) {
                    const currentItem = this.index.find(item => item.id === this.current.item.id);

                    if (currentItem) {
                        this.currentItem(currentItem);

                        this.$nextTick(() => {
                            this.showIndex = true;
                        });
                    }
                } else {
                    this.showIndex = true;
                }
            },
            closeBreadCrumbIndex(){
                this.showIndex = false;
            },
            closeMap(){
                this.showMap = false;
            },
            currentItem(currentItem){
                const parentIds = this.getAllParentIds(currentItem);
                const relatedIds = [...parentIds, currentItem.id];

                relatedIds.forEach(id => {
                    const index = this.index.findIndex(item => item.id === id);
                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                    }
                });

                parentIds.forEach(parentId => {
                    const children = this.findAllChildren(parentId);
                    children.forEach(childId => {
                        const childIndex = this.index.findIndex(item => item.id === childId);
                        if (childIndex !== -1) {
                            this.$set(this.index[childIndex], 'show', true);
                        }
                    });
                });

                this.index.forEach((item, index) => {
                    if (!relatedIds.includes(item.id) && !parentIds.includes(item.parent_id)) {
                        this.$set(this.index[index], 'show', false);
                        this.$set(this.index[index], 'active', false);
                    }
                });

                if(this.current.key === null){
                    this.defaultIndex = this.$props.dataIndex;
                    this.index = this.defaultIndex.childrens;

                    const index = this.index.findIndex(item => item.id === this.current.item.id);

                    if (index !== -1) {
                        this.$set(this.index[index], 'show', true);
                        this.$set(this.index[index], 'active', true);
                        this.$set(this.index[index], 'current', true);
                        this.current.key = index;
                    }
                }

                this.$set(this.index[this.current.key], 'active', false);
            },
            handleClickOutside(event) {
                if(event.srcElement._prevClass !== 'bread-crumb color-light-blue T-header') {
                    const indexContainer = this.$refs.indexContainer;
                    if (indexContainer && !indexContainer.contains(event.target)) {
                        this.showIndex = false;
                    }
                }
            },
            handleClickOutsideMap(event) {
                if(event.srcElement.closest('.gh-padding-index-questionnaire') === null && event.srcElement.parentNode !== null){
                    this.activePopup = false;
                }

                if(event.srcElement.closest('#popup_evaluate') !== null || event.srcElement.closest('#popup_advice') !== null){
                    this.activePopup = true;
                }

                if(!this.activePopup && event.srcElement.parentNode !== null) {
                    this.activePopup = event.srcElement._prevClass === 'T15_action' || event.srcElement.parentNode.id.includes('list_chart_global_map_');
                }

                if(!this.activePopup && event.srcElement.parentNode !== null) {
                    const mapContainer = this.$refs.mapContainer;
                    if (mapContainer && !mapContainer.contains(event.target)) {
                        this.showMap = false;
                    }
                }
            },
            showOrHideIndex(currentItem, currentIndex) {
                const currentLevel = currentItem.level;
                const isActive = !this.index[currentIndex].active;

                this.$set(this.index[currentIndex], 'active', isActive);

                if (currentLevel === 0) {
                    this.index.forEach((item, index) => {
                        if (item.level === 1 && item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                        }
                    });

                    if (!isActive) {
                        this.hideAllDescendants(currentItem.id);
                    }
                } else if (currentLevel !== 0) {
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentItem.id) {
                            this.$set(this.index[index], 'show', isActive);
                            if (!isActive) {
                                this.hideAllDescendants(item.id);
                            }
                        }
                    });
                }
            },
            hideAllDescendants(parentId) {
                const stack = [parentId];
                while (stack.length > 0) {
                    const currentId = stack.pop();
                    this.index.forEach((item, index) => {
                        if (item.parent_id === currentId) {
                            this.$set(this.index[index], 'show', false);
                            this.$set(this.index[index], 'active', false);
                            stack.push(item.id);
                        }
                    });
                }
            },
            checkQuestion(currentItem){
                this.index.forEach((item, index) => {
                    let isCurrent = item.id === currentItem.id;

                    this.$set(this.index[index], 'current', isCurrent);

                    if(isCurrent){
                        this.current.item = this.index[index];
                        this.current.key = index;
                    }
                });

                this.generateBreadCrum(currentItem);

                let than = this;
                setTimeout(function(){
                    than.showIndex = false;
                },100);
            },
            eliminarEtiquetasHTML(cadena) {
                const elemento = document.createElement('div');
                elemento.innerHTML = cadena;
                const textoLimpio = elemento.textContent || elemento.innerText || '';
                elemento.remove();
                return textoLimpio;
            },
            resetBreadCrum(){
                this.tabs = [];
                this.tabs.push({
                    id: this.defaultIndex.parent.id,
                    name: this.defaultIndex.parent.name
                });
            },
            generateBreadCrum(item) {
                this.resetBreadCrum();

                var bread_crum = [];
                let current = item;
                if(current.grouper){
                    bread_crum.push({
                        id: item.id,
                        name: this.eliminarEtiquetasHTML(item.name)
                    });
                }

                while (current.level !== 0) {
                    const parent = this.index.find(parentItem => parentItem.id === current.parent_id);
                    if (parent) {
                        if(parent.grouper){
                            bread_crum.push({
                                id: parent.id,
                                name: this.eliminarEtiquetasHTML(parent.name)
                            });
                        }
                        current = parent;
                    } else {
                        break;
                    }
                }

                if(bread_crum.length !== 0){
                    bread_crum = bread_crum.reverse();

                    bread_crum.forEach((e) => {
                        this.tabs.push(e);
                    });
                }
                this.generateTitleBreadCrum(bread_crum);
            },
            resetTitleBreadCrum(){
                this.titleBreadCrum = this.defaultIndex.parent.name;
            },
            generateTitleBreadCrum(toTitle){
                this.resetTitleBreadCrum();

                toTitle.forEach((e) => {
                    this.titleBreadCrum += ' > ' + e.name;
                });
            },
            cardUpdateBreadCrum(item){
                if(item.id !== null){
                    if(this.index.length === 0) this.index = this.defaultIndex.childrens;
                    var actual = this.index.find(e => e.id === item.id);
                    item['grouper'] = actual['grouper'];
                    item['level'] = actual['level'];
                    item['parent_id'] = actual['parent_id'];
                    this.generateBreadCrum(item);
                }
            },
            getAllParentIds(item) {
                let parentIds = [];
                let current = item;
                while (current.level !== 0) {
                    const parent = this.index.find(parentItem => parentItem.id === current.parent_id);
                    if (parent) {
                        parentIds.unshift(parent.id);
                        current = parent;
                    } else {
                        break;
                    }
                }
                return parentIds;
            },
            findAllChildren(parentId) {
                return this.index.filter(item => item.parent_id === parentId).map(item => item.id);
            },
            checkInput(value){
                if(!this.dataBody.evaluator && !this.dataBody.disabled && !this.$root._route.params.view) {
                    this.$refs['RefCheck' + value][0].checkUncheck();

                    let selecteds = [];

                    Object.keys(this.dataBody.answers).forEach((i) => {
                        if (this.$refs['RefCheck' + i][0].selected) {
                            selecteds.push(i);
                        }
                    });

                    this.selecteds = selecteds;

                    this.disabledSave = this.selecteds.length === 0;
                    this.checkEvidenceLoad();
                }
            },
            checkInputQuestionnaire(id){
                let selecteds = [];

                let disabledFinish = false;

                Object.entries(this.dataBody.questions).forEach((i) => {
                    let selected = '';

                    if(this.dataBody.type_answer === 4){
                        selected = this.$refs['RefCheckQuestionnaireOnly' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireOnly' + id][0].datainput.value : 0;
                    }else{
                        selected = this.$refs['RefCheckQuestionnaireOne' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireOne' + id][0].datainput.value : this.$refs['RefCheckQuestionnaireTwo' + i[1].id][0].selected ? this.$refs['RefCheckQuestionnaireTwo' + id][0].datainput.value : 0
                    }

                    if(selected === 0) {
                        disabledFinish = true
                    }

                    selecteds['ID' + i[0]] = selected;
                });

                this.selecteds = selecteds;

                this.disabledSave = Object.entries(this.selecteds).length === 0;
                if(disabledFinish){
                    this.disabledFinish = true;
                }else {
                    this.checkEvidenceLoad();
                }
            },
            setTextarea(){
                this.disabledSave = this.$refs['RefTextarea'].$el.lastChild._value === '';
                this.checkEvidenceLoad();
            },
            checkSelectorBox(selected){
                this.disabledSave = selected === '';
                this.checkEvidenceLoad();
            },
            checkSelectorRange(selected){
                this.disabledSave = selected === '';
                this.checkEvidenceLoad();
            },
            checkEvidenceLoad(){
                if(this.dataBody.evidence_required){
                    if(this.disabledSave) {
                        this.disabledFinish = true;
                    }else {
                        this.disabledFinish = this.disabledSave === false && this.$refs['RefEvidence'].files.length === 0
                    }
                }else{
                    this.disabledFinish = this.disabledSave;
                }
            }
        }
    }
</script>

<style>
    .color-light-blue {
        color: #1B82C5;
    }

    .gh_position-line {
        width: 100%;
        height: 56px;
        background-color: #FFFFFF;
    }

    .gh_position-index {
        width: 45%;
        min-width: 500px;
        height: auto;
        max-height: 80vh;
        background-color: #FFFFFF;
        z-index: 999;
        position: absolute;
        margin-top: 12px;
        margin-left: -80px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 4px 4px 8px #1b82c570, -4px 4px 8px #1b82c570;
    }

    .gh_position-index::before {
        content: '';
        position: absolute;
        top: 0;
        margin-top: -6px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: #FFFFFF;
        z-index: 1000; /* Ensure it is above the original box-shadow */
    }

    .gh_position-map {
        display: flex;
        right: 0;
        margin-top: -35px;
        width: 80%;
        min-width: 800px;
        margin-right: 60px;
        height: auto;
        max-height: 80vh;
        background-color: #FFFFFF;
        z-index: 999;
        position: absolute;
        border-radius: 8px;
        box-shadow: 4px 4px 8px #1b82c570, -4px 4px 8px #1b82c570;
    }

    .margin-index-questionnaire {
        margin-left: 70px;
        margin-right: 70px;
        margin-bottom: 30px;
    }

    .gh_position-full {
        width: 100%;
        height: 100vh;
        background-color: #FFFFFF;
    }

    .gh-content-global-questionnaire {
        padding: 30px 80px;
        min-width: 944px;
    }

    .gh_margin-arrow, .gh_margin-spaces {
        padding-left: 5px; padding-right: 5px;
    }

    .gh-content-questionnaire {
        padding: 13px 15px;
    }

    .gh-row-content-without-padding {
        display: flex;
    }

    .gh-padding-index-questionnaire {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .gh-pointer {
        margin-right: 10px;
        font-size: 16px;
        margin-left: 3px;
    }

    .bread-crumb {
        display: flex;
        cursor: pointer;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .F18 {
        font-family: IBMPlexSans-Bold !important;
        font-size: 18px !important;
        font-weight: normal !important;
        color: #1B82C5;
    }

    .F15 {
        font-family: IBMPlexSans-Bold !important;
        font-size: 15px !important;
        font-weight: normal !important;
        color: #1B82C5;
    }

    .C12 {
        font-family: IBMPlexSans-Regular !important;
        font-size: 12px !important;
        font-weight: normal !important;
        color: #808080;
    }

    .flex-direction-reverse {
        display: flex;
        flex-direction: row-reverse;
    }

    .flex-direction-reverse > .table-cell_link {
        color: #1B82C5 !important;
    }

    .separator-line {
        height: 1px;
        background-color: #C7C7C7;
        margin-top: 10px;
    }

    .card-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);;
        gap: 30px;
    }

    .card-container-evidence {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
    }

    .question-body {
        background-color: #FFFFFF;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 40px;
    }

    .gh-statement-content {
        padding: 10px;
        display: flex;
    }

    .gh-answer-content {
        padding: 20px;
        display: block;
    }

    .input-contorn-selector {
        cursor: pointer;
        display: flex;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
        padding: 5px 10px;
        padding-bottom: 7px;
    }

    .input-contorn-selector > div > div > .checkmark-radio > .inner_radio-checked {
        background-color: #1B82C5 !important;
    }

    .input-contorn-selector > div > div > .checkmark_checked > .inner-checked {
        background-color: #1B82C5 !important;
    }

    .input-contorn-selector > div > div > .checkmark_checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark-radio > .inner_radio-checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark_checked > .inner-checked {
        background-color: #1B82C5 !important;
    }

    .radio-check-new-style > div > div > .checkmark_checked {
        background-color: #1B82C5 !important;
    }

    .row-material {
        display: flex;
    }

    .column-material {
        cursor: pointer;
        flex: 1;
        box-sizing: border-box;
        padding: 10px;
    }

    .column-material:hover {
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .double-bootom-border {
        border-bottom: 2px solid #AFAFAF;
    }

    .container-evidences {
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #AFAFAF;
        border-radius: 4px;
    }

    #form_result_questionnaire > .gh-answer-content > div > textarea.T17 {
        font-family: IBMPlexSans-Regular;
        font-size: 13px;
        line-height: 16px;
        color: black;
    }

    #form_result_questionnaire > .gh-answer-content > div > textarea {
        height: 230px;
    }

    .gh-answer-content2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
    }

    .row-material {
        display: contents;
    }

    .overflow-index {
        height: auto;
        max-height: 77vh;
        overflow-y: auto;
        padding: 10px;
    }

    .overflow-index::-webkit-scrollbar {
        width: 8px;
    }

    .overflow-index::-webkit-scrollbar-track {
        background: rgba(175, 175, 175, 0.2);
    }

    .overflow-index::-webkit-scrollbar-thumb {
        background-color: #1B82C5;
        border-radius: 4px;
    }

    @-moz-document url-prefix() {
        .overflow-index {
            scrollbar-width: thin;
            scrollbar-color: #1B82C5 rgba(175, 175, 175, 0.2);
            border-radius: 4px;
        }
    }

    @media screen and (max-width: 1450px){
        .card-container-evidence {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }
    }

    @media screen and (max-width: 1150px){
        .card-container-evidence {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
    }
</style>