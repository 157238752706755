var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('input',{attrs:{"type":"hidden","name":"search_supports[type]"},domProps:{"value":_vm.$root._route.params.idblock}}),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('name_title'),
                class: 'T15_b',
                style: 'margin-left: 35px;'
            },"datainput":{
                id: 'search_supports_name_title',
                name:'search_supports[name_title]',
                style: 'width: 673px;',
                type: 'text'
            }}})],1),_c('div',{staticClass:"gh-row-content references"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                type: 'checkbox',
                id: 'search_supports_topic',
                label: _vm.$t('topic'),
                title: _vm.$t('topic'),
                text: _vm.$t('select_topic') + ':',
                name: 'search_supports[topic][]',
                id_value: 'value',
                name_label: 'text',
                child_key: 'children',
                required: true,
                checkparentdata: true,
                style: '',
                label_style: 'margin-left: 35px;',
                popupStyle: 'min-width: 900px; width: 55%;'
            },"selected_input":[],"items":_vm.getFeeds.feed_topic}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }