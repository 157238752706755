var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'holidays_table',
        },"header":_vm.header,"data":_vm.ConfigHolidays.records_data.length == 0 ? [] : _vm.ConfigHolidays.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'holidays_view', params: {id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13",attrs:{"href":itemProp[labelProp]}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'holidays_id', params: { id: itemProp.id, view: false}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_conf_supp_holiday',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }}})],1),(itemProp.delete)?_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_conf_supp_holiday',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Holiday', itemProp.id,{'assignment_group': itemProp.assignment_group,'holidays': itemProp.working_days}, _vm.$t('delete_holiday_by_group'), _vm.$t('preparing_delete_holiday_by_group') + ':', 'delete')}}}):_vm._e()]}}],null,false,1249449332)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }