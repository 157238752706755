<template>
    <div class="gh-content-global">
        <div class="gh-row-content" style="white-space: pre-wrap;">
            {{getFeeds.data_treatment_text.text}}
        </div>
        <div class="gh-row-content">
            <GhChecks
                :datalabel="{text:$t('politic_privacity'),style:'',class:'',id:''}"
                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][politic_privacity]'}"
            />
        </div>
        <div class="gh-row-content" style="white-space: pre-wrap;">
            {{getFeeds.data_treatment_text.text_confirmation}}
        </div>
        <div class="gh-row-content">
            <GhChecks
                :datalabel="{text: $t('legal_advise'), style:'', class:'', id:''}"
                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'request_collaboration[data_treatment][legal_advise]'}"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";
    import {mapState} from "vuex";

    export default {
        name: 'data_treatment',
        components: {
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds']),
        }
    }
</script>