import ContractedPrograms from "@/view/itinerary/client/contracted_programs/list_contracted_programs.vue";

const route = [
    {
        path: '/programs/contracted',
        name: 'contracted_programs',
        component: ContractedPrograms,
        props: {id: null, showMenu: true, otherTitle: 'contracted_programs'},
    },
];

const routes = [
    ...route,
]

export default routes;