import Vue from 'vue'
import vuex from 'vuex';
Vue.use(vuex);
import router from "../routes/router";
import routes from "@/routes/router";
//POPUP FUNCTIOS

function openPopUp(id){
    document.getElementById(id).style.display = '';
    document.getElementsByTagName("html")[0].style.overflow = 'hidden';
}
function openSecondWindow(name,payload){
    var that = this;
    window.UpdatedSelect = function (e, extra) {
        if(extra !== undefined){
            e = e + '-' + extra;
        }

        that.state.temp_response.response = e;
    };

    let routeData = payload != undefined ? router.resolve({name: name, params: payload}) : router.resolve({name: name });
    return routeData;
}
function windowClose(){
    window.close();
}
function windowOpen(name,target, disabled){
    var dualScreenLeft= window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop= window.screenTop != undefined ? window.screenTop : screen.top;
    var width= window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height= window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    var left = ((width / 2) - (1280 / 2)) + dualScreenLeft;
    var top = ((height / 2) - (800 / 2)) + dualScreenTop;
    var extra_css= top + ', left= ' + left;
    extra_css += ', resisable=no';
    var style = ['width=' + 1280, 'height=' + 800, 'resizable=yes', 'scrollbars=yes', 'status=yes'].join(',');

    if (typeof extra_css != 'undefined') {
        style += ',top=' + extra_css;
    }

    if(disabled) {
        this.state.window_open = true;
        this.state.window = window.open(name.href, target, style);
    }else{
        window.open(name.href, target, style);
    }
}

function openNewIncidence(id){
   routes.push({name:'incidence_new',params:{id:id,add:true}})
}

function warningInfo(bodyValues){
    this.state.alert_required.requireds = bodyValues;
    this.state.alert_required.refresh_key++;
    openPopUp('warning_confirm');
}

function closePopUp(id) {
    if(document.getElementById(id) !== null && document.getElementById(id) !== undefined) {
        document.getElementById(id).style.display = 'none';
        document.getElementsByTagName("html")[0].style.overflow = 'auto';
    }
}

function deleteConfirm(id, where, id_delete, item, header, subheader, type){
    document.getElementById(id).children[0].children[0].children[0].children[1].innerHTML = header;

    var arrayItem = new Array();
    Object.entries(item).forEach((i) => {
        var params = {
            'header': i[0],
            'data':i[1]
        };
        arrayItem.push(params);
    });

    this.state.state_confirm.subheader_confirm = subheader;
    this.state.state_confirm.item_confirm = arrayItem;
    this.state.state_confirm.type_confirm = type;
    this.state.state_confirm.where_confirm = where;
    this.state.state_confirm.id_delete_confirm = id_delete;
    this.state.state_confirm.item = item;

    openPopUp(id);
}


//FORM FUNCTIONS
function checkRequired(form, incorrectFormat) {
    var isIncorrect = false;
    var bodyValues = new Array();
    var noRepeatLabelRequired = '';
    // document.getElementById(form).querySelectorAll()
    document.getElementById(form).querySelectorAll('.required').forEach(function(item){
        var type = item.getAttribute('type');
        var hidden = item.getAttribute('hidden');
        var input_value = '';
        var enter = false;

        if (item.offsetWidth > 0 && item.offsetHeight > 0) {
            enter = true;
            input_value = item.value;
        } else if (type == 'radio' || type == 'checkbox') {
            var name = item.getAttribute('name');
            if (item.parentElement.offsetWidth > 0 && item.parentElement.offsetHeight > 0) {
                enter = true;
                document.querySelectorAll(`[name="${name}"]`).forEach(function (radio) {
                    if (radio.checked) {
                        input_value = radio.value;
                    }
                });
            }
        }else if(type == 'hidden' || hidden == 'hidden'){
            if(item.value == '' || item.value === undefined || item.value == 0){
                if(item.closest('form').getAttribute('id') == form) {
                    input_value = '';
                    enter = true;
                }
            }
        }

        if (input_value == '' && enter) {
            var data_input_label = item.getAttribute('label_required');
            if (data_input_label == null) {
                if (item.parentElement.getAttribute('class') == 'mx-input-wrapper') {
                    data_input_label = item.parentElement.parentElement.parentElement.children[0].innerHTML;
                } else {
                    data_input_label = item.parentElement.children[0].innerHTML;
                }
            }

            if (noRepeatLabelRequired != data_input_label && data_input_label !== null) {
                bodyValues.push(data_input_label);
            }
            noRepeatLabelRequired = data_input_label;
            isIncorrect = true;
        }

    });

    if(incorrectFormat !== undefined && incorrectFormat.length !== 0){
        incorrectFormat.forEach((i) => {
            bodyValues.push(i);
        });

        isIncorrect = true;
    }

    if(isIncorrect){
        this.state.alert_required.requireds = bodyValues;
        openPopUp('warning_confirm');
    }else{
        return 'OK';
    }
}

function setRequiredValues(bodyValues){
    this.state.alert_required.requireds = bodyValues;
    openPopUp('warning_confirm');
}

function changeLanguage(){
    this.state.change_language++;
}

export default {
    state: {
        state_confirm: {
            refresh_key: 0,
            header_confirm: '',
            subheader_confirm: '',
            item_confirm: '',
            type_confirm: '',
            where_confirm: '',
            id_delete_confirm: '',
            item: '',
        },
        alert_required: {
            refresh_key: 0,
            requireds: ''
        },
        temp_response: {
            response: '',
            type: ''
        },
        change_language: 0,
        window_open: false,
        window: []
    },
    openPopUp,
    openSecondWindow,
    windowOpen,
    closePopUp,
    checkRequired,
    deleteConfirm,
    windowClose,
    warningInfo,
    changeLanguage,
    openNewIncidence,
    setRequiredValues
   // capture,
   // capture
}
