var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticStyle:{"margin-left":"15px","margin-right":"15px","margin-bottom":"10px"}},[_c('GhTreeActionBlock',{attrs:{"dataBlock":{
                id: 'tree_topics',
                actionWithoutChildren: true,
                actionWithChildren: true,
                extraLabelNumber: false,
                extraLabelText: '',
                loadStorageOpen: true
            },"data":_vm.ConfigTopics.records_data},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [_c('router-link',{attrs:{"to":{name: 'config_topics_id', params: {id: Action.Action.Action.Action.id}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_config_topics',
                            text: _vm.$t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg')
                        }}})],1),_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_config_topics',
                        text: _vm.$t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Topic', Action.Action.Action.Action.id,{'name': Action.Action.Action.Action.name}, _vm.$t('delete_topics'), _vm.$t('preparing_delete_topics'), 'delete')}}})]}}],null,false,3587987927)})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-bottom":"10px"}},[_c('router-link',{attrs:{"to":{name: 'config_topics'}}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_add_config_topics',
                    text: _vm.$t('add')
                }}})],1)],1)]):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }