<template>
    <div class="centered">
        <div class="gh-content-global" style="width: 80%; min-width: 1024px;">
            <GhQuiz ref="quiz"
                :dataBlock="{
                    id: 'quizz_test',
                    text: TabTodo.data.title,
                    icon: {
                        more: require('../../../assets/gh_new_dessign/arrowdown.svg'),
                        less: require('../../../assets/gh_new_dessign/arrowup.svg'),
                        info: require('../../../assets/gh_new_dessign/info-blue.svg')
                    },
                    name: 'quizz',
                    actualPage: TabTodo.actual_page,
                    answer_question:{
                        width: '600'
                    }
                }"
                :data="TabTodo.data"
            >
                <template v-slot:evidenceSlot="{itemProp, keyProp}">
                    <GhEasyUpload
                        :class="itemProp.readonly ? 'disabled_attach' : ''"
                        :extrablock="{
                            id: 'quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                            class: '',
                            style: 'flex-direction: row !important;',
                            name_hidden: 'quizz[attach][' + itemProp.id + ']',
                            id_hidden: 'example',
                            icon_attach: ''
                        }"
                        :datalabel="{
                            id: 'label_quizz_easy_upload_' + keyProp + '_' + itemProp.id,
                            style: ''
                        }"
                        :datainput="{
                            text: $t('attach_here_evidence'),
                            show_image: false,
                            delete: false,
                            href: 'easyUpload',
                            downloadHref: 'easyDownload',
                            file_name: itemProp.file_name,
                            file_path: itemProp.file_path,
                            file_download: itemProp.file_download,
                            required: itemProp.evidence_required,
                        }"
                    />
                </template>
                <template v-slot:extraSlot="{itemProp, keyProp}">
                    <span style="display: none">{{$parent.$parent.stateQuestion = itemProp.state}}</span>
                    <span style="display: none">{{$parent.$parent.pendingChildrens = itemProp.pending_childrens !== undefined ? itemProp.pending_childrens : false}}</span>
                    <template v-if="TabTodo.support !== undefined && TabTodo.support[itemProp.id] !== undefined && TabTodo.support[itemProp.id].title !== undefined">
                        <div class="section_line">
                            <div class="T15_b" style="padding-left:13px;">{{TabTodo.support[itemProp.id].title}}</div>
                        </div>
                        <div style="display: flex">
                            <div style="width: 50%;">
                                <div v-for="(element, keyElement) in TabTodo.support[itemProp.id].elements.slice(0, Math.ceil(TabTodo.support[itemProp.id].elements.length / 2))" :key="'E1' + keyElement + keyProp" class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="text-align: left;">{{element.title}}</label>
                                    </div>
                                    <div v-for="(material, keyMaterial) in element.materials" class="gh-row-content" style="padding-left: 15px; padding-top: 5px;" :key="'M' + keyMaterial">
                                        <template v-if="material.img !== undefined && material.img !== ''">
                                            <img :src="material.img" height="75" style="cursor: pointer; margin-right: 5px; margin-top: -2px; border-radius: 6px;" class="opimg" @click="getSupportContent(material)">
                                            <div>
                                                <div style="display: grid; align-content: center;">
                                                    <label class="gh_text_field-label T15" style="text-align: left; margin-top: -5px; margin-left: 10px;">{{material.title}}</label>
                                                    <GhAction style="padding-left: 10px;"
                                                        :dataction="{
                                                            id: 'edit_config_responsible',
                                                            text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                            icon: '',
                                                        }"
                                                        @click="getSupportContent(material)"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="text-align: left; margin-left: 0px;">{{material.title}}</label>
                                            <GhAction style="padding-left: 10px; margin-top: -2px;"
                                                :dataction="{
                                                    id: 'edit_config_responsible',
                                                    text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                    icon: '',
                                                }"
                                                @click="getSupportContent(material)"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 50%; padding-top: 12px;">
                                <div v-for="(element, keyElement) in TabTodo.support[itemProp.id].elements.slice(Math.ceil(TabTodo.support[itemProp.id].elements.length / 2))" :key="'E2' + keyElement + keyProp" class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="text-align: left;">{{element.title}}</label>
                                    </div>
                                    <div v-for="(material, keyMaterial) in element.materials" class="gh-row-content" style="padding-left: 15px; padding-top: 5px;" :key="'M' + keyMaterial">
                                        <template v-if="material.img !== undefined && material.img !== ''">
                                            <img :src="material.img" height="75" style="cursor: pointer; margin-right: 5px; margin-top: -2px; border-radius: 6px;" class="opimg" @click="getSupportContent(material)">
                                            <div>
                                                <div style="display: grid; align-content: center;">
                                                    <label class="gh_text_field-label T15" style="text-align: left; margin-top: -5px; margin-left: 10px;">{{material.title}}</label>
                                                    <GhAction style="padding-left: 10px;"
                                                        :dataction="{
                                                            id: 'edit_config_responsible',
                                                            text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                            icon: '',
                                                        }"
                                                        @click="getSupportContent(material)"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="text-align: left; margin-left: 0px;">{{material.title}}</label>
                                            <GhAction style="padding-left: 10px; margin-top: -2px;"
                                                :dataction="{
                                                    id: 'edit_config_responsible',
                                                    text: material.type === 'download' ? $t('download') : (material.type === 'link' ? $t('access_link') : $t('see')),
                                                    icon: '',
                                                }"
                                                @click="getSupportContent(material)"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </GhQuiz>
        </div>
    </div>
</template>

<script>
import GhQuiz from "fe-gh-quiz-lib";
import GhEasyUpload from "fe-gh-easy-upload-lib";
import {mapState} from "vuex";
import GhAction from "fe-gh-action-lib";

export default {
    name: "quizz_list",
    components: {
        GhAction,
        GhQuiz,
        GhEasyUpload
    },
    computed: {
        ...mapState(['TabTodo']),
    },
    methods:{
        getSupportContent(material){
            switch(material.type){
                case 'view':
                    this.$parent.$parent.video.name = material.title;
                    this.$parent.$parent.video.url = material.url;
                    this.$parent.$parent.video.refresh++
                    var that = this;
                    setTimeout(function(){
                        that.Global.openPopUp('video_quizz_popup')
                    },100);
                    break;
                default:
                    this.setTargetBlankAction(material);
                    break;
            }
        },
        setTargetBlankAction(material){
            const link = document.createElement('a');
            link.href = material.url;
            link.download = material.file_name;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
}
</script>

<style>
    .disabled_attach > div > .iupload_inputfile_anexar{
        opacity: .70;
        pointer-events: none;
    }

    .opimg{
        box-shadow: #5c5c5c 0px 0px 5px !important;
        opacity: 0.5;
        transition: opacity .6s ease-out;
        -moz-transition: opacity .6s ease-out;
        -webkit-transition: opacity .6s ease-out;
        -o-transition: opacity .6s ease-out;
    }

    .opimg:hover{
        box-shadow: #5c5c5c 0px 0px 5px !important;
        opacity: 1;
    }
</style>