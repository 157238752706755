var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{ id: 'assignment_table' },"header":_vm.header,"data":_vm.ConfigAssignment.records_data.length == 0 ? [] : _vm.ConfigAssignment.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'assignment_view', params: {id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13",attrs:{"href":itemProp[labelProp]}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'assignment_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_config_support_assignment',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }}})],1),(itemProp.delete)?_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_config_support_assignment',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Assignment', itemProp.id,{'assignment_group':itemProp.name, 'description':itemProp.description}, _vm.$t('delete_assignment'), _vm.$t('prepare_delete_assignment') + ':', 'delete')}}}):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'new_assignment'}}},[_c('GhButton',{attrs:{"datainput":{
                          id: 'btn_add_config_support_assignment',
                          text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,1752904040)}):_vm._e()],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }