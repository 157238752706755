import ConfigTheory from "@/view/base_creation/support_material/theory/template/theory.vue";

const routes = [
    {
        path: '/config/creation/theory',
        name: 'config_theory',
        component: ConfigTheory,
        props: {id: null, showMenu: true, otherTitle: 'theory'},
        children: [
            {
                path: '/config/creation/theory/:id',
                name: 'config_theory_id',
                component: ConfigTheory,
                props: {id: null, showMenu: true, otherTitle: 'theory'},
            },
            {
                path: '/config/creation/theory/:id/:view',
                name: 'config_theory_id_view',
                component: ConfigTheory,
                props: {id: null, view: true, showMenu: true, otherTitle: 'theory'},
            }
        ]
    },
    {
        path: '/config/creation/theory/:id/:view/:windowed',
        name: 'config_theory_id_view_windowed',
        component: ConfigTheory,
        props: {id: null, view: true, windowed: true, showMenu: false, background: true, otherTitle: 'theory'},
    }
];

export default routes;