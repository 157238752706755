var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(this.$store.state.SupportHistorical.searchData && this.$store.state.SupportHistorical.searchData != [] ? 'margin-top: 12px;' : '')},[_c('GhTableCommon',{attrs:{"extratable":{id:'result_client_data'},"data":this.$store.state.SupportHistorical.searchData,"header":_vm.header},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'incidence_view', params: {id:itemProp.id,view:true}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'view_support_pending_requests_groups',
                        text: _vm.$t('see'),
                        icon: require('../../../../assets/gh_new_dessign/view.svg'),
                    }}})],1),_c('GhAction',{attrs:{"dataction":{
                    id: 'view_support_pending_requests_groups',
                    text: _vm.$t('reopen'),
                    icon: '',
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Historical', itemProp.id,{'assign_to': itemProp.group_name, 'description': itemProp.description}, _vm.$t('reopen'), _vm.$t('reopen_incidence')+':', 'reopen')}}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }