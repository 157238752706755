<template>
    <GhTableCommon
        style="margin-top: 10px;"
        :extratable="{
            id: 'result_evaluators'
        }"
        :header="header"
        :data="getterEvaluatorsResults"
    >
        <template v-slot:actions="{itemProp}">
            <GhAction
                :dataction="{
                    id: 'view_evaluator',
                    text: $t('view'),
                    icon: require('../../../../assets/gh_new_dessign/view.svg')
                }"
                @click="Global.windowOpen(Global.openSecondWindow('assign_evaluator_id_view', {
                    id: itemProp.id,
                    view: true,
                    windowed: true
                }),'window_evaluator');"
            />
            <template v-if="getterPermissionEvaluator">
                <GhAction
                    :dataction="{
                        id: 'edit_evaluator',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg')
                    }"
                    @click="Global.windowOpen(Global.openSecondWindow('assign_evaluator_id', {
                        id: itemProp.id,
                        windowed: true
                    }),'window_evaluator');"
                />
            </template>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";

    import {mapGetters} from "vuex";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'result_evaluators',
        components: {
            GhAction,
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterEvaluatorsResults', 'getterPermissionEvaluator'])
        },
        data(){
            return {
                header: [
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'surnames', field: 'surnames', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        }
    }
</script>