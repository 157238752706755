var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
        id: 'result_evaluators'
    },"header":_vm.header,"data":_vm.getterEvaluatorsResults},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                id: 'view_evaluator',
                text: _vm.$t('view'),
                icon: require('../../../../assets/gh_new_dessign/view.svg')
            }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('assign_evaluator_id_view', {
                id: itemProp.id,
                view: true,
                windowed: true
            }),'window_evaluator');}}}),(_vm.getterPermissionEvaluator)?[_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_evaluator',
                    text: _vm.$t('modify'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg')
                }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('assign_evaluator_id', {
                    id: itemProp.id,
                    windowed: true
                }),'window_evaluator');}}})]:_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }