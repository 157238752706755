<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'billing_popup',
            title: $t('request_in_progress'),
            type: 'warning',
            style: 'width: 650px; min-width:650px;',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="T21" style="margin-left: 15px; margin-right: 20px;">
                {{$t('your_registration_request') + ' ' + TabBilling.data.code + ', ' + $t('has_been_sent_correctly_on') + ' ' + TabBilling.data.date}}
            </div>
            <div class="gh_delete_confirm-content" style="padding: 0px; margin-left: -10px; margin-right: -10px;">
                <div style="display: flex; margin-top: 3px; padding-top: 10px;">
                    <div class=" T15 label_span-2" style="text-align: right;">
                        {{$t('company') + ':'}}
                    </div>
                    <div class=" T18" style="padding-left: 10px;width: calc(100% / 4 * 3);">
                        {{TabBilling.data.business}}
                    </div>
                </div>
                <div style="display: flex; margin-top: 3px;">
                    <div class=" T15 label_span-2" style="text-align: right;">
                        {{$t('address') + ':'}}
                    </div>
                    <div class=" T18" style="padding-left: 10px;width: calc(100% / 4 * 3);">
                        {{TabBilling.data.adress}}
                    </div>
                </div>
                <div style="display: flex; margin-top: 3px;">
                    <div class=" T15 label_span-2" style="text-align: right; ">
                        {{$t('postal_code') + ':'}}
                    </div>
                    <div class=" T18" style="padding-left: 10px;width: calc(100% / 4 * 3);">
                        {{TabBilling.data.postal_code}}
                    </div>
                </div>
                <div style="display: flex; padding-bottom: 10px; margin-top: 3px;">
                    <div class=" T15 label_span-2" style="text-align: right;">
                        {{$t('town') + ':'}}
                    </div>
                    <div class=" T18" style="padding-left: 10px;width: calc(100% / 4 * 3);">
                        {{TabBilling.data.town}}
                    </div>
                </div>
            </div>
            <br>
            <div class="gh-row-content" style="padding-right: 35px;">
                <label class="gh_text_field-label T15_b container-element-info" style="margin-left: 35px; width: 425px; min-width: 150px; text-align: left;">{{$t('request')}}</label>
            </div>
            <template v-for="(item, index) in TabBilling.data.billing">
                <div class="gh-row-content" :key="index">
                    <label class="gh_text_field-label T15" style="margin-left: 35px; width: 425px; min-width: 150px; text-align: left;">{{item.description}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 125px; min-width: 100px; text-align: right;">{{item.amount + ' x ' + item.price + ' €'}}</label>
                </div>
            </template>
            <hr style="width: 570px; margin-left: 25px;">
            <div class="gh-row-content" style="padding-right: 35px;">
                <label class="gh_text_field-label T14 container-element-info" style="margin-left: 35px; width: 425px; min-width: 150px; text-align: left;">{{$t('total')}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 125px; min-width: 100px; text-align: right;">{{ TabBilling.data.total + ' €'}}</label>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_billing_pay_ok_popup',
                    text: $t('payment_done')
                }"
                @click="madePayment(TabBilling.data.id)"
            />
            <GhButton
                :datainput="{
                    id: 'btn_billing_close_popup',
                    text: $t('close')
                }"
                @click="Global.closePopUp('billing_popup');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from 'fe-gh-popup-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "popup_billing",
        components: {
            GhPopUp,
            GhButton
        },
        computed: {
            ...mapState(['TabBilling']),
        },
        methods: {
            async madePayment(id){
                await this.$store.dispatch('setMadePayment',{
                    id: id
                }, {root: true});

                this.Global.closePopUp('billing_popup');
            }
        }
    }
</script>