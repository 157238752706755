<template>
    <div>
        <div class="section_line" style="margin-top: 10px;">
            <div class="T15_b" style="padding-left:13px;">
                {{$t('type') + ': ' + ($root._route.params.view ? Object.values(ConfigTypeExercise.data.type[0])[1] : $parent.$parent.$refs['RefTypeExercise'].datasaved.text)}}
            </div>
        </div>
        <div class="gh-row-content" style="margin-top: 10px;">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('value_a'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: false,
                        id: 'value_type_exercise_value_1',
                        name: 'type_exercise[value_a]',
                        style: 'width: 100px',
                        value: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.value_a !== undefined ? ConfigTypeExercise.data.config_type.value_a : '',
                        type: 'int',
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_a') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.value_a !== undefined ? ConfigTypeExercise.data.config_type.value_a : ''}}</label>
            </template>
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('value_b'),
                        style: 'width: 160px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: false,
                        id: 'value_type_exercise_value_b',
                        name: 'type_exercise[value_b]',
                        style: 'width: 100px',
                        value: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.value_b !== undefined ? ConfigTypeExercise.data.config_type.value_b : '',
                        type: 'int',
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_b') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.value_b !== undefined ? ConfigTypeExercise.data.config_type.value_b : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" >
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_type_exercise_conditions',
                        class: 'T15_b',
                        subtitle: $t('select_conditions') + ':',
                        text: $t('conditions'),
                        label: $t('conditions'),
                        type: 'checkbox',
                        columns: 2,
                        style: '',
                        required: false
                    }"
                    :datainput="{
                        id: 'value_type_exercise_conditions',
                        name: 'type_exercise[conditions][]'
                    }"
                    :selected_input="ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? ConfigTypeExercise.data.config_type.conditions : []"
                    :feed="getFeeds.feed_condition"
                />
            </template>
            <template v-else>
                <div style="display: flex">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('conditions') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data && ConfigTypeExercise.data.config_type && ConfigTypeExercise.data.config_type.conditions ? Object.values(ConfigTypeExercise.data.config_type.conditions).join(', ') : ''}}</label>
                </div>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;" :style="$root._route.params.view ? '' : 'margin-top: -10px;'">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[0] === 1,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.evidence[1] === 2,
                        disabled: $root._route.params.view,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('feedback'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_feedback'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_feedback',
                    value: 1,
                    name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.feedback[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('subquestion'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_subquestion'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_subquestion',
                    value: 1,
                    name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data && ConfigTypeExercise.data.config_type !== undefined && ConfigTypeExercise.data.config_type.subquestion[0] === 1,
                    disabled: $root._route.params.view
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from 'fe-gh-checks-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    export default {
        name: "type_close_numeric",
        components: {
            GhInputTextField,
            GhChecks,
            GhDataSelector
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds'])
        },
    }
</script>

<style>
    #label_type_exercise_conditions_selector_label{
        width: 150px;
        text-align: end;
    }
</style>