var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'groups',
        },"header":_vm.header,"data":_vm.getterListGroups.length === 0 ? [] : _vm.getterListGroups},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'permission_groups_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [(itemProp.show)?[_c('router-link',{attrs:{"to":{name: 'permission_groups_id', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_group_' + itemProp['id'],
                            text: _vm.$t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }}})],1)]:_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'permission_groups'}}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_group',
                            text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,2798574662)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }