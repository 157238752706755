var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
            id: 'actions_state_' + _vm.id
        },"header":_vm.header,"data":_vm.getterListActionsByType[_vm.id] === undefined || _vm.getterListActionsByType[_vm.id].results === undefined || _vm.getterListActionsByType[_vm.id].results.length === 0 ? [] : _vm.getterListActionsByType[_vm.id].results},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [(labelProp === 'company_name' || labelProp === 'cif')?[_c('router-link',{attrs:{"to":{name: 'actions_company_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13",style:(itemProp.flag === 1 ? 'color: red; font-weight: bold;' : (itemProp.flag === 2 ? 'color: red;' : ''))},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]:[_c('span',{style:(itemProp.flag === 1 ? 'color: red; font-weight: bold;' : (itemProp.flag === 2 ? 'color: red;' : ''))},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterListActionsByType[_vm.id].actions !== undefined && _vm.getterListActionsByType[_vm.id].actions.action)?[_c('router-link',{attrs:{"to":{name: 'actions_company_id', params: {id:itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                            id: 'execute_action',
                            text: _vm.$t('action'),
                            icon: require('../../../assets/gh_new_dessign/edit.svg'),
                        }}})],1)]:_vm._e()]}}],null,false,1934969511)}):_vm._e()],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }