var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'assignation_base',
            title: _vm.$t('assignation_base')
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?[_c('form',{attrs:{"id":"form_assignation_base"}},[_c(_vm.assignation_base,{tag:"component"})],1)]:(!_vm.haveData)?[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px","padding-top":"10px"}},[_c('img',{attrs:{"src":require('../../../assets/gh_new_dessign/ajax-loader_blue.gif'),"alt":""}})])]:_vm._e()]},proxy:true}])}),(_vm.haveData && _vm.getterAssignationBase.show.list_evaluators)?_vm._l((_vm.list),function(label,labelIndex){return _c('div',{key:labelIndex},[(label.show)?_c('GhDropSelector',{attrs:{"datablock":{
                    id: 'section_evaluators_' + label.id,
                    title: _vm.$t(label['name']),
                    second_title: '',
                    num_results: '',
                }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(label.component,{tag:"component"})]},proxy:true}],null,true)}):_vm._e()],1)}):_vm._e(),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"15px","margin-bottom":"15px"}},[(!_vm.$root._route.params.view && _vm.haveData)?[(_vm.getterAssignationBase.buttons.finish)?[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_finish',
                        text: _vm.$t('finish')
                    }},on:{"click":function($event){return _vm.submit('finish')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.submit('save')}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.$router.go(-1)}}})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }