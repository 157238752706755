var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'flows_table',
        },"header":_vm.header,"data":_vm.ConfigFlows.records_data.length == 0 ? [] : _vm.ConfigFlows.records_data},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'flow_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_conf_supp_flow',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }}})],1),(itemProp.delete)?_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_conf_supp_categories',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Flow', itemProp.id,{'name': itemProp.name}, _vm.$t('delete_flow'), _vm.$t('preparing_delete_flow') + ':', 'delete')}}}):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'flow'}}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_flow',
                            text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,2020580561)})],1):(!_vm.haveData)?_c('div',[_c('div',{staticClass:"centered",staticStyle:{"padding-bottom":"10px"}},[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }